import { stringify } from 'qs';
import { raw } from "security/token";
import Logger from "services/logger";

export async function changePasswordWithToken(user: number, token: string, password: string): Promise<Response | null> {
  return fetch(`${process.env.API || ""}/api/v1/users/${user}/password`, {
    method: "PUT",
    headers: {
      "x-auth-token": raw() || "",
      "content-type": "application/x-www-form-urlencoded",
    },
    body: stringify({
      token,
      password
    }),
  }).catch((errors) => {
    Logger.error("could not perform post on 'users/reinitialize-password'", {
      user,
      token,
      password,
      errors,
    });
    return Promise.resolve(null);
  })
}