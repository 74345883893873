import { raw as rawToken } from "security/token";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function raw(url: string): Promise<Record<string, any>> {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "x-auth-token": rawToken() || "",
    },
  });
  const blob = await response.blob();
  return {
    success: response.ok,
    blob: blob,
  };
}
