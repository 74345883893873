import React, { createContext, useContext, useReducer } from "react";
import type { Context, PropsWithChildren } from "react";

import Logger from "../logger";
import ReleaseCouch from "model/release_couch";
import { SessionExtended } from "./sessionsStore";
import { showCouch } from "api/release/show";
import { showCouch as showGameCouch } from "api/game/show";
import GameCouch from "model/game_couch";

interface ReleasesAndGames {
  releases: ReleaseCouch[];
  games: GameCouch[];
}

type State = {
  releasesAndGamesByLauncher: Record<number, ReleasesAndGames>; // key is launcher id
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  releasesAndGamesByLauncher: {},
  loading: false,
  error: null,
};

const reducer = (state: State, unknownAction: unknown): State => {
  if (typeof unknownAction !== 'object' || unknownAction === null) {
    return state
  }
  const action = unknownAction as Record<string, unknown>;

  switch (action.type) {
    case "FETCHING":
      return {
        releasesAndGamesByLauncher: state.releasesAndGamesByLauncher,
        loading: true,
        error: null,
      };

    case "FETCHING_SUCCESS": {
      if (typeof action.launcher !== 'string' || !(action.releases instanceof Array) || !(action.games instanceof Array)) {
        return state;
      }
      const currentReleasesAndGames = state.releasesAndGamesByLauncher[parseInt(action.launcher, 10)]
      if (currentReleasesAndGames === undefined) {
        state.releasesAndGamesByLauncher[parseInt(action.launcher, 10)] = {
          releases: action.releases,
          games: action.games,
        };
      } else {
        currentReleasesAndGames.releases.push(...action.releases)
        currentReleasesAndGames.games.push(...action.games)
      }
      return {
        releasesAndGamesByLauncher: { ...state.releasesAndGamesByLauncher },
        loading: false,
        error: null,
      };
    }

    case "FETCHING_ERROR":
      if (typeof action.error !== 'string') {
        return {
          releasesAndGamesByLauncher: state.releasesAndGamesByLauncher,
          loading: false,
          error: "Error while fetching sessions",
        }
      }

      return {
        releasesAndGamesByLauncher: state.releasesAndGamesByLauncher,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

const ReleaseContext: Context<[State, (...args: Array<unknown>) => unknown]> =
  createContext([
    initialState,
    () => Logger.error("ReleaseContext not correctly initialized"),
  ]);

export const ReleaseConsumer = ReleaseContext.Consumer;
export const ReleaseConsumerHook = () => useContext(ReleaseContext);
export const ReleaseProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <ReleaseContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </ReleaseContext.Provider>
  );
};

export const fetchReleases = async (
  dispatch: (...args: Array<unknown>) => unknown,
  launcher: string,
  sessions: SessionExtended[],
  releasesAndGames?: ReleasesAndGames,
) => {
  // filter releases to find only the ones that are not already fetched
  const releasesToFind = sessions.map(s => s.release)
    .filter(r => r !== null && releasesAndGames?.releases.find(r2 => r2._id === r) === undefined)
    .filter((value, index, array) => array.indexOf(value) === index)

  if (releasesToFind.length === 0) {
    return;
  }

  dispatch({
    type: "FETCHING",
  });

  const newReleases: ReleaseCouch[] = [];
  const newGames: GameCouch[] = [];

  for (const releaseId of releasesToFind) {
    if (releaseId === null) {
      continue;
    }

    // get releases
    const response = await showCouch(launcher, releaseId);

    if (!response?.ok) {
      dispatch({
        type: "FETCHING_ERROR",
        error: "Error while fetching releases",
      });
      return;
    }

    const release = await response.json();

    newReleases.push(release);

    // get games
    // check if game is not already fetched
    if (
      releasesAndGames?.games.find(g => g._id === release.game) === undefined &&
      newGames.find(g => g._id === release.game) === undefined
    ) {
      const responseGame = await showGameCouch(launcher, release.game);

      if (!responseGame?.ok) {
        dispatch({
          type: "FETCHING_ERROR",
          error: "Error while fetching games",
        });
        return;
      }

      const game = await responseGame.json();

      newGames.push(game);
    }
  }

  dispatch({
    type: "FETCHING_SUCCESS",
    launcher,
    releases: newReleases,
    games: newGames,
  });
};

