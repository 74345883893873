import React, { useCallback, useState } from "react";
import moment from 'moment';
import { Trans, useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import { ModalConsumer, ModalProvider } from "stores/modalStore";
import { deleteAvailability } from "services/api/release/edit";
import Release from "model/release";
import isGranted, { Role } from "security/voter";
import { addNotification, NotificationConsumerHook } from "services/stores/notificationStore"

export default function FlagAsUnavailable({ release }: {
  release: Release;
}) {
  const { t } = useTranslation()
  const [, dispatchNotification] = NotificationConsumerHook();

  const [submitting, setSubmitting] = useState(false);
  const [validationVersion, setValidationVersion] = useState("");
  const [forceLauncherUninstallation, setForceLauncherUninstallation] = useState(false);
  const [errors, setErrors] = useState<string[] | null>(null);

  const isAdmin = isGranted(new Set([Role.Admin]))

  const submitFlagAsUnavailableForm = useCallback(async () => {
    setSubmitting(true);

    if (validationVersion != release.version) {
      setErrors([
        t("Please insert {{version}} to confirm the activation.", {
          version: release.version,
        }),
      ]);
      setSubmitting(false);
      return false;
    } else {
      setErrors([])
    }

    let options = undefined
    if (isAdmin) {
      options = {
        forceLauncherUninstallation,
      }
    }

    const response = await deleteAvailability(release.id, options)

    setSubmitting(false);

    if (
      response === null
      || response.status != 202
    ) {
      setErrors([
        t('Could not flag release as unavailable')
      ])
      return false;
    }

    return true;
  }, [validationVersion, forceLauncherUninstallation, setErrors, setSubmitting]);

  if (release.unavailable !== null) {
    return (
      <p className="main-field main-field-release-unavailable">
        {t('Unavailable since {{date}}', {
          date: moment(release.unavailable).format("LL"),
        })}

        <i className="fas fa-trash-check" />
      </p>
    );
  }

  return (
    <ModalProvider>
      <ModalConsumer>
        {([{ opened }, dispatch]) => {
          const formId = "flagReleaseAsUnavailable";

          return (
            <>
              <button
                className="btn btn-outline-danger"
                onClick={() =>
                  dispatch({
                    type: opened ? "close" : "open",
                  })
                }
              >
                {t('Flag as unavailable')}

                <i className="fas fa-trash-plus" />
              </button>

              {opened === true && (
                <Modal
                  header={{
                    title: t("Flag as unavailable confirmation"),
                  }}
                  footer={{
                    center: false,
                    btnCloseClass: "btn-danger w-50 me-2",
                    btnCloseDisabled: submitting,
                    btnCloseLabel: t("Cancel"),
                    btnValidClass: "btn-primary w-50 ms-2",
                    btnValidDisabled: submitting,
                    btnValidLabel: t("Validate"),
                    btnValidForm: formId,
                  }}
                  onClose={() =>
                    dispatch({
                      type: "close",
                    })
                  }
                >
                  <p>
                    <Trans
                      i18nKey="You're about to flag this release as <0>unavailable</0>. This operation can't be reverted."
                      components={[<b />]}
                    />

                    <br />
                    <br />

                    <Trans
                      i18nKey="To confirm, write <0>{{version}}</0> in input field."
                      values={{
                        version: release.version,
                      }}
                      components={[<b />]}
                    />
                  </p>

                  {errors !== null &&
                    errors.map((error) => (
                      <div className="alert alert-danger">{error}</div>
                    ))}

                  <form
                    id={formId}
                    onSubmit={async (e) => {
                      e.preventDefault()
                      setSubmitting(true)

                      const success = await submitFlagAsUnavailableForm()

                      setSubmitting(false)

                      if (success) {
                        dispatch({
                          type: "close",
                        });

                        addNotification(dispatchNotification, t('success'), t('The version will be updated to become unavailable. This operation may take a few minutes.'), 'success')
                      } else {
                        addNotification(dispatchNotification, t('error'), t('Release cannot be marked as unavailable'), 'error')
                      }
                    }}
                  >
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          className="form-control"
                          type="text"
                          required
                          onChange={(e) => setValidationVersion(e.target.value)}
                        />

                        <label className="label-wize">
                          <Trans>Insert version to confirm</Trans>
                        </label>

                        <div className="liner" />
                      </div>
                    </div>

                    {isAdmin && (
                      <div className="form-group">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="force-launcher-uninstallation"
                            checked={forceLauncherUninstallation}
                            onChange={e => setForceLauncherUninstallation(e.target.checked)}
                          />

                          <label className="form-check-label" htmlFor='force-launcher-uninstallation'>
                            {t('Force launchers uninstallation')}
                          </label>
                        </div>
                      </div>
                    )}
                  </form>
                </Modal>
              )}
            </>
          );
        }}
      </ModalConsumer>
    </ModalProvider>
  );
}
