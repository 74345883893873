import { SessionCouchStatus } from "model/session_couch"
import { raw } from "security/token"
import { ContentType } from "services/helper/data/contentType"
import Logger from "services/logger"

export async function list(
  launcher: number,
  from?: string,
  to?: string,
  games?: string[],
  sessionId?: string,
  status?: SessionCouchStatus,
  limit?: number,
  offset?: number,
  type: ContentType = ContentType.JSON,
): Promise<Response | null> {
  const params = new URLSearchParams()

  if (from != null && to != null) {
    params.append("period[from]", `${from}`)
    params.append("period[to]", `${to}`)
  }

  if (games != null && games.length > 0) {
    games.forEach(g => params.append('games[]', g))
  }

  if (sessionId != null) {
    params.append('id-filter', sessionId)
  }

  if (status != null) {
    params.append('status', status)
  }

  if (limit != null) {
    params.append('limit', limit.toString(10))
  }

  if (offset != null) {
    params.append('offset', offset.toString(10))
  }

  const headers: HeadersInit = {
    "x-auth-token": raw() || "",
  }

  if (type === ContentType.XLS) {
    headers.accept = "application/vnd.ms-excel"
  }
  return fetch(
    `${process.env.API || ""}/api/v1/launchers/${launcher}/_/sessions?${params.toString()}`,
    {
      method: "GET",
      headers
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "api/v1/launchers/:id/_/sessions/:id"', {
      launcher,
      from,
      to,
      games,
      sessionId,
      status,
      type,
      errors
    });
    return Promise.resolve(null);
  })
}
