import React, { FormEvent, useState } from "react";
import { useTranslation, Trans } from 'react-i18next';

import FilesUpload from "./FilesUpload";
import { FormConsumer, FormProvider } from "services/stores/formStore";
import create from "api/support";

export default function Support() {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);

  function addFile(dispatch: unknown, attachments: unknown[], file: File | null) {
    if (file === null || dispatch instanceof Function === false) {
      return;
    }
    dispatch({
      type: "setValue",
      field: "attachments",
      value: [
        ...(attachments || []),
        {
          error: false,
          file,
          swap: null,
          uploading: false,
          progression: null,
        },
      ],
    });
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>, dispatch: unknown, ticket: Record<string, unknown>) {
    e.preventDefault();
    if (dispatch instanceof Function) {
      dispatch({
        type: "setSubmitting",
        submitting: true,
      });

      const attachments = [];

      if (ticket.attachments instanceof Array) {
        attachments.push(...ticket.attachments);
      }

      const { success } = await create({
        title: ticket.title,
        description: ticket.description,
        attachments: attachments.map(
          (attachment: Record<string, unknown>) => attachment.swap
        ),
      });

      if (success) {
        dispatch({
          type: "reset",
        });
        setSubmitted(true);
      }

      dispatch({
        type: "setSubmitting",
        submitting: false,
      });
    }
  }

  return (
    <FormProvider>
      <div className="row justify-content-center">
        <div className="col-lg-12 col-xl-11">
          <div className="row">
            <div className="col-lg-12 col-xl-6">
              <div className="support-content">
                {submitted && (
                  <div className="alert alert-success">
                    <Trans>
                      Your message has been successfully sent to our support
                    </Trans>
                  </div>
                )}

                <FormConsumer>
                  {([{ data, submitting }, dispatch]) => (
                    <form onSubmit={(e) => handleSubmit(e, dispatch, data)}>
                      <div className="form-group mb-4">
                        <div className="input-container mb-4">
                          <input
                            className="form-control"
                            placeholder={t("Title")}
                            id="support-title"
                            value={data?.title || ""}
                            onChange={(e) =>
                              dispatch({
                                type: "setValue",
                                field: "title",
                                value: e.target.value,
                              })
                            }
                            type="text"
                            required
                          />

                          <label className="label-wize">
                            <Trans>Title</Trans>
                          </label>

                          <i className="icon icon-edit"></i>
                          <div className="liner"></div>
                        </div>

                        <textarea
                          className="form-control"
                          placeholder={t("Your message...")}
                          rows={3}
                          value={data?.description || ""}
                          onChange={(e) =>
                            dispatch({
                              type: "setValue",
                              field: "description",
                              value: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="form-control mb-4">
                        <input
                          type="file"
                          className="form-control-input"
                          id="support-uploader"
                          onChange={(e) =>
                            addFile(
                              dispatch,
                              data?.attachments,
                              e.target.files ? e.target.files[0] : null
                            )
                          }
                        />

                        <label
                          className="form-control-label"
                          htmlFor="support-uploader"
                        >
                          <i className="icon icon-upload me-3" />
                          <Trans>Upload file</Trans>
                        </label>
                      </div>

                      <div className="uploaded-files mb-4 visible">
                        <FilesUpload />
                      </div>

                      <div className="button-group--right">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={submitting}
                        >
                          {(() => {
                            if (submitting) {
                              return <Trans>Sending</Trans>
                            }

                            return <Trans>Send</Trans>
                          })()}
                        </button>
                      </div>
                    </form>
                  )}
                </FormConsumer>
              </div>
            </div>

            <div className="col-lg-12 col-xl-6 mt-lg-5 mt-xl-0">
              <div className="support-informations">
                <h4 className="support-informations--title">
                  <Trans>Support Information</Trans>
                </h4>

                <p className="support-informations--text">
                  <Trans>
                    You can send a message to OctopodVR support. Be explicit and
                    detailed your issue with log/attachment/screenshot/...
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
}
