import React from "react";
import { useTranslation, Trans } from 'react-i18next';

import categories from "services/helper/data/categories";
import headsets from "services/helper/data/headsets";
import locales from "services/helper/data/locales";
import comfort from "services/helper/data/comfort";
import rooms from "services/helper/data/rooms";
import SelectList from "components/SelectList/SelectList";
import { FormConsumerHook } from "stores/formStore";
import { GameReleasesConsumerHook } from "stores/gameReleasesStore";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import isGranted, { Role } from "security/voter";

export default function GameShowInfos() {
  const { t } = useTranslation();
  const [{ game }] = GameReleasesConsumerHook();
  const [{ data, errors }, dispatch] = FormConsumerHook();
  const canUpdate = isGranted(new Set([Role.Editor])) || isGranted(new Set([Role.Admin]));

  const getErrors = (name: string): JSX.Element[] => {
    return errors
      .filter((error) => error?.name === name)
      .map(({ reason }) => (
        <small className="form-text text-danger">{reason}</small>
      ));
  };

  const capitalize = (s: unknown) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  if (game === null) {
    return <ErrorMessage message={t('Could not find game')} />;
  }

  return (
    <div className="game-container game-releases">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-10">
            <p className="single-label">
              <Trans>Description</Trans>
            </p>

            <textarea
              className="main-field small-body"
              onChange={(e) =>
                dispatch({
                  type: "setValue",
                  field: "description",
                  value: e.target.value,
                  initialValue: game?.description,
                })
              }
              disabled={!canUpdate}
              defaultValue={data?.description || game.description}
            />

            {getErrors("description")}

            <div className="row mt-5">
              <div className="col-4">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="cursor-pointer custom-control-input"
                    id="connectionRequired"
                    defaultChecked={
                      data?.internetConnectionRequired ||
                      game.internetConnectionRequired
                    }
                    onChange={(e) =>
                      dispatch({
                        type: "setValue",
                        field: "internetConnectionRequired",
                        value: e.target.checked,
                        initialValue: game.internetConnectionRequired,
                      })
                    }
                    disabled={!canUpdate}
                  />

                  <label
                    className="cursor-pointer custom-control-label"
                    htmlFor="connectionRequired"
                  >
                    <Trans>Internet connection required</Trans>
                  </label>

                  {getErrors("internetConnectionRequired")}
                </div>
              </div>
              <div className="col-4">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="cursor-pointer custom-control-input"
                    id="warehouseCheckbox"
                    defaultChecked={
                      data?.warehouse ||
                      game.warehouse ||
                      (game.spaces ?? []).filter(s => s === "warehouse").length > 0
                    }
                    onChange={(e) =>
                      dispatch({
                        type: "setValue",
                        field: "warehouse",
                        value: e.target.checked,
                        initialValue: game.warehouse,
                      })
                    }
                    disabled={!canUpdate}
                  />

                  <label
                    className="cursor-pointer custom-control-label"
                    htmlFor="warehouseCheckbox"
                  >
                    <Trans>Warehouse game</Trans>
                  </label>

                  {getErrors("warehouse")}
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-4">
                <p className="single-label">
                  <Trans>Comfort</Trans>
                </p>

                <SelectList
                  dataOptions={comfort.map((value) => ({
                    label: capitalize(value),
                    value,
                  }))}
                  multiSelect={false}
                  placeholderText={t("Select your comfort")}
                  value={(() => {
                    if (data?.comfort) {
                      return {
                        label: capitalize(data.comfort),
                        value: data.comfort,
                      };
                    }

                    return game.comfort !== null
                      ? {
                        label: capitalize(game.comfort),
                        value: game.comfort,
                      }
                      : null;
                  })()}
                  onChange={({ value }: Record<string, string | undefined | null>) =>
                    dispatch({
                      type: "setValue",
                      field: "comfort",
                      value,
                      initialValue: game.comfort,
                    })
                  }
                  isDisabled={!canUpdate}
                />

                {getErrors("comfort")}
              </div>

              <div className="col-4">
                <div className="spaces-tooltip">
                  <p className="single-label">
                    <Trans>VR Spaces</Trans>
                    {game.warehouse !== true && <i className="fas fa-info-circle ms-2" />}
                  </p>
                  {game.warehouse !== true && <p className="spaces-tooltip-content">
                    <Trans>Extracted from the latest stable release's manifest</Trans>
                  </p>}
                </div>

                <SelectList
                  dataOptions={rooms.map((value) => ({
                    label: value,
                    value,
                  }))}
                  multiSelect={true}
                  isReadonly={true}
                  placeholderText={t("Unknown spaces")}
                  value={(() => {
                    let spaces = game.spaces || [];

                    if (Array.isArray(data?.spaces)) {
                      spaces = data.spaces;
                    }

                    return spaces.map((value) => ({
                      label: value,
                      value,
                    }));
                  })()}
                  onChange={(values: unknown) =>
                    dispatch({
                      type: "setValue",
                      field: "spaces",
                      value: Array.isArray(values) ? values.map(({ value }: Record<string, unknown>) => value) : [],
                      initialValue: game.spaces,
                    })
                  }
                  isDisabled={!canUpdate}
                />

                {getErrors("spaces")}
              </div>

              <div className="col-4">
                <p className="single-label">
                  <Trans>Headset type</Trans>
                </p>

                <SelectList
                  dataOptions={headsets.map((value) => ({
                    label: value,
                    value,
                  }))}
                  multiSelect={true}
                  placeholderText={t("Add your headset compatibilities")}
                  value={(() => {
                    let headsets = game.headsets || [];

                    if (Array.isArray(data?.headsets)) {
                      headsets = data.headsets;
                    }

                    return headsets.map((value) => ({
                      label: value,
                      value,
                    }));
                  })()}
                  onChange={(values: unknown) =>
                    dispatch({
                      type: "setValue",
                      field: "headsets",
                      value: Array.isArray(values) ? values.map(({ value }: Record<string, unknown>) => value) : [],
                      initialValue: game.headsets,
                    })
                  }
                  isDisabled={!canUpdate}
                />

                {getErrors("headsets")}
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-4 d-flex flex-column justify-content-between">
                <p className="single-label">
                  <Trans>Languages</Trans>
                </p>

                <SelectList
                  dataOptions={Object.keys(locales).map((value) => ({
                    label: (locales as Record<string, string>)[value],
                    value,
                  }))}
                  multiSelect={true}
                  placeholderText={t("Languages")}
                  value={(() => {
                    let languages = game.languages || [];

                    if (Array.isArray(data?.languages)) {
                      languages = data.languages;
                    }

                    return languages.map((value) => ({
                      label: (locales as Record<string, string>)[value],
                      value,
                    }));
                  })()}
                  onChange={(values: unknown) =>
                    dispatch({
                      type: "setValue",
                      field: "languages",
                      value: Array.isArray(values) ? values.map(({ value }: Record<string, unknown>) => value) : [],
                      initialValue: game.languages,
                    })
                  }
                  isDisabled={!canUpdate}
                />

                {getErrors("languages")}
              </div>

              <div className="col-4 d-flex flex-column justify-content-between">
                <p className="single-label">
                  <Trans>Categories</Trans>
                </p>

                <SelectList
                  dataOptions={categories.map((value) => ({
                    label: value,
                    value,
                  }))}
                  multiSelect={true}
                  placeholderText={t("Categories")}
                  value={(() => {
                    let categories = game.categories || [];

                    if (Array.isArray(data?.categories)) {
                      categories = data.categories;
                    }

                    return categories.map((value) => ({
                      label: value,
                      value,
                    }));
                  })()}
                  onChange={(values: unknown) =>
                    dispatch({
                      type: "setValue",
                      field: "categories",
                      value: Array.isArray(values) ? values.map(({ value }: Record<string, unknown>) => value) : [],
                      initialValue: game.categories,
                    })
                  }
                  isDisabled={!canUpdate}
                />

                {getErrors("categories")}
              </div>

              <div className="col-4">
                <label className="single-label w-100">
                  <Trans>Minimum age</Trans>
                </label>

                {(() => {
                  const value = data?.minimumAge || game.minimumAge;
                  return (
                    <>
                      <label>
                        <input
                          type="radio"
                          name="pegi"
                          value={7}
                          checked={value === 7}
                          onChange={() =>
                            dispatch({
                              type: "setValue",
                              field: "minimumAge",
                              value: 7,
                              initialValue: game.minimumAge,
                            })
                          }
                          disabled={!canUpdate}
                        />
                        <div className="pegi pegi-7" />
                      </label>

                      <label>
                        <input
                          type="radio"
                          name="pegi"
                          value={12}
                          checked={value === 12}
                          onChange={() =>
                            dispatch({
                              type: "setValue",
                              field: "minimumAge",
                              value: 12,
                              initialValue: game.minimumAge,
                            })
                          }
                          disabled={!canUpdate}
                        />
                        <div className="pegi pegi-12" />
                      </label>

                      <label>
                        <input
                          type="radio"
                          name="pegi"
                          value={16}
                          checked={value === 16}
                          onChange={() =>
                            dispatch({
                              type: "setValue",
                              field: "minimumAge",
                              value: 16,
                              initialValue: game.minimumAge,
                            })
                          }
                          disabled={!canUpdate}
                        />
                        <div className="pegi pegi-16" />
                      </label>

                      <label>
                        <input
                          type="radio"
                          name="pegi"
                          value={18}
                          checked={value === 18}
                          onChange={() =>
                            dispatch({
                              type: "setValue",
                              field: "minimumAge",
                              value: 18,
                              initialValue: game.minimumAge,
                            })
                          }
                          disabled={!canUpdate}
                        />
                        <div className="pegi pegi-18" />
                      </label>
                    </>
                  );
                })()}

                {getErrors("minimumAge")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
