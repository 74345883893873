import React from "react";

import { useTranslation } from 'react-i18next';

export default function Article2() {
  const { t } = useTranslation();

  return (
    <div className="text-primary">
      <ul className="list-unstyled d-grid gap-3">
        <li>
          {t(`Access to and use of the Services may be subject to the User providing a number of administrative details in order to create his/her user account. The User undertakes not to provide false and/or erroneous contact details or information. The User is solely responsible for the consequences of any inaccurate information he or she may provide.`)}
        </li>
        <li>
          {t(`Once the Services have been configured and/or installed, the User may access the Services offered according to the User's wishes (hereinafter referred to as the "Content"). The User acknowledges that additional conditions may apply for access to certain content, access to which may change at the sole discretion of Wanadev, in particular in the event of a temporarily limited discovery promotional offer, in accordance with the contractual provisions governing the relationship between the User and Wanadev.`)}
        </li>
        <li>
          {t(`The User is not authorized to use the Services and Content for purposes other than those intended by Wanadev. The User may not under any circumstances sell, grant a privilege or transfer reproductions of the Services or Content to third parties in any manner whatsoever, nor to rent, lease or license the Services & Content to third parties without the prior written consent of Wanadev, except within the limits of the express authorizations of these General Conditions.`)}
        </li>
      </ul>
    </div>
  )
}