import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "@octopod/design-system";

import User, { UserRole } from "model/user";
import ListCard from "components/ListCard/ListCard";
import isGranted from "security/voter";
import listDistributorUsers from "api/distributors/users";
import listClientUsers from "api/clients/users";
import listEditorUsers from "api/editors/users";
import getToken from "security/token";
import list from "api/users/list";
import ResetUserPasswordModal from "./components/ResetUserPasswordModal/ResetUserPasswordModal";
import { NotificationConsumerHook, addNotification } from "services/stores/notificationStore";

export default function Users() {
  const { t } = useTranslation();

  const [, dispatchNotification] = NotificationConsumerHook();

  const [users, setUsers] = useState<User[]>([])
  const [reachedMax, setReachedMax] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const fetchUsers = () => {
    setError(false)

    const limit = 50
    const offset = users.length

    const tokenContext = getToken()?.context
    if (!tokenContext) {
      return; //? User will get redirected to login page
    }
    let userListPromise: Promise<Response | null>
    if (isGranted(new Set(['ROLE_ADMIN']))) {
      userListPromise = list(limit, offset)
    } else if (isGranted(new Set(['ROLE_DISTRIBUTOR']))) {
      userListPromise = listDistributorUsers(tokenContext.distributor, limit, offset)
    } else if (isGranted(new Set(['ROLE_CLIENT']))) {
      userListPromise = listClientUsers(tokenContext.client, limit, offset)
    } else if (isGranted(new Set(['ROLE_EDITOR']))) {
      userListPromise = listEditorUsers(tokenContext.editor, limit, offset)
    } else {
      setError(true)
      addNotification(dispatchNotification, t('error'), t('Could not fetch users'), 'error')
      return;
    }

    userListPromise.then(async (response) => {
      if (response === null || !response.ok) {
        setError(true)
        addNotification(dispatchNotification, t('error'), t('Could not fetch users'), 'error')
        return;
      }

      const data = await response.json();

      if (data.length < limit) {
        setReachedMax(true)
      }
      setUsers([...users, ...data])
    })
  }

  const processUserRoles = (roles: UserRole[]) => {
    return roles.map((role) => {
      switch (role) {
        case UserRole.ROLE_USER: return null
        case UserRole.ROLE_ADMIN: return <span key={role}><Trans>Administrator</Trans></span>
        case UserRole.ROLE_CLIENT: return <span key={role}><Trans>Client</Trans></span>
        case UserRole.ROLE_DISTRIBUTOR: return <span key={role}><Trans>Distributor</Trans></span>
        case UserRole.ROLE_EDITOR: return <span key={role}><Trans>Editor</Trans></span>
        default: return null
      }
    })
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <div className="users col-12">
      {users.map((user) => {
        return (
          <div key={user.id}>
            <div className="mb-5">
              <ListCard
                content={
                  <>
                    <div className="row">
                      <span className="col ">{t('Name')}</span>
                      <span className="col text-center">{t('Surname')}</span>
                      <span className="col text-center">{t('Profile')}</span>
                      <span className="col-4 text-center">{t('Email')}</span>
                      <span className="col text-center">{t('Phone number')}</span>
                    </div>
                    <div className="row align-items-center h-100 mb-3">
                      <span className="col fs-4 fw-bold">{user.lastname}</span>
                      <span className="col fs-4 fw-bold text-center">{user.firstname}</span>
                      <span className="col fs-4 text-center">
                        <div className="d-flex flex-column">
                          {processUserRoles(user.roles)}
                        </div>
                      </span>
                      <span className="col-4 fs-6 text-center">{user.email}</span>
                      <span className="col fs-6 text-center">{user.phone ?? '-'}</span>
                    </div>
                  </>
                }
                actions={(
                  <ResetUserPasswordModal user={user} />
                )}
              />
            </div>
          </div>
        )
      })}
      <div className="row justify-content-center align-items-center">
        {!reachedMax && !error && (
          <div className='users-load-more'>
            <Button label={t('Load more')} onClick={() => fetchUsers()}></Button>
          </div>
        )}
      </div>
    </div>
  )
}