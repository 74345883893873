import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

/**
 * This component is an HTML input that resets cursor position when value is set by code.
 * 
 * Prevents cursor from going back to the end of input text.
 * @param props Any number of HTML input properties
 * @returns An input element
 */
export default function ControlledInput(props: React.HTMLProps<HTMLInputElement>) {
  const { onChange, ...rest } = props
  const [cursor, setCursor] = useState<number | null>(null)
  const ref = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    const input = ref.current
    if (input) {
      input.setSelectionRange(cursor, cursor)
    }
  }, [ref, cursor, props.value])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCursor(e.target.selectionStart)
    if (onChange) {
      onChange(e)
    }
  }

  return <input ref={ref} onChange={handleChange} {...rest} />
}
