import { unstable_usePrompt, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { getPath } from "routes";
import { FormConsumerHook } from "services/stores/formStore";

export default function PreventFormUnsaved() {
  const { t } = useTranslation();
  const [form] = FormConsumerHook()

  const params = useParams();

  unstable_usePrompt({
    when: ({ nextLocation }) => {
      if (Object.keys(form.data).length === 0) {
        return false
      }

      const pathsAllowed = [
        getPath("game-show", {
          id: params.id,
        }),
        getPath("game-show-infos", {
          id: params.id,
        }),
        getPath("game-show-medias", {
          id: params.id,
        }),
        getPath("game-show-devs", {
          id: params.id,
        }),
      ];

      return pathsAllowed.includes(nextLocation.pathname) === false
    },
    message: t("You have made changes without saving. Are you sure you want to exit?"),
  })

  return null
}