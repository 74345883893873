export type Level = {
  label: keyof Console;
  weight: number;
};
export type Hook = {
  minLevel: Level;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fire: (...args: Array<any>) => any;
};
type LoggerType = {
  _hooks: Array<Hook>;
  debug: (msg: string, fields?: Record<string, unknown>) => void;
  info: (msg: string, fields?: Record<string, unknown>) => void;
  warn: (msg: string, fields?: Record<string, unknown>) => void;
  error: (msg: string, fields?: Record<string, unknown>) => void;
  addHook: (hook: Hook) => void;
};
export const LEVEL_DEBUG: Level = {
  label: "debug",
  weight: 10,
};
export const LEVEL_INFO: Level = {
  label: "info",
  weight: 20,
};
export const LEVEL_WARN: Level = {
  label: "warn",
  weight: 30,
};
export const LEVEL_ERROR: Level = {
  label: "error",
  weight: 40,
};
const Logger: LoggerType = {
  _hooks: [],

  debug(msg: string, fields: Record<string, unknown> = {}) {
    process(this._hooks, LEVEL_DEBUG, msg, fields);
  },

  info(msg: string, fields: Record<string, unknown> = {}) {
    process(this._hooks, LEVEL_INFO, msg, fields);
  },

  warn(msg: string, fields: Record<string, unknown> = {}) {
    process(this._hooks, LEVEL_WARN, msg, fields);
  },

  error(msg: string, fields: Record<string, unknown> = {}) {
    process(this._hooks, LEVEL_ERROR, msg, fields);
  },

  addHook(hook: Hook) {
    this._hooks.push(hook);
  },
};

const process = (
  hooks: Array<Hook>,
  level: Level,
  msg: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args: Record<string, any> | null | undefined
) => {
  executeHooks(hooks, level, msg, args);
  format(level, msg, args);
};

const executeHooks = (
  hooks: Array<Hook>,
  level: Level,
  msg: string,
  args: Record<string, unknown> | null | undefined
) => {
  if (hooks === null || hooks === undefined || hooks.length === 0) {
    return;
  }

  hooks.forEach((hook) => {
    if (hook.minLevel.weight <= level.weight) {
      hook.fire(level, msg, args);
    }
  });
};

const format = (
  level: Level,
  msg: string,
  args: Record<string, unknown> | null | undefined
) => {
  const cons: unknown = console[level.label];
  if (cons instanceof Function) {
    if (args) {
      cons(msg, args);
    } else {
      cons(msg);
    }
  }
  
};

export default Logger;
