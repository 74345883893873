import { raw } from "security/token"
import Logger from "services/logger"

export function list(
  launcher: number,
  teamSession: string,
  limit?: number,
  offset?: number,
): Promise<Response | null> {
  const params = new URLSearchParams()

  if (limit != null) {
    params.append('limit', limit.toString(10))
  }

  if (offset != null) {
    params.append('offset', offset.toString(10))
  }

  const headers: HeadersInit = {
    "x-auth-token": raw() || "",
  }

  return fetch(
    `${process.env.API || ""}/api/v1/launchers/${launcher}/_/team-sessions/${teamSession}/player-sessions`,
    {
      method: "GET",
      headers
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "api/v1/launchers/:id/_/team-sessions/:id2/player-sessions"', {
      launcher,
      teamSession,
      limit,
      offset,
      errors
    })
    return Promise.resolve(null);
  })
}