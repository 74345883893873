import React from "react";
import Select from "react-select";

const blue = "#0F2F51";
const blueRGB = "15, 47, 81";
const blue80 = "#3f5873";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCustomStyles = (isReadOnly: boolean): any => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (styles: any) => ({ ...styles, backgroundColor: "transparent" }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? `rgba(${blueRGB}, 0.6)`
          : isSelected
            ? blue
            : isFocused
              ? blue
              : blue80,
        color: isDisabled
          ? "rgba(255,255,255, 0.4)"
          : isFocused
            ? "white"
            : isSelected
              ? "white"
              : "white",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "" : blue),
        },
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: (styles: any) => ({ ...styles, color: blue }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    placeholder: (styles: any) => ({ ...styles, color: `rgba(${blueRGB}, 0.5)` }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    singleValue: (styles: any) => ({ ...styles, color: blue }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiValue: (styles: any) => isReadOnly ? ({ ...styles, paddingRight: '10px !important' }) : (styles),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiValueLabel: (styles: any) => isReadOnly ? ({ ...styles, paddingRight: '6px !important' }) : (styles),
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatGroupLabel = (data: any) => (
  <div>
    <span>{data.label}</span>
    <span>{data.options.length}</span>
  </div>
);

export default function SelectList({
  dataOptions,
  multiSelect,
  placeholderText = "",
  defaultValue = null,
  isDisabled = false,
  isClearable = false,
  isReadonly = false,
  onChange,
  value,
  thirdOption,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) {
  if (typeof dataOptions === "function") {
    dataOptions = dataOptions();
  }

  isDisabled = isDisabled || isReadonly
  const readonlyComponents = isReadonly ? {
    DropdownIndicator: () => <div />,
    Menu: () => <div />,
    MultiValueRemove: () => <div />,
  } : {}


  return (
    <Select
      className={multiSelect ? "" : "basic-multi-select"}
      classNamePrefix={multiSelect ? "multi-select" : "select"}
      components={{
        ...readonlyComponents,
        IndicatorSeparator: () => <div />,
        Menu: (props) => {
          const { children, className, cx, getStyles, innerRef, innerProps } =
            props;
          return (
            <div
              // @ts-expect-error TS2322
              style={getStyles("menu", props)}
              className={cx(
                {
                  menu: true,
                },
                className
              )}
              {...innerProps}
              ref={innerRef}
            >
              {children}

              {thirdOption}
            </div>
          );
        },
      }}
      defaultValue={defaultValue}
      formatGroupLabel={multiSelect ? () => null : formatGroupLabel}
      isDisabled={isDisabled}
      isClearable={isClearable}
      isMulti={multiSelect}
      name={multiSelect ? "" : "colors"}
      onChange={onChange}
      options={dataOptions}
      placeholder={placeholderText}
      styles={getCustomStyles(isReadonly ?? false)}
      value={value}
    />
  );
}
