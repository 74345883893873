import React, { useEffect, useRef, useState } from "react";
import Chart, { ChartTypeRegistry, Color } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';

type Dataset = {
  data: unknown;
  backgroundColor: (...args: Array<unknown>) => Color | undefined;
};

type Props = {
  labels: Array<string>;
  datasets: Array<Dataset>;
  tooltipSpecialProcessing?: (labels: string[], dataIndex: number) => string;
};

const Doughnut = ({ labels, datasets, tooltipSpecialProcessing }: Props) => {
  const [chart, setChart] = useState<Chart<keyof ChartTypeRegistry, unknown, string> | null>(null);
  const element = useRef(null);

  useEffect(() => {
    if (chart === null && element.current !== null) {
      setChart(
        new Chart(element.current, {
          type: "doughnut",
          data: {
            labels,
            datasets,
          },
          plugins: [ChartDataLabels],
          options: {
            responsive: true,
            elements: {
              arc: {
                borderWidth: 0
              },
            },
            plugins: {
              legend: {
                labels: {
                  usePointStyle: false,
                },
                align: "center",
                position: "top",
              },
              datalabels: {
                display: false,
              },
              tooltip: {
                enabled: false,
                displayColors: false,
                external: (context) => {
                  //Tooltip element
                  let tooltipEl = document.getElementById('chartjs-tooltip')

                  //Create element
                  if (!tooltipEl) {
                    tooltipEl = document.createElement('div')
                    tooltipEl.id = 'chartjs-tooltip'
                    tooltipEl.innerHTML = '<table></table>'
                    document.body.appendChild(tooltipEl)
                  }

                  // Hide if no tooltip
                  const tooltipModel = context.tooltip
                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = '0'
                    return
                  }

                  //set caret Position
                  tooltipEl.classList.remove('above', 'below', 'no-transform')
                  if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign)
                  } else {
                    tooltipEl.classList.add('no-transform')
                  }

                  //set text
                  if (tooltipModel.body) {
                    const titleLines = tooltipModel.title || []

                    let innerHTML = '<thread>'

                    if (tooltipSpecialProcessing) {
                      innerHTML += tooltipSpecialProcessing(titleLines, context.tooltip.dataPoints[0].dataIndex);
                    } else {
                      titleLines.forEach((title) => {
                        innerHTML += '<tr><td>' + title + '</td></tr>'
                      })
                    }


                    innerHTML += '</thread><tbody>'

                    innerHTML += '</tbody>'

                    const tableRoot = tooltipEl.querySelector('table')
                    if (tableRoot) {
                      tableRoot.innerHTML = innerHTML
                    }
                  }

                  const position = context.chart.canvas.getBoundingClientRect()

                  tooltipEl.style.opacity = '1'
                  tooltipEl.style.position = 'absolute'
                  tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX + 20 + 'px'
                  // tooltipEl.offsetHeight / 2 is to handle bigger tooltips, offsetting it's position by half it's height 
                  tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY - (tooltipEl.offsetHeight / 2) + 'px'
                  tooltipEl.style.padding = '10px'
                  tooltipEl.style.pointerEvents = 'none'
                  tooltipEl.style.width = 'fit-content'
                  tooltipEl.style.height = 'fit-content'
                  tooltipEl.style.borderRadius = '10px'
                  tooltipEl.style.backgroundColor = '#2f3946b3'
                  tooltipEl.style.color = 'white'
                  tooltipEl.className = 'consumption-tooltip'
                }
              }
            }
          },
        })
      );
    } else {
      if (
        chart !== null &&
        (
          chart.data.labels?.length !== labels.length ||
          chart.data.datasets.length !== datasets.length ||
          !_.isEqual(labels, chart.data.labels) ||
          !_.isEqual(datasets, chart.data.datasets)
        )
      ) {
        chart.data.labels = labels;
        chart.data.datasets = datasets;
        chart.update();
      }
    }
  }, [chart, labels, datasets]);

  return <canvas ref={element} />;
};

export default Doughnut
