import React from "react";

import { Trans } from 'react-i18next';

type Props = {
  background?: boolean;
  inline?: boolean;
  message?: boolean | string;
  paddingX?: boolean;
  position?: string;
};

export default function Loader({
  background,
  inline,
  message,
  paddingX,
  position,
}: Props) {
  return (
    <div
      className={`loading${background === false ? "--white" : ""} ${
        inline === true ? "" : "loading--large"
      } ${position === "left" ? "loading--left" : ""} ${
        paddingX === false ? "loading--no-padding-x" : ""
      }`}
    >
      {message !== false && (
        <p>
          {(() => {
            if (typeof message === 'string') {
              return message
            }

            return <Trans>Charging</Trans>
          })()}
        </p>
      )}

      <i className={`${inline === true ? "icon" : "icon-xl"} icon-loader`} />
    </div>
  );
}
