import React, { Dispatch, createContext, useContext, useReducer } from "react"
import type { Context, PropsWithChildren } from "react"
import moment, { Moment } from "moment"
import _ from "lodash"

import Logger from "../logger"

type State = {
  notifications: Notification[]
};

const initialState: State = {
  notifications: [],
};

type NotificationType = 'error' | 'success' | 'info' | 'warn'

export interface Notification {
  id: string
  title: string
  message: string
  type: NotificationType
  createdAt: Moment
}

const reducer = (state: State, unknownAction: unknown): State => {
  if (typeof unknownAction !== 'object' || unknownAction === null) {
    return state
  }

  const action = unknownAction as Record<string, unknown>
  const newState = { ...state }

  switch (action.type) {
    case "ADD":
      if (typeof action.notification === 'object' && action.notification !== null) {
        newState.notifications = [...state.notifications, action.notification as Notification]
      }

      return newState

    case "REMOVE":
      newState.notifications = state.notifications.filter(({ id }) => id !== action.id)
      return newState

    case "reset":
      return { ...initialState }

    default:
      return state
  }
};

const NotificationContext: Context<[State, (...args: Array<unknown>) => unknown]> =
  createContext([
    initialState,
    () => Logger.error("Context not correctly initialized"),
  ])

export const NotificationConsumer = NotificationContext.Consumer
export const NotificationConsumerHook = () => useContext(NotificationContext)
export const NotificationProvider = ({ children }: PropsWithChildren<unknown>) => (
  <NotificationContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </NotificationContext.Provider>
)

export const addNotification = (dispatch: Dispatch<unknown>, title: string, message: string, type: NotificationType) => {
  const id = _.uniqueId()
  dispatch({
    type: "ADD",
    notification: { id, title, message, type, createdAt: moment() },
  })

  setTimeout(() => {
    removeNotification(dispatch, id)
  }, 5000);
}

export const removeNotification = (dispatch: (...args: Array<unknown>) => unknown, id: string) => {
  dispatch({
    type: "REMOVE",
    id,
  });
}
