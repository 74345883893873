import React, { useMemo } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { useLocalStorage } from 'services/hooks/useLocalStorage';
import SidebarLink from '../SidebarLink/SidebarLink';
import { getPath } from "routes";
import OctopodInsightLogo from "images/octopod-insight-logo.png";
import OctopodLogo from "../../../../../public/logo192.png";
import pictoDashboard from "images/picto-dashboard.svg";
import pictoConsumption from "images/picto-consumption.svg";
import pictoLaunchers from "images/picto-launchers.svg";
import pictoGames from "images/picto-games.svg";
import pictoUsers from "images/picto-users.svg";
import pictoGameSessions from "images/picto-game-sessions.svg";
import octopodDoc from "images/octopod_doc.svg";
import pictoCollapse from "images/picto-collapse.svg";
import pictoDoc from "images/picto-doc.svg";
import pictoBilling from "images/picto-billing.svg"

export interface SidebarProps {
  extraFiltersCount: number
}

export default function Sidebar({ extraFiltersCount }: SidebarProps) {
  const { t } = useTranslation()

  const [collapsed, setCollapsed] = useLocalStorage('sidebar-collapsed', 'false');

  const getCollapsed = () => {
    return collapsed === 'true';
  }

  const isConsumptionActive = useMemo(() => {
    return location.pathname === getPath("consumption") || location.pathname === getPath("billing") || location.pathname === getPath("balance")
  }, [location.pathname])

  return (
    <>
      <div className={`sidebar${getCollapsed() ? '-collapsed' : ''} col`}>
        <div className={`sidebar-content`}>
          <div className="sidebar-logo-container">
            <Link className={`logo${getCollapsed() ? '-collapsed' : ''}`} to={getPath("index", null, null, { keepFiltersParameters: true }) ?? ''}>
              <img alt={t('Octopod Logo')} src={getCollapsed() ? OctopodLogo : OctopodInsightLogo} className='' />
            </Link>
          </div>
          <div className={`p-0 sidebar-nav${getCollapsed() ? '-collapsed' : ''}`}>
            <nav className="nav flex-column">
              <SidebarLink
                label={t('My Dashboard')}
                active={location.pathname === getPath("index")}
                collapsed={getCollapsed()}
                image={pictoDashboard}
                path={getPath("index", null, null, { keepFiltersParameters: true }) ?? ''}
              />
              <SidebarLink
                label={t('My Consumption')}
                active={isConsumptionActive}
                collapsed={getCollapsed()}
                image={pictoConsumption}
                path={getPath("consumption", null, null, { keepFiltersParameters: true }) ?? ''}
                options={{ extraFiltersCount }}
              />
              <SidebarLink
                label={t('My Balance')}
                active={location.pathname === getPath("balance")}
                collapsed={getCollapsed()}
                image={pictoConsumption}
                path={getPath("balance") ?? ''}
                options={{ isChild: true, showCondition: isConsumptionActive }}
              />
              <SidebarLink
                label={t('My Billing')}
                active={location.pathname === getPath("billing")}
                collapsed={getCollapsed()}
                image={pictoBilling}
                path={getPath("billing") ?? ''}
                options={{ isChild: true, showCondition: isConsumptionActive }}
              />
              <SidebarLink
                label={t('My Game Sessions')}
                active={location.pathname === getPath("game-sessions")}
                collapsed={getCollapsed()}
                image={pictoGameSessions}
                path={getPath("game-sessions") ?? ''}
              />
              <SidebarLink
                label={t('My Launchers')}
                active={location.pathname === getPath("launchers")}
                collapsed={getCollapsed()}
                image={pictoLaunchers}
                path={getPath("launchers") ?? ''}
              />
              <SidebarLink
                label={t('My Games')}
                active={getPath("games") ? location.pathname.includes(getPath("games")!) : false}
                collapsed={getCollapsed()}
                image={pictoGames}
                path={getPath("games") ?? ''}
              />
              <SidebarLink
                label={t('My Users')}
                active={location.pathname === getPath("users")}
                collapsed={getCollapsed()}
                image={pictoUsers}
                path={getPath("users") ?? ''}
              />
              <a
                className={`nav-link${getCollapsed() ? '-collapsed' : ''}`}
                href="https://developer.octopodvr.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-container">
                  <img className="icon icon--white icon-mask" alt={t('Octopod doc icon')} src={pictoDoc} />
                </div>
                {!getCollapsed() && (
                  <img className="" alt={t('Octopod doc icon')} src={octopodDoc} />
                )}
              </a>
            </nav>
          </div>
          <div className={`button-collapse${getCollapsed() ? '-collapsed' : ''}`}>
            <img
              className="btn icon icon--white icon-mask"
              alt={t("Menu collapse icon")}
              src={pictoCollapse}
              onClick={() => setCollapsed(`${!getCollapsed()}`)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
