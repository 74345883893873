import React, { useRef, ReactNode, MouseEventHandler } from "react";
import { createPortal } from "react-dom";

type Header = {
  title?: string | null | undefined;
  titleClass?: string | null | undefined;
  icon?: string | null | undefined;
  error?: string | null | undefined;
};

type Footer = {
  btnCloseClass?: string;
  btnCloseDisabled?: boolean;
  btnCloseLabel?: string;
  btnValidClass?: string;
  btnValidDisabled?: boolean;
  btnValidForm?: string;
  btnValidLabel?: string;
  center?: boolean;
};

type Props = {
  header?: Header;
  footer?: Footer;
  children?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (...args: Array<any>) => any;
};

export default function Modal({ header, footer, children, onClose }: Props) {
  const modal = useRef(null);

  const closeModal = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = ({ target }) => {
    if (target === modal.current) {
      closeModal();
    }
  };

  const container: Element | null | undefined =
    document.getElementById("modal");

  if (container === null) {
    return null;
  }

  return createPortal(
    <div className="main-modal" onClick={handleClick} ref={modal}>
      <div className="main-modal__container">
        {header && (
          <div className="form-header">
            {header.title && (
              <h4 className={`main-modal__title mb-3 ${header.titleClass ?? ''}`}>{header.title}</h4>
            )}

            {header.icon && (
              <div className="form-header__container">
                <i className={header.icon} />
              </div>
            )}

            {header.error && (
              <div className="alert alert-danger mt-3">{header.error}</div>
            )}
          </div>
        )}

        <div className="mt-5 main-modal__content">{children}</div>

        {footer && (
          <div
            className={`pt-5 ${footer.center
              ? "main-modal__buttons"
              : "main-modal__buttons--right"
            }`}
          >
            {footer.btnCloseLabel && (
              <button
                type="button"
                className={`btn ${footer.btnCloseClass || ""}`}
                onClick={closeModal}
                disabled={footer.btnCloseDisabled || false}
              >
                {footer.btnCloseLabel}
              </button>
            )}

            {footer.btnValidLabel && (
              <button
                form={footer.btnValidForm}
                type="submit"
                className={`btn ${footer.btnValidClass || ""}`}
                disabled={footer.btnValidDisabled || false}
              >
                {footer.btnValidLabel}
              </button>
            )}
          </div>
        )}
      </div>
    </div>,
    container
  );
}
