import { stringify } from "qs";

import { setToken } from "security/token";

export async function auth(email: string, password: string): Promise<boolean> {
  const response = await fetch(`${process.env.API || ""}/api/v1/auth`, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: stringify({
      email,
      password,
    }),
  });

  if (response.status === 200) {
    const body = await response.json();

    if (body.token === undefined) {
      console.error("No token returned");
      return false;
    }

    setToken(body.token);
    return true;
  }

  console.info("Credentials invalid");
  return false;
}
