import React from "react";
import { useTranslation } from 'react-i18next';

import { Trans } from 'react-i18next';
import Modal from "components/Modal/Modal";
import { FormConsumer } from "services/stores/formStore";
import { GameReleasesConsumer, GameWithAttachments } from "services/stores/gameReleasesStore";
import { ModalConsumer, ModalProvider } from "services/stores/modalStore";
import { GamesConsumerHook } from "services/stores/gamesStore";
import { edit } from "api/game/create";

export default function GameSaveModal() {
  const { t } = useTranslation();
  const [, dispatchGames] = GamesConsumerHook();

  const updateGame = async (game: GameWithAttachments | null, form: Partial<GameWithAttachments> | null) => {
    if (game === null || form === null) {
      const errors = [];
      if (game === null) {
        errors.push(t('Game was not found'))
      }
      if (form === null) {
        errors.push(t('Form is empty'))
      }
      return {
        success: false,
        data: null,
        errors,
      };
    }

    const { success, data, errors } = await edit({ ...game, ...form });

    if (success) {
      dispatchGames({
        type: "updateGame",
        game: data,
      });
    }

    return {
      success,
      data,
      errors,
    };
  };

  return (
    <ModalProvider>
      <ModalConsumer>
        {([{ opened }, modalDispatch]) => (
          <GameReleasesConsumer>
            {([{ game }, gameDispatch]) => (
              <FormConsumer>
                {([{ data, errors, submitting }, formDispatch]) => {
                  const disabled: boolean =
                    Object.keys(data).length === 0;
                  const formId = "game-save";
                  return (
                    <>
                      <div className="button-group--top">
                        <button
                          className="btn btn-primary"
                          disabled={disabled}
                          type="button"
                          onClick={() =>
                            modalDispatch({
                              type: opened ? "close" : "open",
                            })
                          }
                        >
                          <i className="icon me-2 icon--white icon-save" />

                          <Trans>Save</Trans>
                        </button>
                      </div>

                      {opened === true && (
                        <Modal
                          header={{
                            title: t("Confirm changes ?"),
                            icon: "icon icon-save",
                          }}
                          footer={{
                            btnCloseClass: "btn-danger w-50 me-2",
                            btnCloseLabel: t("Cancel"),
                            btnValidClass: "btn-primary w-50 ms-2",
                            btnValidDisabled: submitting,
                            btnValidForm: formId,
                            btnValidLabel: t(
                              submitting ? "Saving" : "Save"
                            ),
                            center: true,
                          }}
                          onClose={() =>
                            modalDispatch({
                              type: "close",
                            })
                          }
                        >
                          {(() => {
                            if (errors.length > 0) {
                              return (
                                <>
                                  <div className="alert alert-danger">
                                    <Trans
                                      i18nKey="{{total}} errors encoutered"
                                      values={{
                                        total: errors.length,
                                      }}
                                    />
                                  </div>

                                  {errors
                                    .filter(
                                      (error) =>
                                        error?.name === undefined
                                    )
                                    .map((error) => (
                                      <div className="alert alert-danger">
                                        {error}
                                      </div>
                                    ))}
                                </>
                              );
                            }
                          })()}

                          <form
                            id={formId}
                            onSubmit={async (e) => {
                              e.preventDefault();
                              formDispatch({
                                type: "setSubmitting",
                                submitting: true,
                              });
                              const {
                                success,
                                data: game2,
                                errors,
                              } = await updateGame(game, data);

                              if (success) {
                                gameDispatch({
                                  type: "setGame",
                                  game: game2,
                                });
                                modalDispatch({
                                  type: "close",
                                });
                                formDispatch({
                                  type: "reset",
                                });
                              } else {
                                formDispatch({
                                  type: "setErrors",
                                  errors,
                                });
                              }
                            }}
                          />
                        </Modal>
                      )}
                    </>
                  );
                }}
              </FormConsumer>
            )}
          </GameReleasesConsumer>
        )}
      </ModalConsumer>
    </ModalProvider>
  )
}