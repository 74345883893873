import React, { createContext, useContext, useReducer } from "react";
import type { Context, PropsWithChildren } from "react";

import Logger from "../logger";
import { list as listGames } from "api/game/list";
import { list as listReleases } from "api/release/list";
import Unauthorized from "exception/unauthorized";
import { getPath } from "routes";
import Game from "model/game";

type State = {
  games: Game[] | null;
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  games: null,
  loading: false,
  error: null,
};

type Action = {
  type: "addGame" | "updateGame" | "FETCHING" | "FETCHING_SUCCESS" | "FETCHING_ERROR";
  game?: Game;
  games?: Game[];
  error?: string;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "addGame":
      if (action.game === undefined) {
        return state;
      }
      return { ...state, games: [...(state.games || []), action.game] };

    case "updateGame":
      return {
        ...state,
        games: (state.games || []).map((game) =>
          game.id === action.game?.id ? action.game : game
        ),
      };

    case "FETCHING":
      return {
        games: null,
        loading: true,
        error: null,
      };

    case "FETCHING_SUCCESS":
      return {
        games: action.games ?? null,
        loading: false,
        error: null,
      };

    case "FETCHING_ERROR":
      return {
        games: [],
        loading: false,
        error: action.error ?? null,
      };

    default:
      return state;
  }
};

const GamesContext: Context<[State, (action: Action) => void]> =
  createContext([
    initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (action: Action) => Logger.error("Context not correctly initialized"),
  ]);
export const GamesConsumer = GamesContext.Consumer;
export const GamesConsumerHook = () => useContext(GamesContext);
export const GamesProvider = ({ children }: PropsWithChildren<unknown>) => (
  <GamesContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </GamesContext.Provider>
);

export const fetchGames = async (
  dispatch: (action: Action) => void,
  navigate: unknown
) => {
  dispatch({
    type: "FETCHING",
  });

  try {
    const response = await listGames();
    if (response === null || response.status !== 200) {
      dispatch({
        type: "FETCHING_ERROR",
        error: 'could not fetch games',
      });

      return
    }

    const games = await response.json()

    for (const index in games) {
      const { data: latestReleases } = await listReleases(
        games[index].id,
        50,
        0,
        {
          field: "createdAt",
          direction: "DESC",
        }
      )

      if (latestReleases.length > 0) {
        games[index].latestRelease = latestReleases[0]
      }
    }

    dispatch({
      type: "FETCHING_SUCCESS",
      games,
    });
  } catch (error: unknown) {
    if (error instanceof Unauthorized && navigate instanceof Function) {
      navigate(getPath("login"));
    } else {
      let message;
      if (error instanceof Error) {
        message = error.message;
      } else if (typeof error === "string") {
        message = error;
      } else {
        message = "unknown error";
      }
      dispatch({
        type: "FETCHING_ERROR",
        error: message,
      });
    }
  }
};
