import getToken, { raw } from "security/token";
import Logger from "services/logger";

export default async function getLaunchers(): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/editors/${
      getToken()?.context.editor
    }/launchers`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "editors launchers"', {
      editor: getToken()?.context.editor,
      errors
    });
    return Promise.resolve(null);
  });
}
