import { raw } from "security/token";
import Logger from "services/logger";

interface response<T> {
  success: boolean;
  data?: T;
  errors?: string[];
}

export async function list(
  limit = 50,
  offset = 0
): Promise<Response | null> {
  const params = new URLSearchParams();

  if (limit && Number.isInteger(limit) === true) {
    params.append("limit", `${limit}`);
  }

  if (offset && Number.isInteger(offset) === true) {
    params.append("offset", `${offset}`);
  }

  return fetch(
    `${process.env.API || ""}/api/v1/games?${params.toString()}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not fetch games', {
      errors
    });
    return Promise.resolve(null);
  })
}

interface Attachment {
  id: number;
  createdAt: string;
  game: number;
  name: string;
  originalFilename: string;
  type: 'cover' | 'thumbnail' | 'trailer' | 'gameguide' | 'teaser' | 'other';
}

export async function listAttachments(
  game: number
): Promise<response<Attachment[]>> {
  const response = await fetch(
    `${process.env.API || ""}/api/v1/games/${game}/attachments`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    errors: body.errors,
  };
}
