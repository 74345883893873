import React from "react";
import { SelectWithCollapsableGroups } from "@octopod/design-system";
import { useTranslation } from "react-i18next";

import { GameSpaces } from "model/game";

interface Props {
  onSelect: (val: string | null) => void
  value: string | null
}

interface Node {
  label: string
  value: string
  children?: Node[]
}

export default function SelectSpace({ onSelect, value = null }: Props) {
  const { t } = useTranslation();

  return (
    <SelectWithCollapsableGroups
      data={processNodes()}
      value={value}
      placeholder={t('All spaces')}
      isClearable={true}
      onChange={onSelect}
    />
  )
}

function processNodes(): Node[] {
  return Object.keys(GameSpaces).map((space) => {
    return {
      label: space,
      value: GameSpaces[space as keyof typeof GameSpaces],
    }
  })
}