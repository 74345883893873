import React from "react";
import { useTranslation } from 'react-i18next';

export default function Article3() {
  const { t } = useTranslation();

  return (
    <div className="text-primary">
      <ul className="list-unstyled d-grid gap-3">
        <li>
          {t(`The User undertakes to use the Services offered in compliance with the regulations in force and with these General Terms and Conditions. Failing this, the User is solely liable for any damage caused and for the consequences of any claims or actions that may result.`)}
        </li>
        <li>
          {t(`The User undertakes to hold Wanadev harmless against all claims, complaints, actions or recourse of any kind from third parties and to indemnify Wanadev and third parties for all damages resulting from use of the Services that does not comply with the applicable regulations and/or these General Terms and Conditions.`)}
        </li>
        <li>
          {t(`All the Services offered and their content are produced for information purposes only. The User alone assumes responsibility for the consequences of using the data and documents generated by using the Services.`)}
        </li>
      </ul>
    </div>
  )
}