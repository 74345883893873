import { raw } from "security/token";
import Logger from "services/logger";

export default async function listUsers(
  distributor: number,
  limit?: number,
  offset?: number
): Promise<Response | null> {
  const params = new URLSearchParams();

  if (Number.isInteger(limit) === true) {
    params.append("limit", `${limit}`);
  }

  if (Number.isInteger(offset) === true) {
    params.append("offset", `${offset}`);
  }

  return fetch(
    `${process.env.API || ""}/api/v1/distributors/${distributor}/users?${params.toString()}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "api/v1/distributors/:id/users"', {
      distributor,
      errors
    });
    return Promise.resolve(null);
  });
}