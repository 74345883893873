import { get, remove, set } from "services/helper/storage";
const bearerKey = "bearer";
export function reset() {
  remove(bearerKey);
}
export function raw(): string | null | undefined {
  return get(bearerKey);
}

function B64ToUtf8(str: string) {
  return decodeURIComponent(escape(window.atob(str)));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function payload(): Record<string, any> | null | undefined {
  const token: string | null | undefined = raw();

  if (token === null) {
    return null;
  }

  const parts: Array<string> = String(token).split(".");

  if (parts.length !== 3) {
    console.error("JWT token non standard");
    return null;
  }

  let decoded: string | null | undefined = null;

  try {
    decoded = B64ToUtf8(parts[1]);
  } catch (exception) {
    console.error("JWT payload is not a base64");
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let payload: Record<string, any> | null = null;

  try {
    payload = JSON.parse(decoded);
  } catch (exception) {
    console.error("JWT payload is not a valid JSON");
  }

  return payload;
}

export function setToken(token: string) {
  set(bearerKey, token);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function getToken(): Record<string, any> | null | undefined {
  return payload();
}
