import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import { lastDayOfMonth, startOfMonth } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import { Badge, Button, DateRangePicker } from '@octopod/design-system'
import { useTranslation } from 'react-i18next'

import SelectLocations from 'components/SelectLocations/SelectLocations'
import { LocationsConsumerHook, fetchLocations } from 'services/stores/locationsStore'
import BalanceCards from 'components/Consumption/ConsumptionSummary/components/BalanceCard/BalanceCard'
import { Balance } from 'model/balance'
import { list } from 'api/balance/list'
import Logger from 'services/logger'
import { GamesConsumerHook, fetchGames } from 'services/stores/gamesStore'
import BalanceGamesModal from './components/BalanceGamesModal/BalanceGamesModal'
import BalanceExport from './components/BalanceExport/BalanceExport'

import pictoPlus from 'images/picto-plus.svg'
import pictoMinus from 'images/picto-minus.svg'

export enum BalanceFilters {
  PeriodFrom = 'filters[period_from]',
  PeriodTo = 'filters[period_to]',
  Location = 'filters[location]',
  Details = 'filters[details]',
}

export default function MyBalance() {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [{ locations, loading: loadingLocations, error: errorLocations }, dispatchLocations] = LocationsConsumerHook()
  const [{ games, loading: loadingGames, error: errorGames }, dispatchGames] = GamesConsumerHook()
  const [balances, setBalances] = useState<Balance[] | null>(null)
  const [reachedMax, setReachedMax] = useState<boolean>(false)

  const setDefaultParamsIfNeeded = () => {
    if (!searchParams.has(BalanceFilters.PeriodFrom)) {
      setSearchParams(prev => {
        prev.set(BalanceFilters.PeriodFrom, moment(startOfMonth(new Date())).format("YYYY-MM-DD"))
        return prev
      })
    }

    if (!searchParams.has(BalanceFilters.PeriodTo)) {
      setSearchParams(prev => {
        prev.set(BalanceFilters.PeriodTo, moment(lastDayOfMonth(new Date())).format("YYYY-MM-DD"))
        return prev
      })
    }
  }

  const handleChange = (value: string | null, filter: BalanceFilters) => {
    if (searchParams.get(filter) != value) {
      //? On location change, we remove the details filter
      if (filter === BalanceFilters.Location) {
        setSearchParams(prev => {
          prev.delete(BalanceFilters.Details)
          return prev
        })
      }

      setSearchParams(prev => {
        if (value !== null && value !== '') {
          prev.set(filter, value)
        } else {
          prev.delete(filter)
        }
        return prev
      })

    }
  }

  const handleChangePeriod = (startDate: Date, endDate: Date) => {
    setSearchParams(prev => {
      prev.set(BalanceFilters.PeriodFrom, moment(startDate).format("YYYY-MM-DD"))
      prev.set(BalanceFilters.PeriodTo, moment(endDate).format("YYYY-MM-DD"))
      return prev
    })
  }

  const fetchBalances = (fetchMore: boolean = false) => {
    const location = searchParams.get(BalanceFilters.Location)
    const periodFrom = searchParams.get(BalanceFilters.PeriodFrom)
    const periodTo = searchParams.get(BalanceFilters.PeriodTo)

    if (location !== null) {
      if (balances && fetchMore && reachedMax === false) {
        list(parseInt(location, 10), periodFrom ? new Date(periodFrom) : undefined, periodTo ? new Date(periodTo) : undefined, 50, balances.length)
          .then(async (response) => {
            if (!response?.ok) {
              setBalances(null)
              return;
            }
            const newBalances = await response.json()
            if (newBalances.length < 50) {
              setReachedMax(true)
            }
            setBalances([...balances, ...newBalances])
          }).catch(err => Logger.error("could not fetch balances", { error: err }))
      } else {
        list(parseInt(location, 10), periodFrom ? new Date(periodFrom) : undefined, periodTo ? new Date(periodTo) : undefined)
          .then(async (response) => {
            if (!response?.ok) {
              setBalances(null)
              return;
            }
            const newBalances = await response.json()
            if (newBalances.length < 50) {
              setReachedMax(true)
            }
            setBalances(newBalances)
          }).catch(err => Logger.error("could not fetch balances", { error: err }))
      }
    } else {
      setBalances(null)
    }
  }

  useEffect(() => {
    setDefaultParamsIfNeeded()
  }, [])

  useEffect(() => {
    if ((loadingLocations === false && locations === null) || errorLocations !== null) {
      fetchLocations(dispatchLocations, history)
        .catch(err => Logger.error("could not fetch locations", { error: err }))
    }

    if ((loadingGames === false && games === null) || errorGames !== null) {
      fetchGames(dispatchGames, history)
        .catch(err => Logger.error("could not fetch games", { error: err }))
    }
  }, [])

  useEffect(() => {
    fetchBalances()
  }, [searchParams])

  return (
    <>
      <div className="mx-0 mb-2">
        <div className='card'>
          <SelectLocations
            locations={locations || []}
            onSelect={(value) => handleChange(value, BalanceFilters.Location)}
            placeholder={t('Please select a location')}
            value={searchParams.get(BalanceFilters.Location) || null}
          />
        </div>
      </div>
      {searchParams.get(BalanceFilters.Location) && (
        <>
          <div className="row mb-5 d-flex justify-content-center align-items-center">
            {searchParams.get(BalanceFilters.Location) &&
              <BalanceCards location={parseInt(searchParams.get(BalanceFilters.Location)!, 10)} handleBanner={() => { /* Do nothing */ }} />
            }

            <div className='col me-2'>
              <div className='card row mt-4 align-items-center'>
                <div className="col balance-date-card">
                  <div className='balance-date-card-content'>
                    <div className='balance-date-card-content-datepicker'>
                      <DateRangePicker
                        onChange={handleChangePeriod}
                        initialValue={{
                          startDate: new Date(searchParams.get(BalanceFilters.PeriodFrom) || moment(startOfMonth(new Date())).toISOString()),
                          endDate: new Date(searchParams.get(BalanceFilters.PeriodTo) || moment(lastDayOfMonth(new Date())).toISOString()),
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-auto'>
                  <BalanceExport balances={balances ?? []} />
                </div>
              </div>
            </div>
          </div>

          <div className='balance-table-border' />

          <table className='table table-light balance-table'>
            <thead className='balance-table-header'>
              <tr>
                <th className='text-primary text-center'>{t('Details')}</th>
                <th className='text-primary text-center'>{t('Date')}</th>
                <th className='text-primary text-center'>{t('Invoices')}</th>
                <th className='text-primary text-center'>{t('Games')}</th>
                <th className='text-primary text-center'>{t('Credit')}</th>
                <th className='text-primary text-center'>{t('Debit')}</th>
              </tr>
            </thead>
            <tbody className='balance-table-body'>
              {balances && balances.length > 0 && balances.map((balance) => {
                const unit = balance.minutes > 0 ? t('minutes') : t('sessions')
                const balanceValue = balance.minutes > 0 ? balance.minutes : balance.sessions
                return (
                  //? <Fragment> = <> --- We use Fragment because we need to put a key on it to avoid a React warning
                  <Fragment key={balance.id}>
                    <tr>
                      <td className='text-primary text-center align-middle'>
                        <div className="balance-action">
                          <button
                            className='btn btn-primary btn-sm balance-action balance-action-button'
                            onClick={() => handleChange(
                              searchParams.get(BalanceFilters.Details) == balance.id.toString() ? null : balance.id.toString(),
                              BalanceFilters.Details
                            )}
                          >
                            <img alt={t('Show details')} src={searchParams.get(BalanceFilters.Details) === balance.id.toString() ? pictoMinus : pictoPlus} />
                          </button>
                        </div>
                      </td>
                      <td className='text-primary text-center align-middle'>{moment(balance.createdAt).format('L')}</td>
                      <td className='text-primary text-center align-middle'>{balance.invoice ? `#${balance.invoice}` : '-'}</td>
                      <td className='text-primary text-center align-middle'>
                        {(() => {
                          if (!balance.games) {
                            return '-'
                          }
                          if (balance.games.length === 1) {
                            const game = games?.find(g => g.id === balance.games[0])
                            if (game) {
                              return game.name
                            }
                            return '-'
                          }
                          return <BalanceGamesModal games={games ?? []} />
                        })()}
                      </td>
                      <td className='text-primary text-center align-middle'>
                        {(() => {
                          if (balance.type === 'credit') {
                            return <Badge label={`+${balanceValue} ${unit}`} variant="custom" className="balance-badge" />
                          }
                          return 0
                        })()}
                      </td>
                      <td className="text-primary text-center align-middle">{`${balance.type === 'debit' ? `-${balanceValue}` : 0} ${unit}`}</td>
                    </tr>
                    {searchParams.get(BalanceFilters.Details) === balance.id.toString() && (
                      <tr>
                        <td></td>
                        <td colSpan={4}>
                          <table className="table table-light">
                            <thead>
                              <tr>
                                <th className='text-primary text-start balance-details-title ps-3 pe-3'>{t('Description')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className='text-primary text-start balance-details-content p-3'>{balance.description}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )
              })}

            </tbody>
          </table>
          {!reachedMax && (
            <div className='w-100 balance-pagination'>
              <Button label={t('Load more')} onClick={() => fetchBalances(true)}></Button>
            </div>
          )}
        </>
      )}
    </>
  )
}