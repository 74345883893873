import React from "react";
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/browser";

import "../stylesheet/app.scss";
import "../stylesheet/theme.css"

import App from "./App";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require("../package.json");

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: version,
    environment: process.env.SENTRY_ENV || "n/a",
  });
}

const container = document.getElementById("root");

if (container !== null) {
  const root = createRoot(container)
  root.render(<App />)
}
