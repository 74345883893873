import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { faFileArrowDown } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@octopod/design-system"
import { useTranslation } from 'react-i18next'

import { ConsumptionFilters } from "../../filters"
import { getConsumptionV2 } from "api/consumption/get"
import fileDownload from "services/helper/file_download"
import ResponseType from "model/response_type"

export default function ExportConsumption() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams()
  const [exporting, setExporting] = useState<boolean>(false)

  const handleExport = async () => {
    setExporting(true);
    const from = searchParams.get(ConsumptionFilters.PeriodFrom)
    const to = searchParams.get(ConsumptionFilters.PeriodTo)
    const game = searchParams.get(ConsumptionFilters.Game)
    const location = searchParams.get(ConsumptionFilters.Location)

    if (!from || !to) {
      setExporting(false)
      return // TODO: handle error
    }

    try {
      const response = await getConsumptionV2(from, to, game ? [parseInt(game, 10)] : undefined, location ? [parseInt(location, 10)] : undefined, undefined, undefined, ResponseType.XLS)
      if (response !== null) {
        const file = await response.blob()
        if (file) {
          fileDownload(file, 'sessions-octopod.xls')
        }
      }
    } finally {
      setExporting(false)
    }
  }

  return (
    <Button
      label={t(exporting ? "Exporting" : "Export")}
      prefixComponent={<span className="pe-2">{exporting ? <i className='fa fa-spin fa-spinner' /> : <FontAwesomeIcon icon={faFileArrowDown} />}</span>}
      onClick={handleExport}
      classNames="h-100"
      disabled={exporting}
    />
  )
}
