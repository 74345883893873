import React from 'react';
import { SelectWithCollapsableGroups } from '@octopod/design-system';
import { useTranslation } from 'react-i18next';

import { SessionCouchStatus } from 'model/session_couch';

interface SessionStatusSelectProps {
  onSelect: (val: string | null) => void
  value: string | null | undefined
}

export function SessionStatusSelect({ onSelect, value }: SessionStatusSelectProps) {
  const { t } = useTranslation();
  const data = [
    {
      label: t('All sessions'),
      value: SessionCouchStatus.All,
    },
    {
      label: t('Finished sessions'),
      value: SessionCouchStatus.DONE,
    },
    {
      label: t('Unfinished sessions'),
      value: SessionCouchStatus.NOT_DONE,
    }
  ]

  if (value === null || value === undefined) {
    value = SessionCouchStatus.All
  }

  return <SelectWithCollapsableGroups
    data={data}
    value={value}
    placeholder={''}
    onChange={onSelect}
  />
}
