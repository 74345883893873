import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { lastDayOfMonth, startOfMonth } from "date-fns";
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import { Banner, Tooltip } from "@octopod/design-system";

import ConsumptionDoughnut from "./components/ConsumptionDoughnut/ConsumptionDoughnut";
import ConsumptionStackedBar from "./components/ConsumptionStackedBar/ConsumptionStackedBar";
import ExportConsumption from "./components/ExportConsumption/ExportConsumption";
import isGranted from "security/voter";
import Loader from "components/Loader/Loader";
import Logger from "services/logger";
import SelectDateRange from "components/SelectDateRange/SelectDateRange";
import SelectGames from "components/SelectGames/SelectGames";
import { ClientsConsumerHook, fetchClients } from "stores/clientsStore";
import { ConsumptionConsumerHook } from "stores/consumptionStore";
import { ConsumptionFilters } from "components/Consumption/ConsumptionSummary/filters";
import { DistributorsConsumerHook, fetchDistributors } from "stores/distributorsStore";
import { EditorsConsumerHook, fetchEditors } from "stores/editorsStore";
import { fetchLocations, LocationsConsumerHook } from "stores/locationsStore";
import { GamesConsumerHook, fetchGames } from "stores/gamesStore";
import BalanceCard from "./components/BalanceCard/BalanceCard";
import BalanceModal from "./components/BalanceModal/BalanceModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { useLocalStorage } from "services/hooks/useLocalStorage";
import { getPath } from "routes";
import SelectLocations from "components/SelectLocations/SelectLocations";

export enum Unit {
  Minutes = 'MINUTES',
  Sessions = 'SESSIONS'
}

export default function ConsumptionSummary() {
  const { t } = useTranslation()
  const [rechargeCredit, setRechargeCredit] = useState(false);
  const [creditBannerClosed, setCreditBannerClosed] = useLocalStorage('creditBannerClosed', 'false');
  const [searchParams, setSearchParams] = useSearchParams()

  const [consumption] = ConsumptionConsumerHook();
  const [{ games, loading: loadingGames, error: errorGames }, dispatch] = GamesConsumerHook();
  const [{ editors, loading: loadingEditors, error: errorEditors }, dispatchEditors] = EditorsConsumerHook();
  const [{ distributors, loading: loadingDistributors, error: errorDistributors }, dispatchDistributors] = DistributorsConsumerHook();
  const [{ clients, loading: loadingClients, error: errorClients }, dispatchClients] = ClientsConsumerHook();
  const [{ locations, loading: loadingLocations, error: errorLocations }, dispatchLocations] = LocationsConsumerHook();
  const [unit, setUnit] = useState<Unit>(Unit.Sessions)
  const [isOpen, setIsOpen] = useLocalStorage('graph-collapsed', 'true')

  // memos to prevent unwanted charts refreshes 
  const doughnut = useMemo(() => {
    return <ConsumptionDoughnut error={errorGames} games={games} loading={loadingGames} unit={unit} />
  }, [unit, consumption, errorGames, loadingGames, games])

  const stackedBar = useMemo(() => {
    return <ConsumptionStackedBar unit={unit} error={errorGames} loading={loadingGames} />
  }, [unit, consumption, errorGames, loadingGames])

  // load data
  {
    useEffect(() => {
      if ((loadingGames === false && games === null) || errorGames) {
        fetchGames(dispatch, history);
      }
    }, [dispatch, errorGames, games, loadingGames])

    useEffect(() => {
      if (!isGranted(new Set(['ROLE_EDITOR'])) && ((loadingEditors === false && editors === null) || errorEditors !== null)) {
        fetchEditors(dispatchEditors, history)
          .catch(err => Logger.error("could not fetch editors", { error: err }))
      }

      if (!isGranted(new Set(['ROLE_DISTRIBUTOR', 'ROLE_CLIENT'])) && ((loadingDistributors === false && distributors === null) || errorDistributors !== null)) {
        fetchDistributors(dispatchDistributors, history)
          .catch(err => Logger.error("could not fetch distributors", { error: err }))
      }

      if (!isGranted(new Set(['ROLE_CLIENT'])) && ((loadingClients === false && clients === null) || errorClients !== null)) {
        fetchClients(dispatchClients, history)
          .catch(err => Logger.error("could not fetch clients", { error: err }))
      }

      if ((loadingLocations === false && locations === null) || errorLocations !== null) {
        fetchLocations(dispatchLocations, history)
          .catch(err => Logger.error("could not fetch locations", { error: err }))
      }
    }, [])
  }

  const handleChangeGame = (_game: string | null) => {
    setSearchParams(prev => {
      if (_game !== null) {
        prev.set(ConsumptionFilters.Game, _game)
      } else {
        prev.delete(ConsumptionFilters.Game)
      }
      return prev
    })
  }

  const handleChangeLocation = (_location: string | null) => {
    setSearchParams(prev => {
      if (_location !== null) {
        prev.set(ConsumptionFilters.Location, _location)
      } else {
        prev.delete(ConsumptionFilters.Location)
      }
      return prev
    })
  }

  const handleChangePeriod = (startDate: Date, endDate: Date) => {
    setSearchParams(prev => {
      prev.set(ConsumptionFilters.PeriodFrom, moment(startDate).format("YYYY-MM-DD"))
      prev.set(ConsumptionFilters.PeriodTo, moment(endDate).format("YYYY-MM-DD"))
      return prev
    })
  }

  const reverseIsOpen = () => {
    if (isOpen === 'true') {
      setIsOpen('false')
    } else {
      setIsOpen('true')
    }
  }

  const checkIsOpen = () => {
    if (location.pathname === getPath("consumption")) {
      return isOpen === 'true'
    } else {
      return true
    }
  }

  return (
    <>
      {rechargeCredit && creditBannerClosed === 'false' && (
        <Banner
          classes="consumption-banner"
          close={() => {
            setRechargeCredit(false)
            setCreditBannerClosed('true')
          }}
        >
          <Trans>You no longer have game credit.</Trans><BalanceModal />
        </Banner>
      )}
      <div className="row card mx-0">
        <div className="col-3">
          <SelectGames
            games={games}
            editors={editors}
            onSelect={handleChangeGame}
            value={searchParams.get(ConsumptionFilters.Game)}
          />
        </div>

        <div className="col-3">
          <SelectLocations
            locations={locations ?? []}
            distributors={distributors ?? []}
            clients={clients ?? []}
            onSelect={handleChangeLocation}
            value={searchParams.get(ConsumptionFilters.Location)}
          />
        </div>

        <div className="col-3 consumption-datepicker">
          <SelectDateRange
            onChange={handleChangePeriod}
            initialValue={{
              // @ts-expect-error TS2769
              startDate: new Date(searchParams.get(ConsumptionFilters.PeriodFrom) || moment(startOfMonth(new Date()))),
              // @ts-expect-error TS2769
              endDate: new Date(searchParams.get(ConsumptionFilters.PeriodTo) || moment(lastDayOfMonth(new Date()))),
            }}
          />
        </div>
        <div className="col text-end">
          <ExportConsumption />
        </div>
      </div>
      <div className="row">
        {searchParams.get(ConsumptionFilters.Location) &&
          <BalanceCard
            location={parseInt(searchParams.get(ConsumptionFilters.Location)!, 10)}
            game={searchParams.get(ConsumptionFilters.Game) ? parseInt(searchParams.get(ConsumptionFilters.Game)!, 10) : undefined}
            handleBanner={(bool) => setRechargeCredit(bool)}
          />
        }
        <div className="col d-flex">
          <div className="row card mx-0 mt-4 w-100">
            <div className="dashboard-info__content col-3">
              <div className="round round--65">
                <img className="p-2" alt="minutes consumed icon" src={require("../../../../static/images/minutes-consumed.svg")} />
              </div>

              <div className="dashboard-info__content-data">
                {(() => {
                  if (consumption.loading) {
                    return (
                      <Loader
                        inline={true}
                        message={false}
                        background={false}
                        position="left"
                        paddingX={false}
                      />
                    );
                  } else if (consumption.error) {
                    return <i className="icon icon-problem" />;
                  } else if (consumption.consumption === null) {
                    return <Trans>n/a</Trans>;
                  }

                  return <h3>
                    {`${(consumption.consumption.global.played.seconds - consumption.consumption.global.played.seconds % 60) / 60}m`}

                    <span className='small'>
                      {`${moment.duration(
                        consumption.consumption.global.played.seconds,
                        "seconds"
                      ).seconds()}s`}
                    </span>
                  </h3>;
                })()}

                <p>
                  <Trans>Duration consumed</Trans>
                </p>
              </div>
            </div>

            <div className="dashboard-info__content col-3">
              <div className="round round--65">
                <img className="p-2" alt="billed minutes icon" src={require("../../../../static/images/billed-minutes.svg")} />
              </div>

              <div className="dashboard-info__content-data">
                {(() => {
                  if (consumption.loading) {
                    return (
                      <Loader
                        inline={true}
                        message={false}
                        background={false}
                        position="left"
                        paddingX={false}
                      />
                    );
                  } else if (consumption.error) {
                    return <i className="icon icon-problem" />;
                  } else if (consumption.consumption === null) {
                    return <Trans>n/a</Trans>;
                  }

                  return <h3>
                    {`${(consumption.consumption.global.billable.seconds - consumption.consumption.global.billable.seconds % 60) / 60}m`}

                    <span className='small'>
                      {`${moment.duration(
                        consumption.consumption.global.billable.seconds,
                        "seconds"
                      ).seconds()}s`}
                    </span>
                  </h3>;
                })()}

                <p>
                  <Tooltip
                    tooltipContent={<Trans>
                      The "Duration billable" indicated may differ from the amount actually billed. To bill, we add up all the sessions to be billed for the same game and round up to the closest minute.
                    </Trans>}
                  >
                    <Trans>Duration billable</Trans>
                    <i className="ps-1 fa-solid fa-circle-info" />
                  </Tooltip>
                </p>
              </div>
            </div>
            <div className="dashboard-info__content col-3">
              <div className="round round--65">
                <img className="p-1" alt="sessions consumed icon" src={require("../../../../static/images/sessions-consumed.svg")} />
              </div>

              <div className="dashboard-info__content-data">
                {(() => {
                  if (consumption.loading) {
                    return (
                      <Loader
                        inline={true}
                        message={false}
                        background={false}
                        position="left"
                        paddingX={false}
                      />
                    );
                  } else if (consumption.error) {
                    return <i className="icon icon-problem" />;
                  } else if (consumption.consumption === null) {
                    return <Trans>n/a</Trans>;
                  }

                  return <h3>{consumption.consumption.global.played.sessions}</h3>;
                })()}

                <p>
                  <Trans>Played sessions</Trans>
                </p>
              </div>
            </div>
            <div className="dashboard-info__content col-3">
              <div className="round round--65">
                <img className="p-1" alt="billed sessions icon" src={require("../../../../static/images/billed-session.svg")} />
              </div>

              <div className="dashboard-info__content-data">
                {(() => {
                  if (consumption.loading) {
                    return (
                      <Loader
                        inline={true}
                        message={false}
                        background={false}
                        position="left"
                        paddingX={false}
                      />
                    );
                  } else if (consumption.error) {
                    return <i className="icon icon-problem" />;
                  } else if (consumption.consumption === null) {
                    return <Trans>n/a</Trans>;
                  }

                  return <h3>{consumption.consumption.global.billable.sessions}</h3>;
                })()}

                <p>
                  <Trans>Billed sessions</Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4 card" key={'foo'}>
        <div className="row">
          <div className="col-4">
            <h4 className="text-start">
              <Trans>Statistics</Trans>
            </h4>
            {checkIsOpen() && <div className='d-flex align-content-center justify-content-center mb-3'>
              <input type="radio" className="" name="options-outlined" id="minutes" checked={unit === Unit.Minutes} onChange={() => unit === Unit.Sessions && setUnit(Unit.Minutes)} />
              <label className={`btn ${unit === Unit.Minutes ? 'btn-primary' : 'btn-unselected'} ps-4 pe-4`} htmlFor="minutes">{t('By minutes')}</label>

              <input type="radio" className="" name="options-outlined" id="sessions" checked={unit === Unit.Sessions} onChange={() => unit === Unit.Minutes && setUnit(Unit.Sessions)} />
              <label className={`btn ${unit === Unit.Sessions ? 'btn-primary' : 'btn-unselected'} ps-4 pe-4`} htmlFor="sessions">{t('By sessions')}</label>
            </div>}
          </div>
          <div className={`col-8 dashboard-graphs-title ${checkIsOpen() ? 'mb-5' : ''} ${location.pathname === getPath("consumption") ? 'dashboard-graphs-title-hover' : ''}`} onClick={() => {
            if (location.pathname === getPath("consumption")) {
              reverseIsOpen()
            }
          }}>
            <h4 className="">
              <Trans>Daily consumption</Trans>
            </h4>
            {location.pathname === getPath("consumption") && <FontAwesomeIcon icon={checkIsOpen() ? faChevronDown : faChevronUp} className='dashboard-graphs-collapse-arrow' size='xl' />}
          </div>
        </div>
        {checkIsOpen() && <div className="row">
          <div className="col-4">
            {doughnut}
          </div>
          <div className="col-8">
            {stackedBar}
          </div>
        </div>}
      </div>
    </>
  );
}
