import React, { useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { useTranslation } from 'react-i18next';

import ErrorMessage from "components/ErrorMessage/ErrorMessage"
import getLaunchers from "api/launchers/get"
import { Trans } from 'react-i18next'
import Loader from "components/Loader/Loader"
import getToken from "services/security/token"
import isGranted from "services/security/voter"
import ListCard from "components/ListCard/ListCard"
import { list as listLaunchers } from "api/launchers/list"
import LaunchersPasswordModal from "./components/LaunchersPasswordModal/LaunchersPasswordModal"
import LaunchersAddModal from "./components/LaunchersAddModal/LaunchersAddModal"
import { get } from "api/launchers/property"
import { list } from "api/locations/list"
import listGames from "api/launchers/games"
import { getPath } from "routes"
import { fetchGames, GamesConsumerHook } from "services/stores/gamesStore"
import Launcher, { LauncherWithVersion } from "model/launcher"
import SelectLocations from "components/SelectLocations/SelectLocations";
import LauncherGame from "model/launcher_game";
import Location from "model/location";
import Logger from "services/logger";

export enum LauncherFilters {
  Location = 'filters[location]',
}

export default function Launchers() {
  const { t } = useTranslation();
  const [{ games, loading, error }, dispatch] = GamesConsumerHook()
  const [launchers, setLaunchers] = useState<LauncherWithVersion[] | null>(null)
  const [locations, setLocations] = useState<Location[] | null>(null)
  const [loadingLauncher, setLoadingLauncher] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()

  const handleLocation = (location: string | null) => {
    if (location === null) {
      setSearchParams(prev => {
        prev.delete(LauncherFilters.Location)
        return prev
      })
    } else {
      const number = Number.parseInt(location, 10)
      setSearchParams(prev => {
        prev.set(LauncherFilters.Location, number.toString())
        return prev
      })
    }
  }

  const getVersionAndGames = async (data: Launcher[]) => {
    const result: LauncherWithVersion[] = []

    for (const launcher of data) {
      const launcherWithVersion: LauncherWithVersion = { ...launcher, version: null, games: [] };
      if (isGranted(new Set(['ROLE_EDITOR']))) {
        launcherWithVersion.games = (games || []).map((g) => g.id)
      } else {
        const responseLauncherGames = await listGames(launcherWithVersion.id)
        if (responseLauncherGames !== null && responseLauncherGames.ok) {
          const launcherGamesJson: LauncherGame[] = await responseLauncherGames.json()
          launcherWithVersion.games = launcherGamesJson.map((lg) => lg.game.id)
        }
      }
      const responseVersion = await get(launcherWithVersion.id.toString())
      if (responseVersion !== null && responseVersion.ok) {
        const version = await responseVersion.json()
        launcherWithVersion.version = version.launcherCurrentVersion
      }
      result.push(launcherWithVersion)
    }
    return result
  }

  const handleAdd = (launcher: Launcher | null) => {
    if (launcher !== null) {
      getVersionAndGames([launcher]).then((results) => {
        setLaunchers(prev => {
          if (prev === null) {
            return results
          }
          return [...prev, ...results]
        })
      })
    }
  }

  useEffect(() => {
    if ((games === null && loading === false) || error !== null) {
      fetchGames(dispatch, history)
    }
  }, [dispatch, error, games, history, loading])

  useEffect(() => {
    if (locations === null) {
      list().then((res) => {
        if (res.success && res.success === true) {
          setLocations(res.data)
        } else {
          // TODO: handle error
          Logger.warn('could not get locations', { errors: res.errors })
        }
      })
    }
  }, [locations])

  useEffect(() => {
    if (
      getToken()?.context.id === null ||
      getToken()?.context.id === undefined
    ) {
      // TODO: handle error
      Logger.warn('could not get user id from token', { token: getToken() })
    } else if (launchers === null && loadingLauncher === false && games !== null) {

      const addVersionAndGamesThenSetLaunchers = async (data: Launcher[]) => {
        const res = await getVersionAndGames(data)
        setLaunchers(res)
        setLoadingLauncher(false)
        if (res?.length >= 1) {
          setDisabled(true)
        }
      }

      if (isGranted(new Set(["ROLE_EDITOR"]))) {
        getLaunchers().then(async (response) => {
          if (response === null || !response.ok) {
            // TODO: handle error
            Logger.warn('could not get launchers', { errors: await response?.json() ?? 'no error' })
            return
          }
          const data = await response.json()
          await addVersionAndGamesThenSetLaunchers(data as Launcher[])
        })
      } else {
        listLaunchers().then(async ({ success, data, errors }) => {
          if (!success) {
            // TODO: handle errors
            Logger.warn('could not get launchers', { errors })
            return
          }
          await addVersionAndGamesThenSetLaunchers(data as Launcher[]);
        })
      }
    }
  }, [launchers, loadingLauncher, error, games])

  return (() => {
    if (isGranted(new Set(["ROLE_EDITOR"])) === false && isGranted(new Set(["ROLE_CLIENT"])) === false && isGranted(new Set(["ROLE_ADMIN"])) === false) {
      return (
        <ErrorMessage
          message={t("You do not have the permissions to access this page")}
        />
      )
    } else if (error !== null && error !== undefined) {
      return <ErrorMessage message={error} />
    } else if (loadingLauncher === true || launchers === null) {
      return <Loader message={t("Fetching launchers data")} inline={true} />
    }

    if (launchers) {
      return (
        <div className="launchers">
          <div className="row">
            {isGranted(new Set(['ROLE_EDITOR'])) &&
              <>
                <div className="col-10">
                  <p className="launchers__description">
                    <Trans>
                      {" "}
                      This page allows you to see your launchers. If you already
                      have a launcher, you reach your maximum then contact an
                      administrator for more launchers.
                    </Trans>
                  </p>
                  <div className="button-group--left mb-5 mt-3">
                    <a
                      className="btn btn-primary"
                      href={`${process.env.API || ""
                        }/api/v1/softwares/launcher-installers/latest/download/windows`}
                    >
                      <i className="icon--white icon-download me-2" />
                      <Trans>Download launcher </Trans>
                    </a>
                  </div>
                </div>
                <div className="col-2">
                  <LaunchersAddModal callback={handleAdd} disabled={disabled} />
                </div>
              </>
            }
            {!isGranted(new Set(['ROLE_EDITOR'])) && locations !== null &&
              <div className="w-50 mb-5">
                <SelectLocations locations={locations} onSelect={handleLocation} value={searchParams.get(LauncherFilters.Location)} />
              </div>
            }
            <div className="col-12">
              {(launchers || []).map((launcher) => {
                return (
                  <div key={launcher.id}>
                    {
                      (
                        searchParams.get(LauncherFilters.Location) === null ||
                        (
                          searchParams.get(LauncherFilters.Location) !== null &&
                          Number.parseInt(searchParams.get(LauncherFilters.Location)!, 10) === launcher.location
                        )
                      ) &&
                      <div className="mb-5">
                        <ListCard
                          key={launcher.id}
                          content={
                            <>
                              <div className="row">
                                <span className="col-4">{t('Login')}</span>
                                <span className="col text-center">{t('Version installed')}</span>
                                <span className="col text-center">{t('Related games')}</span>
                                <span className="col text-center">{t('Blocked account')}</span>
                              </div>
                              <div className="row align-items-center h-100">
                                <span className="col-4 fs-4 fw-bold">{launcher.couchDBId}</span>
                                <span className="col fs-4 fw-bold text-center">{launcher.version ?? '-'}</span>
                                <span className="col fs-6 text-center">
                                  <u>
                                    <Link to={getPath('games', null, { 'filters[launcher]': launcher.id }) ?? ''}>
                                      {(launcher.games || []).length}
                                    </Link>
                                  </u>
                                </span>
                                <span className="col fs-6 text-center">{launcher.blockedAt ?? '-'}</span>
                              </div>
                            </>
                          }
                          actions={<LaunchersPasswordModal launcher={launcher} />}
                        />
                      </div>
                    }
                  </div>
                )
              })
              }
            </div >
          </div >
        </div >
      )
    }

    return null
  })()
}
