import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { SelectWithCollapsableGroups } from "@octopod/design-system";

import { FormProvider, FormConsumer } from "stores/formStore";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import getProfile from "api/profile/get";
import Loader from "components/Loader/Loader";
import getToken from "services/security/token";
import ChangePasswordModal from "./components/ChangePasswordModal/ChangePasswordModal";
import SaveProfileModal from "./components/SaveProfileModal/SaveProfileModal";
import PhoneNumberInput from "./components/PhoneNumberInput/PhoneNumberInput";
import User from "model/user";

export default function Profile() {
  const { t } = useTranslation();
  const [profile, setProfile] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const locales = [{ label: t('English'), value: 'en' }, { label: t('French'), value: 'fr' }]

  useEffect(() => {
    if (
      getToken()?.context.id === null ||
      getToken()?.context.id === undefined
    ) {
      setError(
        t("Try to reconnect. If the problem persists, contact the support.")
      );
    } else if (profile === null && loading === false) {
      setLoading(true);
      getProfile().then(async (response: Response | null) => {
        if (response === null || !response.ok) {
          setError(t("Could not fetch profile data"))
          setLoading(false)
          return
        }

        const data = await response.json()
        if (data.phone === null) {
          data.phone = ''
        }
        setError(null)
        setProfile(data)
        setLoading(false)
      })
    }
  }, [profile, loading, error]);

  return (() => {
    if (loading === true) {
      return <Loader message="Fetching profile data" inline={true} />;
    } else if (error !== null && error !== undefined) {
      return <ErrorMessage message={error} />;
    }

    if (profile) {
      return (
        <div className="row justify-content-center">
          <div className="col-12 col-lg-11 col-xl-8 card p-5">
            <div className="form-header">
              <h4 className="mb-3">
                <Trans>My profile</Trans>
              </h4>
              <div className="form-header__container">
                <i className="icon icon-user" />
              </div>
            </div>

            <FormProvider>
              <form className="profile-form">
                <div className="row py-3 pb-5 justify-content-between mt-5">
                  <div className="col-5">
                    <div className="input-container">
                      <FormConsumer>
                        {([{ data, errors }, dispatch]) => (
                          <>
                            <input
                              className="form-control"
                              placeholder={t("Firstname")}
                              required
                              type="text"
                              defaultValue={
                                data?.firstname || profile.firstname
                              }
                              onChange={(e) =>
                                dispatch({
                                  type: "setValue",
                                  field: "firstname",
                                  value: e.target.value,
                                  initialValue: profile.firstname,
                                })
                              }
                            />

                            {errors
                              .filter(
                                (error) => error?.firstname === "firstname"
                              )
                              .map(({ reason }) => (
                                <small className="form-text text-danger">
                                  {reason}
                                </small>
                              ))}
                          </>
                        )}
                      </FormConsumer>
                      <i className="icon icon-edit" />
                      <div className="liner" />
                    </div>
                  </div>

                  <div className="col-5">
                    <div className="input-container">
                      <FormConsumer>
                        {([{ data, errors }, dispatch]) => (
                          <>
                            <input
                              className="form-control"
                              placeholder={t("Lastname")}
                              required
                              type="text"
                              defaultValue={data?.lastname || profile.lastname}
                              onChange={(e) =>
                                dispatch({
                                  type: "setValue",
                                  field: "lastname",
                                  value: e.target.value,
                                  initialValue: profile.lastname,
                                })
                              }
                            />

                            {errors
                              .filter((error) => error?.lastname === "lastname")
                              .map(({ reason }) => (
                                <small className="form-text text-danger">
                                  {reason}
                                </small>
                              ))}
                          </>
                        )}
                      </FormConsumer>
                      <i className="icon icon-edit" />
                      <div className="liner" />
                    </div>
                  </div>
                </div>

                <div className="row pt-3 mb-5 justify-content-between">
                  <div className="col-5">
                    <p className="main-field semiBold">{profile.email}</p>
                  </div>

                  <div className="col-5">
                    <div className="input-container input-group">
                      <FormConsumer>
                        {([{ data, errors }, dispatch]) => (
                          <>
                            { }
                            <PhoneNumberInput
                              phoneNumber={typeof data?.phone === 'string' ? data.phone : profile.phone}
                              onChange={(value) => {
                                dispatch({
                                  value,
                                  type: "setValue",
                                  field: "phone",
                                  initialValue: profile.phone,
                                })
                              }}
                            />
                            {errors
                              .filter((error) => error?.phone === "phone")
                              .map(({ reason }) => (
                                <small className="form-text text-danger">
                                  {reason}
                                </small>
                              ))}
                          </>
                        )}
                      </FormConsumer>
                      <i className="icon icon-edit" />
                      <div className="liner" />
                    </div>
                  </div>
                </div>
                <div className="row pt-3 mb-5 justify-content-between">

                  <div className="col-5">
                    <FormConsumer>
                      {([{ data, errors }, dispatch]) => (
                        <>
                          <SelectWithCollapsableGroups
                            onChange={(value) => dispatch({
                              type: "setValue",
                              field: "emailLanguage",
                              value: value,
                              initialValue: profile.emailLanguage,
                            })}
                            data={locales}
                            value={data?.emailLanguage || profile.emailLanguage}
                            placeholder={t('Email language')}
                          />
                          {errors
                            .filter((error) => error?.emailLanguage === "emailLanguage")
                            .map(({ reason }) => (
                              <small className="form-text text-danger">
                                {reason}
                              </small>
                            ))}
                        </>
                      )}
                    </FormConsumer>
                    <div className="liner" />
                  </div>
                </div>
                <div className="button-group--right mb-5 float-end">
                  <SaveProfileModal profile={profile} callback={(p) => setProfile(p)} />
                </div>
              </form>
            </FormProvider>
            <ChangePasswordModal profile={profile} />
          </div>
        </div>
      );
    }

    return null;
  })();
}
