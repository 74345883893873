import React, { createContext, useContext, useReducer } from "react";
import type { Context, PropsWithChildren } from "react";

import Logger from "../logger";
import { list as listLaunchers } from "api/launchers/list";
import Unauthorized from "exception/unauthorized";
import { getPath } from "routes";
import { fetchAll } from "services/helper/fetch_all";
import Launcher from "model/launcher";

type State = {
  launchers: Launcher[] | null;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
};

const initialState: State = {
  launchers: null,
  loading: false,
  error: null,
};

type Action = {
  type: "FETCHING" | "FETCHING_SUCCESS" | "FETCHING_ERROR";
  launchers?: Launcher[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "FETCHING":
      return {
        launchers: null,
        loading: true,
        error: null,
      };

    case "FETCHING_SUCCESS":
      return {
        launchers: action.launchers ?? null,
        loading: false,
        error: null,
      };

    case "FETCHING_ERROR":
      return {
        launchers: [],
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

const LaunchersContext: Context<[State, (action: Action) => void]> =
  createContext([
    initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (action: Action) => Logger.error("Context not correctly initialized"),
  ]);

export const LaunchersConsumer = LaunchersContext.Consumer;
export const LaunchersConsumerHook = () => useContext(LaunchersContext);
export const LaunchersProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <LaunchersContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </LaunchersContext.Provider>
  );
};

export const fetchLaunchers = async (
  dispatch: (action: Action) => void,
  history: unknown
) => {
  dispatch({
    type: "FETCHING",
  });

  const { success, data: launchers, error } = await fetchAll(listLaunchers)
  if (!success || error !== null) {
    if (error instanceof Unauthorized && history instanceof Array) {
      history.push(getPath("login"));
    } else {
      dispatch({
        type: "FETCHING_ERROR",
        error: error,
      });
    }
    return
  }

  dispatch({
    type: "FETCHING_SUCCESS",
    launchers
  });
};
