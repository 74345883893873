import { stringify } from "qs";

import { raw } from "security/token";

export default async function create(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  const response = await fetch(`${process.env.API || ""}/api/v1/support`, {
    method: "POST",
    headers: {
      "x-auth-token": raw() || "",
      "content-type": "application/x-www-form-urlencoded",
    },
    body: stringify(data),
  });

  if (response.status === 204) {
    return {
      success: true,
    };
  }

  const body = await response.json();
  return {
    success: false,
    errors: body.errors,
  };
}
