import React, { useEffect, useMemo, useRef, useState } from "react";
import { marked } from "marked";
import { useTranslation } from 'react-i18next';

import { Trans } from 'react-i18next';
import { GameReleasesConsumerHook } from "stores/gameReleasesStore";
import Loader from "components/Loader/Loader";
import showRelease from "api/release/show";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ActivateChannel from "./components/ActivateChannel/ActivateChannel";
import FlagAsUnavailable from "./components/FlagAsUnavailable/FlagAsUnavailable";
import isGranted, { Role } from "security/voter";

export default function GameShowReleases() {
  const { t } = useTranslation();
  const [{ release, releases }, dispatch] = GameReleasesConsumerHook();

  const _release = useMemo(() => {
    if (release === null || releases === null) {
      return null;
    }
    return releases.find((r) => r.id === release) ?? null;
  }, [release, releases]);


  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const manifestContainer = useRef<HTMLDivElement | null>(null);
  const manifestText = useRef<HTMLPreElement | null>(null);
  useEffect(() => {
    if (
      release !== null &&
      _release !== null &&
      _release.statistics === undefined &&
      loading === false
    ) {
      setLoading(true);
      showRelease(release).then((result) => {
        if (result.success) {
          dispatch({
            type: "updateRelease",
            release: result.data,
          });
          setLoading(false);
        } else {
          setErrors(result.errors);
        }
      });
    }
  }, [dispatch, loading, release, _release]);
  useEffect(() => {
    if (manifestContainer.current === null || manifestText.current === null) {
      return;
    }

    let height: number | null | undefined = manifestText.current?.clientHeight;

    if (height) {
      if (expanded === true) {
        height += 20;
        manifestContainer.current.style.height = `${height}px`;
      } else {
        manifestContainer.current.style.height = "100px";
      }
    }
  }, [expanded]);

  if ((releases || []).length === 0) {
    return (
      <ErrorMessage
        message="To upload your first release, see the <0>developer documentation</0>"
        components={[
          <a
            href="https://developer.octopodvr.com"
            target="_blank"
            rel="noopener noreferrer"
          />,
        ]}
      />
    );
  } else if (_release === null) {
    return <ErrorMessage message="No release selected" />;
  }

  return (
    <div className="game-container game-releases">
      {errors && (
        <div className="alert alert-warning">
          <Trans>Could not fetch more detail about this release</Trans>
        </div>
      )}

      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="row group-label-release-header">
              <div>
                <div className="game-tooltip">
                  <p className="single-label">
                    <Trans>Installation</Trans>
                    <i className="fas fa-info-circle ms-2" />
                  </p>
                  <p className="game-tooltip-content">
                    <Trans>Total installations in LBVR</Trans>
                  </p>
                </div>
                <div className="main-field">
                  {(() => {
                    if (errors) {
                      return <i className="icon icon-problem" />;
                    } else if (loading) {
                      return <Loader inline={true} message={false} />;
                    }

                    return _release?.statistics?.installed;
                  })()}
                </div>
              </div>

              <div>
                <p className="single-label">
                  <Trans>Channels</Trans>
                </p>

                <span
                  style={{
                    marginRight: "38px",
                  }}
                >
                  <ActivateChannel release={_release} name="alpha" />
                </span>
                <span>
                  <ActivateChannel release={_release} name="stable" />
                </span>
              </div>

              {isGranted(new Set([Role.Admin])) && (
                <div>
                  <p className="single-label">
                    <Trans>Actions</Trans>
                  </p>

                  <span>
                    <FlagAsUnavailable release={_release}/>
                  </span>
                </div>
              )}
            </div>

            <p className="single-label mt-5">
              <Trans>Note</Trans>
            </p>

            <p
              className="main-field small-body"
              dangerouslySetInnerHTML={{
                __html: marked.parse(_release.changelog || "n/a"),
              }}
            />

            <p className="single-label mt-5">
              <Trans>Manifest</Trans>
            </p>

            <div
              className={`expanded-window ${expanded ? "active" : ""}`}
              ref={manifestContainer}
            >
              <pre ref={manifestText} className="main-field small-body">
                {JSON.stringify(_release.manifest, null, 2)}
              </pre>
            </div>

            <div className="expanded-btn">
              <button onClick={toggleExpanded}>
                <i
                  className={`fa me-2 fa-chevron-down rotate-0 ${expanded ? "rotate-180" : ""
                    }`}
                ></i>
                {expanded ? t("show me less") : t("show me more")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
