import React from "react";
import { useTranslation } from 'react-i18next';

export default function Article1() {
  const { t } = useTranslation();

  return (
    <div className="text-primary">
      <ul className="list-unstyled d-grid gap-3">
        <li>
          {t(`The Services accessible from the online platforms and website(s) are the property of Wanadev, published and hosted by the latter.`)}
        </li>
        <div className="row justify-content-center">
          <span className="col-6 text-end">
            {t(`Director of publication:`)}
          </span>
          <span className="col-6 text-start">
            {t(`Mr. Côme de Percin`)}
          </span>
        </div>
        <div className="row justify-content-center">
          <span className="col-6 text-end">
            {t(`Publisher & Host:`)}
          </span>
          <span className="col-6 text-start">
            {t(`Wanadev, EURL with a share capital of 100 000 €`)}<br/>
            {t(`13 Quai du Commerce 69 009 Lyon`)}<br/>
            {t(`R.C.S. Lyon 511 500 811`)}
          </span>
        </div>
        <div className="row justify-content-center">
          <span className="col-6 text-end">
            {t(`Contact details:`)}
          </span>
          <span className="col-6 text-start list-unstyled">
            <li className="">{t(`Phone: +33 (0)4 28 29 69 01`)}</li>
            <li className="">{t(`Email: contact@wanadev.fr`)}</li>
          </span>
        </div>
        <li>
          {t(`Requests for information and exercise of rights relating to Users' personal data may be sent directly via the company's contact address.`)}
        </li>
      </ul>

    </div>
  )
}