import React, { useMemo } from "react";
import { SelectWithCollapsableGroups } from "@octopod/design-system";
import { useTranslation } from "react-i18next";

import Launcher from "model/launcher";

interface Props {
  launchers: Launcher[] | null
  onSelect: (val: string | null) => void
  value: string | null
}

interface Node {
  label: string
  value: string
  children?: Node[]
}

export default function SelectLaunchers({ launchers, onSelect, value = null }: Props) {
  const { t } = useTranslation();
  // useMemo for performance concerns (re-compute data tree only when needed)
  const dataTree = useMemo(() => processNodes(launchers), [launchers])
  const hasData = dataTree.length > 0

  return (
    <SelectWithCollapsableGroups
      data={dataTree}
      value={value}
      placeholder={hasData ? t('Please select a launcher') : t('Please select a location')}
      isDisabled={!hasData}
      isClearable={hasData}
      onChange={hasData ? onSelect : () => { /* do nothing */ }}
    />
  )
}

function processNodes(launchers: Launcher[] | null): Node[] {
  if (launchers === null) {
    return []
  }

  return launchers.map(launcher => {
    return {
      label: launcher.couchDBId,
      value: launcher.id.toString(),
    }
  })
}