import { Summary } from "model/balance";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Logger from "services/logger";
import { BalanceSummariesConsumerHook, fetchBalanceSummaries } from "services/stores/balanceSummariesStore";
import { LocationsConsumerHook } from "services/stores/locationsStore";
import { useLocalStorage } from "services/hooks/useLocalStorage";

interface Props {
  handleBanner(bool: boolean): void
  location: number
  game?: number
}

interface SummaryWithLastOperation extends Summary {
  lastOperation: { minutes: string | null, sessions: string | null } | null
}

export default function BalanceCards({ location, game, handleBanner }: Props) {
  const { t } = useTranslation();
  const [{ summaries, loading, error }, dispatch] = BalanceSummariesConsumerHook();
  const [{ locations }] = LocationsConsumerHook();
  const [summary, setSummary] = useState<SummaryWithLastOperation | undefined>()
  const [creditBannerClosed, setCreditBannerClosed] = useLocalStorage('creditBannerClosed', 'false');

  const shouldShowBlock = (type: 'minutes' | 'sessions') => {
    if (summary === undefined) {
      return false
    }
    let shouldShow = false
    if (type === 'minutes') {
      if (summary.minutes === 0 && summary.sessions === 0 && summary.lastOperation?.minutes != null && moment(summary.lastOperation?.minutes).isAfter(moment().subtract(3, 'month'))) {
        handleBanner(true)
        shouldShow = true
      } else {
        shouldShow = summary.minutes !== 0
      }
    }
    if (type === 'sessions') {
      if (summary.sessions === 0 && summary.minutes === 0 && summary.lastOperation?.sessions != null && moment(summary.lastOperation?.sessions).isAfter(moment().subtract(3, 'month'))) {
        handleBanner(true)
        shouldShow = true
      } else {
        shouldShow = summary.sessions !== 0
      }
    }

    if (shouldShow && creditBannerClosed === 'true') {
      setCreditBannerClosed('false')
    }
    return shouldShow
  }

  useEffect(() => {
    if (!locations || locations.length === 0) {
      setSummary({
        minutes: 0,
        sessions: 0,
        lastOperation: {
          minutes: null,
          sessions: null
        },
      })
    }
    if (summaries === null && loading === false && locations) {
      fetchBalanceSummaries(dispatch, locations.map((l) => l.id))
    } else if (location && summaries) {
      const summaryIfExists = summaries.find((s) => s.location === location)
      if (summaryIfExists) {
        if (game) {
          const newSummary: SummaryWithLastOperation = {
            minutes: 0,
            sessions: 0,
            lastOperation: {
              minutes: null,
              sessions: null
            },
          }
          if (summaryIfExists.details && summaryIfExists.details.length !== undefined) {
            const gameSummary = (summaryIfExists.details as Record<string, Summary>)[game.toString()]
            if (gameSummary) {
              newSummary.minutes = gameSummary.minutes
              newSummary.sessions = gameSummary.sessions
            }
          }
          newSummary.lastOperation = summaryIfExists.lastOperation
          setSummary(newSummary)
        } else {
          setSummary({ ...summaryIfExists.summary, lastOperation: summaryIfExists.lastOperation })
        }
      }
    }
  }, [location, summaries, game, locations])

  if (error) {
    Logger.error("could not fetch balance summaries", { error })
  }

  if (
    (summaries === null || summaries.length === 0 || summary === undefined) ||
    (!shouldShowBlock('minutes') && !shouldShowBlock('sessions'))
  ) {
    return <></>
  }

  return (
    <>
      <div className="col-auto d-flex">
        <div className="balance-card-container mx-0 mt-4">
          {shouldShowBlock('minutes') &&
            <div className="card balance-card-body">
              <div className="balance-card-title">{t("Remaining credit")}</div>
              <div className="balance-card-content">{`${summary.minutes} ${t('Minutes')}`}</div>
              <div className="balance-card-date">{t('On {{date}}', { date: moment().format('L') })}</div>
            </div>
          }
          {shouldShowBlock('sessions') &&
            <div className="card balance-card-body">
              <div className="balance-card-title">{t("Remaining credit")}</div>
              <div className="balance-card-content">{`${summary.sessions} ${t('Sessions')}`}</div>
              <div className="balance-card-date">{t('On {{date}}', { date: moment().format('L') })}</div>
            </div>
          }
        </div>
      </div>
    </>
  )
}
