import React from 'react'
import { DateRangePicker } from '@octopod/design-system'

import './override.css'

interface Props {
  initialValue?: { startDate: Date, endDate: Date }
  onChange?: (startDate: Date, endDate: Date) => void
}

export default function SelectDateRange({ onChange, initialValue = undefined }: Props) {
  return <DateRangePicker
    onChange={onChange}
    initialValue={initialValue}
  />
}