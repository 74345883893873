import { t } from 'i18next'
import SessionConfig, { Config, TeamConfig } from 'model/session_config'
import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { SessionsConsumerHook, fetchSessionConfig } from 'services/stores/sessionsStore'


interface SessionConfigsProps {
  session: string | null
  launcher: number
}

export function SessionConfigs({ session, launcher }: SessionConfigsProps) {
  const [sessionsState, dispatchSessions] = SessionsConsumerHook()

  const [config, setConfig] = useState<SessionConfig | undefined>(undefined)

  useEffect(() => {
    const sessionExtended = sessionsState.sessionsByLauncher.find(sbl => sbl.launcher === launcher)?.sessions.find(s => s._id === session)
    if (sessionsState.loading === false && session !== null && sessionExtended?.configs === undefined) {
      fetchSessionConfig(dispatchSessions, launcher, session)
      return;
    }
    if (sessionExtended?.configs === undefined && config !== undefined) {
      setConfig(undefined)
      return;
    }
    setConfig(sessionExtended?.configs)
  }, [session, launcher, sessionsState])

  const processTeams = (teams: Record<string, TeamConfig>) => {
    const tables: JSX.Element[] = []
    for (const [key, value] of Object.entries(teams)) {
      tables.push(
        <div key={key}>
          <div className='session-config_teams'>{t('Team {{teamNumber}}', { teamNumber: tables.length + 1 })}</div>
          {getTable(t('Server'), value.server, `server_${key}`)}
          {processPlayers(value.players)}
        </div>
      )
    }
    return (
      <>
        {tables}
      </>
    )
  }

  const processPlayers = (players: Record<string, Config>) => {
    const tables: JSX.Element[] = []
    for (const [key, value] of Object.entries(players)) {
      tables.push(getTable(t('Player {{playerNumber}}', { playerNumber: tables.length + 1 }), value, key))
    }
    return (
      <>
        {tables}
      </>
    )
  }

  const getTable = (title: string, config: Config, key: string) => {
    return <div key={key}>
      <div className='session-config_section-title'>
        {title}
      </div>
      <table className='session-config_table'>
        <thead>
          <tr>
            <th><Trans>Options</Trans></th>
            <th><Trans>Values</Trans></th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(config).length === 0 && (
            <tr>
              <td colSpan={2}><Trans>Nothing to show</Trans></td>
            </tr>
          )}
          {Object.entries(config).map(([k, value]) => (
            <tr key={k}>
              <td>{k}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  }

  if (config === undefined) {
    return (
      <div>
        <span>loading...</span>
      </div>
    )
  }

  const { server, spectator, teams } = config;

  return (
    <div className='session-config'>
      {getTable(t('Server'), server, 'server')}
      {getTable(t('Spectator'), spectator, 'spectator')}
      {processTeams(teams)}
    </div>
  )
}