import { raw } from "security/token"
import Logger from "services/logger"

export async function list(
  launcher: number,
  session: string,
  limit?: number,
  offset?: number,
): Promise<Response | null> {
  const params = new URLSearchParams()

  if (limit != null) {
    params.append('limit', limit.toString(10))
  }

  if (offset != null) {
    params.append('offset', offset.toString(10))
  }

  const headers: HeadersInit = {
    "x-auth-token": raw() || "",
  }

  return fetch(
    `${process.env.API || ""}/api/v1/launchers/${launcher}/_/sessions/${session}/team-sessions`,
    {
      method: "GET",
      headers
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "api/v1/launchers/:id/_/sessions/:id2/team-sessions"', {
      launcher,
      session,
      limit,
      offset,
      errors
    })
    return Promise.resolve(null);
  })
}