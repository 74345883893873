import React, { useMemo } from "react";
import { SelectWithCollapsableGroups } from "@octopod/design-system";
import { useTranslation } from "react-i18next";

import Launcher from "model/launcher";
import Location from "model/location";
import Logger from "services/logger";

import PictoLocation from "images/picto-location.svg";

interface Props {
  launchers: Launcher[] | null
  locations: Location[] | null
  onSelect: (val: string | null) => void
  value: string | null
}

interface Node {
  label: string
  value: string
  children?: Node[]
}

export default function SelectLaunchers({ launchers, locations, onSelect, value = null }: Props) {
  const { t } = useTranslation();
  // useMemo for performance concerns (re-compute data tree only when needed)
  const dataTree = useMemo(() => processNodes(launchers, locations), [launchers, locations])
  const hasData = dataTree.length > 0

  const iconLauncher = <img alt={t("Launcher icon")} className="picto-blue"/>
  const iconLocation = <img alt={t("Location icon")} src={PictoLocation} />

  const levelIcons = []

  if (locations !== null) {
    levelIcons.push(iconLocation)
  }
  levelIcons.push(iconLauncher)

  const handleSelect = (launcherId: string | null) => {
    if (launcherId === null) {
      onSelect(null)
    } else {
      onSelect(launcherId.replace("launcher-", ""))
    }
  }

  return (
    <SelectWithCollapsableGroups
      data={dataTree}
      value={value ? `launcher-${value}` : null}
      placeholder={hasData ? t('Please select a launcher') : t('Loading')}
      levelIcons={levelIcons}
      inputIcon={hasData ? iconLauncher : null}
      isDisabled={!hasData}
      isClearable={hasData}
      onChange={hasData ? handleSelect : () => { /* do nothing */ }}
    />
  )
}

function processNodes(launchers: Launcher[] | null, locations: Location[] | null): Node[] {
  if (launchers === null) {
    return []
  }

  const dataTree: Node[] = []

  if (locations !== null) {
    locations
      .sort((e1, e2) => e1.name.localeCompare(e2.name))
      .forEach(l => dataTree.push({ label: l.name, value: `location-${l.id.toString()}`, children: [] }))
  }

  launchers
    .sort((e1, e2) => e1.couchDBId.localeCompare(e2.couchDBId))
    .forEach(launcher => {
      if (locations !== null) {
        const targetLocation = `location-${launcher.location}`
        for (let i = 0; i < dataTree.length; i++) {
          const dataTreeItem = dataTree[i]
          if (dataTreeItem.value === targetLocation && dataTreeItem.children !== undefined) {
            dataTreeItem.children.push({ label: launcher.couchDBId, value: `launcher-${launcher.id}` })
            return
          }
        }

        Logger.warn("unknown location for launcher", { launcher: launcher })
      } else {
        dataTree.push({ label: launcher.couchDBId, value: `launcher-${launcher.id}` })
        return
      }
    })

  return dataTree.filter(node => node.children !== undefined && node.children.length > 0)
}
