import React from "react";
import moment from 'moment'
import { useSearchParams } from "react-router-dom";

import {Consumption} from "components/Consumption/ConsumptionWrapper/compute";
import {GamesConsumerHook} from "stores/gamesStore";
import Logger from "services/logger";
import LocationGameDetailsTable
  from "components/Consumption/ConsumptionDetails/components/LocationGameDetailsTable/LocationGameDetailsTable";

export enum DetailsParams {
  Game = 'details[game]',
  Location = 'details[location]'
}

interface Props {
  game: number
  location: number
  consumption: Consumption
}

export default function GameLocationRow({ game: gameId, location, consumption}: Props) {
  const [{ games, loading, error }] = GamesConsumerHook()
  const [searchParams, setSearchParams] = useSearchParams()

  const detailsOpened = (searchParams.get(DetailsParams.Game) === gameId.toString()) && (searchParams.get(DetailsParams.Location) === location.toString())

  const handleOpenDetails = () => {
    setSearchParams(prev => {
      prev.set(DetailsParams.Game, gameId.toString())
      prev.set(DetailsParams.Location, location.toString())
      return prev
    })
  }

  const handleCloseDetails = () => {
    setSearchParams(prev => {
      prev.delete(DetailsParams.Game)
      prev.delete(DetailsParams.Location)
      return prev
    })
  }

  if (loading) {
    Logger.debug('games not loaded', {})
    return null
  } else if (error) {
    Logger.warn('could not get games', { error })
    return null
  }

  const game = games?.find(g => g.id === gameId)
  if (!game) {
    Logger.warn('game not found', { id: gameId })
    return null
  }

  return (
    <>
      <tr>
        <td>
          <button
            className="btn btn-primary"
            style={{ border: "none", marginRight: "1rem", }}
            onClick={() => detailsOpened ? handleCloseDetails() : handleOpenDetails()}
          >
            <span>{detailsOpened ? "-" : "+"}</span>
          </button>
          {game.name}
        </td>
        <td className="text-center">
          {`${(consumption.played.seconds - consumption.played.seconds % 60) / 60}m${moment.duration(
              consumption.played.seconds,
              "seconds"
            ).seconds()}s`}
        </td>
        <td className="text-center">
          {`${(consumption.billable.seconds - consumption.billable.seconds % 60) / 60}m${moment.duration(
              consumption.billable.seconds,
              "seconds"
            ).seconds()}s`}
        </td>
        <td className="text-center">
          {consumption.played.sessions}
        </td>
        <td className="text-center">
          {consumption.billable.sessions}
        </td>
      </tr>
      {detailsOpened && <LocationGameDetailsTable game={game.id} location={location} />}
    </>
  )
}
