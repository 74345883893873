import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import moment from "moment";

import Loader from "components/Loader/Loader";
import { fetchAll } from "services/helper/fetch_all";
import { getGameSessionDetails } from "api/consumption/get";
import { ConsumptionFilters } from "components/Consumption/ConsumptionSummary/filters";
import Logger from "services/logger";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

interface Props {
  game: number
  location: number
}

export default function LocationGameDetailsTable({ game, location }: Props) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [details, setDetails] = useState<any[]>([])

  useEffect(() => {
    const filterFrom = searchParams.get(ConsumptionFilters.PeriodFrom)
    const filterTo = searchParams.get(ConsumptionFilters.PeriodTo)
    if (!filterFrom || !filterTo) {
      Logger.warn("missing filters", { filterFrom, filterTo })
      setError(true)
      return
    }

    const from = new Date(filterFrom)
    const to = new Date(filterTo)

    fetchAll((limit: number, offset: number) => getGameSessionDetails(game, location, from, to, limit, offset))
      .then(res => {
        if (res.success === false || res.error) {
          Logger.warn("could not load details", { game, location })
          setError(true)
          return
        }
        setError(false)
        setDetails(res.data)
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return <Loader
      message={t("Fetching invoices data")}
      inline={true}
      background={false}
    />
  } else if (error) {
    return <ErrorMessage message={t("Could not load invoices data")} />
  }

  return (
    <tr>
      <td colSpan={5} className="px-5">
        <table className="table consumption-table-details">
          <thead>
            <tr>
              <th>
                <i> {t("Done at")} </i>
              </th>
              <th className="text-center">
                <i> {t("Version")} </i>
              </th>
              <th className="text-center">
                <i> {t("Duration")} </i>
              </th>
              <th className="text-center">
                <i> {t("Players")} </i>
              </th>
              <th className="text-center">
                <i> {t("Total consumption")} </i>
              </th>
              <th className="text-center">
                <i> {t("Billable")} </i>
              </th>
            </tr>
          </thead>
          <tbody>
            {details.map(detail => {
              return (
                <tr key={detail.id}>
                  <td>
                    {moment(
                      detail.doneAt,
                      "YYYY-MM-DDThh:mm:ssZ"
                    ).format("L LTS")}
                  </td>
                  <td className="text-center">
                    {detail.version}
                  </td>
                  <td className="text-center">
                    {`${(detail.duration - detail.duration % 60) / 60}m${moment.duration(
                      detail.duration,
                      "seconds"
                    ).seconds()}s`}
                  </td>
                  <td className="text-center">
                    {detail.players}
                  </td>
                  <td className="text-center">

                    {(() => {
                      const duration = detail.duration * detail.players


                      return `${(duration - duration % 60) / 60}m${moment.duration(
                        duration,
                        "seconds"
                      ).seconds()}s`
                    })()}
                  </td>
                  <td className="text-center">
                    {detail.billable ? (
                      <i className="fa fa-check"></i>
                    ) : (
                      <i className="fa fa-times">
                        {" "}
                      </i>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </td>
    </tr>
  )
}