import React, { useEffect, useRef, useState } from "react";
import Chart from 'chart.js/auto';

type Dataset = {
  data: unknown;
  label: string;
  backgroundColor: string;
};

type Props = {
  labels: Array<string>;
  datasets1: Dataset;
  datasets2: Dataset;
};

export default function StackedBar({ labels, datasets1, datasets2 }: Props) {
  const [chart, setChart] = useState<Chart<"bar", unknown, string> | null>(null);
  const element = useRef(null);
  const data = {
    labels: labels,
    datasets: [
      {
        label: datasets1.label,
        data: datasets1.data,
        backgroundColor: datasets1.backgroundColor,
      },
      {
        label: datasets2.label,
        data: datasets2.data,
        backgroundColor: datasets2.backgroundColor,
      },
    ],
  };
  useEffect(() => {
    if (chart === null && element.current !== null) {
      setChart(
        new Chart(element.current, {
          type: "bar",
          data: data,
          options: {
            responsive: true,
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                },
              },
              y: {
                stacked: true,
                beginAtZero: true,
                type: "linear",
              },
            },
          },
        })
      );
    } else {
      if (chart === null) {
        return
      }
      chart.data.labels = labels;
      chart.data = data;
      chart.update();
    }
  }, [chart, labels, data]);
  return <canvas ref={element} />;
}
