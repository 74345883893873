import { useEffect, useRef } from "react";

/**
 * Custom hook that stores the previous state of the given value.
 * Freely inspired of https://blog.logrocket.com/accessing-previous-props-state-react-hooks/
 */
export default function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  },[value])
  return ref.current
}
