export default {
    "69009": "69009",
    "Download Logo": "Télécharger le logo",
    "Legal notice & Terms of use": "Conditions juridique et d'utilisation",
    "Dashboard": "Tableau de bord",
    "Support": "Support",
    "Games": "Jeux",
    "Game Sessions": "",
    "Tokens": "Jetons",
    "Profile": "Profil",
    "Company": "Entreprise",
    "Launchers": "Launchers",
    "Consumption": "Consommation",
    "Balance": "Solde",
    "Terms of use": "Conditions d'utilisation",
    "Logout": "Déconnexion",
    "by": "par",
    "Octopod Logo": "Logo Octopod",
    "My Dashboard": "Mon Tableau de Bord",
    "My Consumption": "Ma Consommation",
    "My Balance": "Mon Solde",
    "My Billing": "Ma Facturation",
    "My Game Sessions": "Mes Parties",
    "My Launchers": "Mes Launchers",
    "My Games": "Mes Jeux",
    "My Users": "Mes Utilisateurs",
    "Octopod doc icon": "Icône de la doc Octopod",
    "Menu collapse icon": "Icône de fermeture du menu",
    "You do not have the permissions to access this page": "Vous n'avez pas les autorisations pour accéder à cette page",
    "Fetching consumption": "Chargement de la consommation",
    "Minutes consumed (playing time)": "Minutes consommées (temps de jeu)",
    "Billable minutes (playing time)": "",
    "Session played": "Parties jouées",
    "Billable sessions": "",
    "Fetching invoices data": "Chargement des factures",
    "Could not load invoices data": "Erreur lors du chargement des factures",
    "Done at": "Terminé à",
    "Version": "Version",
    "Duration": "Durée",
    "Players": "Joueurs",
    "Total consumption": "Consommation total",
    "Billable": "Facturable",
    "By minutes": "Par minutes",
    "By sessions": "Par parties",
    "You no longer have game credit.": "Vous n'avez plus de crédit de partie.",
    "n/a": "-",
    "Duration consumed": "",
    "The \"Duration billable\" indicated may differ from the amount actually billed. To bill, we add up all the sessions to be billed for the same game and round up to the closest minute.": "",
    "Duration billable": "",
    "Played sessions": "Parties jouées",
    "Billed sessions": "Parties facturées",
    "Statistics": "Statistiques",
    "Daily consumption": "Consommation journalière",
    "Remaining credit": "Crédit restant",
    "Minutes": "Minutes",
    "On {{date}}": "Au {{date}}",
    "Sessions": "Parties",
    "Credits - More information": "Crédits - Plus d'information",
    "Ok": "Ok",
    "More information": "Plus d'information",
    "Please make contact with Octopod or your usual retailer to recharge your game credits.": "Veuillez prendre contact avec Octopod ou votre distributeur pour acheter vos crédits de partie.",
    "Others": "Autres",
    "sessions": "parties",
    "minutes": "minutes",
    "message": "message",
    "Actions": "Actions",
    "Charging": "En charge",
    "In production": "En production",
    "Not in production": "Pas en production",
    "Editor icon": "Icône de l'éditeur",
    "Game icon": "Icône du jeu",
    "All games": "Tous les jeux",
    "Loading": "Chargement",
    "Please select a launcher": "Veuillez sélectionner un launcher",
    "Please select a location": "Veuillez sélectionner une adresse",
    "Distributor icon": "Icône du distributeur",
    "Client icon": "Icône de l'exploitant",
    "Location icon": "Icône de l'adresse",
    "All locations": "Toutes les adresses",
    "Details": "Détails",
    "Date": "Date",
    "Invoices": "Factures",
    "Credit": "Crédit",
    "Debit": "Débit",
    "Show details": "Afficher les détails",
    "Description": "Description",
    "Load more": "Charger plus",
    "Export": "Exporter",
    "Games involved": "Jeux concernés",
    "Close": "Fermer",
    "See the games concerned": "Voir les jeux concernés",
    "error": "erreur",
    "Could not fetch more invoices": "Erreur pour charger plus de factures",
    "Could not find this invoice link": "",
    "Could not find this invoice details": "Erreur pour trouver les détails de la facture",
    "Could not fetch invoices": "Erreur pour charger les factures",
    "Id": "Id",
    "Address": "Adresse",
    "Creation date": "Date de création",
    "Download scores button": "Bouton de téléchargement des scores",
    "Show details button": "Afficher le bouton des détails",
    "Fetching invoice details": "Chargement des détails de la facture",
    "Game": "Jeu",
    "Played": "Joué",
    "Retained on credits": "Retenu sur les crédits",
    "Total billed": "Total facturée",
    "Total number of sessions/minutes played": "Nombre total de parties/minutes jouées",
    "Total number of sessions/minutes billable": "Nombre total de parties/minutes facturables",
    "Total number of billable sessions/minutes retained on credits": "Nombre total de parties/minutes facturables retenues sur les crédits",
    "Total number of sessions/minutes actually billed": "Nombre total de parties/minutes actuellement facturées",
    "Try to reconnect. If the problem persists, contact the support.": "Essayer de reconnecter. Si le problème continue, contactez le support.",
    "Fetching company data": "Chargement des données de l'entreprise",
    "Wanadev": "Wanadev",
    "13 Quai du Commerce": "13 Quai du Commerce",
    "Lyon": "Lyon",
    "Confirm changes ?": "Confirmez les changements ?",
    "Cancel": "Annuler",
    "My company": "Mon entreprise",
    "Save Company": "Enregistrer l'Entreprise",
    "{{total}} errors encoutered": "{{total}} erreurs rencontrées",
    "Upload a company picture": "Envoyer une image de l'entreprise",
    "Could not update your company logo, please try again.": "Impossible de mettre à jour le logo de votre entreprise, veuillez réessayer.",
    "File weight": "Poids du fichier",
    "max": "max",
    "{{filesize}} MB": "{{filesize}} Mo",
    "No file uploaded": "Pas de fichier envoyé",
    "Search in your computer": "Rechercher dans votre ordinateur",
    "Preview": "Aperçu",
    "Name": "Nom",
    "Weight": "Poids",
    "Size": "Taille",
    "{{filesize}} KB": "{{filesize}} Ko",
    "File uploaded": "Fichier envoyé",
    "min": "",
    "s": "",
    "Error while downloading scores": "",
    "Scores PDF downloaded": "",
    "The scores PDF of your game has just been downloaded": "",
    "Session #": "",
    "Session": "",
    "Starting date": "",
    "Ending date": "",
    "Launcher": "",
    "Copy session id button": "",
    "Unknown": "Inconnu",
    "Please select a launcher to access the corresponding game sessions": "",
    "Launcher icon": "",
    "Show session details button": "",
    "Launch options details": "",
    "Team {{teamNumber}}": "",
    "Server": "",
    "Player {{playerNumber}}": "",
    "Spectator": "",
    "Options": "",
    "Values": "",
    "Nothing to show": "",
    "All sessions": "",
    "Finished sessions": "",
    "Unfinished sessions": "",
    "Fetching game data": "Chargement des données du jeu",
    "No game found": "Aucun jeu trouvé",
    "Fetching releases data": "Chargement des données des versions",
    "Channels": "Canaux",
    "Availability": "Disponibilité",
    "Since {{date}}": "Depuis le {{date}}",
    "Game was not found": "Le jeu n'a pas été trouvé",
    "Form is empty": "Le formulaire est vide",
    "Save": "Sauvegarder",
    "Game name": "Nom du jeu",
    "Current status": "Statut actuel",
    "Versions": "Versions",
    "Releases": "Versions",
    "More releases": "Plus de versions",
    "Informations": "Informations",
    "Medias": "Médias",
    "Devs": "Dévs",
    "You have made changes without saving. Are you sure you want to exit?": "Vous avez effectués des changements sans les sauvegarder. Êtes-vous de vouloir quitter ?",
    "To deploy new releases, you can read <0>developer documentation</0>.": "Pour déployer des nouvelles versions, vous pouvez lire <0>la documentation pour les développeurs</0>.",
    "You can create a token in the menu \"<0>Tokens</0>\"": "Vous pouvez créer un jeton dans le menu \"<0>Jetons</0>\"",
    "Your game identifier": "Votre identifiant de jeu",
    "Could not find game": "Impossible de trouver le jeu",
    "Select your comfort": "Sélectionner le confort",
    "Unknown spaces": "Espaces inconnus",
    "Add your headset compatibilities": "Ajouter la compatibilité des casques",
    "Languages": "Langages",
    "Categories": "Catégories",
    "Internet connection required": "Connexion internet requise",
    "Warehouse game": "Jeu warehouse",
    "Comfort": "Confort",
    "VR Spaces": "Espaces VR",
    "Extracted from the latest stable release's manifest": "Extrait depuis le manifeste de la dernière version stable",
    "Headset type": "Type de casque",
    "Minimum age": "Âge minimum",
    "Could not fetch attachments": "Impossible de charger les pièces jointes",
    "Loading attachments": "Changement des pièces jointes",
    "Add new file": "Ajouter un nouveau fichier",
    "Submit": "Envoyer",
    "Category": "Catégorie",
    "Action": "Action",
    "Edit file": "Éditer le fichier",
    "Delete file": "Supprimer le fichier",
    "Comfirm deletion": "Confirmer la suppression",
    "Add file": "Ajouter un fichier",
    "Game guides & Marketing assets": "Guides de jeu & Ressource marketing",
    "File not found": "Fichier non trouvé",
    "Could not send file": "Impossible d'envoyer le fichier",
    "Attachment not found": "Pièce jointe non trouvée",
    "Uploading {{filename}}": "Envoie de {{filename}}",
    "Com pack game": "Pack com du jeu",
    "Upload file": "Envoyer un fichier",
    "File name": "Nom du fichier",
    "Category required": "Catégorie requise",
    "show me less": "montrer en moins",
    "show me more": "montrer en plus",
    "Could not fetch more detail about this release": "Impossible de charger plus de détails à propos de cette version",
    "Installation": "Installation",
    "Total installations in LBVR": "Nombre d'installations total dans les LBVR",
    "Note": "Note",
    "Manifest": "Manifeste",
    "Activation Confirmation": "Confirmation d'Activation",
    "Validate": "Valider",
    "You're about to put this release on channel <0>{{channel}}</0>. This operation cannot be undone.": "Vous êtes sur le point d'activer le canal <0>{{canal}}</0> sur cette version. Cette opération ne peut être annulée.",
    "To confirm, write <0>{{channel}}</0> in input field.": "Pour confirmer, écrivez <0>{{channel}}</0> dans le champs texte.",
    "Insert channel name to confirm": "Insérer le nom du canal pour confirmer",
    "Please insert {{version}} to confirm the activation.": "Veuillez saisir {{version}} pour activer.",
    "Could not flag release as unavailable": "Impossible de noter la version comme non-disponible",
    "Unavailable since {{date}}": "Non-disponible depuis {{date}}",
    "Flag as unavailable": "Noter comme non-disponible",
    "Flag as unavailable confirmation": "Confirmation pour noter comme non-disponible",
    "success": "succès",
    "The version will be updated to become unavailable. This operation may take a few minutes.": "La version sera modifiée pour devenir non-disponible. Cette opération peut prendre quelques minutes.",
    "Release cannot be marked as unavailable": "La version ne peut être notée comme non-disponible",
    "Force launchers uninstallation": "Forcer la désinstallation sur les launchers",
    "You're about to flag this release as <0>unavailable</0>. This operation can't be reverted.": "Vous allez noter cette version comme <0>non-disponible</0>. Cette opération est irréversible.",
    "To confirm, write <0>{{version}}</0> in input field.": "Pour confirmer, saisissez <0>{{version}}</0> dans le champs texte.",
    "Insert version to confirm": "Saisir la version pour confirmer",
    "Go to game details": "Voir les détails du jeu",
    "Spaces": "Espaces",
    "Game Guides": "Guides de jeu",
    "Marketing Assets": "Ressource marketing",
    "An error occurs when creating the game": "Une erreur est survenue durant la création du jeu",
    "Add new game": "Ajouter un nouveau jeu",
    "Create": "Créer",
    "Add game": "Ajouter un jeu",
    "All spaces": "Tous les espaces",
    "Fetching launchers data": "Chargement des launchers",
    "Login": "Connexion",
    "Version installed": "Version installée",
    "Related games": "Relatif aux jeux",
    "Blocked account": "Compte bloqué",
    " This page allows you to see your launchers. If you already have a launcher, you reach your maximum then contact an administrator for more launchers.": " Cette page vous permet de voir vos launchers. Si vous disposez déjà d'un lanceur, vous atteignez votre maximum, contactez alors un administrateur pour obtenir d'autres lanceurs.",
    "Download launcher ": "Télécharger le launcher ",
    "Add new launcher": "Ajouter un nouveau launcher",
    "Enter a login for your launcher": "Entrez un login pour votre launcher",
    "Only lowercase chars, numbers, dash and low dash allowed": "Seuls les minuscules, les chiffres, les tirets et les tirets bas sont autorisés",
    "Add launcher": "Ajouter un launcher",
    "Change Password": "Changer le Mot de Passe",
    "Change password": "Changer le mot de passe",
    "Save new password": "Enregistrer le nouveau mot de passe",
    "Min 8 chars, 2 numbers": "Min 8 caractères, 2 chiffres",
    "Your password must contain at least 8 chars and 2 numbers": "Votre mot de passe doit contenir au moins 8 caractères et 2 chiffres",
    "Couldn't update your password": "Impossible de mettre à jour votre mot de passe",
    "Password do not match": "Le mot de passe ne correspond pas",
    "New password": "Nouveau mot de passe",
    "Repeat new password": "Répéter le nouveau mot de passe",
    "Email": "Email",
    "Please enter an email address": "Veuillez saisir une adresse email",
    "Password": "Mot de passe",
    "Hide password": "Cacher le mot de passe",
    "Show password": "Afficher le mot de passe",
    "Forgot your password ?": "Mot de passe oublié ?",
    "English": "Anglais",
    "French": "Français",
    "Could not fetch profile data": "Erreur lors du chargement des données du profil",
    "Firstname": "Prénom",
    "Lastname": "Nom",
    "Email language": "Langue de l'email",
    "My profile": "Mon profil",
    "Enter your current password": "Saisir votre mot de passe actuel",
    "Change my password": "Changer mon mot de passe",
    "Current password": "Mot de passe actuel",
    "Phone": "Téléphone",
    "Save Profile": "Enregistrer le profil",
    "{{total}} errors encountered": "{{total}} erreurs rencontrées",
    "Something went wrong": "Quelque chose n'a pas fonctionné",
    "Passwords do not match": "Les mots de passe ne correspondent pas",
    "Invalid parameters": "Paramètres invalides",
    "Invalid credentials": "Identifiants invalides",
    "Please enter your desired password": "Veuillez saisir le mot de passe souhaité",
    "Password confirmation": "Confirmation du mot de passe",
    "Please confirm your desired password": "Veuillez confirmer le mot de passe souhaité",
    "Sign in": "Connexion",
    "Send me an email": "Envoyez-moi un email",
    "Remove": "Supprimer",
    "Remove file": "Supprimer le fichier",
    "{{size}}B": "{{size}}o",
    "{{size}}KB": "{{size}}Ko",
    "{{size}}MB": "{{size}}Mo",
    "{{size}}GB": "{{size}}Go",
    "Title": "Titre",
    "Your message...": "Votre message...",
    "Your message has been successfully sent to our support": "Votre message a été envoyé à notre support",
    "Sending": "Envoi en cours",
    "Send": "Envoyer",
    "Support Information": "Information du support",
    "You can send a message to OctopodVR support. Be explicit and detailed your issue with log/attachment/screenshot/...": "Vous pouvez envoyer un message au support Octopod. Soyez explicite et détaillez votre problème avec des logs/pièces jointes/captures d'écran/...",
    "Terms and conditions of use": "Conditions d'utilisation",
    "Wanadev is a limited liability company with capital of €100,000, registered with the Versailles Trade and Companies Register under number 511 500 811, with its registered office at 13 Quai du Commerce, 69 009, represented by its manager Mr. Côme de Percin (hereinafter \"Wanadev\").": "Wanadev est une société à responsabilité limitée au capital de 100 000 euros, immatriculée au RCS de Versailles sous le numéro 511 500 811, dont le siège social est 31 rue de Grenette, 69 002, représentée par son gérant Monsieur Côme de Percin (ci-après « Wanadev »).",
    "Wanadev offers access via online platforms to software solutions to support companies operating accessible virtual reality rooms. These include in particular the \"Octopod: Insight\", \"Octopod: Play\" and \"Octopod: Games\" offers (hereinafter individually or jointly the \"Services\").": "Wanadev propose un accès via des plateformes en ligne à des solutions logiciel d’accompagnement de société exploitant des salles de réalité virtuelle, accessibles. Elles comprennent notamment les offres « Octopod : Insight », « Octopod : Play » et « Octopod : Games » (ci-après individuellement ou conjointement les « Services »).",
    "These general conditions of use of the Services offered here (hereinafter the \"General Conditions\") govern the relationship between the user of the Services (hereinafter the \"User\") and Wanadev.": "Les présentes conditions générales d'utilisation des Services qui y sont proposés (ci-après les « Conditions Générales ») régissent les relations entre l’utilisateur des Services (ci-après « l’Utilisateur ») et Wanadev.",
    "Access to and use of the Services imply unreserved acceptance of the terms of these General Conditions. Users are reminded that special additional conditions may also apply to Users of the Services depending on the professional contracts entered into with Wanadev - solution subscription, integration of third-party content, solution distribution, etc. - the stipulations of which shall prevail in the event of contradiction with these General Conditions. - whose stipulations shall prevail in the event of contradiction with the present General Terms and Conditions.": "L’accès et l’utilisation des Services impliquent l’acceptation sans réserve des termes des présentes Conditions Générales. Il est rappelé que des conditions spéciales supplémentaires peuvent également s’appliquer aux Utilisateurs des Services selon les contrats professionnels conclus avec Wanadev – Souscription de solution, intégration de contenus tiers, distribution de solution, etc. – dont les stipulations prévaudront en cas de contradiction avec les présentes conditions générales.",
    "Article 1 - Legal notice": "Article 1 – Mentions légales",
    "Article 2 - Description and use of the Services": "Article 2 - Description et utilisation des Services",
    "Article 3 - Use of the Services": "Article 3 – Utilisation des Services",
    "Article 4 - Wanadev's obligations and liability": "Article 4 - Obligations et Responsabilité de Wanadev",
    "Article 5 - Intellectual property": "Article 5 - Propriété intellectuelle",
    "Article 6 - Personal data & GDPR": "Article 6 - Données personnelles & RGPD",
    "Article 7 - Confidentiality & Cookies": "Article 7 - Confidentialité & Cookies",
    "Article 8 - Miscellaneous provisions": "Article 8 - Dispositions diverses",
    "The Services accessible from the online platforms and website(s) are the property of Wanadev, published and hosted by the latter.": "Les Services accessibles depuis les plateformes en ligne et site(s) internet sont la propriété de Wanadev, édités et hébergés par cette dernière.",
    "Director of publication:": "Directeur de la publication :",
    "Mr. Côme de Percin": "Monsieur Côme de Percin",
    "Publisher & Host:": "Éditeur & Hébergeur :",
    "Wanadev, EURL with a share capital of 100 000 €": "Wanadev, EURL au capital social de 100 000 €",
    "13 Quai du Commerce 69 009 Lyon": "13 Quai du Commerce 69 009 Lyon",
    "R.C.S. Lyon 511 500 811": "R.C.S. Lyon 511 500 811",
    "Contact details:": "Informations de contact :",
    "Phone: +33 (0)4 28 29 69 01": "Tel : +33 (0)4 28 29 69 01",
    "Email: contact@wanadev.fr": "Email : contact@wanadev.fr",
    "Requests for information and exercise of rights relating to Users' personal data may be sent directly via the company's contact address.": "Les demandes d’informations et exercice des droits relatif aux données personnelles des Utilisateurs peuvent être directement adressées via l’adresse de contact de la société.",
    "Access to and use of the Services may be subject to the User providing a number of administrative details in order to create his/her user account. The User undertakes not to provide false and/or erroneous contact details or information. The User is solely responsible for the consequences of any inaccurate information he or she may provide.": "L’accès et l’utilisation des Services peuvent être conditionnés par le renseignement de l’Utilisateur de plusieurs éléments administratifs qui permettront de constituer son compte utilisateur. L’Utilisateur s’engage à ne pas renseigner des coordonnées ou informations fantaisistes et/ou erronées. L’Utilisateur est seul responsable des conséquences découlant des informations inexactes qu’il aura transmises.",
    "Once the Services have been configured and/or installed, the User may access the Services offered according to the User's wishes (hereinafter referred to as the \"Content\"). The User acknowledges that additional conditions may apply for access to certain content, access to which may change at the sole discretion of Wanadev, in particular in the event of a temporarily limited discovery promotional offer, in accordance with the contractual provisions governing the relationship between the User and Wanadev.": "Après le paramétrage et/ou l’installation des Services, l’Utilisateur peut accéder aux Services proposés selon les souhaits formulés par l’Utilisateur (ci-après les « Contenus »). L’Utilisateur reconnait que des conditions supplémentaires peuvent s'appliquer pour l’accès à certains contenus, leur accès étant susceptible d’évoluer à l’entière discrétion de Wanadev, notamment en cas d’offre promotionnelle de découverte temporairement limitées, conformément aux dispositions contractuelles encadrant la relation entre l’Utilisateur et Wanadev.",
    "The User is not authorized to use the Services and Content for purposes other than those intended by Wanadev. The User may not under any circumstances sell, grant a privilege or transfer reproductions of the Services or Content to third parties in any manner whatsoever, nor to rent, lease or license the Services & Content to third parties without the prior written consent of Wanadev, except within the limits of the express authorizations of these General Conditions.": "L’Utilisateur n'est pas autorisé à utiliser les Services et Contenus à d'autres fins que celles prévues par Wanadev. L’Utilisateur ne peut en aucun cas vendre, accorder un privilège ou transférer des reproductions des Services ou des Contenus à des tiers d'une quelconque manière, ni à louer, donner en location-bail ou concéder sous licence les Service & Contenus à des tiers sans l'accord préalable écrit de Wanadev, sauf dans la limite des autorisations expresses des présentes Conditions Générales.",
    "The User undertakes to use the Services offered in compliance with the regulations in force and with these General Terms and Conditions. Failing this, the User is solely liable for any damage caused and for the consequences of any claims or actions that may result.": "L’Utilisateur s’engage à utiliser les Services proposés en respectant la réglementation en vigueur et les présentes Conditions Générales. À défaut, l'Utilisateur est seul responsable des dommages causés et des conséquences des réclamations ou actions qui pourraient en découler. ",
    "The User undertakes to hold Wanadev harmless against all claims, complaints, actions or recourse of any kind from third parties and to indemnify Wanadev and third parties for all damages resulting from use of the Services that does not comply with the applicable regulations and/or these General Terms and Conditions.": "L’Utilisateur s'engage à garantir Wanadev contre toutes réclamations, plaintes, actions ou recours de toute nature émanant de tiers et à indemniser Wanadev et les tiers de tous les dommages résultant d’une utilisation des Services non conforme avec la règlementation applicable et/ou les présentes Conditions Générales.",
    "All the Services offered and their content are produced for information purposes only. The User alone assumes responsibility for the consequences of using the data and documents generated by using the Services.": "L'ensemble des Services proposés et leurs contenus sont produits à titre informatif. L’Utilisateur assume seul la responsabilité de conséquences liées à l’exploitation des données et documents générés à partir de l’utilisation des Services.",
    "Wanadev undertakes, as part of an obligation of means, to make the Services available. Although Wanadev endeavors to keep the Services accessible, Wanadev may nevertheless find itself obliged to temporarily suspend partial or total access to the Services, in particular for technical maintenance reasons, without this entailing any liability on the part of Wanadev.": "Wanadev s’engage, dans le cadre d’une obligation de moyens, à mettre à disposition les Services. Si Wanadev s'efforce de maintenir accessible les Services, Wanadev pourra cependant se trouver dans l’obligation de suspendre momentanément l'accès partiel ou total aux Services, notamment pour des raisons techniques de maintenance, sans que ceci n’entraîne de responsabilité de la part de Wanadev.",
    "Furthermore, it is specified that the Internet network and the computer and telecommunications systems used by the User to access and use the Services are not error-free and interruptions and breakdowns may occasionally occur. Wanadev cannot provide any guarantee in this respect and cannot therefore be held responsible for any damage inherent in the said use of the Internet network and computer and telecommunications systems, in particular, but without this list being limitative:": "Par ailleurs, il est précisé que le réseau Internet et les systèmes informatiques et de télécommunications utilisés par l’Utilisateur dans le cadre de l’accès à et de l’utilisation des Services ne sont pas exempts d’erreurs et des interruptions et pannes peuvent occasionnellement survenir. Wanadev ne peut fournir aucune garantie à cet égard et ne saurait dès lors être tenue responsable pour tous dommages inhérents auxdites utilisations du réseau Internet et des systèmes informatiques et de télécommunications, notamment sans que cette liste ne soit limitative :",
    "poor transmission and/or reception of any data and/or information on the Internet;": "la mauvaise transmission et/ou réception de toute donnée et/ou information sur Internet ;",
    "external intrusion;": "l'intrusion extérieure ;",
    "failure of any reception equipment or communication lines;": "la défaillance de tout équipement de réception ou des lignes de communication ;",
    "any other malfunction of the Internet network that prevents the Services from functioning properly and/or running smoothly.": "tout autre dysfonctionnement du réseau Internet empêchant le bon fonctionnement et/ou la fluidité des services.",
    "Furthermore, the User acknowledges and accepts that Wanadev may not be held liable for any consequences in the performance of the Services resulting from erroneous, approximate or incomplete information communicated by the User.": "Par ailleurs, l’Utilisateur reconnaît et accepte que la responsabilité de Wanadev ne saurait être engagée pour toutes conséquences dans la réalisation des Services découlant d’informations erronées, approximatives ou incomplètes communiquées par l’Utilisateur.",
    "Also, the information transmitted when consulting or using the Services is provided for information purposes only and cannot be guaranteed to be accurate or up to date. The User acknowledges that Wanadev offers no guarantee and is under no obligation to verify the content made available by its partners and/or suppliers and disseminated via the website(s).": "Également, les informations transmises à l’occasion de la consultation ou exploitation des Services sont délivrées à titre informatives et ne peuvent être certifiées exactes ou à jour. L’Utilisateur reconnait que Wanadev n’offre aucune garantie et n’est tenue d’aucune obligation de vérification des contenus mis à sa disposition par ses partenaires et/ou fournisseurs et diffusés via le(s) site(s) internet.",
    "In the event of a breach by the User of these General Conditions, Wanadev may terminate the User's access to the Services, notwithstanding any damages to which Wanadev may be entitled.": "En cas de manquement par l’Utilisateur aux présentes Conditions Générales, Wanadev pourra mettre fin à l’accès aux Services par l’Utilisateur, nonobstant tous dommages et intérêts auxquels Wanadev pourrait prétendre.",
    "Wanadev is the exclusive owner of all intellectual property rights relating to the structure and content of the Services and associated software applications, including in particular graphics, images, text, logos, databases, programs and software, throughout the world.": "Wanadev est le titulaire exclusif de tous les droits de propriété intellectuelle portant sur la structure et le contenu des Services et des applications logicielles associées comprenant notamment les graphismes, images, textes, logos, bases de données, programmes, logiciel, et ce pour le monde entier.",
    "The use or reproduction of all or part of the Wanadev Services is authorized solely for the purposes of information for personal use, any reproduction or representation made for other purposes being authorized only with the express prior written consent of Wanadev.": "L’utilisation ou la reproduction de tout ou partie des Services Wanadev est seulement autorisée aux fins exclusives d'information pour un usage personnel, toute reproduction ou représentation réalisée à d'autres fins n’étant autorisée qu’avec un accord préalable exprès écrit de Wanadev.",
    "With the exception of the foregoing, it is strictly forbidden for Users to:": "À l’exception de ce qui précède, il est strictement interdit à l’Utilisateur de :",
    "copy, reproduce, represent, disseminate, distribute and publish, permanently or temporarily, all or part of the Services by any means and on any medium known or unknown to date;": "copier, reproduire, représenter, diffuser, distribuer, publier, de façon permanente ou provisoire, tout ou partie des Services par tout moyen et sur tout support connu ou inconnu à ce jour ;",
    "create derivative works from the Services;": "créer des œuvres dérivées à partir des Services ;",
    "modify, translate, adapt or arrange all or part of the Services;": "modifier, traduire, adapter, arranger, tout ou partie des Services ;",
    "disassemble, decompile or reverse engineer the Services;": "désassembler, décompiler, effectuer de l’ingénierie inverse sur les Services ;",
    "take any action to obtain the source codes of Services;": "entreprendre toute action dans le but d'obtenir les codes sources de Services ;",
    "Furthermore, in accordance with the provisions of article L. 342-1 and Wanadev's status as a producer of databases within the meaning of the provisions of articles L.341-1 et seq. of the French Intellectual Property Code, the User shall refrain from:": "De plus, conformément aux dispositions de l'article L. 342-1 et du statut de Wanadev de producteur de bases de données au sens des dispositions des articles L.341-1 et suivants du Code de la propriété intellectuelle, l’Utilisateur s'interdit de procéder à :",
    "the extraction by permanent or temporary transfer of all or a qualitatively or quantitatively substantial part of the content of the Services, by any means and in any form whatsoever, including for the purposes of use or consultation by a medium and/or process(es) not authorized by Wanadev;": "l'extraction par transfert permanent ou temporaire de la totalité ou d'une partie qualitativement ou quantitativement substantielle des contenus des Services, par tout moyen et sous toute forme que ce soit, en ce compris à des fins d'utilisation ou de consultation par un média et/ou un procédé(s) non autorisé(s) par Wanadev;",
    "re-use, by making available to the public all or a qualitatively or quantitatively substantial part of the content of the Services, in any form whatsoever, including by hypertext link, media and/or process(es) not authorized by Wanadev;": "la réutilisation, par la mise à la disposition du public de la totalité ou d'une partie qualitativement ou quantitativement substantielle des contenus des Services, quelle qu'en soit la forme, y compris par un lien hypertexte, un média et/ou un procédé(s) non autorisé(s) par Wanadev;",
    "creating, publishing, maintaining, updating, importing, exporting, making available to third parties, whether free of charge or against payment, and participating in the aforementioned acts, a competing database derived from all or part of the content of the Services;": "la constitution, l'édition, la maintenance, la mise à jour, l'importation, l'exportation, la mise à disposition de tiers, à titre gracieux ou onéreux, et la participation aux actes précités, d'une base de données concurrente issue de tout ou partie des contenus des Services;",
    "viewing on a screen by a process or media other than those by which Wanadev intends to disclose the Internet site(s), the Services and their content;": "la visualisation sur un écran par un autre procédé ou média que ceux par lesquels Wanadev entend divulguer le(s) site(s) Internet, les Services et leurs contenus ;",
    "in general, any extraction, use, storage, reproduction, representation or conservation, direct or indirect, partial or total, including in buffer or temporary memory, qualitatively or quantitatively substantial of the contents of the Services, committed by one of the processes referred to above is strictly prohibited, including by a medium not authorized by Wanadev.": "d'une manière générale, toute extraction, utilisation, stockage, reproduction, représentation ou conservation, directe ou indirecte, partielle ou totale, y compris en mémoire tampon ou temporaire, qualitativement ou quantitativement substantielle des contenus des Services, commise par l'un des procédés visés ci-dessus est strictement prohibée, y compris par un média non autorisé par Wanadev.",
    "All elements covered by an intellectual property right that do not belong to Wanadev but nevertheless appear on the website(s) as a result of their publication by a partner or supplier are the property of their respective holder, granting Wanadev a free, non-exclusive license on these contents allowing Wanadev to use, reproduce, represent, disclose, modify, to all third parties and for all purposes, all or part by any means, and for the whole world.": "Tous les éléments couverts par un droit de propriété intellectuelle qui n’appartiennent pas à Wanadev mais figurent néanmoins sur le(s) site(s) internet du fait de leur publication par un partenaire ou un fournisseur sont la propriété de leur titulaire respectif, concédant à Wanadev une licence gratuite, non-exclusive, sur ces contenus permettant à Wanadev d’en utiliser, reproduire, représenter, divulguer, modifier, à tous tiers et à toutes fins, tout ou partie par tout moyen, et pour le monde entier.",
    "In this respect, Users guarantee that the content published by them does not infringe any third party rights, including the intellectual property rights of third parties. Users agree to indemnify Wanadev against all costs, damages and indemnities incurred by Wanadev as a result of any such content.": "À cet égard, les Utilisateurs garantissent que les contenus publiés par leurs soins ne violent aucun droit de tiers, y compris les droits de propriété intellectuelle de tiers. Les Utilisateurs acceptent de garantir Wanadev contre l’ensemble des frais, dommages, indemnités incombant à Wanadev du fait de l’un de ces contenus.",
    "The General Terms and Conditions are subject to the provisions of European Regulation 2016/679 of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data, as well as those of Law No. 78-17 of 6 January 1978 on information technology, files and freedoms and any other applicable regulations in this area that may subsequently supplement or replace them.": "Les Conditions Générales sont soumises aux dispositions du règlement européen 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données, ainsi qu’à celles de la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés et à tout autre règlementation applicable en la matière qui viendrait les compléter ou s’y substituer ultérieurement.",
    "Wanadev needs to use the User's data to manage his/her account. Without such information, it will not be able to provide useful access to the Services. It may also use this information to send the User its best promotional offers, for statistical purposes and to combat fraud, for its legitimate business development interests, for the technical improvement of its systems and its knowledge of the User and, finally, where applicable with the User's consent, to personalize its services, in particular by means of advertising.": "Wanadev a besoin d’utiliser les données de l’Utilisateur pour la gestion de son compte. En l’absence de telles informations, il ne pourra pas fournir utilement l’accès aux Services. Il peut également les utiliser afin de lui adresser ses meilleures offres promotionnelles, à des fins statistiques et de lutte contre la fraude, pour son intérêt légitime de développement commercial, d’amélioration technique de ses systèmes et de la connaissance de l’Utilisateur et, enfin, le cas échéant avec le consentement de ce dernier, de personnalisation des services, notamment par le biais de publicités.",
    "The User's data is kept for the duration of use of the services by the User and, beyond that, for a period of three years for commercial purposes, as well as for purposes of proof and accounting obligations, for a period not exceeding the applicable legal limitation periods.": "Les données de l’Utilisateur sont conservées pour la durée d’utilisation des services par l’Utilisateur et, au-delà, pour une durée de trois ans à des fins commerciales, ainsi qu’à des fins de preuve et d’obligations comptables, pour une durée n’excédant pas les délais de prescription légale applicables.",
    "For processing carried out by and/or on behalf of Wanadev, the User has, if necessary after providing valid identification, a right of access, where appropriate portability, modification, limitation and deletion of data concerning him and a right to object, possibly subject to legitimate and compelling reasons, to the processing of such data. They may also define directives concerning the fate of their personal data in the event of their death. These rights may be exercised directly with Wanadev by e-mail at contact@wanadev.fr or by post at the following address: Wanadev, 31 rue de Grenette 69002 Lyon.": "Pour les traitements réalisés par et/ou pour le compte de Wanadev, l’Utilisateur dispose, au besoin après communication d’une pièce d’identité en cours de validité, d’un droit d’accès, le cas échéant de portabilité, de modification, de limitation, et de suppression des données le concernant ainsi que d’un droit d’opposition, éventuellement sous réserve de motifs légitimes et impérieux, au traitement de ces données. Il peut également définir des directives relatives au sort de ses données personnelles en cas de décès. Ces droits peuvent être exercés directement auprès de Wanadev par courrier électronique à l’adresse contact@wanadev.fr ou par courrier à l’adresse : Wanadev, 31 rue de Grenette 69002 Lyon.",
    "In accordance with legal provisions, the User has the right to lodge a complaint with a supervisory authority - the CNIL.": "Conformément aux dispositions légales, l’Utilisateur bénéficie du droit d’introduire une réclamation auprès d’une autorité de contrôle – la CNIL.",
    "Personal data provided.": "Données personnelles fournies.",
    "For the purposes of this article, \"personal data\" means any information relating to an identified or identifiable natural person, within the meaning of the applicable legislation in force. For all intents and purposes, an \"identifiable natural person\" is one who can be identified, directly or indirectly, in particular by reference to an identifier, such as a name, an identification number, location data, an online identifier, or to one or more factors specific to his or her physical, physiological, genetic, mental, economic, cultural or social identity. The categories of data concerned are as follows:": "Dans le cadre du présent article, les « données personnelles » désignent toute information se rapportant à une personne physique identifiée ou identifiable, au sens de la législation applicable en vigueur. A toutes fins utiles, il est précisé qu’est réputée être une « personne physique identifiable » une personne physique qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale. Les catégories de données concernées sont les suivantes :",
    "Identity data": "Données d’identité",
    ": this information may include surname, first name, telephone number, email address or postal address;": ": ces informations peuvent inclurent notamment les nom, prénom, numéro de téléphone, adresse email ou encore adresse postale ;",
    "Technical and browsing data": "Données techniques et de navigation",
    ": Wanadev is likely to collect several types of data relating to the technical methods by which the User accesses and uses the Services and Internet sites, partner sites or third-party sites whose data is imported into the Site. This data may be collected using cookies and other similar techniques.": ": Wanadev est susceptible de collecter plusieurs types de données se rapportant aux modalités techniques par lesquelles l’Utilisateur accède et utilise les Services et sites internet, les sites de partenaires ou encore de sites tiers dont les données sont importées sur le Site. Ces différentes données peuvent notamment être collectées par le biais de cookies et autres techniques similaires. ",
    "Use of Users' personal data.": "Utilisation des données personnelles des Utilisateurs. ",
    "Wanadev may use Users' personal data in defined cases:": "Wanadev a vocation à utiliser les données personnelles des Utilisateurs dans des cas définis : ",
    "Operation & Optimization of Services": "Fonctionnement & Optimisation des Services",
    ": the personal data collected is necessary for the operation of the services offered as well as for the development and improvement of Wanadev's services;": ": les données personnelles collectées sont nécessaires au fonctionnement des services proposés ainsi qu’au développement et amélioration des services de Wanadev ;",
    "Communication & Personalization": "Communication & Personnalisation",
    ": subject to the required consent, Wanadev may use certain data in order to offer, in particular by means of advertising, personalized services and offers, promotions or any other commercial information relating to the services or services of partners;": ": sous réserve notamment du consentement requis, Wanadev pourra utiliser certaines données afin de proposer, notamment par le biais de publicités, des services et offres personnalisés, promotions ou toute autre information commerciale liés aux services ou aux services de partenaires ;",
    "Compliance with current legislation.": "Respect de la législation en vigueur.",
    "Sharing of personal data.": "Partage des données personnelles.",
    "Wanadev may share Users' personal data with commercial partners or other companies with which Wanadev is affiliated (parent companies, sister companies or daughter companies), as part of the operation of the Services or in particular for logistical purposes.": "Wanadev peut être amené à partager les données personnelles des Utilisateurs avec des partenaires commerciaux ou autres sociétés auxquelles Wanadev est affilié (sociétés mères, sociétés sœurs ou sociétés filles), dans le cadre du fonctionnement des Services ou notamment à des fins logistiques.",
    "Similarly, the User's data may be communicated in response to a specific request made by a competent administrative or judicial authority, and more generally in all situations where this is required by law, regulation or an administrative or judicial decision;": "De même, les données de l’Utilisateur seront susceptibles d’être communiquées en réponse à une demande spécifique formulée par une autorité administrative ou judiciaire compétente, ainsi que plus généralement dans toutes les situations où la loi, la règlementation ou une décision administrative ou judiciaire l’impose ;",
    "More generally, this data may be shared with any other third party after the User has been informed and subject to the absence of opposition or prior consent in accordance with the applicable rules.": "Plus généralement, ces données peuvent être partagées avec tout autre tiers après information de l’Utilisateur et sous réserve de l’absence d’opposition ou de consentement préalable selon les règles applicables.",
    "Retention period for personal data.": "Durée de conservation des données personnelles.",
    "Personal data will be kept in active files or systems only for as long as is necessary to fulfil the purposes for which they were collected in accordance with the applicable rules, i.e.:": "Les données personnelles seront conservées dans des fichiers ou des systèmes actifs pendant la seule durée nécessaire à l’accomplissement des finalités pour lesquelles elles ont été collectées conformément aux règles applicables en la matière, c’est-à-dire :",
    "In principle, for all data;": "Par principe, pour l’ensemble des données ;",
    "By exception, for the purposes of commercial prospecting by electronic means, Wanadev may keep the email address, as well as the first and last names for up to three years after the complete cessation of use of Wanadev's services, subject to the exercise of a right of opposition.": "Par exception, aux fins de prospection commerciale par voie électronique, Wanadev pourra conserver l’adresse email, ainsi que des nom et prénom jusqu’à trois ans après l’arrêt complet d’utilisation des services et prestations de Wanadev, sous réserve de l’exercice d’un droit d’opposition.",
    "Wanadev may collect and process personal information from Users of the Services in order to offer an improved web service and personalized benefits.": "Wanadev peut collecter et traiter les informations à caractère personnel des Utilisateurs des Services afin de proposer un service web amélioré et d'avantages personnalisés.",
    "Definition(s).": "Définition(s).",
    "Cookies are files that websites place on the hard disk of the User's computer when they first visit the site. Their function is to maintain a link between the site and the computer medium used. The site will be able to retrieve the information stored in the cookie during previous visits. Cookies make browsing more efficient by accessing memory functions. Cookies are used to store preferences, register products and services and personalize pages.": "Les cookies sont des fichiers que les sites Internet placent sur le disque dur de l’ordinateur de l’Utilisateur lors de sa première visite. Sa fonction est de garder un lien entre le site et le support informatique utilisé. Le site sera en mesure de récupérer les informations stockées dans le cookie lors des précédentes visites. Les cookies participent à une navigation plus efficace en accédant à des fonctions de mémorisation. Les cookies permettent de stocker des préférences, enregistrer des produits et des services et personnaliser des pages.",
    "Types & purposes.": "Types & finalités.",
    "The cookies used by Wanadev are:": "Les cookies utilisés par Wanadev sont :",
    "Exercising your rights.": "Exercice des droits.",
    "Each Internet browser offers ways of limiting and deleting cookies. For more information on how to manage cookies, visit the appropriate link below, or check the settings of your browser if it is not on this list: Firefox, Google Chrome, Safari.": "Chaque navigateur Internet offre des moyens de limiter et supprimer les cookies. Pour plus d’informations sur la façon de gérer les cookies, visitez le lien approprié ci-dessous, ou informez-vous sur les réglages de votre navigateur s’il ne fait pas partie de cette liste : Firefox, Google Chrome, Safari.",
    "Any other request relating to the exercise of the User's rights to access, modify, oppose or delete personal data may be made directly to Wanadev at the following address: contact@wanadev.fr": "Toute autre demande relative à l’exercice des droits de l’Utilisateur liée à l’accès, modification, opposition ou suppression de données personnelles peut être faite directement à Wanadev via l’adresse : contact@wanadev.fr",
    "the <0>strictly necessary cookies</0> enabling the User to move around the website(s) supporting the Services and to use its basic functionalities;": "les <0>cookies strictement nécessaires</0> permettant à l’Utilisateur de se déplacer sur le(s) site(s) internet, support des Services et d'utiliser ses fonctionnalités de base ;",
    "the enhanced <0>functionality cookies</0> used to recognize Internet users when they return to the website and to count the number of visitors as well as to collect information concerning the use of the Services;": "les <0>cookies de fonctionnalité</0> améliorées utilisés pour reconnaître les internautes lorsque ces derniers reviennent sur le site internet et comptabiliser le nombre de visiteurs ainsi que recueillir des informations concernant l’utilisation des Services ;",
    "the <0>analytical cookies</0> enabling the collection of data relating to your use of the website in order to improve its performance and design.": "les <0>cookies analytiques</0> permettant de recueillir des données relatives à votre utilisation du site Internet afin d’améliorer la performance et la conception de celui-ci.",
    "Invalidity of a clause.": "Nullité de clause.",
    "In the event that any provision of these General Terms and Conditions is null and void or annulled, the Parties shall endeavour to replace it with a provision of the closest legal and economic effect and the other provisions shall continue to have effect in accordance with the intentions of the Parties as set out in the General Terms and Conditions.": "Au cas où l'une des dispositions des présentes Conditions Générales serait nulle ou annulée, les Parties s'efforceront de la remplacer par une disposition du plus proche effet juridique et économique et les autres dispositions continueront de produire leur effet conformément aux intentions des Parties, telles qu'elles résultent des Conditions Générales.",
    "Electronic evidence.": "Preuve électronique.",
    "The parties acknowledge and agree that SMS messages, MMS messages, electronic mail (emails), instant messaging exchanges and all other electronic writings resulting from the use of the Services and exchanged between Wanadev and the User within the framework of the Services constitute:": "Les parties reconnaissent et conviennent que les SMS, les MMS, les courriers électroniques (emails), les échanges par messagerie instantanée et tous autres écrits électroniques issus de l’utilisation des Services et échangés entre Wanadev et l’Utilisateur dans le cadre des Services constituent :",
    "original documents between the parties;": "des documents originaux entre les parties ;",
    "proof and": "feront preuve et",
    "admissible before the courts, provided that the person from whom they emanate can be duly identified and that they are established and stored in conditions that guarantee their integrity.": "seront admissibles devant les tribunaux, sous réserve que puisse être dûment identifiée la personne dont ils émanent et qu’il soient établis et conservés dans des conditions permettant d’en garantir l’intégrité.",
    "Force majeure & act of a third party.": "Force majeure & fait d’un tiers.",
    "In the event that Wanadev finds itself partially or totally unable to carry out one of its obligations under these General Terms and Conditions, as a result of a case of force majeure, a fortuitous event caused by the User or a third party, it must inform the User as soon as possible, without being held liable. Wanadev undertakes to do everything in its power to limit the duration and effects of the fortuitous event, force majeure or external cause. The obligations of the present General Conditions shall resume as soon as the force majeure ceases.": "Au cas où Wanadev se trouverait dans l’impossibilité partielle ou totale d’exécuter l’une de ses obligations prévues dans les présentes Conditions Générales, par suite d’un cas de force majeure, d'un cas fortuit du fait de l'Utilisateur ou d'un tiers, elle devra en informer l’Utilisateur dans les plus brefs délais, sans que sa responsabilité ne puisse être recherchée. Wanadev s’engage à faire tout ce qui est en son pouvoir pour limiter la durée et les effets du cas fortuit, de la force majeure ou de la cause extérieure. Les obligations des présentes Conditions Générales reprennent dès que la force majeure cesse, la responsabilité.",
    "Hypertext links.": "Liens hypertextes.",
    "Wanadev ensures that the Services do not include any hypertext link allowing direct or indirect access to sites or pages of third party websites whose content would be contrary to applicable legal or regulatory provisions. In the event of notification or information on such content, Wanadev undertakes to take all steps without delay to remove it. The User acknowledges that he/she has been informed of the risks associated with consulting third party sites and may under no circumstances seek Wanadev's liability for their content.": "Wanadev s'assure que les Services ne comportent pas de lien hypertexte permettant d'accéder directement ou indirectement à des sites ou à des pages de sites internet tiers dont le(s) contenu(s) serai(en)t contraire(s) aux dispositions légales ou règlementaires applicables. En cas de notification ou d’information sur de tels contenus, Wanadev s’engage à prendre sans délai toutes dispositions pour les supprimer. L’Utilisateur reconnait être informé des risques associés à la consultation des sites tiers et ne pourra en aucun cas rechercher la responsabilité de Wanadev quant à leurs contenus.",
    "Tolerance.": "Tolérance.",
    "The fact that Wanadev does not avail itself at a given time of any of the present General Conditions and/or tolerates a breach by the other Party of any of the obligations referred to in the present General Conditions may not be interpreted as a waiver by Wanadev of its right to avail itself of any of the said Conditions at a later date.": "Le fait que Wanadev ne se prévale pas à un moment donné de l'une quelconque des présentes Conditions générales et/ou tolère un manquement par l'autre Partie à l'une quelconque des obligations visées dans les présentes Conditions générales ne peut être interprétée comme valant renonciation par Wanadev à se prévaloir ultérieurement de l'une quelconque desdites Conditions.",
    "Modification.": "Modification.",
    "Wanadev reserves the right to modify these General Terms and Conditions and the accessible Services at any time. Users have a duty to keep themselves informed of any updates. Modification of the General Conditions will be deemed tacitly accepted if Users do not object to it.": "Wanadev se réserve le droit de modifier à tout moment les présentes Conditions Générales ainsi que les Services accessibles. Les Utilisateurs ont le devoir de se tenir informés de toute actualisation. La modification des Conditions Générales sera réputée tacitement acceptée si les Utilisateurs n’y opposent aucune contestation.",
    "Applicable law & jurisdiction.": "Droit applicable & compétence juridictionnelle.",
    "These General Terms and Conditions are governed by French law. Any dispute relating to the interpretation, performance and/or termination of these General Terms and Conditions, which cannot be resolved amicably, will be subject to the exclusive jurisdiction of the courts within the jurisdiction of the Lyon Court of Appeal, even in the event of an incidental claim or guarantee or multiple defendants. This provision applies without prejudice to any claim that the User may lodge with the Commission Nationale de l'Informatique et des Libertés.": "Les présentes Conditions Générales sont soumises au droit français. Tout litige portant sur l’interprétation, l’exécution et/ou la cessation des présentes Conditions Générales, ne pouvant faire l’objet d’une résolution amiable sera soumis à la compétence exclusive des juridictions du ressort de la Cour d’appel de Lyon, même en cas de demande incidente ou en garantie ou de pluralité de défendeurs. La présente disposition s’applique sans préjudice de l’éventuelle réclamation que l’Utilisateur peut déposer auprès de la Commission nationale de l'informatique et des libertés.",
    "Loading tokens": "Chargement des jetons",
    "Could not load tokens": "Erreur lors du chargement des jetons",
    "Generated token": "Jeton généré",
    "ok": "ok",
    "Could not create token": "Impossible de créer un jeton",
    "Created at": "Créé le",
    "A permanent token allows you to attack API without re-authentication. Tokens can be used in lot of cases such deploy new game releases.": "Un jeton permanent vous permet d'attaquer les API sans avoir à vous re-authentifier. Les jetons peuvent être utilisés dans plusieurs cas comme le déploiement d'une nouvelle version de jeu.",
    "Generate token": "Générer un jeton",
    "Enabled": "Activé",
    "Disabled": "Désactivé",
    "Could not fetch users": "Erreur pour charger les utilisateurs",
    "Surname": "Nom de famille",
    "Phone number": "Numéro de téléphone",
    "Administrator": "Administrateur",
    "Client": "Exploitant",
    "Distributor": "Distributeur",
    "Editor": "Éditeur",
    "Send a password reset email to {{email}}?": "Envoyer un e-mail de réinitialisation de mot de passe à {{email}} ?",
    "No": "Non",
    "Yes": "Oui",
    "Success": "Succès",
    "Successfully sent password reset email": "E-mail de réinitialisation de mot de passe envoyé avec succès",
    "Error": "Erreur",
    "Couldn't send the email, please try again later.": "Impossible d'envoyer l'e-mail, veuillez réessayer plus tard."
};