import pictoFr from "images/fr.svg";
import pictoEn from "images/en.svg";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { default: translationEn } = require("locales/en/translation.po");

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { default: translationFr } = require("locales/fr/translation.po");

type Language = {
  name: string
  key: string
  flag: string
  catalog: Catalog
}

export type Catalog = {
  translation: Record<string, string>
}

const languages: Map<string, Language> = new Map();
languages.set("en", {
  name: "English",
  key: "en",
  flag: pictoEn,
  catalog: { translation: translationEn }
});
languages.set("fr", {
  name: "Français",
  key: "fr",
  flag: pictoFr,
  catalog: { translation: translationFr }
});

export default languages;