import React, { createContext, useContext, useReducer } from "react";
import type { Context, PropsWithChildren } from "react";

import Logger from "../logger";
import { ComputedConsumption } from "components/Consumption/ConsumptionWrapper/compute";

type State = {
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
  consumption: ComputedConsumption | null
};

const initialState: State = {
  loading: false,
  error: null,
  consumption: null
};

export enum ConsumptionAction {
  CLEAR = 'CLEAR',
  FETCHING = 'FETCHING',
  FETCHING_SUCCESS = 'FETCHING_SUCCESS',
  FETCHING_ERROR = 'FETCHING_ERROR'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: State, action: Record<string, any>): State => {
  switch (action.type) {
    case ConsumptionAction.CLEAR:
      return initialState

    case "FETCHING":
      return {
        loading: true,
        error: null,
        consumption: null,
      };

    case "FETCHING_SUCCESS":
      return {
        loading: false,
        error: null,
        consumption: action.data,
      };

    case "FETCHING_ERROR":
      return {
        loading: false,
        error: action.error,
        consumption: null,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConsumptionContext: Context<[State, (...args: Array<any>) => any]> =
  createContext([
    initialState,
    () => Logger.error("Context not correctly initialized"),
  ]);

export const ConsumptionConsumer = ConsumptionContext.Consumer;
export const ConsumptionConsumerHook = () => useContext(ConsumptionContext);
export const ConsumptionProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <ConsumptionContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </ConsumptionContext.Provider>
  );
};
