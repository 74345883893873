import React from "react";
import { useTranslation } from 'react-i18next';

export default function Article5() {
  const { t } = useTranslation()
  
  return (
    <div className="text-primary">
      <ul className="list-unstyled d-grid gap-3">
        <li>
          {t(`Wanadev is the exclusive owner of all intellectual property rights relating to the structure and content of the Services and associated software applications, including in particular graphics, images, text, logos, databases, programs and software, throughout the world.`)}
        </li>
        <li>
          {t(`The use or reproduction of all or part of the Wanadev Services is authorized solely for the purposes of information for personal use, any reproduction or representation made for other purposes being authorized only with the express prior written consent of Wanadev.`)}
        </li>
        <li>
          {t(`With the exception of the foregoing, it is strictly forbidden for Users to:`)}
        </li>
        <div className="d-flex flex-column align-items-center">
          <ol type="a">
            <li>
              {t(`copy, reproduce, represent, disseminate, distribute and publish, permanently or temporarily, all or part of the Services by any means and on any medium known or unknown to date;`)}
            </li>
            <li>{t(`create derivative works from the Services;`)}</li>
            <li>{t(`modify, translate, adapt or arrange all or part of the Services;`)}</li>
            <li>{t(`disassemble, decompile or reverse engineer the Services;`)}</li>
            <li>{t(`take any action to obtain the source codes of Services;`)}</li>
          </ol>
        </div>
        <li>
          {t(`Furthermore, in accordance with the provisions of article L. 342-1 and Wanadev's status as a producer of databases within the meaning of the provisions of articles L.341-1 et seq. of the French Intellectual Property Code, the User shall refrain from:`)}
        </li>
        <div className="d-flex flex-column align-items-center">
          <ul>
            <li>
              {t(`the extraction by permanent or temporary transfer of all or a qualitatively or quantitatively substantial part of the content of the Services, by any means and in any form whatsoever, including for the purposes of use or consultation by a medium and/or process(es) not authorized by Wanadev;`)}
            </li>
            <li>
              {t(`re-use, by making available to the public all or a qualitatively or quantitatively substantial part of the content of the Services, in any form whatsoever, including by hypertext link, media and/or process(es) not authorized by Wanadev;`)}
            </li>
            <li>
              {t(`creating, publishing, maintaining, updating, importing, exporting, making available to third parties, whether free of charge or against payment, and participating in the aforementioned acts, a competing database derived from all or part of the content of the Services;`)}
            </li>
            <li>
              {t(`viewing on a screen by a process or media other than those by which Wanadev intends to disclose the Internet site(s), the Services and their content;`)}
            </li>
            <li>
              {t(`in general, any extraction, use, storage, reproduction, representation or conservation, direct or indirect, partial or total, including in buffer or temporary memory, qualitatively or quantitatively substantial of the contents of the Services, committed by one of the processes referred to above is strictly prohibited, including by a medium not authorized by Wanadev.`)}
            </li>
          </ul>
        </div>
        <li>
          {t(`All elements covered by an intellectual property right that do not belong to Wanadev but nevertheless appear on the website(s) as a result of their publication by a partner or supplier are the property of their respective holder, granting Wanadev a free, non-exclusive license on these contents allowing Wanadev to use, reproduce, represent, disclose, modify, to all third parties and for all purposes, all or part by any means, and for the whole world.`)}
        </li>
        <li>
          {t(`In this respect, Users guarantee that the content published by them does not infringe any third party rights, including the intellectual property rights of third parties. Users agree to indemnify Wanadev against all costs, damages and indemnities incurred by Wanadev as a result of any such content.`)}
        </li>
      </ul>
    </div>
  )
}