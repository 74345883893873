import React from "react";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import Modal from "components/Modal/Modal";
import { FormConsumer, FormProvider } from "services/stores/formStore";
import { ModalConsumer, ModalProvider } from "services/stores/modalStore";
import { changePassword } from "api/profile/edit";
import User from "model/user";
import Password from "model/password";

export interface ChangePasswordProps {
  profile: User
}

export default function ChangePasswordModal(props: ChangePasswordProps) {
  const { t } = useTranslation();

  const updatePassword = async (id: number, form: Omit<Password, 'profile'>) => {
    const { success, errors } = await changePassword({
      profile: id,
      ...form,
    });
    return {
      success,
      errors,
    };
  };

  return (
    <ModalProvider>
      <FormProvider>
        <ModalConsumer>
          {([{ opened }, modalDispatch]) => (
            <>
              <button
                className="btn btn-outline-secondary me-3"
                onClick={() =>
                  modalDispatch({
                    type: opened ? "close" : "open",
                  })
                }
              >
                <i className="icon icon-lock me-2" />
                <Trans>Change my password</Trans>
              </button>

              {(() => {
                if (opened === true) {
                  const formId = "changePasswordForm";
                  return (
                    <FormConsumer>
                      {([{ data, errors }, formDispatch]) => (
                        <Modal
                          header={{
                            title: t("Change password"),
                            icon: "icon icon-lock",
                          }}
                          footer={{
                            btnCloseClass: "btn-danger me-3",
                            btnCloseLabel: t("Cancel"),
                            btnValidClass: "btn-primary",
                            btnValidForm: formId,
                            btnValidLabel: t("Save new password"),
                          }}
                          onClose={() =>
                            modalDispatch({
                              type: "close",
                            })
                          }
                        >
                          <form>
                            <div className="row py-3 pb-5 mt-5">
                              <div className="col-12">
                                <div className="form-floating input-container">
                                  <input
                                    className="form-control"
                                    placeholder={t(
                                      "Enter your current password"
                                    )}
                                    required
                                    type="password"
                                    defaultValue={
                                      data?.currentPassword || null
                                    }
                                    onChange={(e) =>
                                      formDispatch({
                                        type: "setValue",
                                        field: "currentPassword",
                                        value: e.target.value,
                                      })
                                    }
                                    id="currentPassword"
                                  />
                                  <label className="label-wize" htmlFor='currentPassword'>
                                    <Trans>Current password</Trans>
                                  </label>
                                  {errors
                                    .filter(
                                      (error) =>
                                        error?.currentPassword ===
                                        "currentPassword"
                                    )
                                    .map(({ reason }) => (
                                      <small className="form-text text-danger">
                                        {reason}
                                      </small>
                                    ))}
                                  <i className="icon icon-edit"></i>
                                  <div className="liner"></div>
                                </div>
                              </div>
                            </div>
                            <div className="row pb-5 mt-3">
                              <div className="col-12">
                                <div className="form-floating input-container">
                                  <input
                                    className="form-control"
                                    placeholder={t(
                                      "Min 8 chars, 2 numbers"
                                    )}
                                    required
                                    type="password"
                                    defaultValue={
                                      data?.newPassword || null
                                    }
                                    onChange={(e) =>
                                      formDispatch({
                                        type: "setValue",
                                        field: "newPassword",
                                        value: e.target.value,
                                      })
                                    }
                                    id="newPassword"
                                  />
                                  <label className="label-wize" htmlFor="newPassword">
                                    <Trans>New password</Trans>
                                  </label>
                                  {errors
                                    .filter(
                                      (error) =>
                                        error?.newPassword ===
                                        "newPassword"
                                    )
                                    .map(({ reason }) => (
                                      <small className="form-text text-danger">
                                        {reason}
                                      </small>
                                    ))}
                                  <i className="icon icon-edit"></i>
                                  <div className="liner"></div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="form-floating input-container">
                                  <input
                                    className="form-control"
                                    placeholder={t(
                                      "Min 8 chars, 2 numbers"
                                    )}
                                    required
                                    type="password"
                                    defaultValue={
                                      data?.repeatNP || null
                                    }
                                    onChange={(e) =>
                                      formDispatch({
                                        type: "setValue",
                                        field: "repeatNP",
                                        value: e.target.value,
                                      })
                                    }
                                    id="repeatNewPassword"
                                  />
                                  <label className="label-wize" htmlFor="repeatNewPassword">
                                    <Trans>Repeat new password</Trans>
                                  </label>
                                  {errors
                                    .filter(
                                      (error) =>
                                        error?.repeatNP === "repeatNP"
                                    )
                                    .map(({ reason }) => (
                                      <small className="form-text text-danger">
                                        {reason}
                                      </small>
                                    ))}
                                  <i className="icon icon-edit"></i>
                                  <div className="liner"></div>
                                </div>
                              </div>
                            </div>
                          </form>

                          {(() => {
                            if (data.repeatNP) {
                              if (
                                data.newPassword !== data.repeatNP
                              ) {
                                return (
                                  <>
                                    <div className="alert alert-danger">
                                      <Trans i18nKey="Password do not match" />
                                    </div>

                                    {errors
                                      .filter(
                                        (error) =>
                                          error?.name === undefined
                                      )
                                      .map((error) => (
                                        <div className="alert alert-danger">
                                          {error}
                                        </div>
                                      ))}
                                  </>
                                );
                              }
                            }
                          })()}

                          <form
                            id={formId}
                            onSubmit={async (e) => {
                              e.preventDefault();
                              formDispatch({
                                type: "setSubmitting",
                                submitting: true,
                              });

                              if (
                                data.newPassword === data.repeatNP
                              ) {
                                const result = await updatePassword(
                                  props.profile.id,
                                  data
                                );

                                if (result) {
                                  modalDispatch({
                                    type: "close",
                                  });
                                  formDispatch({
                                    type: "reset",
                                  });
                                } else {
                                  formDispatch({
                                    type: "setErrors",
                                    errors,
                                  });
                                }
                              }
                            }}
                          />
                        </Modal>
                      )}
                    </FormConsumer>
                  );
                }
              })()}
            </>
          )}
        </ModalConsumer>
      </FormProvider>
    </ModalProvider>
  )
}
