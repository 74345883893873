import { raw } from "security/token";
import Logger from "services/logger";

export function showCouch(
  launcher: string,
  game: string
): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/launchers/${launcher}/_/games/${game}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "api/v1/launchers/:id/_/games/:id"', {
      launcher,
      game,
      errors
    });
    return Promise.resolve(null);
  })

}