import { raw } from "security/token";
import Logger from "services/logger";

export function getSummary(location: number): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/locations/${location}/balances/summary`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "api/v1/location/:id/balances/summary"', {
      location,
      errors
    });
    return Promise.resolve(null);
  });
}
