import React from "react";
import type { PropsWithChildren } from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

import isGranted from "security/voter";
import token from "security/token";
import type { RouteBuilt } from "routes";
import Unauthorized from "scenes/Unauthorized/Unauthorized";
import { getPath, getRoutes } from "routes";
import NotFound from "scenes/NotFound/NotFound";
import { GamesProvider } from "stores/gamesStore";
import { EditorsProvider } from "stores/editorsStore";
import { DistributorsProvider } from "stores/distributorsStore";
import { ClientsProvider } from "stores/clientsStore";
import { LocationsProvider } from "stores/locationsStore";
import { LaunchersProvider } from "stores/launchersStore";
import { ConsumptionProvider } from "stores/consumptionStore";
import { BalanceSummariesProvider } from "services/stores/balanceSummariesStore";
import "services/helper/i18n";
import { NotificationProvider } from "services/stores/notificationStore";
import "services/helper/i18n";

function IsGranted({ children, roles }: PropsWithChildren<{ roles: Set<string> }>) {
  if (isGranted(roles) === true) {
    return children
  } else if (token() === null) {
    return <Navigate to={getPath("login") ?? ''} />;
  }

  return <Unauthorized />;
}

function compile({
  component: Component,
  path,
  props,
  roles,
  routes,
}: RouteBuilt): Record<string, unknown> {
  const element = (
    <IsGranted roles={roles}>
      {/* @ts-expect-error TS2604 TS2786 */}
      <Component {...(props())} />
    </IsGranted>
  )

  if (routes === undefined && path.length === 0) {
    return {
      path,
      element,
    }
  }

  let children: unknown[] = []

  if (routes !== undefined) {
    children = routes.map(compile)
  }

  return {
    path,
    element,
    children,
  }
}

const App = () => {
  const router = createBrowserRouter(
    getRoutes().map(compile),
  )

  return (
    <EditorsProvider>
      <GamesProvider>
        <DistributorsProvider>
          <ClientsProvider>
            <LocationsProvider>
              <LaunchersProvider>
                <ConsumptionProvider>
                  <BalanceSummariesProvider>
                    <NotificationProvider>
                      <RouterProvider
                        router={router}
                        fallbackElement={<NotFound />}
                      />
                    </NotificationProvider>
                  </BalanceSummariesProvider>
                </ConsumptionProvider>
              </LaunchersProvider>
            </LocationsProvider>
          </ClientsProvider>
        </DistributorsProvider>
      </GamesProvider>
    </EditorsProvider>
  );
};

export default App;
