import React from "react";
import { useTranslation } from 'react-i18next';

import Modal from "components/Modal/Modal";
import { FormConsumer, FormProvider } from "services/stores/formStore";
import { ModalConsumer, ModalProvider } from "services/stores/modalStore";
import { resetPassword } from "api/users/resetPassword";
import User from "model/user";
import { NotificationConsumerHook, addNotification } from "services/stores/notificationStore";

import pictoKey from "images/picto-key.svg";

export interface ResetUserPasswordModalProps {
  user: User
}

export default function ChangePasswordModal(props: ResetUserPasswordModalProps) {
  const { t } = useTranslation();
  const [, dispatchNotification] = NotificationConsumerHook();

  return (
    <ModalProvider>
      <FormProvider>
        <ModalConsumer>
          {([{ opened }, modalDispatch]) => (
            <>
              <div className='d-flex justify-content-center align-items-center'>
                <button
                  title={t("Change Password")}
                  className="btn btn-primary users__button d-flex justify-content-center align-items-center"
                  onClick={() =>
                    modalDispatch({
                      type: opened ? "close" : "open",
                    })
                  }
                >
                  <img className="p-2" alt="reset password icon" src={pictoKey} />
                </button>
              </div>

              {(() => {
                if (opened === true) {
                  const formId = "resetPasswordForm";
                  return (
                    <FormConsumer>
                      {([, formDispatch]) => (
                        <Modal
                          header={{
                            title: t(`Send a password reset email to {{email}}?`, { email: props.user.email }),
                          }}
                          footer={{
                            btnCloseClass: "btn-danger w-50 me-2",
                            btnCloseLabel: t("No"),
                            btnValidClass: "btn-primary  w-50 ms-2",
                            btnValidForm: formId,
                            btnValidLabel: t("Yes"),
                          }}
                          onClose={() =>
                            modalDispatch({
                              type: "close",
                            })
                          }
                        >
                          <form
                            id={formId}
                            onSubmit={async (e) => {
                              e.preventDefault();
                              formDispatch({
                                type: "setSubmitting",
                                submitting: true,
                              });

                              const response = await resetPassword(props.user.email);

                              if (response?.ok) {
                                modalDispatch({
                                  type: "close",
                                });
                                addNotification(
                                  dispatchNotification,
                                  t('Success'),
                                  t('Successfully sent password reset email'),
                                  'success'
                                )
                              } else {
                                addNotification(
                                  dispatchNotification,
                                  t('Error'),
                                  t("Couldn't send the email, please try again later."),
                                  'error'
                                )
                              }
                            }}
                          />
                        </Modal>
                      )}
                    </FormConsumer>
                  );
                }
              })()}
            </>
          )}
        </ModalConsumer>
      </FormProvider>
    </ModalProvider>
  )
}
