import React from "react";

import { ConsumptionDetails } from "components/Consumption/ConsumptionWrapper/compute";
import { ClientsConsumerHook } from "stores/clientsStore";
import { LocationsConsumerHook } from "stores/locationsStore";
import Logger from "services/logger";
import GameLocationRow from "components/Consumption/ConsumptionDetails/components/GameLocationRow/GameLocationRow";

interface Props {
  location: number
  consumptionDetails: Omit<ConsumptionDetails, 'date'>[]
}

export default function LocationRow({ location: locationId, consumptionDetails }: Props) {
  const [{ clients, loading: loadingClients, error: errorClients }] = ClientsConsumerHook()
  const [{ locations, loading: loadingLocations, error: errorLocations }] = LocationsConsumerHook()

  if (!consumptionDetails) {
    return null
  }

  if (loadingClients || loadingLocations) {
    Logger.debug('clients and locations not loaded')
    return null
  } else if (errorClients || errorLocations) {
    Logger.warn('could not get clients and locations', { errorClients, errorLocations })
    return null
  }

  const location = locations?.find(l => l.id === locationId)
  if (!location) {
    Logger.warn('location not found', { id: locationId })
    return null
  }

  const client = (clients || []).find(c => c.id === location.client)
  if (!client) {
    Logger.warn('client not found', { id: location.client })
    return null
  }

  const gameLocationRows: JSX.Element[] = []
  for (const details of consumptionDetails) {
    gameLocationRows.push(<GameLocationRow
      key={`${details.game}-${locationId}`}
      game={details.game}
      location={locationId}
      consumption={details.consumption}
    />)
  }

  return (
    <>
      <tr>
        <td colSpan={5}>
          <b>
            {`${client.name} - ${location.name} - ${location.address} ${location.city}, ${location.country}`}
          </b>
        </td>
      </tr>
      {gameLocationRows}
    </>
  )
}