import { BadgeNotification } from '@octopod/design-system'
import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export interface SidebarLinkProps {
  image: string
  label: string
  path: string
  active: boolean
  collapsed: boolean
  options?: { isChild?: boolean, showCondition?: boolean, extraFiltersCount?: number }
}

export default function SidebarLink({ image, label, path, active, collapsed, options }: SidebarLinkProps) {
  const { t } = useTranslation()

  if (options?.showCondition === false) {
    return null
  }

  return (
    <Link
      className={`nav-link${collapsed ? '-collapsed' : ''} ${active ? 'active' : ""} ${options?.isChild ? 'child' : ''} ${options?.isChild && !collapsed ? 'offset' : ''}`}
      to={path}
    >
      <div className="icon-container">
        <img className="icon icon--white icon-mask" alt={t(`${label} icon`)} src={image} />
      </div>
      {!collapsed && (
        <p>
          {label}
        </p>
      )}
      {options?.extraFiltersCount !== undefined && (
        <div className={collapsed ? 'badge-collapsed' : ''}>
          {options.extraFiltersCount > 0 && <BadgeNotification label={options.extraFiltersCount.toString(10)} />}
        </div>
      )}
    </Link>
  )
}