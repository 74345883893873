export default {
    "69009": "",
    "Download Logo": "",
    "Legal notice & Terms of use": "",
    "Dashboard": "",
    "Support": "",
    "Games": "",
    "Game Sessions": "",
    "Tokens": "",
    "Profile": "",
    "Company": "",
    "Launchers": "",
    "Consumption": "",
    "Balance": "",
    "Terms of use": "",
    "Logout": "",
    "by": "",
    "Octopod Logo": "",
    "My Dashboard": "",
    "My Consumption": "",
    "My Balance": "",
    "My Billing": "",
    "My Game Sessions": "",
    "My Launchers": "",
    "My Games": "",
    "My Users": "",
    "Octopod doc icon": "",
    "Menu collapse icon": "",
    "You do not have the permissions to access this page": "",
    "Fetching consumption": "",
    "Minutes consumed (playing time)": "",
    "Billable minutes (playing time)": "",
    "Session played": "",
    "Billable sessions": "",
    "Fetching invoices data": "",
    "Could not load invoices data": "",
    "Done at": "",
    "Version": "",
    "Duration": "",
    "Players": "",
    "Total consumption": "",
    "Billable": "",
    "By minutes": "",
    "By sessions": "",
    "You no longer have game credit.": "",
    "n/a": "",
    "Duration consumed": "",
    "The \"Duration billable\" indicated may differ from the amount actually billed. To bill, we add up all the sessions to be billed for the same game and round up to the closest minute.": "",
    "Duration billable": "",
    "Played sessions": "",
    "Billed sessions": "",
    "Statistics": "",
    "Daily consumption": "",
    "Remaining credit": "",
    "Minutes": "",
    "On {{date}}": "",
    "Sessions": "",
    "Credits - More information": "",
    "Ok": "",
    "More information": "",
    "Please make contact with Octopod or your usual retailer to recharge your game credits.": "",
    "Others": "",
    "sessions": "",
    "minutes": "",
    "message": "",
    "Actions": "",
    "Charging": "",
    "In production": "",
    "Not in production": "",
    "Editor icon": "",
    "Game icon": "",
    "All games": "",
    "Loading": "",
    "Please select a launcher": "",
    "Please select a location": "",
    "Distributor icon": "",
    "Client icon": "",
    "Location icon": "",
    "All locations": "",
    "Details": "",
    "Date": "",
    "Invoices": "",
    "Credit": "",
    "Debit": "",
    "Show details": "",
    "Description": "",
    "Load more": "",
    "Export": "",
    "Games involved": "",
    "Close": "",
    "See the games concerned": "",
    "error": "",
    "Could not fetch more invoices": "",
    "Could not find this invoice link": "",
    "Could not find this invoice details": "",
    "Could not fetch invoices": "",
    "Id": "",
    "Address": "",
    "Creation date": "",
    "Download scores button": "",
    "Show details button": "",
    "Fetching invoice details": "",
    "Game": "",
    "Played": "",
    "Retained on credits": "",
    "Total billed": "",
    "Total number of sessions/minutes played": "",
    "Total number of sessions/minutes billable": "",
    "Total number of billable sessions/minutes retained on credits": "",
    "Total number of sessions/minutes actually billed": "",
    "Try to reconnect. If the problem persists, contact the support.": "",
    "Fetching company data": "",
    "Wanadev": "",
    "13 Quai du Commerce": "",
    "Lyon": "",
    "Confirm changes ?": "",
    "Cancel": "",
    "My company": "",
    "Save Company": "",
    "{{total}} errors encoutered": "",
    "Upload a company picture": "",
    "Could not update your company logo, please try again.": "",
    "File weight": "",
    "max": "",
    "{{filesize}} MB": "",
    "No file uploaded": "",
    "Search in your computer": "",
    "Preview": "",
    "Name": "",
    "Weight": "",
    "Size": "",
    "{{filesize}} KB": "",
    "File uploaded": "",
    "min": "",
    "s": "",
    "Error while downloading scores": "",
    "Scores PDF downloaded": "",
    "The scores PDF of your game has just been downloaded": "",
    "Session #": "",
    "Session": "",
    "Starting date": "",
    "Ending date": "",
    "Launcher": "",
    "Copy session id button": "",
    "Unknown": "",
    "Please select a launcher to access the corresponding game sessions": "",
    "Launcher icon": "",
    "Show session details button": "",
    "Launch options details": "",
    "Team {{teamNumber}}": "",
    "Server": "",
    "Player {{playerNumber}}": "",
    "Spectator": "",
    "Options": "",
    "Values": "",
    "Nothing to show": "",
    "All sessions": "",
    "Finished sessions": "",
    "Unfinished sessions": "",
    "Fetching game data": "",
    "No game found": "",
    "Fetching releases data": "",
    "Channels": "",
    "Availability": "",
    "Since {{date}}": "",
    "Game was not found": "",
    "Form is empty": "",
    "Save": "",
    "Game name": "",
    "Current status": "",
    "Versions": "",
    "Releases": "",
    "More releases": "",
    "Informations": "",
    "Medias": "",
    "Devs": "",
    "You have made changes without saving. Are you sure you want to exit?": "",
    "To deploy new releases, you can read <0>developer documentation</0>.": "",
    "You can create a token in the menu \"<0>Tokens</0>\"": "",
    "Your game identifier": "",
    "Could not find game": "",
    "Select your comfort": "",
    "Unknown spaces": "",
    "Add your headset compatibilities": "",
    "Languages": "",
    "Categories": "",
    "Internet connection required": "",
    "Warehouse game": "",
    "Comfort": "",
    "VR Spaces": "",
    "Extracted from the latest stable release's manifest": "",
    "Headset type": "",
    "Minimum age": "",
    "Could not fetch attachments": "",
    "Loading attachments": "",
    "Add new file": "",
    "Submit": "",
    "Category": "",
    "Action": "",
    "Edit file": "",
    "Delete file": "",
    "Comfirm deletion": "",
    "Add file": "",
    "Game guides & Marketing assets": "",
    "File not found": "",
    "Could not send file": "",
    "Attachment not found": "",
    "Uploading {{filename}}": "",
    "Com pack game": "",
    "Upload file": "",
    "File name": "",
    "Category required": "",
    "show me less": "",
    "show me more": "",
    "Could not fetch more detail about this release": "",
    "Installation": "",
    "Total installations in LBVR": "",
    "Note": "",
    "Manifest": "",
    "Activation Confirmation": "",
    "Validate": "",
    "You're about to put this release on channel <0>{{channel}}</0>. This operation cannot be undone.": "",
    "To confirm, write <0>{{channel}}</0> in input field.": "",
    "Insert channel name to confirm": "",
    "Please insert {{version}} to confirm the activation.": "",
    "Could not flag release as unavailable": "",
    "Unavailable since {{date}}": "",
    "Flag as unavailable": "",
    "Flag as unavailable confirmation": "",
    "success": "",
    "The version will be updated to become unavailable. This operation may take a few minutes.": "",
    "Release cannot be marked as unavailable": "",
    "Force launchers uninstallation": "",
    "You're about to flag this release as <0>unavailable</0>. This operation can't be reverted.": "",
    "To confirm, write <0>{{version}}</0> in input field.": "",
    "Insert version to confirm": "",
    "Go to game details": "",
    "Spaces": "",
    "Game Guides": "",
    "Marketing Assets": "",
    "An error occurs when creating the game": "",
    "Add new game": "",
    "Create": "",
    "Add game": "",
    "All spaces": "",
    "Fetching launchers data": "",
    "Login": "",
    "Version installed": "",
    "Related games": "",
    "Blocked account": "",
    " This page allows you to see your launchers. If you already have a launcher, you reach your maximum then contact an administrator for more launchers.": "",
    "Download launcher ": "",
    "Add new launcher": "",
    "Enter a login for your launcher": "",
    "Only lowercase chars, numbers, dash and low dash allowed": "",
    "Add launcher": "",
    "Change Password": "",
    "Change password": "",
    "Save new password": "",
    "Min 8 chars, 2 numbers": "",
    "Your password must contain at least 8 chars and 2 numbers": "",
    "Couldn't update your password": "",
    "Password do not match": "",
    "New password": "",
    "Repeat new password": "",
    "Email": "",
    "Please enter an email address": "",
    "Password": "",
    "Hide password": "",
    "Show password": "",
    "Forgot your password ?": "",
    "English": "",
    "French": "",
    "Could not fetch profile data": "",
    "Firstname": "",
    "Lastname": "",
    "Email language": "",
    "My profile": "",
    "Enter your current password": "",
    "Change my password": "",
    "Current password": "",
    "Phone": "",
    "Save Profile": "",
    "{{total}} errors encountered": "",
    "Something went wrong": "",
    "Passwords do not match": "",
    "Invalid parameters": "",
    "Invalid credentials": "",
    "Please enter your desired password": "",
    "Password confirmation": "",
    "Please confirm your desired password": "",
    "Sign in": "",
    "Send me an email": "",
    "Remove": "",
    "Remove file": "",
    "{{size}}B": "",
    "{{size}}KB": "",
    "{{size}}MB": "",
    "{{size}}GB": "",
    "Title": "",
    "Your message...": "",
    "Your message has been successfully sent to our support": "",
    "Sending": "",
    "Send": "",
    "Support Information": "",
    "You can send a message to OctopodVR support. Be explicit and detailed your issue with log/attachment/screenshot/...": "",
    "Terms and conditions of use": "",
    "Wanadev is a limited liability company with capital of €100,000, registered with the Versailles Trade and Companies Register under number 511 500 811, with its registered office at 13 Quai du Commerce, 69 009, represented by its manager Mr. Côme de Percin (hereinafter \"Wanadev\").": "",
    "Wanadev offers access via online platforms to software solutions to support companies operating accessible virtual reality rooms. These include in particular the \"Octopod: Insight\", \"Octopod: Play\" and \"Octopod: Games\" offers (hereinafter individually or jointly the \"Services\").": "",
    "These general conditions of use of the Services offered here (hereinafter the \"General Conditions\") govern the relationship between the user of the Services (hereinafter the \"User\") and Wanadev.": "",
    "Access to and use of the Services imply unreserved acceptance of the terms of these General Conditions. Users are reminded that special additional conditions may also apply to Users of the Services depending on the professional contracts entered into with Wanadev - solution subscription, integration of third-party content, solution distribution, etc. - the stipulations of which shall prevail in the event of contradiction with these General Conditions. - whose stipulations shall prevail in the event of contradiction with the present General Terms and Conditions.": "",
    "Article 1 - Legal notice": "",
    "Article 2 - Description and use of the Services": "",
    "Article 3 - Use of the Services": "",
    "Article 4 - Wanadev's obligations and liability": "",
    "Article 5 - Intellectual property": "",
    "Article 6 - Personal data & GDPR": "",
    "Article 7 - Confidentiality & Cookies": "",
    "Article 8 - Miscellaneous provisions": "",
    "The Services accessible from the online platforms and website(s) are the property of Wanadev, published and hosted by the latter.": "",
    "Director of publication:": "",
    "Mr. Côme de Percin": "",
    "Publisher & Host:": "",
    "Wanadev, EURL with a share capital of 100 000 €": "",
    "13 Quai du Commerce 69 009 Lyon": "",
    "R.C.S. Lyon 511 500 811": "",
    "Contact details:": "",
    "Phone: +33 (0)4 28 29 69 01": "",
    "Email: contact@wanadev.fr": "",
    "Requests for information and exercise of rights relating to Users' personal data may be sent directly via the company's contact address.": "",
    "Access to and use of the Services may be subject to the User providing a number of administrative details in order to create his/her user account. The User undertakes not to provide false and/or erroneous contact details or information. The User is solely responsible for the consequences of any inaccurate information he or she may provide.": "",
    "Once the Services have been configured and/or installed, the User may access the Services offered according to the User's wishes (hereinafter referred to as the \"Content\"). The User acknowledges that additional conditions may apply for access to certain content, access to which may change at the sole discretion of Wanadev, in particular in the event of a temporarily limited discovery promotional offer, in accordance with the contractual provisions governing the relationship between the User and Wanadev.": "",
    "The User is not authorized to use the Services and Content for purposes other than those intended by Wanadev. The User may not under any circumstances sell, grant a privilege or transfer reproductions of the Services or Content to third parties in any manner whatsoever, nor to rent, lease or license the Services & Content to third parties without the prior written consent of Wanadev, except within the limits of the express authorizations of these General Conditions.": "",
    "The User undertakes to use the Services offered in compliance with the regulations in force and with these General Terms and Conditions. Failing this, the User is solely liable for any damage caused and for the consequences of any claims or actions that may result.": "",
    "The User undertakes to hold Wanadev harmless against all claims, complaints, actions or recourse of any kind from third parties and to indemnify Wanadev and third parties for all damages resulting from use of the Services that does not comply with the applicable regulations and/or these General Terms and Conditions.": "",
    "All the Services offered and their content are produced for information purposes only. The User alone assumes responsibility for the consequences of using the data and documents generated by using the Services.": "",
    "Wanadev undertakes, as part of an obligation of means, to make the Services available. Although Wanadev endeavors to keep the Services accessible, Wanadev may nevertheless find itself obliged to temporarily suspend partial or total access to the Services, in particular for technical maintenance reasons, without this entailing any liability on the part of Wanadev.": "",
    "Furthermore, it is specified that the Internet network and the computer and telecommunications systems used by the User to access and use the Services are not error-free and interruptions and breakdowns may occasionally occur. Wanadev cannot provide any guarantee in this respect and cannot therefore be held responsible for any damage inherent in the said use of the Internet network and computer and telecommunications systems, in particular, but without this list being limitative:": "",
    "poor transmission and/or reception of any data and/or information on the Internet;": "",
    "external intrusion;": "",
    "failure of any reception equipment or communication lines;": "",
    "any other malfunction of the Internet network that prevents the Services from functioning properly and/or running smoothly.": "",
    "Furthermore, the User acknowledges and accepts that Wanadev may not be held liable for any consequences in the performance of the Services resulting from erroneous, approximate or incomplete information communicated by the User.": "",
    "Also, the information transmitted when consulting or using the Services is provided for information purposes only and cannot be guaranteed to be accurate or up to date. The User acknowledges that Wanadev offers no guarantee and is under no obligation to verify the content made available by its partners and/or suppliers and disseminated via the website(s).": "",
    "In the event of a breach by the User of these General Conditions, Wanadev may terminate the User's access to the Services, notwithstanding any damages to which Wanadev may be entitled.": "",
    "Wanadev is the exclusive owner of all intellectual property rights relating to the structure and content of the Services and associated software applications, including in particular graphics, images, text, logos, databases, programs and software, throughout the world.": "",
    "The use or reproduction of all or part of the Wanadev Services is authorized solely for the purposes of information for personal use, any reproduction or representation made for other purposes being authorized only with the express prior written consent of Wanadev.": "",
    "With the exception of the foregoing, it is strictly forbidden for Users to:": "",
    "copy, reproduce, represent, disseminate, distribute and publish, permanently or temporarily, all or part of the Services by any means and on any medium known or unknown to date;": "",
    "create derivative works from the Services;": "",
    "modify, translate, adapt or arrange all or part of the Services;": "",
    "disassemble, decompile or reverse engineer the Services;": "",
    "take any action to obtain the source codes of Services;": "",
    "Furthermore, in accordance with the provisions of article L. 342-1 and Wanadev's status as a producer of databases within the meaning of the provisions of articles L.341-1 et seq. of the French Intellectual Property Code, the User shall refrain from:": "",
    "the extraction by permanent or temporary transfer of all or a qualitatively or quantitatively substantial part of the content of the Services, by any means and in any form whatsoever, including for the purposes of use or consultation by a medium and/or process(es) not authorized by Wanadev;": "",
    "re-use, by making available to the public all or a qualitatively or quantitatively substantial part of the content of the Services, in any form whatsoever, including by hypertext link, media and/or process(es) not authorized by Wanadev;": "",
    "creating, publishing, maintaining, updating, importing, exporting, making available to third parties, whether free of charge or against payment, and participating in the aforementioned acts, a competing database derived from all or part of the content of the Services;": "",
    "viewing on a screen by a process or media other than those by which Wanadev intends to disclose the Internet site(s), the Services and their content;": "",
    "in general, any extraction, use, storage, reproduction, representation or conservation, direct or indirect, partial or total, including in buffer or temporary memory, qualitatively or quantitatively substantial of the contents of the Services, committed by one of the processes referred to above is strictly prohibited, including by a medium not authorized by Wanadev.": "",
    "All elements covered by an intellectual property right that do not belong to Wanadev but nevertheless appear on the website(s) as a result of their publication by a partner or supplier are the property of their respective holder, granting Wanadev a free, non-exclusive license on these contents allowing Wanadev to use, reproduce, represent, disclose, modify, to all third parties and for all purposes, all or part by any means, and for the whole world.": "",
    "In this respect, Users guarantee that the content published by them does not infringe any third party rights, including the intellectual property rights of third parties. Users agree to indemnify Wanadev against all costs, damages and indemnities incurred by Wanadev as a result of any such content.": "",
    "The General Terms and Conditions are subject to the provisions of European Regulation 2016/679 of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data, as well as those of Law No. 78-17 of 6 January 1978 on information technology, files and freedoms and any other applicable regulations in this area that may subsequently supplement or replace them.": "",
    "Wanadev needs to use the User's data to manage his/her account. Without such information, it will not be able to provide useful access to the Services. It may also use this information to send the User its best promotional offers, for statistical purposes and to combat fraud, for its legitimate business development interests, for the technical improvement of its systems and its knowledge of the User and, finally, where applicable with the User's consent, to personalize its services, in particular by means of advertising.": "",
    "The User's data is kept for the duration of use of the services by the User and, beyond that, for a period of three years for commercial purposes, as well as for purposes of proof and accounting obligations, for a period not exceeding the applicable legal limitation periods.": "",
    "For processing carried out by and/or on behalf of Wanadev, the User has, if necessary after providing valid identification, a right of access, where appropriate portability, modification, limitation and deletion of data concerning him and a right to object, possibly subject to legitimate and compelling reasons, to the processing of such data. They may also define directives concerning the fate of their personal data in the event of their death. These rights may be exercised directly with Wanadev by e-mail at contact@wanadev.fr or by post at the following address: Wanadev, 31 rue de Grenette 69002 Lyon.": "",
    "In accordance with legal provisions, the User has the right to lodge a complaint with a supervisory authority - the CNIL.": "",
    "Personal data provided.": "",
    "For the purposes of this article, \"personal data\" means any information relating to an identified or identifiable natural person, within the meaning of the applicable legislation in force. For all intents and purposes, an \"identifiable natural person\" is one who can be identified, directly or indirectly, in particular by reference to an identifier, such as a name, an identification number, location data, an online identifier, or to one or more factors specific to his or her physical, physiological, genetic, mental, economic, cultural or social identity. The categories of data concerned are as follows:": "",
    "Identity data": "",
    ": this information may include surname, first name, telephone number, email address or postal address;": "",
    "Technical and browsing data": "",
    ": Wanadev is likely to collect several types of data relating to the technical methods by which the User accesses and uses the Services and Internet sites, partner sites or third-party sites whose data is imported into the Site. This data may be collected using cookies and other similar techniques.": "",
    "Use of Users' personal data.": "",
    "Wanadev may use Users' personal data in defined cases:": "",
    "Operation & Optimization of Services": "",
    ": the personal data collected is necessary for the operation of the services offered as well as for the development and improvement of Wanadev's services;": "",
    "Communication & Personalization": "",
    ": subject to the required consent, Wanadev may use certain data in order to offer, in particular by means of advertising, personalized services and offers, promotions or any other commercial information relating to the services or services of partners;": "",
    "Compliance with current legislation.": "",
    "Sharing of personal data.": "",
    "Wanadev may share Users' personal data with commercial partners or other companies with which Wanadev is affiliated (parent companies, sister companies or daughter companies), as part of the operation of the Services or in particular for logistical purposes.": "",
    "Similarly, the User's data may be communicated in response to a specific request made by a competent administrative or judicial authority, and more generally in all situations where this is required by law, regulation or an administrative or judicial decision;": "",
    "More generally, this data may be shared with any other third party after the User has been informed and subject to the absence of opposition or prior consent in accordance with the applicable rules.": "",
    "Retention period for personal data.": "",
    "Personal data will be kept in active files or systems only for as long as is necessary to fulfil the purposes for which they were collected in accordance with the applicable rules, i.e.:": "",
    "In principle, for all data;": "",
    "By exception, for the purposes of commercial prospecting by electronic means, Wanadev may keep the email address, as well as the first and last names for up to three years after the complete cessation of use of Wanadev's services, subject to the exercise of a right of opposition.": "",
    "Wanadev may collect and process personal information from Users of the Services in order to offer an improved web service and personalized benefits.": "",
    "Definition(s).": "",
    "Cookies are files that websites place on the hard disk of the User's computer when they first visit the site. Their function is to maintain a link between the site and the computer medium used. The site will be able to retrieve the information stored in the cookie during previous visits. Cookies make browsing more efficient by accessing memory functions. Cookies are used to store preferences, register products and services and personalize pages.": "",
    "Types & purposes.": "",
    "The cookies used by Wanadev are:": "",
    "Exercising your rights.": "",
    "Each Internet browser offers ways of limiting and deleting cookies. For more information on how to manage cookies, visit the appropriate link below, or check the settings of your browser if it is not on this list: Firefox, Google Chrome, Safari.": "",
    "Any other request relating to the exercise of the User's rights to access, modify, oppose or delete personal data may be made directly to Wanadev at the following address: contact@wanadev.fr": "",
    "the <0>strictly necessary cookies</0> enabling the User to move around the website(s) supporting the Services and to use its basic functionalities;": "",
    "the enhanced <0>functionality cookies</0> used to recognize Internet users when they return to the website and to count the number of visitors as well as to collect information concerning the use of the Services;": "",
    "the <0>analytical cookies</0> enabling the collection of data relating to your use of the website in order to improve its performance and design.": "",
    "Invalidity of a clause.": "",
    "In the event that any provision of these General Terms and Conditions is null and void or annulled, the Parties shall endeavour to replace it with a provision of the closest legal and economic effect and the other provisions shall continue to have effect in accordance with the intentions of the Parties as set out in the General Terms and Conditions.": "",
    "Electronic evidence.": "",
    "The parties acknowledge and agree that SMS messages, MMS messages, electronic mail (emails), instant messaging exchanges and all other electronic writings resulting from the use of the Services and exchanged between Wanadev and the User within the framework of the Services constitute:": "",
    "original documents between the parties;": "",
    "proof and": "",
    "admissible before the courts, provided that the person from whom they emanate can be duly identified and that they are established and stored in conditions that guarantee their integrity.": "",
    "Force majeure & act of a third party.": "",
    "In the event that Wanadev finds itself partially or totally unable to carry out one of its obligations under these General Terms and Conditions, as a result of a case of force majeure, a fortuitous event caused by the User or a third party, it must inform the User as soon as possible, without being held liable. Wanadev undertakes to do everything in its power to limit the duration and effects of the fortuitous event, force majeure or external cause. The obligations of the present General Conditions shall resume as soon as the force majeure ceases.": "",
    "Hypertext links.": "",
    "Wanadev ensures that the Services do not include any hypertext link allowing direct or indirect access to sites or pages of third party websites whose content would be contrary to applicable legal or regulatory provisions. In the event of notification or information on such content, Wanadev undertakes to take all steps without delay to remove it. The User acknowledges that he/she has been informed of the risks associated with consulting third party sites and may under no circumstances seek Wanadev's liability for their content.": "",
    "Tolerance.": "",
    "The fact that Wanadev does not avail itself at a given time of any of the present General Conditions and/or tolerates a breach by the other Party of any of the obligations referred to in the present General Conditions may not be interpreted as a waiver by Wanadev of its right to avail itself of any of the said Conditions at a later date.": "",
    "Modification.": "",
    "Wanadev reserves the right to modify these General Terms and Conditions and the accessible Services at any time. Users have a duty to keep themselves informed of any updates. Modification of the General Conditions will be deemed tacitly accepted if Users do not object to it.": "",
    "Applicable law & jurisdiction.": "",
    "These General Terms and Conditions are governed by French law. Any dispute relating to the interpretation, performance and/or termination of these General Terms and Conditions, which cannot be resolved amicably, will be subject to the exclusive jurisdiction of the courts within the jurisdiction of the Lyon Court of Appeal, even in the event of an incidental claim or guarantee or multiple defendants. This provision applies without prejudice to any claim that the User may lodge with the Commission Nationale de l'Informatique et des Libertés.": "",
    "Loading tokens": "",
    "Could not load tokens": "",
    "Generated token": "",
    "ok": "",
    "Could not create token": "",
    "Created at": "",
    "A permanent token allows you to attack API without re-authentication. Tokens can be used in lot of cases such deploy new game releases.": "",
    "Generate token": "",
    "Enabled": "",
    "Disabled": "",
    "Could not fetch users": "",
    "Surname": "",
    "Phone number": "",
    "Administrator": "",
    "Client": "",
    "Distributor": "",
    "Editor": "",
    "Send a password reset email to {{email}}?": "",
    "No": "",
    "Yes": "",
    "Success": "",
    "Successfully sent password reset email": "",
    "Error": "",
    "Couldn't send the email, please try again later.": ""
};