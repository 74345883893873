import { raw } from "security/token";
import Logger from "services/logger";


export default async function listGames(
  launcher: number
): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/launchers/${launcher}/games`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "launchers games"', {
      launcher,
      errors
    });
    return Promise.resolve(null);
  });
}
