import { stringify } from "qs";

import Distributor from "model/distributor";
import { raw } from "security/token";
import Logger from "services/logger";

export async function editDistributor(
  distributor: Distributor
): Promise<Response | null> {
  const {id, ...data} = distributor

  return fetch(
    `${process.env.API || ""}/api/v1/distributors/${id}`,
    {
      method: "PUT",
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
      body: stringify(data),
    }
  ).catch((errors) => {
    Logger.error('could not perform edit on "distributor"', {
      distributor: id,
      body: data,
      errors
    });
    return Promise.resolve(null);
  });
}
