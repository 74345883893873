import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownToSquare } from '@fortawesome/pro-regular-svg-icons'
import { FileUpload, ProgressBar } from "@octopod/design-system"
import { useTranslation } from 'react-i18next'

import { ModalConsumer, ModalProvider } from "stores/modalStore"
import { FormProvider, FormConsumer } from "stores/formStore"
import Modal from "components/Modal/Modal"
import upload from "services/helper/upload"
import { addOrReplaceClientAttachment } from "scenes/Company/services/helper/company"
import { getMedia } from "api/clients/media"
import isGranted, { Role } from "security/voter"

export interface CompanyPictureModalProps {
  companyId: number
  role: string
  logoId: number | null
}

export default function CompanyPictureModal(props: CompanyPictureModalProps) {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState<number | null>(null)
  const [fileDimensions, setFileDimensions] = useState({ width: 0, height: 0 })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [swap, setSwap] = useState<Record<string, any>>()
  const [logoURL, setLogoURL] = useState<string | null>(null)
  const maxSize = 10 * 1024 * 1024

  const img = new Image()
  img.onload = () => {
    setFileDimensions({
      width: img.naturalWidth,
      height: img.naturalHeight,
    })
  }

  const handleProgress = (p: number) => {
    if (file === null) {
      return
    }
    setProgress(((100 * p) / file.size))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFile = (f: File, callback: (...args: any[]) => void) => {
    if (f.size <= maxSize) {
      setFile(f)
      callback({
        type: "reset",
      })
    } else {
      callback({
        type: "setErrors",
        errors: [`File is too big, maximum size allowed is ${convertByteToMegaByte(maxSize)}MB and your file is ${Math.trunc(convertByteToMegaByte(f.size))}MB.`],
      })
    }
  }

  const convertByteToMegaByte = (value: number) => {
    return value / 1024 / 1024
  }

  const updateLogo = (id: number | null) => {
    if (id && props.logoId) {
      getMedia(props.logoId).then(async (response) => {
        if (response === null || !response.ok) {
          return; // TODO: handle error
        }
        const blob = await response.blob()
        setLogoURL(URL.createObjectURL(blob))
      })
    }
  }

  useEffect(() => {
    if (file) {
      img.src = URL.createObjectURL(file)
      upload(
        file,
        handleProgress
      ).then(res => {
        setSwap(res)
      })
    }
  }, [file])

  useEffect(() => {
    updateLogo(props.logoId);
  }, [])

  return (
    <FormProvider>
      <ModalProvider>
        <ModalConsumer>
          {([{ opened }, modalDispatch]) => (
            <FormConsumer>
              {([{ errors, submitting }, formDispatch]) => {
                const formId = "company-picture"
                return (
                  <>
                    <div
                      className={`form-header__container ${isGranted(new Set([Role.Client])) ? 'company-header' : ''}`}
                      onClick={() =>
                        modalDispatch({
                          type: opened ? "close" : "open",
                        })
                      }
                    >
                      {logoURL !== null ? <img src={logoURL} className="company-logo icon-company" /> : <i className="icon icon-company" />}
                      <FontAwesomeIcon icon={faArrowDownToSquare} size="2xl" className="icon-download" />

                    </div>
                    {opened === true && (
                      <Modal
                        header={{
                          title: t('Upload a company picture'),
                          titleClass: 'company-modal-title'
                        }}
                        footer={{
                          btnCloseClass: "btn-secondary me-2",
                          btnCloseLabel: t("Cancel"),
                          btnValidClass: "btn-primary ms-2",
                          btnValidDisabled: !file || errors.length > 0,
                          btnValidForm: formId,
                          btnValidLabel: t(
                            submitting ? "Adding" : "Add"
                          ),
                          center: true,
                        }}
                        onClose={() => {
                          setFile(null)
                          modalDispatch({
                            type: "close",
                          })
                        }}
                      >
                        {(() => {
                          if (errors.length > 0) {
                            return errors
                              .filter(
                                (error) => error?.name === undefined
                              )
                              .map((error) => (
                                <div className="alert alert-danger">
                                  {error}
                                </div>
                              ))
                          }
                        })()}

                        <form
                          id={formId}
                          onSubmit={async (e) => {
                            e.preventDefault()
                            formDispatch({
                              type: "setSubmitting",
                              submitting: true,
                            })

                            const result = await addOrReplaceClientAttachment(props.companyId, swap ?? null)
                            if (result === null || !result.ok) {
                              formDispatch({
                                type: "setErrors",
                                errors: [t("Could not update your company logo, please try again.")],
                              })
                            } else {
                              modalDispatch({
                                type: "close",
                              })
                              if (file !== null) {
                                setLogoURL(URL.createObjectURL(file))
                                setFile(null)
                              }
                            }
                          }}
                        >
                          <div className="container d-flex flex-column color-primary modal-container-company">
                            {(!file || (file && errors.length > 0)) && (
                              <>
                                <div className="mb-5 align-content-center align-items-start">
                                  <div>
                                    <span className="fw-bold">{t('File weight')}: </span>{t('max')} {t('{{filesize}} MB', { filesize: convertByteToMegaByte(maxSize) })}
                                  </div>
                                </div>
                                <div className="row justify-content-center align-items-center">
                                  <div className="text-center fs-4 mb-2 text-primary">{t('No file uploaded')}</div>
                                  <div className="company-file-upload-container ps-5 pe-5">
                                    <FileUpload handleFile={(file) => { handleFile(file, formDispatch) }} label={t('Search in your computer')} />
                                  </div>
                                </div>
                              </>
                            )}

                            {file && errors.length === 0 && (
                              <>
                                <div className="p-5 pt-0">
                                  <div className="row mt-3 p-2 fs-6 fw-bold file-list-header align-items-center text-center">
                                    <div className="col-2 text-start ps-0">{t('Preview')}</div>
                                    <div className="col-6">{t('Name')}</div>
                                    <div className="col-2">{t('Weight')}</div>
                                    <div className="col-2 text-end pe-0">{t('Size')}</div>
                                  </div>
                                  <div className="row align-items-center pt-3 text-center">
                                    <div className="col-2 text-start"><img className="img-preview" src={URL.createObjectURL(file)} /></div>
                                    <div className="col-6">{file.name}</div>
                                    <div className="col-2">{t('{{filesize}} KB', { filesize: Math.trunc(file.size / 1024) })}</div>
                                    <div className="col-2 text-end">{fileDimensions.width}x{fileDimensions.height}</div>
                                  </div>
                                </div>
                                <div className="mb-5 ms-3 me-3">
                                  <ProgressBar completedLabel={t('File uploaded')} progress={progress ?? 0} />
                                </div>
                              </>
                            )}
                          </div>

                        </form>
                      </Modal>
                    )
                    }
                  </>
                )
              }}
            </FormConsumer>
          )}
        </ModalConsumer>
      </ModalProvider>
    </FormProvider >
  )
}