import React, { useEffect, useState } from "react"
import { Tooltip } from "@octopod/design-system"
import { Trans, useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

import Invoice from "model/invoice"
import show from "api/invoices/show"
import Game from "model/game"
import { addNotification, NotificationConsumerHook } from "services/stores/notificationStore"
import { BillingFilters } from "scenes/Billing/Billing"
import Loader from "components/Loader/Loader"

import pictoInfo from "images/picto-info.svg"

export interface BillingDetailsProps {
  invoice: Invoice,
  games: Game[],
  updateInvoice: (invoice: Invoice) => void,
}

enum ConsumptionUnit {
  Minutes = 'minutes',
  Sessions = 'sessions',
}

export default function BillingDetails({ invoice, games, updateInvoice }: BillingDetailsProps) {
  const { t } = useTranslation()

  const [, setSearchParams] = useSearchParams()
  const [, dispatchNotification] = NotificationConsumerHook();

  const [loading, setLoading] = useState<boolean>(false);
  const [elements, setElements] = useState<JSX.Element[]>([]);

  const processDetails = (invoice: Invoice) => {

    if (invoice.lines === undefined) {
      return
    }

    const newElements: JSX.Element[] = invoice.lines.map((d) => {
      const game = games?.find(g => g.id === d.game)
      const consumptionForGame = {
        unit: ConsumptionUnit.Minutes,
        played: 0,
        billable: 0,
        retainedOnCredits: 0,
        total: 0,
      }

      if (d.minutesPlayed > 0) {
        consumptionForGame.played = d.minutesPlayed
        consumptionForGame.billable = d.minutesBilled
        consumptionForGame.retainedOnCredits = d.minutesBilled - d.remainingMinutesAfterBalanceUsed
        consumptionForGame.total = d.remainingMinutesAfterBalanceUsed
      } else {
        consumptionForGame.unit = ConsumptionUnit.Sessions
        consumptionForGame.played = d.sessionsPlayed
        consumptionForGame.billable = d.sessionsBilled
        consumptionForGame.retainedOnCredits = d.sessionsBilled - d.remainingSessionsAfterBalanceUsed
        consumptionForGame.total = d.remainingSessionsAfterBalanceUsed
      }

      const unit = t(consumptionForGame.unit);
      return <tr key={`${invoice.id}-${d.game}`} className="">
        <td className='text-primary text-start align-middle'>{`${game?.name ?? '-'}`}</td>
        <td className='text-primary text-center align-middle'>{`${consumptionForGame.played} ${unit}`}</td>
        <td className='text-primary text-center align-middle'>{`${consumptionForGame.billable} ${unit}`}</td>
        <td className='text-primary text-center align-middle'>{`${consumptionForGame.retainedOnCredits} ${unit}`}</td>
        <td className='text-primary text-center align-middle'><div>{`${consumptionForGame.total} ${unit}`}</div></td>
      </tr>
    })

    setElements(newElements)
  }

  useEffect(() => {
    if (elements.length === 0 && !loading && invoice.lines === undefined) {
      setLoading(true)
      show(invoice.id)
        .then(async (response: Response | null) => {
          if (response?.ok) {
            const body = await response.json()
            updateInvoice(body)
            invoice.lines = body.lines
            setLoading(false)
          } else {
            return Promise.reject(response)
          }
        })
        .catch(() => {
          // reset details search param
          setSearchParams(prev => {
            prev.delete(BillingFilters.Details)
            return prev
          })
          addNotification(dispatchNotification, t('error'), t('Could not find this invoice details'), 'error')
        })
    }
    if ((elements.length > 0 || (invoice.lines !== undefined && invoice.lines.length > 0)) && !loading) {
      processDetails(invoice)
    }
  }, [invoice, games, loading])

  if (loading || elements.length === 0) {
    return (
      <tr>
        <td></td>
        <td colSpan={3}>
          <Loader message={t("Fetching invoice details")} inline={true} />
        </td>
      </tr>
    )
  }
  return (
    <tr>
      <td></td>
      <td colSpan={3}>
        <table className="table table-light">
          <thead>
            <tr>
              <th className='text-primary text-start'>{t('Game')}</th>
              <th className='text-primary text-center'>
                <div className="billing-tooltip">
                  {t('Played')}
                  <Tooltip
                    tooltipContent={
                      <div className="billing-tooltip-content"><Trans>Total number of sessions/minutes played</Trans></div>
                    }
                    tooltipClass="billing-tooltip-border"
                  >
                    <img className="billing-tooltip-info" src={pictoInfo}></img>
                  </Tooltip>
                </div>
              </th>
              <th className='text-primary text-center'>
                <div className="billing-tooltip">
                  {t('Billable')}
                  <Tooltip
                    tooltipContent={
                      <div className="billing-tooltip-content"><Trans>Total number of sessions/minutes billable</Trans></div>
                    }
                    tooltipClass="billing-tooltip-border"
                  >
                    <img className="billing-tooltip-info" src={pictoInfo}></img>
                  </Tooltip>
                </div>
              </th>
              <th className='text-primary text-center'>
                <div className="billing-tooltip">
                  {t('Retained on credits')}
                  <Tooltip
                    tooltipContent={
                      <div className="billing-tooltip-content"><Trans>Total number of billable sessions/minutes retained on credits</Trans></div>
                    }
                    tooltipClass="billing-tooltip-border"
                  >
                    <img className="billing-tooltip-info" src={pictoInfo}></img>
                  </Tooltip>
                </div>
              </th>
              <th className='text-primary text-center'>
                <div className="billing-tooltip billing-details-total-header">
                  {t('Total billed')}
                  <Tooltip
                    tooltipContent={
                      <div className="billing-tooltip-content"><Trans>Total number of sessions/minutes actually billed</Trans></div>
                    }
                    tooltipClass="billing-tooltip-border"
                  >
                    <img className="billing-tooltip-info" src={pictoInfo}></img>
                  </Tooltip>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="billing-details-total-body">
            {elements}
          </tbody>
        </table>
      </td>
    </tr>
  )
}
