import React, { useEffect, useRef } from "react";

import { raw } from "api/raw";

type Props = {
  alt: string;
  className?: string;
  src: string | null | undefined;
};

export default function ApiImage({ alt, className, src }: Props) {
  const element = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (src && element?.current != null) {
      raw(src).then(({ blob, success }) => {
        if (success && element?.current != null) {
          element.current.src = URL.createObjectURL(blob);
        }
      });
    }
  }, [src, element]);

  return (
    <img
      className={className || ""}
      alt={alt}
      src={require("../../../static/images/default_thumbnail.svg")}
      ref={element}
    />
  );
}
