import React, { useEffect, useState } from "react";
import { getCountries, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import en from 'react-phone-number-input/locale/en.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';

import 'react-phone-number-input/style.css';

export interface PhoneCountrySelectProps {
  defaultCountry: string
  phoneNumber: string
  onChange: (v: string | undefined) => void
}

export default function PhoneCountrySelect(props: PhoneCountrySelectProps) {
  const { defaultCountry, phoneNumber, onChange } = props
  const [countryCode, setCountryCode] = useState<string | undefined>(defaultCountry || undefined)

  const processCountryCode = (phoneNumber: string) => {
    const parsedPhone = parsePhoneNumber(phoneNumber)
    if (parsedPhone) {
      setCountryCode(parsedPhone.country)
    } else if ((countryCode !== undefined)) {
      setCountryCode(undefined)
    }
  }

  useEffect(() => {
    if (phoneNumber && phoneNumber.length > 0) {
      processCountryCode(phoneNumber)
    }
  }, [phoneNumber])

  return (
    <div className="PhoneInputCountry">
      <select
        className="PhoneInputCountrySelect"
        onChange={(e) => {
          setCountryCode(e.currentTarget.value)
          onChange(e.currentTarget.value)
        }}
        defaultValue={defaultCountry}
      >
        {getCountries().map(country =>
          <option key={country} value={country}>
            <>{en[country]} (+{getCountryCallingCode(country)})</>
          </option>
        )}
      </select>
      {countryCode ?
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <div className="phone-country-select-flag">{(flags as Record<string, any>)[countryCode]({ title: `${countryCode}-flag` })}</div>
        : <FontAwesomeIcon icon={faGlobe} size='xl' />
      }
      <div className="PhoneInputCountrySelectArrow"></div>
    </div>
  )
}