import React, { useMemo, useState, FormEventHandler } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import { getPath } from "routes";
import { resetPassword } from "api/user/resetPassword";
import { changePasswordWithToken } from "api/user/password";

export default function ResetPassword() {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const params: { id: string | null, token: string | null } | null = useMemo(() => {
    const id = searchParams.get('id')
    const token = searchParams.get('token')
    if (id === null || token === null) {
      return null
    }
    return { id, token }
  }, [searchParams])

  const onSubmitReset: FormEventHandler<HTMLFormElement> = async (e) => {
    setSubmitting(true);
    e.preventDefault();
    const response = await resetPassword(email);

    if (response?.ok) {
      navigate(getPath("index") ?? '');
    } else {
      setErrorMessage(t("Something went wrong"));
    }

    setSubmitting(false);
  };

  const onSubmitPassword: FormEventHandler<HTMLFormElement> = async (e) => {
    setSubmitting(true);
    e.preventDefault();

    if (password !== passwordConfirmation) {
      setErrorMessage(t("Passwords do not match"));
      setSubmitting(false);
      return
    }

    if (params === null || params.id === null || params.token === null) {
      setErrorMessage(t("Invalid parameters"));
      setSubmitting(false);
      return
    }

    const response = await changePasswordWithToken(parseInt(params.id, 10), params.token, password);

    if (response?.ok === true) {
      navigate(getPath("index") ?? '');
    } else {
      if (response?.status === 401) {
        setErrorMessage(t("Invalid credentials"));
      } else {
        setErrorMessage(t("Something went wrong"));
      }
    }

    setSubmitting(false);
  };


  return (
    <div className="row w-100 justify-content-center">
      <div className="col-8 reset-password-form">
        <div className="row justify-content-center">
          {params === null && (
            <form className="col-6 p-0" onSubmit={onSubmitReset}>
              {submitting === false && errorMessage.length > 0 && (
                <div>{errorMessage}</div>
              )}

              <div className="form-group">
                <div className="input-container">
                  <input
                    className="form-control form-control-lg simple-placeholder"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("Email")}
                    required
                    type="email"
                    value={email}
                    title={t("Please enter an email address")}
                  />
                  <i className="icon icon-edit"></i>
                  <div className="liner"></div>
                </div>
              </div>

              <div className="row">
                <div className="col reset-password-form__sign-in">
                  <Link className="link--blue" to={getPath("login") ?? ''}>
                    <Trans>Sign in</Trans>
                  </Link>
                </div>

                <div className="col-auto">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={submitting}
                  >
                    {submitting === true && "Loading"}
                    {submitting === false && <Trans>Send me an email</Trans>}
                  </button>
                </div>
              </div>
            </form>
          )}

          {params !== null && (
            <form className="col-6 p-0" onSubmit={onSubmitPassword}>
              {submitting === false && errorMessage.length > 0 && (
                <div>{errorMessage}</div>
              )}

              <div className="form-group">
                <div className="input-container">
                  <input
                    className="form-control form-control-lg simple-placeholder"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t("Password")}
                    required
                    type="password"
                    value={password}
                    title={t("Please enter your desired password")}
                  />
                  <i className="icon icon-edit"></i>
                  <div className="liner"></div>
                </div>

                <div className="input-container">
                  <input
                    className="form-control form-control-lg simple-placeholder"
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    placeholder={t("Password confirmation")}
                    required
                    type="password"
                    value={passwordConfirmation}
                    title={t("Please confirm your desired password")}
                  />
                  <i className="icon icon-edit"></i>
                  <div className="liner"></div>
                </div>
              </div>

              <div className="row">
                <div className="col reset-password-form__sign-in">
                  <Link className="link--blue" to={getPath("login") ?? ''}>
                    <Trans>Sign in</Trans>
                  </Link>
                </div>

                <div className="col-auto">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={submitting}
                  >
                    {submitting === true && "Loading"}
                    {submitting === false && params === null && <Trans>Send me an email</Trans>}
                    {submitting === false && params !== null && <Trans>Save</Trans>}
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
