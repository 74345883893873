import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button } from "@octopod/design-system"
import { faFileArrowDown } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { list } from "api/balance/list"
import { Balance } from "model/balance"
import ResponseType from "model/response_type"
import { BalanceFilters } from "scenes/Balance/Balance"
import fileDownload from "services/helper/file_download"
import Logger from "services/logger"

export interface BalanceExportProps {
  balances: Balance[]
}

export default function BalanceExport({ balances }: BalanceExportProps) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const [exporting, setExporting] = useState<boolean>(false)

  const handleExport = async () => {
    setExporting(true);
    const from = searchParams.get(BalanceFilters.PeriodFrom)
    const to = searchParams.get(BalanceFilters.PeriodTo)
    const location = searchParams.get(BalanceFilters.Location)

    if (!from || !to || !location) {
      setExporting(false)
      return
    }

    try {
      const response = await list(parseInt(location, 10), new Date(from), new Date(to), balances?.length, 0, ResponseType.XLS)
      if (response?.ok) {
        const file = await response.blob()
        if (file) {
          fileDownload(file, 'balance-octopod.xls')
        }
      } else {
        Logger.error('could not download balances', {
          location,
          from,
          to,
          errors: response
        });
        return;
      }
    } finally {
      setExporting(false)
    }
  }

  return (
    <Button
      label={t('Export')}
      prefixComponent={<span className="pe-2">{exporting ? <i className='fa fa-spin fa-spinner' /> : <FontAwesomeIcon icon={faFileArrowDown} />}</span>}
      onClick={handleExport}
      disabled={exporting}
    />
  )
}