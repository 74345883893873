import React, { createContext, useContext, useReducer } from "react";
import type { Context, PropsWithChildren } from "react";

import Logger from "../logger";

type State = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: Array<any>;
  submitting: boolean;
};

const initialState: State = {
  data: {},
  errors: [],
  submitting: false,
};

type Action = {
  type: "setValue" | "setErrors" | "setSubmitting" | "reset";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field?: any;
  submitting?: boolean;
}

const reducer = (state: State, action: Action) => {
  const newState = { ...state };

  switch (action.type) {
    case "setValue":
      newState.data = { ...state.data, [action.field]: action.value };

      if (action.initialValue === action.value) {
        delete newState.data[action.field];
      }

      return newState;

    case "setErrors":
      newState.errors = action.errors;
      newState.submitting = false;
      return newState;

    case "setSubmitting":
      newState.submitting = action.submitting ?? false;
      return newState;

    case "reset":
      return { ...initialState };

    default:
      return state;
  }
};

const FormContext: Context<[State, (action: Action) => void]> =
  createContext([
    initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (action: Action) => Logger.error("Context not correctly initialized"),
  ]);

export const FormConsumer = FormContext.Consumer;
export const FormConsumerHook = () => useContext(FormContext);
export const FormProvider = ({ children }: PropsWithChildren<unknown>) => (
  <FormContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </FormContext.Provider>
);
