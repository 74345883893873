import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal/Modal'
import { ModalConsumer, ModalProvider } from 'services/stores/modalStore'
import { Trans } from 'react-i18next'

const BalanceModal = () => {
  const { t } = useTranslation();

  return (
    <ModalProvider>
      <ModalConsumer>
        {([{ opened }, dispatch]) => (
          <>
            <a className="balance-modal-link" onClick={() => dispatch({ type: opened ? "close" : "open" })}>
              <Trans>More information</Trans>
            </a>

            {(() => {
              if (opened === true) {
                return (
                  <Modal
                    header={{
                      title: t("Credits - More information"),
                    }}
                    footer={{
                      btnCloseClass: "btn btn-primary",
                      btnCloseLabel: t("Ok"),
                    }}
                    onClose={() =>
                      dispatch({
                        type: "close",
                      })
                    }
                  >
                    <div className='balance-modal-body'>
                      <p><Trans>Please make contact with Octopod or your usual retailer to recharge your game credits.</Trans></p>
                    </div>
                  </Modal>
                );
              }
            })()}
          </>
        )}
      </ModalConsumer>
    </ModalProvider>
  )
}

export default BalanceModal
