import { raw } from "security/token";

export async function list(
  limit = 50,
  offset = 0
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  const params = new URLSearchParams();

  if (limit && Number.isInteger(limit) === true) {
    params.append("limit", `${limit}`);
  }

  if (offset && Number.isInteger(offset) === true) {
    params.append("offset", `${offset}`);
  }

  const response = await fetch(
    `${process.env.API || ""}/api/v1/locations?${params.toString()}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    errors: body.errors,
  };
}
