import { raw } from "security/token";
import Logger from "services/logger";

export default async function show(
  release: number
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  const response = await fetch(
    `${process.env.API || ""}/api/v1/releases/${release}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    errors: body.errors,
  };
}

export function showCouch(
  launcher: string,
  release: string
): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/launchers/${launcher}/_/releases/${release}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "api/v1/launchers/:id/_/releases/:id"', {
      launcher,
      release,
      errors
    });
    return Promise.resolve(null);
  })
}
