import moment from "moment";

import ResponseType from "model/response_type";
import { raw } from "security/token";
import Logger from "services/logger";

/**
 * Returns a response containing the consumption data filtered with the function's params as a Blob or a JSON object.
 * On error, returns null and logs the error and context
 * 
 * @param from starting date
 * @param to ending date
 * @param games game ids (optional)
 * @param locations location ids (optional)
 * @param limit number of items to fetch (optional)
 * @param offset number of items to offset (optional)
 * @param type the response type, either XLS (for a Blob) or JSON. (optional, default = JSON) 
 * @returns The response promise or null
 */
export async function getConsumptionV2(
  from: string,
  to: string,
  games?: number[],
  locations?: number[],
  limit?: number,
  offset?: number,
  type: ResponseType = ResponseType.JSON,
): Promise<Response | null> {

  const params = new URLSearchParams()
  params.append("period[from]", `${from}`)
  params.append("period[to]", `${to}`)

  if (games != null) {
    games.forEach(g => params.append('games[]', g.toString(10)))
  }

  if (locations != null) {
    (locations || []).forEach(l => params.append('locations[]', l.toString(10)))
  }

  if (limit != null) {
    params.append('limit', limit.toString(10))
  }

  if (offset != null) {
    params.append('offset', offset.toString(10))
  }

  const headers: HeadersInit = {
    "x-auth-token": raw() || "",
  }

  if (type === ResponseType.XLS) {
    headers.accept = "application/vnd.ms-excel"
  }

  const response = await fetch(
    `${process.env.API || ""}/api/v1/consumption?${params.toString()}`,
    {
      method: "GET",
      headers
    }
  )

  if (!response.ok) {
    Logger.error('could not perform get on "consumption"', {
      from,
      to,
      games,
      locations,
      limit,
      offset,
      type,
    });
    return null
  }
  return response
}

export async function getGameSessionDetails(
  game: number,
  location: number,
  from: Date,
  to: Date,
  limit: number | null | undefined,
  offset: number | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  const params = new URLSearchParams();
  params.append("location", location.toString(10))
  params.append("period[from]", moment(from).format("YYYY-MM-DD"));
  params.append("period[to]", moment(to).format("YYYY-MM-DD"));

  if (limit && Number.isInteger(limit) === true) {
    params.append("limit", limit.toString(10));
  }

  if (offset && Number.isInteger(offset) === true) {
    params.append("offset", offset.toString(10));
  }

  const response = await fetch(
    `${process.env.API || ""
    }/api/v1/games/${game}/consumption/sessions?${params.toString()}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    errors: body.errors,
  };
}
