import { stringify } from "qs";

import { raw } from "security/token";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function create(): Promise<Record<string, any>> {
  const response = await fetch(`${process.env.API || ""}/api/v1/tokens`, {
    method: "POST",
    headers: {
      "x-auth-token": raw() || "",
    },
  });
  const content = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: content,
    };
  }

  return {
    success: false,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function list(): Promise<Record<string, any>> {
  const response = await fetch(`${process.env.API || ""}/api/v1/tokens`, {
    method: "GET",
    headers: {
      "x-auth-token": raw() || "",
    },
  });
  const content = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: content,
    };
  }

  return {
    success: false,
  };
}

export async function update(
  id: number,
  active: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  const response = await fetch(`${process.env.API || ""}/api/v1/tokens/${id}`, {
    method: "PUT",
    headers: {
      "x-auth-token": raw() || "",
      "content-type": "application/x-www-form-urlencoded",
    },
    body: stringify({
      enable: active ? "true" : "false",
    }),
  });
  const content = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: content,
    };
  }

  return {
    success: false,
  };
}
