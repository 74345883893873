import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';

import { GameReleasesConsumerHook } from "stores/gameReleasesStore";
import { getPath } from "routes";

export default function GameShowDevs() {
  const { t } = useTranslation();
  const [{ game }] = GameReleasesConsumerHook();

  return (
    <div className="game-container game-infos">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="row">
              <div className="small-body">
                <Trans
                  i18nKey="To deploy new releases, you can read <0>developer documentation</0>."
                  components={[
                    <a
                      href="https://developer.octopodvr.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    />,
                  ]}
                />

                <ul className="mt-3">
                  <li>
                    <Trans
                      i18nKey='You can create a token in the menu "<0>Tokens</0>"'
                      components={[<Link to={getPath("tokens") ?? ''} />]}
                    />
                  </li>

                  <li>
                    <div>
                      <Trans>Your game identifier</Trans>
                    </div>

                    <p className="main-field semiBold">{game?.key || t('Unknown')}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
