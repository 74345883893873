import React from "react";
import { useTranslation } from 'react-i18next';

export default function Article8() {
  const { t } = useTranslation();

  return (
    <div className="text-primary">
      <ul className="list-unstyled d-grid gap-3 justify-content">
        <li>
          <span className="fw-bold color-purple">{t(`Invalidity of a clause.`)} </span>
          {t(`In the event that any provision of these General Terms and Conditions is null and void or annulled, the Parties shall endeavour to replace it with a provision of the closest legal and economic effect and the other provisions shall continue to have effect in accordance with the intentions of the Parties as set out in the General Terms and Conditions.`)}
        </li>
        <li>
          <span className="fw-bold color-purple">{t(`Electronic evidence.`)} </span>
          {t(`The parties acknowledge and agree that SMS messages, MMS messages, electronic mail (emails), instant messaging exchanges and all other electronic writings resulting from the use of the Services and exchanged between Wanadev and the User within the framework of the Services constitute:`)}
        </li>
        <div className="d-flex flex-column align-items-center">
          <ol type="i">
            <li>
              {t(`original documents between the parties;`)}
            </li>
            <li>
              {t(`proof and`)}
            </li>
            <li>
              {t(`admissible before the courts, provided that the person from whom they emanate can be duly identified and that they are established and stored in conditions that guarantee their integrity.`)}
            </li>
          </ol>
        </div>
        <li>
          <span className="fw-bold color-purple">{t(`Force majeure & act of a third party.`)} </span>
          {t(`In the event that Wanadev finds itself partially or totally unable to carry out one of its obligations under these General Terms and Conditions, as a result of a case of force majeure, a fortuitous event caused by the User or a third party, it must inform the User as soon as possible, without being held liable. Wanadev undertakes to do everything in its power to limit the duration and effects of the fortuitous event, force majeure or external cause. The obligations of the present General Conditions shall resume as soon as the force majeure ceases.`)}
        </li>
        <li>
          <span className="fw-bold color-purple">{t(`Hypertext links.`)} </span>
          {t(`Wanadev ensures that the Services do not include any hypertext link allowing direct or indirect access to sites or pages of third party websites whose content would be contrary to applicable legal or regulatory provisions. In the event of notification or information on such content, Wanadev undertakes to take all steps without delay to remove it. The User acknowledges that he/she has been informed of the risks associated with consulting third party sites and may under no circumstances seek Wanadev's liability for their content.`)}
        </li>
        <li>
          <span className="fw-bold color-purple">{t(`Tolerance.`)} </span>
          {t(`The fact that Wanadev does not avail itself at a given time of any of the present General Conditions and/or tolerates a breach by the other Party of any of the obligations referred to in the present General Conditions may not be interpreted as a waiver by Wanadev of its right to avail itself of any of the said Conditions at a later date.`)}
        </li>
        <li>
          <span className="fw-bold color-purple">{t(`Modification.`)} </span>
          {t(`Wanadev reserves the right to modify these General Terms and Conditions and the accessible Services at any time. Users have a duty to keep themselves informed of any updates. Modification of the General Conditions will be deemed tacitly accepted if Users do not object to it.`)}
        </li>
        <li>
          <span className="fw-bold color-purple">{t(`Applicable law & jurisdiction.`)} </span>
          {t(`These General Terms and Conditions are governed by French law. Any dispute relating to the interpretation, performance and/or termination of these General Terms and Conditions, which cannot be resolved amicably, will be subject to the exclusive jurisdiction of the courts within the jurisdiction of the Lyon Court of Appeal, even in the event of an incidental claim or guarantee or multiple defendants. This provision applies without prejudice to any claim that the User may lodge with the Commission Nationale de l'Informatique et des Libertés.`)}
        </li>
      </ul>
    </div>
  )
}