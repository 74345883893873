import React from "react";
import { Navigate } from "react-router-dom";

import Authenticated from "components/Authenticated/Authenticated";
import CompanyAA from "scenes/Company/Company";
import Games from "scenes/Games/Games";
import GameShow from "scenes/GameShow/GameShow";
import Users from "scenes/Users/Users";
import GameShowDevs from "scenes/GameShowDevs/GameShowDevs";
import GameShowInfos from "scenes/GameShowInfos/GameShowInfos";
import GameShowMedias from "scenes/GameShowMedias/GameShowMedias";
import GameShowReleases from "scenes/GameShowReleases/GameShowReleases";
import Index from "scenes/Index/Index";
import Launchers from "scenes/Launchers/Launchers";
import Consumption from "scenes/Consumption/Consumption";
import Login from "scenes/Login/Login";
import Logout from "scenes/Logout/Logout";
import Profile from "scenes/Profile/Profile";
import ResetPassword from 'scenes/ResetPassword/ResetPassword';
import Support from "scenes/Support/Support";
import Tokens from "scenes/Tokens/Tokens";
import Unauthenticated from "components/Unauthenticated/Unauthenticated";
import NotFound from "scenes/NotFound/NotFound";
import { keepFiltersParametersFromLocation } from "components/Consumption/ConsumptionSummary/filters";
import GameSessionWrapper from "scenes/GameSessionWrapper/GameSessionWrapper";
import { Role } from "security/voter";
import TermsOfUse from "scenes/TermsOfUse/TermsOfUse";
import Billing from "scenes/Billing/Billing";
import MyBalance from 'scenes/Balance/Balance';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = Record<string, any>
type GetProps = () => Props

export type RouteDeclaration = {
  name?: string;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ((...args: Array<any>) => any) | React.Component<any>;
  props?: GetProps;
  roles?: Array<string>;
  routes?: Array<RouteDeclaration>;
  options?: { keepFiltersParams?: boolean };
};

export type RouteBuilt = {
  name?: string;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ((...args: Array<any>) => any) | React.Component<any>;
  props: GetProps;
  roles: Set<string>;
  routes?: Array<RouteBuilt>;
}

let routes: RouteBuilt[] | null = null;
const catalog: RouteDeclaration[] = [
  {
    path: "/",
    component: Unauthenticated,
    routes: [
      {
        path: "",
        component: Navigate,
        props: () => ({
          to: getPath("index"),
        }),
      },
      {
        name: "login",
        path: "login",
        component: Login,
      },
      {
        name: "logout",
        path: "logout",
        component: Logout,
      },
      {
        name: 'reset-password',
        path: 'reset-password',
        component: ResetPassword
      },
      {
        name: 'terms-of-use-unauthenticated',
        path: 'terms-of-use',
        component: TermsOfUse,
      }
    ],
  },
  {
    component: Authenticated,
    path: "/-",
    roles: [Role.User],
    routes: [
      {
        name: "index",
        path: "/dashboard",
        component: Index,
        options: { keepFiltersParams: true }
      },
      {
        name: "games",
        path: "/games",
        component: Games,
      },
      {
        name: "profile",
        path: "/profile",
        component: Profile,
      },
      {
        name: "company",
        path: "/company",
        component: CompanyAA,
      },
      {
        name: "launchers",
        path: "/launchers",
        component: Launchers,
      },
      {
        name: "consumption",
        path: "/consumption",
        component: Consumption,
        options: { keepFiltersParams: true },
      },
      {
        name: "billing",
        path: "/billing",
        component: Billing,
      },
      {
        name: "balance",
        path: "/balance",
        component: MyBalance,
      },
      {
        name: "tokens",
        path: "/tokens",
        component: Tokens,
      },
      {
        path: "/games/:id",
        component: GameShow,
        routes: [
          {
            name: "game-show",
            path: "/releases",
            component: GameShowReleases,
          },
          {
            name: "game-show-infos",
            path: "",
            component: GameShowInfos,
          },
          {
            name: "game-show-medias",
            path: "/medias",
            component: GameShowMedias,
          },
          {
            name: "game-show-devs",
            path: "/devs",
            component: GameShowDevs,
          },
        ],
      },
      {
        name: "game-sessions",
        path: "/game-sessions",
        component: GameSessionWrapper,
      },
      {
        name: "users",
        path: "/users",
        component: Users,
      },
      {
        name: "game-sessions",
        path: "/game-sessions",
        component: GameSessionWrapper,
      },
      {
        name: "support",
        path: "/support",
        component: Support,
      },
      {
        name: 'terms-of-use',
        path: '/terms-of-use',
        component: TermsOfUse,
      },
      {
        path: '*',
        component: NotFound,
      },
    ],
  }
];

function compile(root: RouteDeclaration): RouteBuilt {
  const _root: RouteBuilt = {
    name: root.name,
    path: root.path,
    component: root.component,
    props: root.props || (() => ({})),
    roles: new Set(root.roles || []),
  }

  root.roles = root.roles || [];

  if (root.routes === undefined) {
    return _root;
  }

  _root.routes = []

  for (const route of root.routes || []) {
    const _route = compile({
      ...route,
      path: `${root.path}${route.path}`,
    });

    root.roles.forEach(role => _route.roles.add(role))

    _root.routes.push(_route);
  }

  return _root
}

export function getRoutes(): RouteBuilt[] {
  if (routes === null) {
    routes = catalog.map(compile)
  }

  return routes;
}

function getRouteByName(name: string, roots: RouteBuilt[]): RouteBuilt | null {
  for (const root of roots) {
    const route = _getRouteByName(name, root)

    if (route !== null) {
      return route
    }
  }

  return null
}

function _getRouteByName(name: string, root: RouteBuilt | null): RouteBuilt | null {
  if (root === null) {
    return null
  }

  if (root.name === name) {
    return root
  }

  for (const index in root.routes || []) {
    const route = _getRouteByName(name, root.routes ? root.routes[index] : null)

    if (route !== null) {
      return route
    }
  }

  return null
}

export interface PathOptions {
  keepFiltersParameters: boolean
}

export function getPath(
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: Record<string, any> | null = null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams: Record<string, any> | null = null,
  options: PathOptions | null = null
) {
  const route = getRouteByName(name, getRoutes());
  if (route === null) {
    return null
  }

  let { path } = route

  if (params) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.entries(params).forEach(([key, value]: [string, any]) => {
      path = path ? path.replace(`:${key}`, value) : "";
    });
  }

  const _params = new URLSearchParams(queryParams || {})

  if (options?.keepFiltersParameters) {
    for (const [key, val] of keepFiltersParametersFromLocation()) {
      _params.append(key, val)
    }
  }

  const _query = _params.toString()
  if (_query.length > 0) {
    path += `?${_query}`
  }

  return path;
}
