import React from "react";
import { Trans } from 'react-i18next';
type Props = {
  production: boolean;
};
export default function ProductionLabel({ production }: Props) {
  return (
    <span
      className={`badge rounded-pill ${
        production ? "bg-success" : "bg-secondary"
      }`}
    >
      <i className={`fal ${production ? "fa-check" : "fa-times"} pe-2`} />
      {(() => {
        if (production) {
          return <Trans>In production</Trans>;
        }

        return <Trans>Not in production</Trans>;
      })()}
    </span>
  );
}
