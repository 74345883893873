import { raw } from "security/token";
import Logger from "services/logger";

export default async function getDistributor(
  distributor: number
): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/distributors/${distributor}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "distributor"', {
      distributor,
      errors
    });
    return Promise.resolve(null);
  })
}
