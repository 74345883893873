import Client from "model/client";
import { stringify } from "qs";

import { raw } from "security/token";
import Logger from "services/logger";

export async function editClient(
  client: Client
): Promise<Response | null> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: Record<string, any> = {
    name: client.name,
    email: client.email
  }

  return fetch(
    `${process.env.API || ""}/api/v1/clients/${client.id}`,
    {
      method: "PUT",
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
      body: stringify(data),
    }
  ).catch((errors) => {
    Logger.error('could not perform edit on "client"', {
      client: client.id,
      body: data,
      errors
    });
    return Promise.resolve(null);
  });
}
