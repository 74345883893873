import getToken, { raw } from "security/token";
import Logger from "services/logger";

export default async function getProfile(): Promise<Response | null> {
  const tokenContext = getToken()?.context
  if (tokenContext == null) {
    Logger.error('no context found in token', {
      token: getToken()
    })
    return null
  }
  return fetch(
    `${process.env.API || ""}/api/v1/users/${tokenContext.id}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "api/v1/users/:id"', {
      user: tokenContext.id,
      errors
    });
    return Promise.resolve(null);
  });
}
