import { raw } from "security/token";
import handleUnauthorized from "services/helper/unauthorized_handler";
import Logger from "services/logger";

export default async function show(
  invoice: number
): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/billing/invoices/${invoice}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  )
    .then(handleUnauthorized)
    .catch((errors) => {
      Logger.error('could not perform get on "api/v1/billing/invoices/:id"', {
        invoice,
        errors
      })
      return Promise.resolve(null)
    })
}