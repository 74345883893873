import { stringify } from "qs";

import Editor from "model/editor";
import { raw } from "security/token";
import Logger from "services/logger";

export async function editEditor(
  editor: Editor
): Promise<Response | null> {
  const {id, ...data} = editor;

  return fetch(
    `${process.env.API || ""}/api/v1/editors/${id}`,
    {
      method: "PUT",
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
      body: stringify(data),
    }
  ).catch((errors) => {
    Logger.error('could not perform edit on "editor"', {
      editor: id,
      body: data,
      errors
    });
    return Promise.resolve(null);
  });
}
