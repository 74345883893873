import { stringify } from "qs"

import { raw } from "security/token"
import Logger from "services/logger";

export async function listAttachments(client: number): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/clients/${client}/attachments`,
    {
      method: 'GET',
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get attachments on "client"', {
      client,
      errors
    });
    return Promise.resolve(null);
  })
}

export async function deleteAttachment(
  client: number,
  attachment: number
): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/clients/${client}/attachments/${attachment}`,
    {
      method: 'DELETE',
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform delete attachment on "client"', {
      client,
      attachment,
      errors
    });
    return Promise.resolve(null);
  })
}

export async function addAttachment(
  client: number,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
  swap: Record<string, any>
): Promise<Response | null> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: Record<string, any> = {
    type: 'logo',
    swap
  }

  return fetch(
    `${process.env.API || ""}/api/v1/clients/${client}/attachments`,
    {
      method: 'POST',
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
      body: stringify(data)
    }
  ).catch((errors) => {
    Logger.error('could not perform add attachment on "client"', {
      client,
      body: data,
      errors
    });
    return Promise.resolve(null);
  })
}
