import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';

import ApiImage from "components/ApiImage/ApiImage";
import { GamesConsumerHook, fetchGames } from "stores/gamesStore";
import { getPath } from "routes";
import ListCard from "components/ListCard/ListCard";
import AddGameModal from "./components/AddGameModal/AddGameModal";
import { LaunchersConsumerHook, fetchLaunchers } from "services/stores/launchersStore";
import { GameSpaces } from "model/game";
import listGames from "api/launchers/games";
import LauncherGame from "model/launcher_game";
import isGranted from "security/voter";
import SelectLaunchers from "components/SelectLauncher/SelectLauncher";
import SelectSpace from "./components/SelectSpace/SelectSpace";

import pictoGameGuides from "images/picto-game-guides.svg";
import pictoMarketing from "images/picto-marketing.svg";


enum GameFilters {
  Space = 'filters[space]',
  Launcher = 'filters[launcher]',
}

export default function Games() {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [{ games, loading: loadingGames, error: errorGames }, dispatchGames] = GamesConsumerHook()
  const [{ launchers, loading: loadingLaunchers, error: errorLaunchers }, dispatchLaunchers] = LaunchersConsumerHook()

  const [gamesFiltered, setGamesFiltered] = useState<number[] | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if ((loadingGames === false && games === null) || errorGames !== null) {
      fetchGames(dispatchGames, history)
    }
  }, [dispatchGames, errorGames, games, history, loadingGames])

  useEffect(() => {
    if ((loadingLaunchers === false && launchers === null) || errorLaunchers !== null) {
      fetchLaunchers(dispatchLaunchers, history);
    }
  }, [dispatchLaunchers, errorLaunchers, launchers, history, loadingLaunchers])

  useEffect(() => {
    const launcher = searchParams.get(GameFilters.Launcher)
    if (launcher !== null) {
      setLoading(true)
      listGames(parseInt(launcher, 10))
        .then((response) => {
          return response?.json()
        })
        .then((launcherGames: LauncherGame[] | null) => {
          if (launcherGames !== null) {
            setGamesFiltered(launcherGames.map(({ game }) => game.id))
          } else {
            setGamesFiltered([])
          }
          setLoading(false)
        })
    } else {
      setGamesFiltered(undefined)
    }
  }, [searchParams.get(GameFilters.Launcher)])

  const handleChange = (value: string | null, filter: GameFilters) => {
    setSearchParams(prev => {
      if (value === null) {
        prev.delete(filter)
        return prev
      }
      prev.set(filter, value)
      return prev
    })
  }

  return (
    <div className="games">
      {isGranted(new Set(['ROLE_EDITOR'])) && (
        <div className="row mb-5">
          <div className="col-12 px-0">
            <AddGameModal />
          </div>
        </div>
      )}

      {!isGranted(new Set(['ROLE_EDITOR'])) && (
        <div>
          <div className="row mb-5">
            <div className="col card me-3">
              <SelectLaunchers
                launchers={launchers}
                onSelect={(value) => handleChange(value, GameFilters.Launcher)}
                value={searchParams.get(GameFilters.Launcher)}
              />
            </div>
            <div className="col card ms-3">
              <SelectSpace
                onSelect={(value) => handleChange(value, GameFilters.Space)}
                value={searchParams.get(GameFilters.Space)}
              />
            </div>
          </div>
        </div>
      )}

      {(() => {
        if (loadingGames || loading) {

          return (<div className={`loading ${loadingGames ? "" : "d-none"}`}>
            <p>
              <Trans>Loading</Trans>
            </p>

            <i className="icon-xl icon-loader" />
          </div>)
        } else if (errorGames !== null) {
          return errorGames
        }
        return (games || [])
          .filter(({ id }) => gamesFiltered === undefined || gamesFiltered.includes(id))
          .filter(({ spaces }) => {
            const space = searchParams.get(GameFilters.Space)?.toUpperCase()
            if (space == null) {
              return true
            }
            if (space in GameSpaces) {
              return spaces.includes(GameSpaces[space as keyof typeof GameSpaces])
            }
            return false
          })
          .map((game) => (
            <div key={game.id} className="mb-5">
              <div className="row">
                <div className="col">
                  <ListCard
                    className="games-card-container"
                    content={
                      <Link
                        to={getPath("game-show-infos", {
                          id: game.id,
                        }) ?? ''}
                        className="games-card-link"
                      >
                        <div className="row games-card-link-container">
                          <div className="col-auto p-0 games-card-link-cover">
                            <ApiImage
                              src={game.thumbnail}
                              alt={game.name}
                              className="w-100"
                            />
                          </div>
                          <div className="col d-flex flex-column justify-content-between">
                            <h4>{game.name}</h4>
                            <div className="row align-items-center">
                              {game.minimumAge && <div className="col-auto d-flex">
                                <div>
                                  <div className={`align-self-end pegi-${game.minimumAge}`} />
                                  <div className="pegi-text">PEGI</div>
                                </div>
                              </div>}
                              <div className="col d-flex flex-column ps-5 pe-5">
                                <p className="card-label text-center">
                                  <Trans>Languages</Trans>
                                </p>
                                <div className="text-center d-flex flex-column">
                                  <div className="d-flex flex-row games-card-link-list" key={`languages-${game.id}`}>
                                    {game.languages.map((lang) => (
                                      <div className={`games-card-link-list-content col`} key={`${lang}-${game.id}`}>
                                        {lang}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-1 d-flex flex-column">
                            <p className="mb-3 card-label text-center">
                              <Trans>Players</Trans>
                            </p>
                            <p className="text-center">
                              {game.maximumPlayersAllowed ?? '-'}
                            </p>
                          </div>
                          <div className="col-2 d-flex flex-column">
                            <p className="mb-3 card-label">
                              <Trans>Spaces</Trans>
                            </p>
                            <div className="d-flex flex-row row-gap-3 games-card-link-list">
                              {game.spaces.map((space, index) => (
                                <p className="pe-2" key={`${game.id}-${space}`}>
                                  {`${space}${index < game.spaces.length - 1 ? ', ' : ''}`}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="col-2 d-flex flex-column">
                            <p className="mb-3 card-label">
                              <Trans>Categories</Trans>
                            </p>
                            <div className="d-flex flex-column justify-content-between">
                              {game.categories.map((category, index) => (
                                <p key={`${game.id}-${category}`} className={index < game.categories.length - 1 ? 'mb-3' : ''}>
                                  {category}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="col-1 games-card-link__btn">
                            <div>
                              <i className="icon icon-main-arrow" />
                            </div>
                          </div>
                        </div>
                      </Link>
                    } />
                </div>
                <div className="games-button-container col-auto d-flex align-items-center justify-content-center flex-column">
                  <Link
                    className="games-button d-flex align-items-center justify-content-center flex-column"
                    to={getPath("game-show-medias", { id: game.id }) ?? ''}
                  >
                    <img className="icon icon-main-arrow" alt={t("Go to game details")} src={pictoGameGuides} />
                    <span className="pt-1"><Trans>Game Guides</Trans></span>
                  </Link>
                  <Link
                    className="games-button d-flex align-items-center justify-content-center flex-column"
                    to={getPath("game-show-medias", { id: game.id }) ?? ''}
                  >
                    <img className="icon icon-main-arrow" alt={t("Go to game details")} src={pictoMarketing} />
                    <span className="pt-1"><Trans>Marketing Assets</Trans></span>
                  </Link>
                </div>
              </div>
            </div>
          ))
      })()}
    </div>
  )
}
