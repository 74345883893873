import React from "react";
import { useTranslation } from 'react-i18next';
import _ from "lodash";

import { ConsumptionConsumerHook } from "stores/consumptionStore";
import Loader from "components/Loader/Loader";
import isGranted, { Role } from "security/voter";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import LocationRow from "components/Consumption/ConsumptionDetails/components/LocationRow/LocationRow";
import { ConsumptionDetails as Consumption } from "../ConsumptionWrapper/compute";

export default function ConsumptionDetails() {
  const { t } = useTranslation();

  const [consumption] = ConsumptionConsumerHook();

  const getConsumptionDetailsByGamesForLocation = (location: number) => {
    if (consumption.consumption === null) {
      return []
    }
    const consumptionDetailsForOneLocation = consumption.consumption.consumptionDetails.filter(cd => cd.location === location)
    const result: Omit<Consumption, 'date'>[] = []
    consumptionDetailsForOneLocation.forEach((consumptionDetailForOneLocation) => {
      const consumptionPerGameAndLocation = result.find((cd) => cd.game === consumptionDetailForOneLocation.game)
      if (consumptionPerGameAndLocation !== undefined) {
        consumptionPerGameAndLocation.consumption.billable.seconds += consumptionDetailForOneLocation.consumption.billable.seconds
        consumptionPerGameAndLocation.consumption.billable.sessions += consumptionDetailForOneLocation.consumption.billable.sessions
        consumptionPerGameAndLocation.consumption.played.seconds += consumptionDetailForOneLocation.consumption.played.seconds
        consumptionPerGameAndLocation.consumption.played.sessions += consumptionDetailForOneLocation.consumption.played.sessions
      } else {
        result.push({
          location: consumptionDetailForOneLocation.location,
          game: consumptionDetailForOneLocation.game,
          consumption: _.cloneDeep(consumptionDetailForOneLocation.consumption)
        })
      }
    })
    return result
  }

  if (isGranted(new Set([Role.User])) === false) {
    return (
      <ErrorMessage
        message={t("You do not have the permissions to access this page")}
      />
    );
  } else if (consumption.error) {
    return <ErrorMessage message={consumption.error} />;
  } else if (consumption.loading || consumption.consumption === null) {
    return <Loader message={t("Fetching consumption")} inline={true} />;
  }
  const locations = [...new Set(consumption.consumption.consumptionDetails.map(({ location }) => location))]

  return (
    <div className="col-12">
      <table className="table">
        <thead className="consumption-table-header">
          <tr>
            <th>{t("Games")}</th>
            <th className="text-center">
              {t("Minutes consumed (playing time)")}
            </th>
            <th className="text-center">
              {t("Billable minutes (playing time)")}
            </th>
            <th className="text-center">{t("Session played")}</th>
            <th className="text-center">{t("Billable sessions")}</th>
          </tr>
        </thead>
        <tbody>
          {locations.map(location => {
            return <LocationRow
              key={location}
              location={location}
              consumptionDetails={getConsumptionDetailsByGamesForLocation(location)}
            />
          })}
        </tbody>
      </table>
    </div>
  )
}

