import React from "react"
import GameSessions from "scenes/GameSessions/GameSessions"
import { ReleaseProvider } from "services/stores/releaseStore"
import { SessionsProvider } from "services/stores/sessionsStore"

export default function GameSessionWrapper() {
  return (
    <SessionsProvider>
      <ReleaseProvider>
        <GameSessions />
      </ReleaseProvider>
    </SessionsProvider>
  )
}