import { stringify } from 'qs';
import Logger from "services/logger";

export async function resetPassword(email: string): Promise<Response | null> {
  return fetch(`${process.env.API || ""}/api/v1/users/reinitialize-password`, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: stringify({
      email,
    }),
  }).catch((errors) => {
    Logger.error("could not perform post on 'users/reinitialize-password'", {
      email,
      errors,
    });
    return Promise.resolve(null);
  })
}