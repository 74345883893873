import { useState } from 'react';

import { get, set } from 'services/helper/storage';
import Logger from 'services/logger';

export function useLocalStorage(key: string, initialValue: string): [string, (value: string) => void] {
  const [storedValue, setStoredValue] = useState<string>(() => {
    try {
      const item = get(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      Logger.error('Could not get the value from the local storage', { error })
      return initialValue;
    }
  });

  const setValue = (valueToStore: string) => {
    try {
      setStoredValue(valueToStore);
      set(key, JSON.stringify(valueToStore));
    } catch (error) {
      Logger.error('Could not set the value in the local storage', { error })
    }
  };

  return [storedValue, setValue];
}