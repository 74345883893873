import moment from 'moment';

import { getConsumptionV2 } from "api/consumption/get";

export interface Consumption {
  played: {
    seconds: number
    sessions: number
  }
  billable: {
    seconds: number
    sessions: number
  }
}

export interface ComputedConsumption {
  global: Consumption
  consumptionDetails: ConsumptionDetails[]
}

export interface ConsumptionDetails {
  location: number
  game: number
  date: { format: string, value: string }
  consumption: Consumption
}

export async function compute(
  from: Date,
  to: Date,
  game: number | null,
  location: number | null
): Promise<ComputedConsumption> {
  const result: ComputedConsumption = {
    global: { billable: { seconds: 0, sessions: 0 }, played: { seconds: 0, sessions: 0 } },
    consumptionDetails: []
  }

  const limit = 250
  let offset = 0
  while (true) { /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
    const response = await getConsumptionV2(
      moment(from).format('YYYY-MM-DD'),
      moment(to).format('YYYY-MM-DD'),
      game ? [game] : undefined,
      location ? [location] : undefined,
      limit,
      offset
    )

    if (response === null) {
      return Promise.reject(null)
    }

    const data = await response.json()

    for (const { produced_by, produced_with, period, consumption } of data) {
      // set global
      result.global.played.seconds += consumption?.played?.seconds || 0
      result.global.played.sessions += consumption?.played?.sessions || 0
      result.global.billable.seconds += consumption?.billable?.seconds || 0
      result.global.billable.sessions += consumption?.billable?.sessions || 0

      // set consumptionDetails
      if ((produced_by?.type === 'location' && produced_by?.id) || (produced_with?.type === 'game' && produced_with?.id)) {
        const details: ConsumptionDetails = {
          location: produced_by?.id,
          game: produced_with?.id,
          date: period,
          consumption: {
            billable: {
              seconds: consumption?.billable?.seconds || 0,
              sessions: consumption?.billable?.sessions || 0
            },
            played: {
              seconds: consumption?.played?.seconds || 0,
              sessions: consumption?.played?.sessions || 0
            }
          }
        }
        result.consumptionDetails.push(details)
      }
    }

    if ((data || []).length < limit) {
      break
    }
    offset += limit
  }

  return result
}