import moment from 'moment';

import { ResponseType } from './../../model/response_type';
import { raw } from "security/token";
import Logger from "services/logger";

export async function list(location: number, from?: Date, to?: Date, limit?: number, offset?: number, responseType: ResponseType = ResponseType.JSON): Promise<Response | null> {
  const params = new URLSearchParams();

  if (from && to) {
    params.append("period[from]", `${moment(from).format("YYYY-MM-DD")}`)
    params.append("period[to]", `${moment(to).format("YYYY-MM-DD")}`)
  }

  if (limit && Number.isInteger(limit) === true) {
    params.append("limit", `${limit}`);
  }

  if (offset && Number.isInteger(offset) === true) {
    params.append("offset", `${offset}`);
  }

  const headers: HeadersInit = {
    "x-auth-token": raw() || "",
  }

  if (responseType === ResponseType.XLS) {
    headers.accept = "application/vnd.ms-excel"
  }

  return fetch(
    `${process.env.API || ""}/api/v1/locations/${location}/balances?${params.toString()}`,
    {
      method: "GET",
      headers
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "api/v1/locations/{id}/balances"', {
      location,
      from,
      to,
      limit,
      offset,
      responseType,
      errors
    });
    return Promise.resolve(null);
  });
}