import React, { FormEvent, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import { GameReleasesConsumerHook } from "stores/gameReleasesStore";
import Modal from "components/Modal/Modal";
import { ModalConsumer, ModalProvider } from "stores/modalStore";
import { edit } from "services/api/release/edit";
import Release from "model/release";

export default function ActivateChannel(props: {
  release: Release;
  name: string;
}) {
  const { t } = useTranslation();
  const [, dispatch] = GameReleasesConsumerHook();
  const [submitting, setSubmitting] = useState(false);
  const [validationName, setValidationName] = useState("");
  const [errors, setErrors] = useState<string[] | null>(null);

  const submitActivateChannelForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    if (validationName !== props.name) {
      setErrors([
        "Please insert " + props.name + " to confirm the activation.",
      ]);
      setSubmitting(false);
      return false;
    }

    const updatedRelease = props.release;
    const channels = Object.values(updatedRelease.channels);
    channels.push(props.name.toLowerCase());
    updatedRelease.channels = channels;
    const { success, data, errors } = await edit(updatedRelease);

    if (success === true) {
      dispatch({
        type: "updateRelease",
        release: data,
      });
    } else {
      setErrors(errors);
    }

    setSubmitting(false);
    return success;
  };

  const activated = Object.values(props.release.channels).includes(
    props.name.toLowerCase()
  );

  if (activated === true) {
    return (
      <p className="main-field main-field-channel-activated">
        <b>{props.name}</b> activated
        <i className="fas fa-check" />
      </p>
    );
  }

  return (
    <ModalProvider>
      <ModalConsumer>
        {([{ opened }, dispatch]) => {
          const formId = "activateChannelForm";
          return (
            <>
              <button
                className="btn-secondary"
                onClick={() =>
                  dispatch({
                    type: opened ? "close" : "open",
                  })
                }
              >
                Activate <b>{props.name}</b>
                <i className="fas fa-arrow-right" />
              </button>

              {opened === true && (
                <Modal
                  header={{
                    title: t("Activation Confirmation"),
                  }}
                  footer={{
                    center: false,
                    btnCloseClass: "btn-danger w-50 me-2",
                    btnCloseDisabled: submitting,
                    btnCloseLabel: t("Cancel"),
                    btnValidClass: "btn-primary w-50 ms-2",
                    btnValidDisabled: submitting,
                    btnValidLabel: t("Validate"),
                    btnValidForm: formId,
                  }}
                  onClose={() =>
                    dispatch({
                      type: "close",
                    })
                  }
                >
                  <p>
                    <Trans
                      i18nKey="You're about to put this release on channel <0>{{channel}}</0>. This operation cannot be undone."
                      values={{
                        channel: props.name,
                      }}
                      components={[<b />]}
                    />

                    <br />
                    <br />

                    <Trans
                      i18nKey="To confirm, write <0>{{channel}}</0> in input field."
                      values={{
                        channel: props.name,
                      }}
                      components={[<b />]}
                    />
                  </p>

                  {errors !== null &&
                    errors.map((error) => (
                      <div className="alert alert-danger">{error}</div>
                    ))}

                  <form
                    id={formId}
                    onSubmit={(e) => {
                      submitActivateChannelForm(e).then((success) => {
                        if (success === true) {
                          dispatch({
                            type: "close",
                          });
                        }
                      });
                    }}
                  >
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          className="form-control"
                          type="text"
                          required
                          onChange={(e) => setValidationName(e.target.value)}
                        />

                        <label className="label-wize">
                          <Trans>Insert channel name to confirm</Trans>
                        </label>

                        <div className="liner" />
                      </div>
                    </div>
                  </form>
                </Modal >
              )}
            </>
          );
        }}
      </ModalConsumer>
    </ModalProvider >
  );
}
