import React from 'react'
import { useTranslation } from 'react-i18next'

import { FormProvider } from 'services/stores/formStore'
import { ModalConsumer, ModalProvider } from 'services/stores/modalStore'
import { SessionExtended } from 'services/stores/sessionsStore'
import Modal from 'components/Modal/Modal'

import pictoEye from 'images/picto-eye.svg'
import { SessionConfigs } from './components/SessionConfigs'

interface SessionConfigsModalProps {
  session: SessionExtended
  launcher: number
}

export function SessionConfigsModal({ session, launcher }: SessionConfigsModalProps) {
  const { t } = useTranslation()

  return (
    <ModalProvider>
      <FormProvider>
        <ModalConsumer>
          {([{ opened }, modalDispatch]) => (
            <>
              <button
                className='btn btn-primary btn-sm'
                onClick={() =>
                  modalDispatch({
                    type: opened ? "close" : "open",
                  })
                }
              >
                <img alt={t('Show session details button')} src={pictoEye} />
              </button>

              {opened === true && (
                <Modal
                  header={{
                    title: t("Launch options details"),
                  }}
                  footer={{
                    btnCloseClass: "btn btn-primary w-50",
                    btnCloseLabel: t("Close"),
                  }}
                  onClose={() =>
                    modalDispatch({
                      type: "close",
                    })
                  }
                >
                  <SessionConfigs session={session._id} launcher={launcher} />
                </Modal>
              )}
            </>
          )}
        </ModalConsumer>
      </FormProvider>
    </ModalProvider >
  )
}