import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import Loader from "components/Loader/Loader";
import countries from "services/helper/data/countries";
import SelectList from "components/SelectList/SelectList";
import { Trans } from 'react-i18next';
import { ModalConsumer, ModalProvider } from "stores/modalStore";
import { FormProvider, FormConsumer } from "stores/formStore";
import Modal from "components/Modal/Modal";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import CompanyPictureModal from "./components/CompanyPictureModal/CompanyPictureModal";
import { Company, editCompany, getCompany, processRole } from "./services/helper/company";
import { Role } from "security/voter";

export default function MyCompany() {
  const { t } = useTranslation();
  const [company, setCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [role, setRole] = useState<Role | null>(null);

  const processLogoId = (logoURL?: string): number | null => {
    if (!logoURL) {
      return null;
    }

    const split = logoURL.split('/');
    const id = split[split.length - 1];
    return Number.parseInt(id, 10);
  }

  useEffect(() => {
    if (role === null) {
      setRole(processRole())
    }
    if (company === null && loading === false && role !== null) {
      setLoading(true)
      getCompany(role || processRole()).then((c) => {
        if (c === null) {
          setError(
            t("Try to reconnect. If the problem persists, contact the support.")
          );
        }
        setCompany(c)
        setLoading(false)
      });
    }
  }, [company, loading, error, role]);

  const updateCompany = async (company: Company | null, form: Company | null) => {
    if (company === null || form === null) {
      return false
    }
    const response = await editCompany(role, { ...company, ...form })
    return response?.ok === true
  };

  if (role === null) {
    return (
      <ErrorMessage message="You do not have the permissions to access this page" />
    );
  } else if (error !== null && error !== undefined) {
    return <ErrorMessage message={error} />;
  } else if (loading === true || company === null && error == null) {
    return <Loader message={t("Fetching company data")} inline={true} />;
  } else if (company === null) {
    return <Loader message={t("Fetching company data")} inline={true} />;
  }

  return (
    <div className="row justify-content-center company">
      <div className="col-lg-11 col-xl-9 card p-5">
        <div className="form-header">
          <h4 className="mb-3">
            <Trans>My company</Trans>
          </h4>
          <CompanyPictureModal companyId={company.id} role={role} logoId={processLogoId(company.logo)} />
        </div>
        <FormProvider>
          <form className="compagny-form">
            <div className="form-row justify-content-between">
              <div className="col-12">
                <div className="input-container">
                  <FormConsumer>
                    {([{ data, errors }, dispatch]) => (
                      <>
                        <input
                          className="form-control"
                          placeholder={t("Wanadev")}
                          required
                          type="text"
                          defaultValue={data?.name || company.name}
                          onChange={(e) =>
                            dispatch({
                              type: "setValue",
                              field: "name",
                              value: e.target.value,
                              initialValue: company.name,
                            })
                          }
                        />

                        {errors
                          .filter((error) => error?.name === "name")
                          .map(({ reason }) => (
                            <small className="form-text text-danger">
                              {reason}
                            </small>
                          ))}
                      </>
                    )}
                  </FormConsumer>
                  <i className="icon icon-edit" />
                  <div className="liner" />
                </div>
              </div>
            </div>
            {role !== Role.Client && (
              <div className="form-row justify-content-between ">
                <div className="col-12 mb-4">
                  <div className="input-container">
                    <FormConsumer>
                      {([{ data, errors }, dispatch]) => (
                        <>
                          <input
                            className="form-control"
                            placeholder={t("13 Quai du Commerce")}
                            required
                            type="text"
                            defaultValue={data?.address || company.address}
                            onChange={(e) =>
                              dispatch({
                                type: "setValue",
                                field: "address",
                                value: e.target.value,
                                initialValue: company.address,
                              })
                            }
                          />

                          {errors
                            .filter((error) => error?.address === "address")
                            .map(({ reason }) => (
                              <small className="form-text text-danger">
                                {reason}
                              </small>
                            ))}
                        </>
                      )}
                    </FormConsumer>
                    <i className="icon icon-edit" />
                    <div className="liner" />
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="col-3">
                    <div className="input-container">
                      <FormConsumer>
                        {([{ data, errors }, dispatch]) => (
                          <>
                            <input
                              className="form-control"
                              placeholder={t("Lyon")}
                              required
                              type="text"
                              defaultValue={data?.city || company.city}
                              onChange={(e) =>
                                dispatch({
                                  type: "setValue",
                                  field: "city",
                                  value: e.target.value,
                                  initialValue: company.city,
                                })
                              }
                            />

                            {errors
                              .filter((error) => error?.city === "city")
                              .map(({ reason }) => (
                                <small className="form-text text-danger">
                                  {reason}
                                </small>
                              ))}
                          </>
                        )}
                      </FormConsumer>
                      <i className="icon icon-edit" />
                      <div className="liner" />
                    </div>
                  </div>
                  <div className="col-3 ">
                    <div className="input-container">
                      <FormConsumer>
                        {([{ data, errors }, dispatch]) => (
                          <>
                            <input
                              className="form-control"
                              placeholder={t("69009")}
                              required
                              type="text"
                              defaultValue={data?.zipcode || company.zipcode}
                              onChange={(e) =>
                                dispatch({
                                  type: "setValue",
                                  field: "zipcode",
                                  value: e.target.value,
                                  initialValue: company.zipcode,
                                })
                              }
                            />

                            {errors
                              .filter((error) => error?.zipcode === "zipcode")
                              .map(({ reason }) => (
                                <small className="form-text text-danger">
                                  {reason}
                                </small>
                              ))}
                          </>
                        )}
                      </FormConsumer>
                      <i className="icon icon-edit" />
                      <div className="liner" />
                    </div>
                  </div>
                  <div className="col-3 mt-3">
                    <div className="input-container">
                      <FormConsumer>
                        {([{ data, errors }, dispatch]) => (
                          <>
                            <SelectList
                              dataOptions={countries.map((country) => ({
                                label: country,
                                value: country,
                              }))}
                              multiSelect={false}
                              placeholderText={t(company.country ?? '')}
                              value={(() => {
                                if (data?.country) {
                                  return {
                                    label: data.country,
                                    value: data.country,
                                  };
                                }

                                return company.country !== null
                                  ? {
                                    label: company.country,
                                    value: company.country,
                                  }
                                  : null;
                              })()}
                              onChange={({ value }: Record<string, unknown>) =>
                                dispatch({
                                  type: "setValue",
                                  field: "country",
                                  value,
                                  initialValue: company.country,
                                })
                              }
                            />
                            {errors
                              .filter((error) => error?.zipcode === "zipcode")
                              .map(({ reason }) => (
                                <small className="form-text text-danger">
                                  {reason}
                                </small>
                              ))}
                          </>
                        )}
                      </FormConsumer>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="button-group--right mt-5">
              <ModalProvider>
                <ModalConsumer>
                  {([{ opened }, modalDispatch]) => (
                    <FormConsumer>
                      {([{ data, errors, submitting }, formDispatch]) => {
                        const disabled: boolean =
                          Object.keys(data).length === 0;
                        const formId = "game-save";
                        return (
                          <>
                            <div className="button-group--top">
                              <button
                                className="btn btn-primary"
                                disabled={disabled}
                                type="button"
                                onClick={() =>
                                  modalDispatch({
                                    type: opened ? "close" : "open",
                                  })
                                }
                              >
                                <i className="icon me-2 icon--white icon-save" />
                                <Trans>Save Company</Trans>
                              </button>
                            </div>

                            {opened === true && (
                              <Modal
                                header={{
                                  title: t("Confirm changes ?"),
                                  icon: "icon icon-save",
                                }}
                                footer={{
                                  btnCloseClass: "btn-danger w-50 me-2",
                                  btnCloseLabel: t("Cancel"),
                                  btnValidClass: "btn-primary w-50 ms-2",
                                  btnValidDisabled: submitting,
                                  btnValidForm: formId,
                                  btnValidLabel: t(
                                    submitting ? "Saving" : "Save"
                                  ),
                                  center: true,
                                }}
                                onClose={() =>
                                  modalDispatch({
                                    type: "close",
                                  })
                                }
                              >
                                {(() => {
                                  if (errors.length > 0) {
                                    return (
                                      <>
                                        <div className="alert alert-danger">
                                          <Trans
                                            i18nKey="{{total}} errors encoutered"
                                            values={{
                                              total: errors.length,
                                            }}
                                          />
                                        </div>

                                        {errors
                                          .filter(
                                            (error) => error?.name === undefined
                                          )
                                          .map((error) => (
                                            <div className="alert alert-danger">
                                              {error}
                                            </div>
                                          ))}
                                      </>
                                    );
                                  }
                                })()}

                                <form
                                  id={formId}
                                  onSubmit={async (e) => {
                                    e.preventDefault();
                                    formDispatch({
                                      type: "setSubmitting",
                                      submitting: true,
                                    });

                                    const result = await updateCompany(
                                      company,
                                      data
                                    );

                                    if (result) {
                                      modalDispatch({
                                        type: "close",
                                      });
                                      formDispatch({
                                        type: "reset",
                                      });
                                    } else {
                                      formDispatch({
                                        type: "setErrors",
                                        errors,
                                      });
                                    }
                                  }}
                                />
                              </Modal>
                            )}
                          </>
                        );
                      }}
                    </FormConsumer>
                  )}
                </ModalConsumer>
              </ModalProvider>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
