import React, { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import Loader from 'components/Loader/Loader';
import { Consumption, ConsumptionDetails } from 'components/Consumption/ConsumptionWrapper/compute';
import { Unit } from '../../ConsumptionSummary';
import StackedBar from 'components/StackedBar/StackedBar';
import { ConsumptionConsumerHook } from 'services/stores/consumptionStore';

export interface ConsumptionStackedBarProps {
  loading: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
  unit: Unit
}

interface Dataset {
  date: Date,
  consumption: Consumption
}

interface DataItem {
  date: Date,
  billable: number,
  nonBillable: number,
}

export default function ConsumptionStackedBar(props: ConsumptionStackedBarProps) {
  const { t } = useTranslation()

  const [consumption] = ConsumptionConsumerHook();

  const { loading, error, unit } = props
  const [datasets, setDatasets] = useState<Dataset[]>([]);

  const orderedDataItems = useMemo<DataItem[]>(() => {
    const sortedData = datasets.sort((x, y) => moment(x.date).isBefore(moment(y.date)) ? -1 : 1)
    const data = sortedData.map((data) => {
      return {
        date: data.date,
        billable: unit === Unit.Sessions ? data.consumption.billable.sessions : Math.trunc(data.consumption.billable.seconds / 60),
        nonBillable: unit === Unit.Sessions ?
          (data.consumption.played.sessions - data.consumption.billable.sessions)
          : Math.trunc((data.consumption.played.seconds - data.consumption.billable.seconds) / 60)
      }
    })
    return data
  }, [datasets, unit])

  const processDatasets = () => {
    const dates: Moment[] = [];
    if (consumption.consumption === null) {
      return // TODO: handle error
    }
    consumption.consumption.consumptionDetails.forEach((cd) => {
      if (dates.find((d) => d.isSame(moment(cd.date.value), 'day')) === undefined) {
        dates.push(moment(cd.date.value))
      }
    })

    const data: Dataset[] = []

    dates.forEach((date) => {
      const consumptionForDate = getConsumptionForDate(date)
      if (
        consumptionForDate.billable.seconds !== 0 ||
        consumptionForDate.billable.sessions !== 0 ||
        consumptionForDate.played.seconds !== 0 ||
        consumptionForDate.played.sessions !== 0
      ) {
        data.push({
          date: date.toDate(),
          consumption: consumptionForDate
        })
      }
    })

    setDatasets(data)
  }

  const getConsumptionForDate = (date: Moment) => {
    const result: Consumption = {
      billable: {
        seconds: 0,
        sessions: 0
      },
      played: {
        seconds: 0,
        sessions: 0
      }
    }

    if (consumption.consumption === null) {
      return result
    }

    consumption.consumption.consumptionDetails.forEach((cd: ConsumptionDetails) => {
      if (moment(cd.date.value).isSame(date, 'day')) {
        result.billable.seconds += cd.consumption.billable.seconds
        result.billable.sessions += cd.consumption.billable.sessions
        result.played.seconds += cd.consumption.played.seconds
        result.played.sessions += cd.consumption.played.sessions
      }
    })
    return result;
  }

  useEffect(() => {
    if (!loading && !consumption.loading && consumption.consumption !== null) {
      processDatasets()
    }
  }, [consumption, loading])

  if (loading || consumption.loading) {
    return <Loader inline={true} message={false} background={false} />;
  } else if (error || consumption.error) {
    return <i className="icon icon-problem" />;
  } else if (consumption.consumption === null || orderedDataItems.length === 0) {
    return <Trans>n/a</Trans>;
  }

  return (
    <StackedBar
      labels={orderedDataItems.map(({ date }) => moment(date).format('L'))}
      datasets1={{
        label: t(`Billable ${unit === Unit.Sessions ? 'sessions' : 'minutes'}`),
        data: orderedDataItems.map(({ billable }) => billable),
        backgroundColor: "#0F2F51",
      }}
      datasets2={{
        label: t(`Non billable ${unit === Unit.Sessions ? 'sessions' : 'minutes'}`),
        data: orderedDataItems.map(({ nonBillable }) => nonBillable),
        backgroundColor: "#8a36fd",
      }}
    />
  )

}
