import React from 'react';
import { useTranslation } from 'react-i18next';

import { Trans } from 'react-i18next';
import Game from 'model/game';
import ProductionLabel from 'components/ProductionLabel/ProductionLabel';
import { GameReleasesConsumer } from 'services/stores/gameReleasesStore';
import { FormConsumer } from 'services/stores/formStore';

export interface GameShowHeaderProps {
  game: Game;
}

export default function GameShowHeader({ game }: GameShowHeaderProps) {
  const { t } = useTranslation();
  
  return (
    <div className="game-show-header__information">
      <div className="d-flex flex-column justify-content-between h-100">
        <div className="form-group m-0 pt-0 pb-2">
          <div className="input-container">
            <GameReleasesConsumer>
              {([{ game }]) => (
                <FormConsumer>
                  {([{ data, errors }, dispatch]) => (
                    <>
                      <input
                        className="form-control simple-placeholder"
                        placeholder={t("Game name")}
                        required
                        type="text"
                        defaultValue={data?.name || game?.name}
                        onChange={(e) =>
                          dispatch({
                            type: "setValue",
                            field: "name",
                            value: e.target.value,
                            initialValue: game?.name,
                          })
                        }
                      />

                      {errors
                        .filter((error) => error?.name === "name")
                        .map(({ reason }) => (
                          <small className="form-text text-danger">
                            {reason}
                          </small>
                        ))}
                    </>
                  )}
                </FormConsumer>
              )}
            </GameReleasesConsumer>

            <i className="icon icon-edit" />

            <div className="liner" />
          </div>
        </div>

        <div>
          <p className="single-label mb-0">
            <Trans>Current status</Trans>
          </p>

          <ProductionLabel production={game.production} />
        </div>
      </div>
    </div>
  )
}