import React, { useCallback, useState, useEffect, UIEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from '@octopod/design-system';
import { useTranslation } from 'react-i18next';
import moment from "moment";

import Modal from "components/Modal/Modal";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Loader from "components/Loader/Loader";
import { getPath } from "routes";
import { list } from "api/release/list";
import Release from "model/release";

interface ModalComponentProps {
  gameId: number;
  closeModal: () => void;
}

export default function ModalComponent(props: ModalComponentProps) {
  const { t } = useTranslation();
  const [releases, setReleases] = useState<Release[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [fullLoaded, setFullLoaded] = useState(false);
  const limit = 15;
  const dataLoader = useCallback(() => {
    setLoading(true);
    list(props.gameId, limit, releases?.length || 0, {
      direction: "DESC",
      field: "createdAt",
    }).then(({ data, errors }) => {
      setReleases((releases || []).concat(data));
      setLoading(false);
      setError(errors);

      if (data.length < limit) {
        setFullLoaded(true);
      }
    });
  }, [props.gameId, releases]);

  useEffect(() => {
    if (releases === null && loading === false) {
      setLoading(true);
      dataLoader();
    }
  }, [releases, loading, error, dataLoader]);

  const handleScroll: UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    const scrollTop = currentTarget.scrollTop;
    const clientHeight = currentTarget.clientHeight;
    const scrollHeight = currentTarget.scrollHeight;

    if (
      scrollTop + clientHeight >= scrollHeight &&
      fullLoaded === false &&
      loading === false
    ) {
      dataLoader();
    }
  };

  return (
    <Modal
      header={{
        title: "All releases",
      }}
      footer={{
        btnCloseClass: "btn-danger w-100 me-2",
        btnCloseLabel: t("Cancel"),
        center: false,
      }}
      onClose={() => props.closeModal()}
    >
      <div
        className="displayDiv"
        style={{
          height: "500px",
          overflowY: "auto",
        }}
        onScroll={handleScroll}
      >
        {(() => {
          if (error !== null && error !== undefined) {
            return <ErrorMessage message={error} />;
          }

          return (
            <div className="displayContent">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">{t("Version")}</th>
                    <th scope="col">{t("Date")}</th>
                    <th scope="col">{t("Channels")}</th>
                    <th className="text-center" scope="col">{t("Availability")}</th>
                  </tr>
                </thead>
                <tbody>
                  {(releases || [])
                    .sort((a, b) => {
                      const createdAt1 = moment(a.createdAt, "YYYY-MM-DDTHH:mm:ssZZ");
                      const createdAt2 = moment(b.createdAt, "YYYY-MM-DDTHH:mm:ssZZ");

                      if (createdAt1.isBefore(createdAt2)) {
                        return 1;
                      }

                      if (createdAt1.isSame(createdAt2)) {
                        return 0;
                      }

                      return -1;
                    })
                    .map((release: Release) => (
                      <tr
                        key={release.id}
                        onClick={() => {
                          navigate(
                            getPath(
                              "game-show",
                              {
                                id: props.gameId,
                              },
                              {
                                release: release.id,
                              }
                            ) ?? ''
                          );
                          props.closeModal();
                        }}
                        className="table__tr"
                      >
                        <td>{release.version}</td>
                        <td>{moment(release.createdAt, "YYYY-MM-DDTHH:mm:ssZZ").format("L")}</td>
                        <td>{release.channels.join(", ")}</td>
                        <td className='text-center'>
                          {(() => {
                            if (release.unavailable === null) {
                              return <i className='fas fa-check' />
                            }

                            return <Tooltip
                              tooltipContent={<>{t('Since {{date}}', {
                                date: moment(release.unavailable).format('LL'),
                              })}</>}
                            >
                              <i className='fas fa-trash' />
                            </Tooltip>
                          })()}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {(() => {
                if (loading === true) {
                  return (
                    <Loader
                      message="Fetching releases data"
                      inline={true}
                      background={false}
                    />
                  );
                }
              })()}
            </div>
          );
        })()}
      </div>
    </Modal>
  );
}
