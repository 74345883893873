import React, { useMemo } from "react";
import { SelectWithCollapsableGroups } from "@octopod/design-system";
import { useTranslation } from 'react-i18next';
import Logger from "services/logger";
import Distributor from "model/distributor";
import Client from "model/client";
import Location from "model/location";

import PictoDistributor from "images/picto-distributor.svg";
import PictoClient from "images/picto-client.svg";
import PictoLocation from "images/picto-location.svg";

interface Props {
  locations: Location[]
  distributors?: Distributor[]
  clients?: Client[]
  onSelect: (val: string | null) => void
  value: string | null
  placeholder?: string
}

export default function SelectLocations({ distributors, clients, locations, onSelect, placeholder, value = null }: Props) {
  const { t } = useTranslation();

  // useMemo for performance concerns (re-compute data tree only when needed)
  const dataTree = useMemo(
    () => distributorClientLocationsDataTree(locations, distributors, clients),
    [distributors, clients, locations]
  )
  const hasData = (dataTree || []).length > 0

  const iconDistrib = <img alt={t("Distributor icon")} src={PictoDistributor} />
  const iconClient = <img alt={t("Client icon")} src={PictoClient} />
  const iconLocation = <img alt={t("Location icon")} src={PictoLocation} />

  const levelIcons = []

  if (distributors && clients) {
    levelIcons.push(iconDistrib)
  }
  if (clients) {
    levelIcons.push(iconClient)
  }
  levelIcons.push(iconLocation)

  const processIconDepth = () => {
    if (distributors && clients) {
      return 2
    }
    if (clients) {
      return 1
    }
    return 0
  }

  const handleChange = (location: string | null) => {
    if (location != null) {
      onSelect(location.substring(location.indexOf('-') + 1))
    } else {
      onSelect(location)
    }
  }

  return (
    <SelectWithCollapsableGroups
      data={dataTree}
      value={value ? `location-${value}` : null}
      placeholder={hasData ? (placeholder ?? t('All locations')) : t('Loading')}
      levelIcons={levelIcons}
      inputIcon={hasData ? levelIcons[processIconDepth()] : null}
      isDisabled={!hasData}
      isClearable={hasData}
      onChange={hasData ? handleChange : () => { /* do nothing */ }}
    />
  )
}

interface Node {
  label: string
  value: string
  children?: Node[]
}

const distributorClientLocationsDataTree = (locations: Location[], distributors?: Distributor[], clients?: Client[]): Node[] => {
  if (locations.length === 0) {
    return []
  }

  const distributorsExists: boolean = (distributors || []).length !== 0
  const clientsExists: boolean = (clients || []).length !== 0

  const dataTree: Node[] = []

  if (distributorsExists && clientsExists) {
    distributors!
      .sort((d1, d2) => d1.name.localeCompare(d2.name))
      .forEach(d => dataTree.push({ label: d.name, value: `distributor-${d.id}`, children: [] }))
    dataTree.push({ label: 'Unknown', value: 'distributor-unknown', children: [] })
  }

  if (clientsExists) {
    clients!
      .sort((c1, c2) => c1.name.localeCompare(c2.name))
      .forEach(c => {
        if (distributorsExists) {
          const targetDistributor = `distributor-${c.distributor}`
          for (let i = 0; i < dataTree.length; i++) {
            if (dataTree[i].value === targetDistributor) {
              dataTree[i].children?.push({ label: c.name, value: `client-${c.id}`, children: [] })
              return
            }
          }

          Logger.warn("distributor unknown for client, skip", { client: c })
        } else {
          dataTree.push({ label: c.name, value: `client-${c.id}`, children: [] })
        }
      })
  }

  locations!
    .sort((l1, l2) => l1.name.localeCompare(l2.name))
    .forEach(l => {
      if (clientsExists) {
        const targetClient = `client-${l.client}`
        for (let i = 0; i < dataTree.length; i++) {
          if (distributorsExists) {
            const children = dataTree[i].children;
            if (children) {
              for (let j = 0; j < children.length; j++) {
                if (children[j].value === targetClient) {
                  children[j].children?.push({ label: l.name, value: `location-${l.id}` })
                  return
                }
              }
            }
          }
          if (dataTree[i].value === targetClient && dataTree[i].children) {
            dataTree[i].children!.push({ label: l.name, value: `location-${l.id}` })
            return
          }
        }

        Logger.warn("client unknown for location, skip", { location: l })
      } else {
        dataTree.push({ label: l.name, value: `location-${l.id}` })
      }

    })
  if ((distributorsExists && clientsExists) || clientsExists) {
    return dataTree.filter(e => e.children && e.children.length > 0)
  } else {
    return dataTree
  }
}