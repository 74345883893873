import React, { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from 'react-i18next';

import SelectList from "components/SelectList/SelectList";
import { Trans } from 'react-i18next';
import upload from "services/helper/upload";
import { addAttachment, editAttachment } from "api/game/create";
import { game as gameMedias } from "services/helper/data/media";
import Game from "model/game";
import GameAttachment from "model/game_attachment";

interface AttachmentFormProps {
  game: Game;
  attachment?: GameAttachment;
  form: string;
  closeModal: () => void;
}

export default function AttachmentForm({ game, attachment, form, closeModal }: AttachmentFormProps) {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [filename, setFilename] = useState<string | null>(null);
  const [rulesDisplayed, setRulesDisplayed] = useState(false);
  const [swap, setSwap] = useState<Record<string, unknown> | null>(null);
  const [name, setName] = useState(attachment?.name || "");
  const [category, setCategory] = useState(attachment?.type || null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleFile = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files === null) {
      setError(t("File not found"));
      return;
    }
    setUploading(true);
    const file = target.files[0];
    setFilename(file.name);
    let swap = null;

    try {
      swap = await upload(file);
    } finally {
      if (swap === null) {
        window.alert(t("Could not send file"));
        setFilename(null);
      }

      setSwap(swap);
      setUploading(false);
    }
  };

  const handleAdd = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      submitting === true ||
      uploading === true ||
      swap === null ||
      name === null ||
      category === null
    ) {
      setRulesDisplayed(true);
      return;
    }

    setError(null);
    setSubmitting(true);
    const { success, error } = await addAttachment(game.id, {
      name,
      category,
      swap,
    });

    if (success === true) {
      closeModal();
    } else {
      setError(error);
      setSubmitting(false);
    }
  };

  const handleEdit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (submitting === true || name === null) {
      setRulesDisplayed(true);
      return;
    }

    if (attachment === undefined) {
      setError(t("Attachment not found"));
      return;
    }

    setError(null);
    setSubmitting(true);

    const { success, error } = await editAttachment(
      game.id,
      attachment.id,
      name
    );

    if (success === true) {
      closeModal();
    } else {
      setError(error);
      setSubmitting(false);
    }
  };

  return (
    <form
      id={form}
      onSubmit={attachment?.id === undefined ? handleAdd : handleEdit}
    >
      {error && <div className="alert alert-danger">{error}</div>}

      {attachment?.id === undefined && (
        <div className="form-row py-3 pb-5 mt-5">
          <div className="col-12">
            <div className="form-control">
              <input
                type="file"
                className="form-control-input"
                id="file-uploader"
                onChange={handleFile}
                disabled={uploading || submitting}
                required
              />

              <label className="form-control-label" htmlFor="file-uploader">
                <i className="icon icon-upload me-3" />

                {(() => {
                  if (uploading) {
                    return t("Uploading {{filename}}", {
                      filename,
                    });
                  } else if (swap !== null) {
                    return filename;
                  }

                  return <Trans>Upload file</Trans>;
                })()}
              </label>
            </div>
          </div>
        </div>
      )}

      <div className="form-row  pb-5 mt-3">
        <div className="col-12">
          <div className="input-container">
            <input
              className="form-control"
              placeholder={t("Com pack game")}
              type="text"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={submitting}
            />

            <label className="label-wize">
              <Trans>File name</Trans>
            </label>

            <i className="icon icon-edit" />

            <div className="liner" />
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-12">
          <div className="input-container">
            {attachment?.id === undefined && (
              <SelectList
                dataOptions={Object.keys(gameMedias).map((value) => ({
                  label: (gameMedias as Record<string, string>)[value],
                  value,
                }))}
                disabled={submitting}
                multiSelect={false}
                onChange={({ value }: Record<string, string | null | undefined>) => setCategory(value ?? null)}
                placeholderText={t("Category")}
                value={
                  category !== null
                    ? {
                      label: category,
                      value: category,
                    }
                    : null
                }
              />
            )}
          </div>

          {rulesDisplayed && (
            <small className="form-error">
              <Trans>Category required</Trans>
            </small>
          )}
        </div>
      </div>
    </form>
  );
}
