import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Loader from 'components/Loader/Loader';
import Doughnut from 'components/Doughnut/Doughnut';
import { ConsumptionDetails } from 'components/Consumption/ConsumptionWrapper/compute';
import { Unit } from '../../ConsumptionSummary';
import { ConsumptionConsumerHook } from 'services/stores/consumptionStore';
import Game from 'model/game';

export interface SessionsDoughnutProps {
  loading: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
  games: Game[] | null
  unit: Unit
}

interface Dataset {
  label: string,
  sessions: number,
  minutes: number
}

interface DataItem {
  label: string,
  value: number,
}

export default function ConsumptionDoughnut(props: SessionsDoughnutProps) {
  const { t } = useTranslation()

  const [consumption] = ConsumptionConsumerHook();

  const { loading, error, games, unit } = props
  const maxLegends = 4;
  const [datasets, setDatasets] = useState<Dataset[]>([]);

  const orderedDataItems = useMemo<DataItem[]>(() => {
    const sortedData = datasets.sort((x, y) => unit === Unit.Sessions ? y.sessions - x.sessions : y.minutes - x.minutes)
    const data = sortedData.map((data) => {
      return { label: data.label, value: unit === Unit.Sessions ? data.sessions : data.minutes }
    })
    return data
  }, [datasets, unit])

  const processDatasets = () => {
    if (consumption.consumption === null) {
      return // TODO: handle error
    }

    const gameIds: Set<number> = new Set(consumption.consumption.consumptionDetails.map((cd => cd.game)))

    const data: Dataset[] = []

    gameIds.forEach((id) => {
      const consumptionForGame = getConsumptionForGame(id)
      if (consumptionForGame.seconds !== 0 || consumptionForGame.sessions !== 0) {
        const game = games?.find(g => g.id === id);
        if (!game) {
          return
        }
        data.push({
          label: game.name,
          sessions: consumptionForGame.sessions,
          minutes: Math.trunc(consumptionForGame.seconds / 60),
        })
      }
    })

    setDatasets(data)
  }

  const processLabels = () => {
    if (orderedDataItems.length > maxLegends) {
      const labels = []
      for (let i = 0; i < maxLegends; i++) {
        labels.push(orderedDataItems[i].label)
      }
      labels.push(t('Others'))
      return labels
    } else {
      return orderedDataItems.map(({ label }) => label)
    }
  }

  const processValues = () => {
    if (orderedDataItems.length > maxLegends) {
      const values = []
      let total = 0
      for (let i = 0; i < orderedDataItems.length; i++) {
        if (i < maxLegends) {
          values.push(orderedDataItems[i].value)
        } else {
          total += orderedDataItems[i].value
        }
      }
      return [...values, total]
    } else {
      return orderedDataItems.map(({ value }) => value)
    }
  }

  const getPercentage = (label: string) => {
    const dataItem = orderedDataItems.find((dataset) => dataset.label === label)
    const total = orderedDataItems.map(({ value }) => value).reduce((prev, curr) => prev + curr)

    if (dataItem) {
      return Math.trunc(dataItem.value * 100 / total)
    } else {
      return null
    }
  }

  const getConsumptionForGame = (game: number) => {
    const result: { seconds: number, sessions: number } = {
      seconds: 0,
      sessions: 0
    }

    if (consumption.consumption === null) {
      return result // TODO: handle error
    }

    consumption.consumption.consumptionDetails.forEach((cd: ConsumptionDetails) => {
      if (cd.game === game) {
        result.seconds += cd.consumption.billable.seconds
        result.sessions += cd.consumption.billable.sessions
      }
    })

    return result;
  }

  useEffect(() => {
    if (!loading && !consumption.loading && consumption.consumption !== null) {
      processDatasets()
    }
  }, [consumption, loading])

  if (loading || consumption.loading) {
    return <Loader inline={true} message={false} background={false} />;
  } else if (error || consumption.error) {
    return <i className="icon icon-problem" />;
  } else if (consumption.consumption === null || games === null || orderedDataItems.length === 0) {
    return <Trans>n/a</Trans>;
  }

  return (
    <Doughnut
      key={Date.now()}
      labels={processLabels()}
      datasets={[
        {
          data: processValues(),
          // @ts-expect-error TS2322
          backgroundColor: [
            "#001b30",
            "#334959",
            "#667683",
            "#99a4ac",
            "#ccd1d6",
          ],
        },
      ]}
      tooltipSpecialProcessing={(labels, dataIndex) => {
        let innerHTML = ''

        labels.forEach((label) => {
          if (dataIndex !== maxLegends) {
            const dataItem = orderedDataItems.find((dataset) => dataset.label === label)
            if (dataItem !== undefined) {
              innerHTML += `<tr><td>${label}: ${dataItem.value} ${unit === Unit.Sessions ? t('sessions') : t('minutes')} (${getPercentage(label)}%)</td></tr>`
            }
          } else {
            orderedDataItems.forEach((dataItem, i) => {
              if (i >= maxLegends) {
                if (i === maxLegends) {
                  innerHTML += '<tr><td>' + label + '</td></tr>'
                  innerHTML += '<ul style="list-style-position: inside; padding-left: 0">'
                }

                innerHTML += `<li>${dataItem.label}: ${dataItem.value} ${unit === Unit.Sessions ? t('sessions') : t('minutes')} (${getPercentage(dataItem.label)}%)</li>`

                if (i === orderedDataItems.length - 1) {
                  innerHTML += '</ul>'
                }
              }
            })
          }
        })
        return innerHTML
      }}
    />
  )
}
