import React, { FormEventHandler, useState } from "react"
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Modal from "components/Modal/Modal";
import { ModalConsumer, ModalProvider } from "services/stores/modalStore";
import { GamesConsumerHook } from "services/stores/gamesStore";
import { create } from "api/game/create";
import { getPath } from "routes";

export default function AddGameModal() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [, dispatch] = GamesConsumerHook();

  const [name, setName] = useState("");
  const [errors, setErrors] = useState<string[] | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const submitForm: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const { success, data, errorsCreate } = await create(name);

    if (success === true) {
      dispatch({
        type: "addGame",
        game: data,
      });
      navigate(
        getPath("game-show", {
          id: data.id,
        }) ?? ''
      );
    } else {
      setErrors(errorsCreate.map((error: unknown) =>
        typeof error === 'object' && error !== null ? (error as Record<string, unknown>).reason : t('An error occurs when creating the game'))
      );
    }

    setSubmitting(false);
  };

  return (
    <ModalProvider>
      <ModalConsumer>
        {([{ opened }, dispatch]) => {
          const formId = "addGameForm";
          return (
            <>
              <div className="button-group--right">
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    dispatch({
                      type: opened ? "close" : "open",
                    })
                  }
                >
                  <i className="icon icon--white icon-mask-add me-2" />

                  <Trans>Add game</Trans>
                </button>
              </div>

              {opened === true && (
                <Modal
                  header={{
                    title: t("Add new game"),
                    icon: "icon icon-mask-add",
                  }}
                  footer={{
                    center: true,
                    btnCloseClass: "btn-danger w-50 me-2",
                    btnCloseDisabled: submitting,
                    btnCloseLabel: t("Cancel"),
                    btnValidClass: "btn-primary w-50 ms-2",
                    btnValidDisabled: submitting,
                    btnValidLabel: t("Create"),
                    btnValidForm: formId,
                  }}
                  onClose={() => {
                    dispatch({ type: 'close' })
                    setErrors(null)
                  }}
                >
                  {errors !== null &&
                    errors.map((error) => (
                      <div className="alert alert-danger">{error}</div>
                    ))}

                  <form id={formId} onSubmit={submitForm}>
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          className="form-control"
                          type="text"
                          required
                          onChange={(e) => setName(e.target.value)}
                        />

                        <label className="label-wize">
                          <Trans>Name</Trans>
                        </label>

                        <i className="icon icon-edit" />

                        <div className="liner" />
                      </div>
                    </div>
                  </form>
                </Modal>
              )}
            </>
          );
        }}
      </ModalConsumer>
    </ModalProvider>
  )
}