import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import { ModalConsumer, ModalProvider } from "stores/modalStore";
import { GameReleasesConsumerHook } from "stores/gameReleasesStore";
import Modal from "components/Modal/Modal";
import { listAttachments } from "api/game/list";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import AttachmentForm from "./attachmentForm";
import { deleteAttachment as deleteGameAttachment } from "api/game/delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import isGranted, { Role } from "security/voter";

export default function GameShowMedias() {
  const { t } = useTranslation();
  const [{ game }, dispatch] = GameReleasesConsumerHook();
  const [deleteError, setDeleteError] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [loading, setLoading] = useState(false);

  const canUpdate = isGranted(new Set([Role.Admin])) || isGranted(new Set([Role.Editor]));

  const handleDeleteAttachment = (attachment: number, e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (game == null) {
      return;
    }
    setDeleting(true);
    deleteGameAttachment(game.id, attachment).then((result) => {
      if (result.success) {
        dispatch({
          type: "removeGameAttachment",
          attachment,
        });
      } else {
        setDeleteError(result.error);
      }

      setDeleting(false);
    });
  };

  const loadAttachments = useCallback(() => {
    if (game == null) {
      return;
    }
    setLoading(true);
    listAttachments(game.id).then((result) => {
      if (result.success) {
        dispatch({
          type: "updateGameAttachments",
          attachments: result.data,
        });
        setLoading(false);
      } else {
        setErrors(result.errors ?? [t("Could not fetch attachments")]);
      }
    });
  }, [dispatch, game?.id]);

  useEffect(() => {
    if (game?.attachments === undefined && loading === false) {
      loadAttachments();
    }
  }, [dispatch, loading, game, loadAttachments]);

  if (game?.attachments === undefined) {
    if (loading) {
      return <Loader message={t("Loading attachments")} />;
    } else if (errors !== null) {
      return <ErrorMessage message="Could not fetch attachments" />;
    }

    return <ErrorMessage message="Could not display attachments" />;
  }

  return (
    <div className="game-container">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="row">
              <div className="col-12">
                <div className="button-group mb-3 d-flex justify-content-between">
                  {canUpdate &&
                    <ModalProvider>
                      <ModalConsumer>
                        {([{ opened }, dispatch]) => (
                          <>
                            <button
                              className="btn btn-primary me-3"
                              onClick={() =>
                                dispatch({
                                  type: opened ? "close" : "open",
                                })
                              }
                            >
                              <i className="icon--white icon-add me-2" />

                              <Trans>Add file</Trans>
                            </button>

                            {(() => {
                              if (opened === true) {
                                const formId = "add-file";
                                return (
                                  <Modal
                                    header={{
                                      title: t("Add new file"),
                                      icon: "icon icon-add",
                                    }}
                                    footer={{
                                      btnCloseClass: "btn-danger me-3",
                                      btnCloseLabel: t("Cancel"),
                                      btnValidClass: "btn-primary",
                                      btnValidForm: formId,
                                      btnValidLabel: t("Submit"),
                                    }}
                                    onClose={() =>
                                      dispatch({
                                        type: "close",
                                      })
                                    }
                                  >
                                    <AttachmentForm
                                      game={game}
                                      form={formId}
                                      closeModal={() => {
                                        loadAttachments();
                                        dispatch({
                                          type: "close",
                                        });
                                      }}
                                    />
                                  </Modal>
                                );
                              }
                            })()}
                          </>
                        )}
                      </ModalConsumer>
                    </ModalProvider>}
                  <a
                    className="btn btn-primary"
                    href="https://doc.octopodvr.com/fr/docs/marketing-assets/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="pe-2" />
                    <span className="pt-1"><Trans>Game guides & Marketing assets</Trans></span>
                  </a>
                </div>
              </div>

              <div className="col-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{t("Name")}</th>
                      <th scope="col">{t("Category")}</th>
                      {canUpdate && <th className="text-right" scope="col">
                        {t("Action")}
                      </th>}
                    </tr>
                  </thead>

                  <tbody>
                    {game.attachments.map((media) => (
                      <tr key={media.id}>
                        <td>{media.name}</td>

                        <td>{media.type}</td>

                        {canUpdate && <td className="text-right pe-0">
                          <ModalProvider>
                            <ModalConsumer>
                              {([{ opened }, dispatch]) => (
                                <>
                                  <button
                                    title={t("Edit file")}
                                    className="btn btn-action"
                                    onClick={() =>
                                      dispatch({
                                        type: opened ? "close" : "open",
                                      })
                                    }
                                  >
                                    <i className="icon icon-edit" />
                                  </button>

                                  {(() => {
                                    if (opened === true) {
                                      const formId = `edit-file-${media.id}`;
                                      return (
                                        <Modal
                                          header={{
                                            title: t("Edit file"),
                                            icon: "icon icon-edit",
                                          }}
                                          footer={{
                                            btnCloseClass: "btn-danger me-3",
                                            btnCloseLabel: t("Cancel"),
                                            btnValidClass: "btn-primary",
                                            btnValidForm: formId,
                                            btnValidLabel: t("Submit"),
                                          }}
                                          onClose={() =>
                                            dispatch({
                                              type: "close",
                                            })
                                          }
                                        >
                                          <AttachmentForm
                                            game={game}
                                            attachment={media}
                                            form={formId}
                                            closeModal={() => {
                                              loadAttachments();
                                              dispatch({
                                                type: "close",
                                              });
                                            }}
                                          />
                                        </Modal>
                                      );
                                    }
                                  })()}
                                </>
                              )}
                            </ModalConsumer>
                          </ModalProvider>

                          <ModalProvider>
                            <ModalConsumer>
                              {([{ opened }, dispatch]) => (
                                <>
                                  <button
                                    title={t("Delete file")}
                                    className="btn btn-action--danger"
                                    onClick={() =>
                                      dispatch({
                                        type: opened ? "close" : "open",
                                      })
                                    }
                                  >
                                    <i className="icon icon-trash" />
                                  </button>

                                  {(() => {
                                    if (opened === true) {
                                      const form = "delete-game-attachment";
                                      return (
                                        <Modal
                                          header={{
                                            title: t("Comfirm deletion"),
                                            error: deleteError,
                                            icon: "icon icon-trash",
                                          }}
                                          footer={{
                                            btnCloseClass:
                                              "btn-danger me-2 w-50 ",
                                            btnCloseDisabled: deleting,
                                            btnCloseLabel: t("Cancel"),
                                            btnValidClass:
                                              "btn-primary w-50 ms-2",
                                            btnValidDisabled: deleting,
                                            btnValidForm: form,
                                            btnValidLabel: t(
                                              deleting ? "Deleting" : "Delete"
                                            ),
                                            center: true,
                                          }}
                                          onClose={() => {
                                            if (deleting === false) {
                                              dispatch({
                                                type: "close",
                                              });
                                              setDeleteError(null);
                                            }
                                          }}
                                        >
                                          <form
                                            id={form}
                                            onSubmit={handleDeleteAttachment.bind(
                                              null,
                                              media.id
                                            )}
                                          />
                                        </Modal>
                                      );
                                    }
                                  })()}
                                </>
                              )}
                            </ModalConsumer>
                          </ModalProvider>
                        </td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
