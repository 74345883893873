import React, { useEffect } from "react";
import i18next from "i18next";

import { useLocalStorage } from "services/hooks/useLocalStorage";
import languages from "services/languages";

export default function SelectLanguage() {
  const [currentLanguage, setCurrentLanguage] = useLocalStorage('language', i18next.language)

  useEffect(() => {
    i18next.changeLanguage(currentLanguage) //? without this line, the pages will be 'en' after refresh (even if the language is 'fr')
  }, [])

  const changeLanguage = (lng: string) => {
    setCurrentLanguage(lng)
    i18next.changeLanguage(lng)
  }
  return (
    <div className="select-language d-flex align-items-center justify-content-center p-2">
      <img className="select-language-flag" src={languages.get(currentLanguage)?.flag} />
      <div className="select-language-dropdown ms-2 me-2">
        {Array.from(languages.keys()).map((key) => {
          const lng = languages.get(key);
          return (
            <div
              key={key}
              className={`${currentLanguage === key ? 'select-language-selected' : ''} select-language-dropdown-item`}
              onClick={() => changeLanguage(key)}
            >
              <img className="select-language-flag" src={lng?.flag} />
            </div>
          )
        })}
      </div>
    </div>
  )
}