import { stringify } from "qs";

export const defaultChunkSize: number = 100 * 1024 * 1024;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function create(filename: string): Promise<Record<string, any>> {
  const body: Record<string, string | undefined> = {
    filename: undefined,
  };

  if (filename) {
    body.filename = filename;
  }

  const response = await fetch(`${process.env.API || ""}/api/v1/swap`, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: stringify(body),
  });
  const content = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: content,
    };
  }

  return {
    success: false,
    errors: content.errors,
  };
}

export async function uploadPart(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { id, key }: Record<string, any>,
  part: number,
  body: Blob
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  const response = await fetch(
    `${process.env.API || ""}/api/v1/swap/${id}/parts/${part}?key=${key}`,
    {
      method: "PUT",
      body,
    }
  );

  if (response.status === 200) {
    return {
      success: true,
    };
  }

  let errors = null;

  try {
    const content = await response.json();
    errors = content.errors;
  } finally {
    // eslint-disable-next-line no-empty
  }

  return {
    success: false,
    errors,
  };
}
export async function compile({
  id,
  key,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: Record<string, any>): Promise<Record<string, any>> {
  const response = await fetch(
    `${process.env.API || ""}/api/v1/swap/${id}/compile?key=${key}`,
    {
      method: "POST",
    }
  );
  const content = await response.json();

  if (response.status === 200) {
    return {
      success: true,
    };
  }

  return {
    success: false,
    errors: content.errors,
  };
}
