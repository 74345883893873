import { raw } from "security/token";
import Logger from "services/logger";

export async function getMedia(media: number): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/clients/medias/${media}`,
    {
      method: 'GET',
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get media on "clients/medias/:id"', {
      media,
      errors
    });
    return Promise.resolve(null);
  })
}
