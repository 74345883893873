import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import "moment/locale/en-gb";

import { get } from "./storage";
import languages, { Catalog } from "services/languages";


moment.locale("en-gb");

const resources: Record<string, Catalog> = {};

Array.from(languages.keys()).forEach((key) => resources[key] = languages.get(key)!.catalog);

i18next
  .use(initReactI18next) // bind react-i18next to the instance
  // @ts-expect-error TS2769
  .init({
    resources,
    defaultNS: "translation",
    lng: "en",
    debug: false,
    escapeValue: false,
    returnEmptyString: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // needed for dates to render correctly
    }
  });

i18next.changeLanguage(get("language") || "en");
