import React, { createContext, useContext, useReducer } from "react"
import type { Context, PropsWithChildren } from "react"

import Logger from "../logger"
import { list } from "api/sessions/list"
import { list as listPlayers } from "api/sessions/playerSession/list"
import { list as listTeams } from "api/sessions/teamSession/list"
import { show } from "api/sessions/player/show"
import { SessionCouch, SessionCouchStatus } from "model/session_couch"
import { TeamSessionCouch } from "model/team_session_couch"
import { PlayerSessionCouch } from "model/player_session"
import PlayerCouch from "model/player"
import SessionConfig from "model/session_config"
import { config } from "api/sessions/config"

export interface SessionExtended extends SessionCouch {
  teamSessions?: TeamSessionCouch[];
  playerSessions?: PlayerSessionCouch[];
  players?: PlayerCouch[];
  configs?: SessionConfig;
}
export interface SessionsByLauncher {
  launcher: number;
  sessions: SessionExtended[];
  reachedMax: boolean;
}

type State = {
  sessionsByLauncher: SessionsByLauncher[];
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  sessionsByLauncher: [],
  loading: false,
  error: null,
};

const reducer = (state: State, unknownAction: unknown): State => {
  if (typeof unknownAction !== 'object' || unknownAction === null) {
    return state
  }
  const action = unknownAction as Record<string, unknown>

  switch (action.type) {
    case "FETCHING":
      return {
        sessionsByLauncher: state.sessionsByLauncher,
        loading: true,
        error: null,
      }

    case "FETCHING_SUCCESS": {
      if (typeof action.launcher === 'number' && action.sessions instanceof Array) {
        const currentSessions = state.sessionsByLauncher.find((sbl) => sbl.launcher === action.launcher)
        let reachedMax = false
        if (action.sessions.length < 50) {
          reachedMax = true
        }
        if (currentSessions === undefined) {
          state.sessionsByLauncher.push({
            launcher: action.launcher,
            sessions: action.sessions,
            reachedMax,
          })
        } else {
          currentSessions.sessions = action.sessions
          currentSessions.reachedMax = reachedMax
        }
      }
      return {
        sessionsByLauncher: [...state.sessionsByLauncher],
        loading: false,
        error: null,
      }
    }

    case "FETCHING_PAGINATION_SUCCESS": {
      if (typeof action.launcher === 'number' && action.sessions instanceof Array) {
        const currentSessions = state.sessionsByLauncher.find((sbl) => sbl.launcher === action.launcher)
        let reachedMax = false
        if (action.sessions.length < 50) {
          reachedMax = true
        }
        if (currentSessions === undefined) {
          state.sessionsByLauncher.push({
            launcher: action.launcher,
            sessions: action.sessions,
            reachedMax,
          })
        } else {
          currentSessions.sessions.push(...action.sessions)
          currentSessions.reachedMax = reachedMax
        }
      }
      return {
        sessionsByLauncher: state.sessionsByLauncher,
        loading: false,
        error: null,
      }
    }

    case "FETCHING_PLAYERS_SUCCESS": {
      if (
        action.playerSessions instanceof Array &&
        action.teamSessions instanceof Array &&
        action.players instanceof Array &&
        typeof action.launcher === 'number' &&
        typeof action.sessionId === 'string'
      ) {
        const targetSessionPlayer = state.sessionsByLauncher.find(sbl => sbl.launcher === action.launcher)?.sessions.find(s => s._id === action.sessionId)
        if (targetSessionPlayer !== undefined) {
          if (targetSessionPlayer.playerSessions === undefined || targetSessionPlayer.teamSessions === undefined || targetSessionPlayer.players === undefined) {
            targetSessionPlayer.playerSessions = action.playerSessions
            targetSessionPlayer.teamSessions = action.teamSessions
            targetSessionPlayer.players = action.players
          } else {
            targetSessionPlayer.playerSessions.push(...action.playerSessions)
            targetSessionPlayer.teamSessions.push(...action.teamSessions)
            targetSessionPlayer.players.push(...action.players.filter(p => targetSessionPlayer.players!.find(tp => tp._id === p._id) === undefined))
          }
        }
      }
      return {
        sessionsByLauncher: [...state.sessionsByLauncher],
        loading: false,
        error: null,
      }
    }

    case "FETCHING_CONFIG_SUCCESS": {
      if (
        typeof action.sessionId === 'string' &&
        typeof action.launcher === 'number' &&
        action.config instanceof Object
      ) {
        const targetSession = state.sessionsByLauncher.find(sbl => sbl.launcher === action.launcher)?.sessions.find(s => s._id === action.sessionId)
        if (targetSession !== undefined) {
          targetSession.configs = action.config as SessionConfig
        }
      }
      return {
        sessionsByLauncher: [...state.sessionsByLauncher],
        loading: false,
        error: null,
      }
    }

    case "FETCHING_ERROR":
      if (typeof action.error !== 'string') {
        return {
          sessionsByLauncher: state.sessionsByLauncher,
          loading: false,
          error: "Error while fetching sessions",
        }
      }

      return {
        sessionsByLauncher: state.sessionsByLauncher,
        loading: false,
        error: action.error,
      }

    default:
      return state
  }
}

const SessionsContext: Context<[State, (...args: Array<unknown>) => unknown]> =
  createContext([
    initialState,
    () => Logger.error("SessionContext not correctly initialized"),
  ])

export const SessionsConsumer = SessionsContext.Consumer
export const SessionsConsumerHook = () => useContext(SessionsContext)
export const SessionsProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <SessionsContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </SessionsContext.Provider>
  )
}

export const fetchSessions = async (
  dispatch: (...args: Array<unknown>) => unknown,
  launcher: number,
  from?: string,
  to?: string,
  games?: string[],
  sessionId?: string,
  status?: SessionCouchStatus,
  offset?: number,
) => {
  dispatch({
    type: "FETCHING",
  })
  const response = await list(launcher, from, to, games, sessionId, status, undefined, offset)

  if (!response?.ok) {
    dispatch({
      type: "FETCHING_ERROR",
      error: "Error while fetching sessions",
    })
    return
  }

  const json = await response.json();
  const sessionsList = json || [];

  if (offset === undefined || offset === 0) {
    dispatch({
      type: "FETCHING_SUCCESS",
      sessions: sessionsList,
      launcher,
    })
  } else {
    dispatch({
      type: "FETCHING_PAGINATION_SUCCESS",
      sessions: sessionsList,
      launcher,
    })
  }
}

export const fetchSessionPlayers = async (
  dispatch: (...args: Array<unknown>) => unknown,
  launcher: number,
  sessionId: string,
) => {
  dispatch({
    type: "FETCHING",
  })

  const responseTeamSessions = await listTeams(launcher, sessionId)
  if (!responseTeamSessions?.ok) {
    dispatch({
      type: "FETCHING_ERROR",
      error: "Error while fetching session teams",
    })
    return
  }

  const teamSessions = await responseTeamSessions.json()
  const playerSessions = []

  for (const team of teamSessions) {
    const responsePlayers = await listPlayers(launcher, team._id)

    if (responsePlayers === null) {
      dispatch({
        type: "FETCHING_ERROR",
        error: "Error while fetching session players",
      })
      return
    }

    playerSessions.push(...await responsePlayers.json())
  }

  const players = []

  for (const player of playerSessions) {
    if (players.find(p => p._id === player.player) !== undefined) {
      continue
    }
    const responsePlayer = await show(launcher, player.player)

    if (!responsePlayer?.ok) {
      dispatch({
        type: "FETCHING_ERROR",
        error: "Error while fetching player's details",
      })
      return
    }

    players.push(await responsePlayer.json())
  }
  dispatch({
    type: "FETCHING_PLAYERS_SUCCESS",
    teamSessions: teamSessions,
    playerSessions: playerSessions,
    players: players,
    launcher: launcher,
    sessionId,
  })
}

export const fetchSessionConfig = async (
  dispatch: (...args: Array<unknown>) => unknown,
  launcher: number,
  sessionId: string,
) => {
  dispatch({
    type: "FETCHING",
  })
  const response = await config(launcher, sessionId)

  if (!response?.ok) {
    dispatch({
      type: "FETCHING_ERROR",
      error: "Error while fetching session config",
    })
    return
  }

  const json = await response.json();
  const sessionConfig: SessionConfig = json;

  dispatch({
    type: "FETCHING_CONFIG_SUCCESS",
    config: sessionConfig,
    launcher,
    sessionId
  })
}
