import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { lastDayOfMonth, startOfMonth } from "date-fns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { Button, Tooltip } from '@octopod/design-system';
import { Trans, useTranslation } from 'react-i18next';

import SelectGames from 'components/SelectGames/SelectGames';
import SelectDateRange from 'components/SelectDateRange/SelectDateRange';
import { EditorsConsumerHook, fetchEditors } from 'services/stores/editorsStore';
import { fetchGames, GamesConsumerHook } from 'services/stores/gamesStore';
import { fetchLocations, LocationsConsumerHook } from 'services/stores/locationsStore';
import { SessionStatusSelect } from './components/SessionStatusSelect/SessionStatusSelect';
import SelectLaunchers from './components/SelectLauncher/SelectLauncher';
import { fetchLaunchers, LaunchersConsumerHook } from 'services/stores/launchersStore';
import { fetchSessionPlayers, fetchSessions, SessionsByLauncher, SessionsConsumerHook } from 'services/stores/sessionsStore';
import { addNotification, NotificationConsumerHook } from 'services/stores/notificationStore';
import { scores } from 'api/sessions/scores';
import { getSessionCouchStatusFromString } from 'model/session_couch';
import { fetchReleases, ReleaseConsumerHook } from 'services/stores/releaseStore';

import pictoCopy from 'images/picto-copy.svg';
import pictoScore from 'images/picto-score.svg';
import { SessionConfigsModal } from './components/SessionConfigsModal/SessionConfigsModal';

export enum SessionsFilters {
  Game = 'filters[game]',
  PeriodFrom = 'filters[period_from]',
  PeriodTo = 'filters[period_to]',
  Id = 'filters[id]',
  Status = 'filters[status]',
  Launcher = 'filters[launcher]',
}

export default function GameSessions() {
  const { t } = useTranslation();

  const [{ games, loading: loadingGames, error: errorGames }, dispatchGames] = GamesConsumerHook();
  const [{ editors, loading: loadingEditors, error: errorEditors }, dispatchEditors] = EditorsConsumerHook();
  const [{ locations, loading: loadingLocations, error: errorLocations }, dispatchLocations] = LocationsConsumerHook();
  const [{ launchers, loading: loadingLaunchers, error: errorLaunchers }, dispatchLaunchers] = LaunchersConsumerHook();
  const [{ sessionsByLauncher }, dispatchSessions] = SessionsConsumerHook();
  const [{ releasesAndGamesByLauncher }, dispatchRelease] = ReleaseConsumerHook();

  const [, dispatchNotification] = NotificationConsumerHook();

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const launcher = searchParams.get(SessionsFilters.Launcher)
    if (sessionsByLauncher !== null && launcher !== null && !Number.isNaN(parseInt(launcher, 10))) {
      const releasesAndGames = releasesAndGamesByLauncher[parseInt(launcher, 10)];
      fetchReleases(dispatchRelease, launcher, sessionsForSelectedLauncher?.sessions ?? [], releasesAndGames)
    }
  }, [sessionsByLauncher])

  // Current launcher's sessions and players
  const sessionsForSelectedLauncher = useMemo<SessionsByLauncher | undefined>(() => {
    const launcherIfExists = searchParams.get(SessionsFilters.Launcher)
    if (sessionsByLauncher != null && launcherIfExists != null) {
      return sessionsByLauncher.find(({ launcher }) => launcher === Number.parseInt(launcherIfExists, 10))
    }
    return undefined
  }, [sessionsByLauncher, searchParams.get(SessionsFilters.Launcher)])

  const { releasesCouch, gamesCouch } = useMemo(() => {
    const launcherIfExists = searchParams.get(SessionsFilters.Launcher)
    if (releasesAndGamesByLauncher != null && launcherIfExists != null && !Number.isNaN(parseInt(launcherIfExists, 10))) {
      const launcher = parseInt(launcherIfExists, 10);
      const releasesCouch = releasesAndGamesByLauncher[launcher]?.releases ?? []
      const gamesCouch = releasesAndGamesByLauncher[launcher]?.games ?? []
      return { releasesCouch, gamesCouch }
    }
    return { releasesCouch: [], gamesCouch: [] }
  }, [releasesAndGamesByLauncher, searchParams.get(SessionsFilters.Launcher)])

  const handleChangePeriod = (startDate: Date, endDate: Date) => {
    setSearchParams(prev => {
      prev.set(SessionsFilters.PeriodFrom, moment(startDate).format("YYYY-MM-DD"))
      prev.set(SessionsFilters.PeriodTo, moment(endDate).format("YYYY-MM-DD"))
      return prev
    })
  }

  const handleChange = (value: string | null, filter: SessionsFilters) => {
    if (searchParams.get(filter) != value) {
      setSearchParams(prev => {
        if (value !== null && value !== '') {
          prev.set(filter, value)
        } else {
          prev.delete(filter)
        }
        return prev
      })
      if (filter === SessionsFilters.Launcher) {
        setSearchParams(prev => {
          prev.delete(SessionsFilters.PeriodFrom)
          prev.delete(SessionsFilters.PeriodTo)
          prev.delete(SessionsFilters.Id)
          prev.delete(SessionsFilters.Game)
          prev.delete(SessionsFilters.Status)
          return prev
        })
      }
    }
  }

  const processDurationString = (dateStart: string, dateEnd: string): string => {
    return moment.utc(moment(dateEnd).diff(moment(dateStart), 'ms')).format(`mm[${t('min')}] ss[${t('s')}]`)
  }

  const loadPlayers = (sessionId: string | null) => async () => {
    const launcher = searchParams.get(SessionsFilters.Launcher)
    if (launcher === null || sessionId === null) {
      return
    }
    fetchSessionPlayers(dispatchSessions, parseInt(launcher, 10), sessionId)
  }

  const callFetchSessions = (offset: boolean = false) => {
    const launcher = searchParams.get(SessionsFilters.Launcher)
    if (launcher !== null && launchers !== null) {
      const game = searchParams.get(SessionsFilters.Game)
      fetchSessions(
        dispatchSessions,
        parseInt(launcher, 10),
        searchParams.get(SessionsFilters.PeriodFrom) ?? undefined,
        searchParams.get(SessionsFilters.PeriodTo) ?? undefined,
        game !== null ? [game] : undefined,
        searchParams.get(SessionsFilters.Id) ?? undefined,
        getSessionCouchStatusFromString(searchParams.get(SessionsFilters.Status) ?? '') ?? undefined,
        offset && sessionsForSelectedLauncher ? sessionsForSelectedLauncher?.sessions.length + 50 : 0,
      )
    }
  }

  const downloadScores = (sessionId: string | null): void => {
    const launcher = searchParams.get(SessionsFilters.Launcher)
    if (launcher === null || sessionId === null) {
      addNotification(dispatchNotification, t('error'), t('Error while downloading scores'), 'error')
      return
    }
    scores(launcher, sessionId)
      .then((response: Response | null) => {
        if (response === null) {
          return null
        } else {
          return response.blob()
        }
      })
      .then((blob: Blob | null) => {
        if (blob === null) {
          addNotification(dispatchNotification, t('error'), t('Error while downloading scores'), 'error')
          return
        }
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${sessionId}_scores.pdf`;
        a.click();
        addNotification(dispatchNotification, t('Scores PDF downloaded'), t('The scores PDF of your game has just been downloaded'), 'success')
      })
  }

  const processGame = (release: string | null) => {
    if (release === null) {
      return '-'
    }
    const releaseCouch = releasesCouch.find(r => r._id === release)
    if (releaseCouch === undefined) {
      return '-'
    }
    const gameCouch = gamesCouch.find(g => g._id === releaseCouch.game)
    if (gameCouch === undefined) {
      return '-'
    }
    return gameCouch.name
  }

  const processSessionName = (name: string | null, sid: string | null) => {
    if (name === null) {
      return `${t('Game')} ${sid ? `#${sid}` : ''}`
    }
    const legacyNameSplit = name.split('- Session')
    if (legacyNameSplit !== undefined && legacyNameSplit.length > 1) {
      return `${t('Game')} ${legacyNameSplit[1]}`
    }
    return `${name} ${sid ? `#${sid}` : ''}`
  }

  useEffect(() => {
    if ((loadingGames === false && games === null) || errorGames !== null) {
      fetchGames(dispatchGames, history)
    }
  }, [dispatchGames, errorGames, games, history, loadingGames])

  useEffect(() => {
    if ((loadingLaunchers === false && launchers === null) || errorLaunchers !== null) {
      fetchLaunchers(dispatchLaunchers, history)
    }
  }, [dispatchLaunchers, errorLaunchers, history, launchers, loadingLaunchers])

  useEffect(() => {
    if ((loadingLocations === false && locations === null) || errorLocations !== null) {
      fetchLocations(dispatchLocations, history)
    }
  }, [dispatchLocations, errorLocations, history, locations, loadingLocations])

  useEffect(() => {
    if ((loadingEditors === false && editors === null) || errorEditors !== null) {
      fetchEditors(dispatchEditors, history)
    }
  }, [dispatchEditors, editors, errorEditors, history, loadingEditors])

  useEffect(() => {
    callFetchSessions();
  }, [searchParams, launchers])

  return (
    <>
      <div className="row card mx-0 mb-5">
        <div className='col'>
          <SelectLaunchers
            launchers={launchers}
            locations={locations ?? []}
            onSelect={(value) => handleChange(value, SessionsFilters.Launcher)}
            value={searchParams.get(SessionsFilters.Launcher)}
          />
        </div>
      </div>

      {searchParams.get(SessionsFilters.Launcher) &&
        <>
          <div className="row card mx-0 mb-5">
            <div className='col-3'>
              <SelectGames
                games={games}
                editors={editors}
                onSelect={(value) => handleChange(value, SessionsFilters.Game)}
                value={searchParams.get(SessionsFilters.Game)}
              />
            </div>
            <div className='col-2 game-sessions-input-container'>
              <input
                className="game-sessions-input"
                placeholder={t("Session #")}
                type="text"
                defaultValue={searchParams.get(SessionsFilters.Id) ?? undefined}
                onChange={(e) => {
                  handleChange(e.target.value, SessionsFilters.Id)
                }}
              />
            </div>
            <div className='col-3'>
              <SessionStatusSelect
                value={searchParams.get(SessionsFilters.Status)}
                onSelect={(value) => handleChange(value, SessionsFilters.Status)}
              />
            </div>
            <div className='col'>
              <SelectDateRange
                onChange={handleChangePeriod}
                initialValue={{
                  startDate: new Date(searchParams.get(SessionsFilters.PeriodFrom) || moment(startOfMonth(new Date())).toISOString()),
                  endDate: new Date(searchParams.get(SessionsFilters.PeriodTo) || moment(lastDayOfMonth(new Date())).toISOString()),
                }}
              />
            </div>
          </div>
          <div className='game-sessions-table-border' />
          <table className='table table-light game-sessions-table'>
            <thead className=' text-primary game-sessions-table-header'>
              <tr>
                <th className='text-center'>{t('Id')}</th>
                <th className='text-center'>{t('Session')}</th>
                <th className='text-center'>{t('Game')}</th>
                <th className='text-center'>{t('Version')}</th>
                <th className='text-center'>{t('Starting date')}</th>
                <th className='text-center'>{t('Ending date')}</th>
                <th className='text-center'>{t('Duration')}</th>
                <th className='text-center'>{t('Launcher')}</th>
                <th className='text-center'>{t('Players')}</th>
                <th className='text-center'>{t('Actions')}</th>
              </tr>
            </thead>
            <tbody className='game-sessions-table-body'>
              {sessionsForSelectedLauncher && sessionsForSelectedLauncher.sessions.map((session) => {
                return <tr key={session._id}>
                  <td className='text-center'>
                    <img className='game-sessions-pointer' alt={t('Copy session id button')} src={pictoCopy} onClick={() => navigator.clipboard.writeText(session._id ?? '')} />
                  </td>
                  <td className='text-center align-middle'>{processSessionName(session.name, session.sid)}</td>
                  <td className='text-center align-middle'>{processGame(session.release)}</td>
                  <td className='text-center align-middle'>{releasesCouch.find(r => r._id === session.release)?.version || '-'}</td>
                  <td className='text-center align-middle'>{session.launchedAt ? moment(session.launchedAt).format('L H:m') : '-'}</td>
                  <td className='text-center align-middle'>{session.doneAt ? moment(session.doneAt).format('L H:m') : '-'}</td>
                  <td className='text-center align-middle'>{session.launchedAt && session.doneAt ? processDurationString(session.launchedAt, session.doneAt) : '-'}</td>
                  <td className='text-center align-middle'>{launchers?.find(l => searchParams.get(SessionsFilters.Launcher) === l.id.toString())?.couchDBId || '-'}</td>
                  <td className='text-center align-middle'>
                    <div className='game-sessions-players'>
                      {
                        session.playerSessions ?
                          (
                            session.playerSessions.length > 0 ?
                              <Tooltip
                                tooltipContent={<>
                                  {session.playerSessions.map(({ player }, index) => {
                                    const p = session.players?.find(p => p._id === player)
                                    return <div key={p?._id ?? index}>{p?.name ?? t('Unknown')}</div>
                                  })}
                                </>}
                              >
                                <span className='text-decoration-underline'>
                                  {session.playerSessions.length}
                                </span>
                              </Tooltip> :
                              <span className='text-decoration-underline'>{session.playerSessions.length}</span>
                          ) : <FontAwesomeIcon className='game-sessions-pointer' icon={faArrowRotateLeft} onClick={loadPlayers(session._id)} />
                      }
                    </div>
                  </td>
                  <td>
                    <div className='game-sessions-actions'>
                      <button className='btn btn-primary btn-sm' onClick={() => downloadScores(session._id)}>
                        <img alt={t('Download scores button')} src={pictoScore} />
                      </button>
                      <SessionConfigsModal launcher={parseInt(searchParams.get(SessionsFilters.Launcher) ?? '')} session={session} />
                    </div>
                  </td>
                </tr>
              })}

            </tbody>
          </table>
        </>
      }
      {!searchParams.get(SessionsFilters.Launcher) &&
        <div className='w-100'>
          <Trans>Please select a launcher to access the corresponding game sessions</Trans>
        </div>
      }
      {searchParams.get(SessionsFilters.Launcher) && (!sessionsForSelectedLauncher?.reachedMax && sessionsByLauncher && sessionsByLauncher.length > 0) && (
        <div className='w-100 game-sessions-pagination'>
          <Button label={t('Load more')} onClick={() => callFetchSessions(true)}></Button>
        </div>
      )}
    </>
  );
}
