import getToken from "./token";

export enum Role {
  User = 'ROLE_USER',
  Admin = 'ROLE_ADMIN',
  Editor = 'ROLE_EDITOR',
  Distributor = 'ROLE_DISTRIBUTOR',
  Client = 'ROLE_CLIENT'
}

export default function isGranted(roles: Set<string>): boolean {
  const token = getToken();

  if (roles.size === 0) {
    return true;
  } else if (Array.isArray(token?.context?.roles) === false) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tokenRoles: Array<any> = token?.context?.roles || [];

  return Array.from(roles).every((role) => tokenRoles.includes(role));
}
