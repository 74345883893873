import React, { createContext, useContext, useReducer } from "react";
import type { Context, PropsWithChildren } from "react";

import Logger from "../logger";
import { list as listDistributors } from "api/distributors/list";
import Unauthorized from "exception/unauthorized";
import { getPath } from "routes";
import { fetchAll } from "services/helper/fetch_all";
import Distributor from "model/distributor";

type State = {
  distributors: Distributor[] | null;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
};

const initialState: State = {
  distributors: null,
  loading: false,
  error: null,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: State, action: Record<string, any>) => {
  switch (action.type) {
    case "FETCHING":
      return {
        distributors: null,
        loading: true,
        error: null,
      };

    case "FETCHING_SUCCESS":
      return {
        distributors: action.distributors,
        loading: false,
        error: null,
      };

    case "FETCHING_ERROR":
      return {
        distributors: [],
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DistributorsContext: Context<[State, (...args: Array<any>) => any]> =
  createContext([
    initialState,
    () => Logger.error("Context not correctly initialized"),
  ]);

export const DistributorsConsumer = DistributorsContext.Consumer;
export const DistributorsConsumerHook = () => useContext(DistributorsContext);
export const DistributorsProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <DistributorsContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </DistributorsContext.Provider>
  );
};

export const fetchDistributors = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: (...args: Array<any>) => any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    history: any
) => {
  dispatch({
    type: "FETCHING",
  });

  const { success, data: distributors, error } = await fetchAll(listDistributors)

  if (!success || error !== null) {
    if (error instanceof Unauthorized) {
      history.push(getPath("login"));
    } else {
      dispatch({
        type: "FETCHING_ERROR",
        error: error,
      });
    }
    return
  }

  dispatch({
    type: "FETCHING_SUCCESS",
    distributors
  });
};
