import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { CollapsableSection } from "@octopod/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

import Article1 from "./components/Article1/Article1";
import Article2 from "./components/Article2/Article2";
import Article3 from "./components/Article3/Article3";
import Article4 from "./components/Article4/Article4";
import Article5 from "./components/Article5/Article5";
import Article6 from "./components/Article6/Article6";
import Article7 from "./components/Article7/Article7";
import Article8 from "./components/Article8/Article8";
import isGranted from "security/voter";
import { getPath } from "routes";


export default function TermsOfUse() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isAuth = isGranted(new Set(["ROLE_USER"]));

  const [searchParams, setSearchParams] = useSearchParams()

  const isOpen = (id: string): boolean => {
    return searchParams.get('article') === id;
  }

  const handleClick = (id: string): void => {
    setSearchParams(prev => {
      prev.set('article', isOpen(id) ? '0' : id)
      return prev
    })
  }

  return (
    <div className="mt-5 mb-5">
      <header>
        <div className="d-flex align-items-center">
          {!isAuth && (
            <>
              <FontAwesomeIcon icon={faArrowLeft} size="2xl" className="tou__arrow ms-1" onClick={() => navigate(getPath("index") ?? '')} />
            </>
          )}
          <div className={`h2 ms-5 mb-0`}>{t(`Terms and conditions of use`)}</div>
        </div>
        <div className="">
          <ul className="fw-bold text-primary list-group m-5 list-unstyled d-grid gap-3">
            <li className="list-group-item">
              {t(`Wanadev is a limited liability company with capital of €100,000, registered with the Versailles Trade and Companies Register under number 511 500 811, with its registered office at 13 Quai du Commerce, 69 009, represented by its manager Mr. Côme de Percin (hereinafter "Wanadev").`)}
            </li>
            <li className="list-group-item">
              {t(`Wanadev offers access via online platforms to software solutions to support companies operating accessible virtual reality rooms. These include in particular the "Octopod: Insight", "Octopod: Play" and "Octopod: Games" offers (hereinafter individually or jointly the "Services").`)}
            </li>
            <li className="list-group-item">
              {t(`These general conditions of use of the Services offered here (hereinafter the "General Conditions") govern the relationship between the user of the Services (hereinafter the "User") and Wanadev.`)}
            </li>
            <li className="list-group-item">
              {t(`Access to and use of the Services imply unreserved acceptance of the terms of these General Conditions. Users are reminded that special additional conditions may also apply to Users of the Services depending on the professional contracts entered into with Wanadev - solution subscription, integration of third-party content, solution distribution, etc. - the stipulations of which shall prevail in the event of contradiction with these General Conditions. - whose stipulations shall prevail in the event of contradiction with the present General Terms and Conditions.`)}
            </li>
          </ul>
        </div>
      </header>
      <div className="ms-5 me-5 d-grid gap-3">
        <CollapsableSection label={t('Article 1 - Legal notice')} content={<Article1 />} isOpen={isOpen('1')} onClick={() => handleClick('1')} />
        <CollapsableSection label={t('Article 2 - Description and use of the Services')} content={<Article2 />} isOpen={isOpen('2')} onClick={() => handleClick('2')} />
        <CollapsableSection label={t('Article 3 - Use of the Services')} content={<Article3 />} isOpen={isOpen('3')} onClick={() => handleClick('3')} />
        <CollapsableSection label={t('Article 4 - Wanadev\'s obligations and liability')} content={<Article4 />} isOpen={isOpen('4')} onClick={() => handleClick('4')} />
        <CollapsableSection label={t('Article 5 - Intellectual property')} content={<Article5 />} isOpen={isOpen('5')} onClick={() => handleClick('5')} />
        <CollapsableSection label={t('Article 6 - Personal data & GDPR')} content={<Article6 />} isOpen={isOpen('6')} onClick={() => handleClick('6')} />
        <CollapsableSection label={t('Article 7 - Confidentiality & Cookies')} content={<Article7 />} isOpen={isOpen('7')} onClick={() => handleClick('7')} />
        <CollapsableSection label={t('Article 8 - Miscellaneous provisions')} content={<Article8 />} isOpen={isOpen('8')} onClick={() => handleClick('8')} />
      </div>

    </div>
  )
}