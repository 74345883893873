import { raw } from "security/token"
import Logger from "services/logger"

export function show(
  launcher: number,
  player: string,
): Promise<Response | null> {
  const headers: HeadersInit = {
    "x-auth-token": raw() || "",
  }

  return fetch(
    `${process.env.API || ""}/api/v1/launchers/${launcher}/_/players/${player}`,
    {
      method: "GET",
      headers
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "api/v1/launchers/:id/_/players/:id"', {
      launcher,
      player,
      errors
    });
    return Promise.resolve(null);
  })
}