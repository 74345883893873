export function getAsNumber(query: URLSearchParams, key: string): number | null {
  const value = query.get(key)
  return value !== null ? parseInt(value, 10) : null
}

export function getAsNumberOrDefault(query: URLSearchParams, key: string, defaultValue: number): number {
  return getAsNumber(query, key) ?? defaultValue
}

export function getAsDate(query: URLSearchParams, key: string): Date | null {
  const rawValue = query.get(key)
  if (rawValue === null) {
    return null
  }

  const _date = new Date(rawValue)

  // check if date is invalid
  if (isNaN(_date.valueOf())) {
    return null
  }

  return _date
}

export function getAsDateOrDefault(query: URLSearchParams, key: string, defaultValue: Date): Date  {
  return getAsDate(query, key) ?? defaultValue
}