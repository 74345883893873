import React from "react";
import { useTranslation } from 'react-i18next';

export interface ListCardProps {
  content: JSX.Element
  actions?: JSX.Element
  className?: string
}

const ListCard = (props: ListCardProps): JSX.Element => {
  const { t } = useTranslation();
  
  return (
    <div className={`listCardContainer d-flex p-3 pb-4 ${props.className ?? ''}`}>
      <div className={`contentContainer ${props.actions ? 'col-10' : 'col-12'}`}>
        {props.content}
      </div>
      {props.actions != null && (
        <div className="actionContainer col-2">
          <div className="row">
            <span className="col-12 text-center">{t('Actions')}</span>
          </div>
          <div className="row h-100">
            {props.actions}
          </div>
        </div>
      )}
    </div>
  )
}

export default ListCard
