import React, { useMemo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';

import { getPath } from "routes";
import getToken from "../../services/security/token";
import { extraFiltersParametersCount } from "components/Consumption/ConsumptionSummary/filters";
import Sidebar from "./components/Sidebar/Sidebar";
import NotificationManager from "components/NotificationManager/NotificationManager";
import isGranted, { Role } from "security/voter";

import pictoDownload from "images/picto-download.svg";
import SelectLanguage from "./components/SelectLanguage/SelectLanguage";

export default function Authenticated() {
  const { t } = useTranslation();
  const location = useLocation();
  const userToken = getToken()?.context;

  const extraFiltersCount = extraFiltersParametersCount()

  const isConsumptionActive = useMemo(() => {
    return location.pathname === getPath("consumption") || location.pathname === getPath("billing") || location.pathname === getPath("balance")
  }, [location.pathname])

  return (
    <div className="container-fluid">
      <NotificationManager />
      <div className="row authenticated">
        <Sidebar extraFiltersCount={extraFiltersCount} />
        <div className="col">
          <div className="row h-100 flex-column">
            <header className="col authenticated__header">
              <nav
                aria-label="breadcrumb"
                className="breadcrumb authenticated__header__breadcrumb"
              >
                <ol className="breadcrumb">
                  <li
                    className="breadcrumb-item active d-flex"
                    aria-current="page"
                  >
                    <Link
                      className={`${location.pathname === getPath("index") ? "active" : ""
                        }`}
                      to={getPath("index") ?? ''}
                    >
                      <p>
                        <Trans>Dashboard</Trans>
                      </p>
                    </Link>
                  </li>
                  <li
                    className={`breadcrumb-item ${location.pathname === getPath("support") ? "" : "d-none"
                      }`}
                    aria-current="page"
                  >
                    <i className="fas fa-chevron-right" />
                    <Link className="disable-link" to={getPath("support") ?? ''}>
                      <p>
                        <Trans>Support</Trans>
                      </p>
                    </Link>
                  </li>
                  <li
                    className={`breadcrumb-item disable-link ${location.pathname === getPath("games") ? "" : "d-none"
                      }`}
                    aria-current="page"
                  >
                    <i className="fas fa-chevron-right" />
                    <Link className="disable-link" to={getPath("games") ?? ''}>
                      <p>
                        <Trans>Games</Trans>
                      </p>
                    </Link>
                  </li>
                  <li
                    className={`breadcrumb-item disable-link ${location.pathname === getPath("game-sessions") ? "" : "d-none"
                      }`}
                    aria-current="page"
                  >
                    <i className="fas fa-chevron-right" />
                    <Link className="disable-link" to={getPath("game-sessions") ?? ''}>
                      <p>
                        <Trans>Game Sessions</Trans>
                      </p>
                    </Link>
                  </li>
                  {isGranted(new Set([Role.Editor])) || isGranted(new Set([Role.Admin])) && <li
                    className={`breadcrumb-item disable-link ${location.pathname === getPath("tokens") ? "" : "d-none"
                      }`}
                    aria-current="page"
                  >
                    <i className="fas fa-chevron-right" />
                    <Link className="disable-link" to={getPath("tokens") ?? ''}>
                      <p>
                        <Trans>Tokens</Trans>
                      </p>
                    </Link>
                  </li>}
                  <li
                    className={`breadcrumb-item ${location.pathname === getPath("profile") ? "" : "d-none"
                      }`}
                    aria-current="page"
                  >
                    <i className="fas fa-chevron-right" />
                    <Link className="disable-link" to={getPath("profile") ?? ''}>
                      <p>
                        <Trans>Profile</Trans>
                      </p>
                    </Link>
                  </li>
                  {!isGranted(new Set([Role.Admin])) && <li
                    className={`breadcrumb-item ${location.pathname === getPath("company") ? "" : "d-none"
                      }`}
                    aria-current="page"
                  >
                    <i className="fas fa-chevron-right" />
                    <Link className="disable-link" to={getPath("company") ?? ''}>
                      <p>
                        <Trans>Company</Trans>
                      </p>
                    </Link>
                  </li>}
                  <li
                    className={`breadcrumb-item ${location.pathname === getPath("launchers") ? "" : "d-none"
                      }`}
                    aria-current="page"
                  >
                    <i className="fas fa-chevron-right" />
                    <Link className="disable-link" to={getPath("launchers") ?? ''}>
                      <p>
                        <Trans>Launchers</Trans>
                      </p>
                    </Link>
                  </li>
                  <li
                    className={`breadcrumb-item ${isConsumptionActive
                      ? ""
                      : "d-none"
                      }`}
                    aria-current="page"
                  >
                    <i className="fas fa-chevron-right" />
                    <Link className="disable-link" to={getPath("consumption") ?? ''}>
                      <p>
                        <Trans>Consumption</Trans>
                      </p>
                    </Link>
                  </li>
                  <li
                    className={`breadcrumb-item ${location.pathname === getPath("balance")
                      ? ""
                      : "d-none"
                      }`}
                    aria-current="page"
                  >
                    <i className="fas fa-chevron-right" />
                    <Link className="disable-link" to={getPath("balance") ?? ''}>
                      <p>
                        <Trans>Balance</Trans>
                      </p>
                    </Link>
                  </li>
                  <li
                    className={`breadcrumb-item ${location.pathname === getPath("terms-of-use") ? "" : "d-none"
                      }`}
                    aria-current="page"
                  >
                    <i className="fas fa-chevron-right" />
                    <Link className="disable-link" to={getPath("terms-of-use") ?? ''}>
                      <p>
                        <Trans>Terms of use</Trans>
                      </p>
                    </Link>
                  </li>
                </ol>
              </nav>
              <div className="authenticated-launcher button-group m-2 d-flex align-items-center justify-content-center">
                <a
                  className="btn"
                  href={`${process.env.API || ""
                    }/api/v1/softwares/launcher-installers/latest/download/windows`}
                >
                  <img alt={t('Download Logo')} src={pictoDownload} className='icon icon-download me-2' />
                  Octopod:Play
                </a>
              </div>
              <div className="m-2 d-flex align-items-center justify-content-center">
                <SelectLanguage />
              </div>
              <ul className="authenticated__header-profile">
                <li className="li-parent ">
                  {/* eslint-disable */}
                  <a className="user-info">
                    {(() => {
                      if (userToken?.lastname && userToken?.firstname) {
                        if (userToken.firstname.length > 35) {
                          return `${userToken.firstname
                            .substring(0, 35)
                            .charAt(0)
                            .toUpperCase()}${userToken?.firstname
                              ?.substring(0, 35)
                              .slice(1)} ${userToken.lastname
                                .charAt(0)
                                .toUpperCase()}.`;
                        } else {
                          return `${userToken.firstname
                            .charAt(0)
                            .toUpperCase()}${userToken?.firstname?.slice(
                              1
                            )} ${userToken.lastname.charAt(0).toUpperCase()}.`;
                        }
                      } else {
                        return "Menu";
                      }
                    })()}
                    <i className="icon icon-user" />
                  </a>
                  {/* eslint-enable */}
                  <ul className="ul-child">
                    <li className="li-child">
                      <Link className="li-child__item" to={getPath("profile") ?? ''}>
                        <p>
                          <Trans>Profile</Trans>
                        </p>
                      </Link>
                    </li>
                    {!isGranted(new Set([Role.Admin])) && <li className="li-child">
                      <Link className="li-child__item" to={getPath("company") ?? ''}>
                        <p>
                          <Trans>Company</Trans>
                        </p>
                      </Link>
                    </li>}
                    {isGranted(new Set([Role.Editor])) || isGranted(new Set([Role.Admin])) && <li className="li-child">
                      <Link className="li-child__item" to={getPath("tokens") ?? ''}>
                        <p>
                          <Trans>Tokens</Trans>
                        </p>
                      </Link>
                    </li>}
                    <li className="li-child">
                      <Link className="li-child__item" to={getPath("logout") ?? ''}>
                        <p>
                          <Trans>Logout</Trans>
                        </p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </header>

            <main className="col authenticated__main">
              <Outlet />
            </main>

            <footer className="col authenticated__footer">
              © Octopod:Insight <Trans>by</Trans>{" "}
              <a
                className="link--purple"
                href="https://www.wanadev.fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                Wanadev
              </a>{" "}
              <Link to={getPath("terms-of-use") ?? ''}>
                <span className="link--blue">
                  {t('Legal notice & Terms of use')}
                </span>
              </Link>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}
