import { addAttachment, deleteAttachment, listAttachments } from "api/clients/attachment";
import { editClient } from "api/clients/edit";
import getClient from "api/clients/get";
import { editDistributor } from "api/distributors/edit";
import getDistributor from "api/distributors/get";
import { editEditor } from "api/editors/edit";
import getEditor from "api/editors/get";
import Client from "model/client";
import Distributor from "model/distributor";
import Editor from "model/editor";
import getToken from "security/token";
import isGranted, { Role } from "security/voter";
import Logger from "services/logger";

export interface Company {
  id: number;
  name: string;
  address?: string;
  city?: string;
  zipcode?: string;
  country?: string;
  logo?: string;
}

export function processRole(): Role | null {
  let role: Role;

  if (isGranted(new Set([Role.Editor]))) {
    role = Role.Editor
  } else if (isGranted(new Set([Role.Client]))) {
    role = Role.Client
  } else if (isGranted(new Set([Role.Distributor]))) {
    role = Role.Distributor
  } else {
    return null
  }

  return role;
}

export async function getCompany(role: string): Promise<Company | null> {
  const tokenContext = getToken()?.context
  if (tokenContext == null) {
    Logger.error('no context found in token', {
      token: getToken()
    })
    return null
  }
  if (
    tokenContext.editor == null &&
    tokenContext.distributor == null &&
    tokenContext.client == null
  ) {
    Logger.error('no editor, distributor or client id found in token', {
      tokenContext
    })
    return null
  }

  switch (role) {
    case Role.Editor: {
      const editorResponse = await getEditor(tokenContext.editor)
      if (editorResponse?.ok) {
        return await editorResponse.json()
      }
      return null
    }
    case Role.Distributor: {
      const distributorResponse = await getDistributor(tokenContext.distributor)
      if (distributorResponse?.ok) {
        return await distributorResponse.json()
      }
      return null
    }
    case Role.Client: {
      const clientResponse = await getClient(tokenContext.client)
      if (clientResponse?.ok) {
        return await clientResponse.json()
      }
      return null
    }
    default: return null
  }
}

export async function editCompany(role: Role | null, company: Company): Promise<Response | null> {
  switch (role) {
    case Role.Editor:
      return await editEditor(company as Editor)
    case Role.Distributor:
      return await editDistributor(company as Distributor)
    case Role.Client:
      return await editClient(company as Client)
    default: return null
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function addOrReplaceClientAttachment(client: number, swap: Record<string, any> | null): Promise<Response | null> {
  if (swap === null) {
    return null
  }
  const listResponse = await listAttachments(client)
  if (listResponse === null || !listResponse.ok) {
    return listResponse
  } else {
    const json = await listResponse.json()
    if (json.length > 0) {
      for (const attachment of json) {
        if (attachment.type === 'logo') {
          const deleteResponse = await deleteAttachment(client, attachment.id)
          if (deleteResponse === null || !deleteResponse.ok) {
            return deleteResponse
          }
        }
      }
    }
    const addResponse = await addAttachment(client, swap)
    return addResponse
  }
}
