import React, { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { auth } from "api/security";
import { getPath } from "routes";
import { Trans } from 'react-i18next';
import letterImage from "images/letter.svg";
import hiddenPasswordImage from "images/eye-hide-password.svg";
import visiblePasswordImage from "images/eye-show-password.svg";

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    setSubmitted(true);
    e.preventDefault();
    const success: boolean = await auth(login, password);

    if (success === true) {
      navigate(getPath("index") ?? '');
    } else {
      setErrorMessage("Invalid credentials");
    }

    setSubmitted(false);
  };

  return (
    <div className="row w-100 justify-content-center">
      <div className="col-8 login-form">
        <div className="row justify-content-center">
          <form className="col-6 p-0" onSubmit={onSubmit}>
            {submitted === false && errorMessage.length > 0 && (
              <div>{errorMessage}</div>
            )}

            <div className="form-group">
              <div className="input-container">
                <input
                  className="form-control form-control-lg simple-placeholder"
                  onChange={(e) => setLogin(e.target.value)}
                  placeholder={t("Email")}
                  required
                  type="email"
                  value={login}
                  title={t("Please enter an email address")}
                />
                <img
                  className="icon icon-edit"
                  alt={t("Email")}
                  src={letterImage}
                />
                <div className="liner"></div>
              </div>
            </div>

            <div className="form-group">
              <div className="input-container">
                <input
                  className="form-control form-control-lg simple-placeholder"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t("Password")}
                  required
                  type={showPassword ? "text" : "password"}
                  value={password}
                />
                <img
                  className="icon icon-edit cursor-pointer"
                  alt={showPassword ? t("Hide password") : t("Show password")}
                  src={showPassword ? visiblePasswordImage : hiddenPasswordImage}
                  onClick={() => setShowPassword(!showPassword)}
                />
                <div className="liner"></div>
              </div>
            </div>

            <div className="row">
              <div className="col login-form__password-forgot">
                <Link className="link--blue" to={getPath('reset-password') ?? ''}>
                  <Trans>Forgot your password ?</Trans>
                </Link>
              </div>

              <div className="col-auto">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={submitted}
                >
                  {submitted === true && "Loading"}
                  {submitted === false && <Trans>Login</Trans>}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
