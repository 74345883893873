import { raw } from "security/token";

export async function list(
  game: number,
  limit = 50,
  offset = 0,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sort: Record<string, any> | null | undefined
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  const params = new URLSearchParams();

  if (limit && Number.isInteger(limit) === true) {
    params.append("limit", `${limit}`);
  }

  if (offset && Number.isInteger(offset) === true) {
    params.append("offset", `${offset}`);
  }

  if (sort) {
    if (sort.direction) {
      params.append("sort[direction]", sort.direction);
    }

    if (sort.field) {
      params.append("sort[field]", sort.field);
    }
  }

  const response = await fetch(
    `${
      process.env.API || ""
    }/api/v1/games/${game}/releases?${params.toString()}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    errors: body.errors,
  };
}
