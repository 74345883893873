import { raw } from "security/token"
import Logger from "services/logger"

export async function config(
  launcher: number,
  sessionId: string,
): Promise<Response | null> {
  const headers: HeadersInit = {
    "x-auth-token": raw() || "",
  }

  return fetch(
    `${process.env.API || ""}/api/v1/launchers/${launcher}/_/sessions/${sessionId}/configs`,
    {
      method: "GET",
      headers
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "api/v1/launchers/:id/_/sessions/:id/configs"', {
      errors,
      launcher,
      sessionId,
    });
    return Promise.resolve(null);
  })
}
