import React from "react";
import { useLocation } from "react-router-dom";

const NotFound = () => {
  const location = useLocation();

  return <p>Page not found for {location.pathname}!</p>;
};

export default NotFound;
