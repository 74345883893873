import React from "react";
import { Trans, useTranslation } from 'react-i18next';

export default function Article7() {
  const { t } = useTranslation()

  return (
    <div className="text-primary">
      <ul className="list-unstyled d-grid gap-3">
        <li>
          {t(`Wanadev may collect and process personal information from Users of the Services in order to offer an improved web service and personalized benefits.`)}
        </li>
        <li>
          <span className="fw-bold color-purple">{t(`Definition(s).`)} </span>
          {t(`Cookies are files that websites place on the hard disk of the User's computer when they first visit the site. Their function is to maintain a link between the site and the computer medium used. The site will be able to retrieve the information stored in the cookie during previous visits. Cookies make browsing more efficient by accessing memory functions. Cookies are used to store preferences, register products and services and personalize pages.`)}
        </li>
        <li>
          <span className="fw-bold color-purple">{t(`Types & purposes.`)} </span>
          {t(`The cookies used by Wanadev are:`)}
        </li>
        <div className="d-flex flex-column align-items-center">
          <ol type="a">
            <li>
              <Trans
                i18nKey="the <0>strictly necessary cookies</0> enabling the User to move around the website(s) supporting the Services and to use its basic functionalities;"
                components={[
                  <span className="fw-bold" />
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="the enhanced <0>functionality cookies</0> used to recognize Internet users when they return to the website and to count the number of visitors as well as to collect information concerning the use of the Services;"
                components={[
                  <span className="fw-bold" />
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="the <0>analytical cookies</0> enabling the collection of data relating to your use of the website in order to improve its performance and design."
                components={[
                  <span className="fw-bold" />
                ]}
              />
            </li>
          </ol>
        </div>
        <li>
          <span className="fw-bold color-purple">{t(`Exercising your rights.`)} </span>
          {t(`Each Internet browser offers ways of limiting and deleting cookies. For more information on how to manage cookies, visit the appropriate link below, or check the settings of your browser if it is not on this list: Firefox, Google Chrome, Safari.`)}
        </li>
        <li>
          {t(`Any other request relating to the exercise of the User's rights to access, modify, oppose or delete personal data may be made directly to Wanadev at the following address: contact@wanadev.fr`)}
        </li>
      </ul>
    </div>
  )
}