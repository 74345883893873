import React, { createContext, useContext, useReducer, ReactNode } from "react";
import type { Context } from "react";

import Logger from "../logger";

type State = {
  opened: boolean;
};

const initialState: State = {
  opened: false,
};

type Action = {
  type: "close" | "open" | "toggle";
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "close":
      return { ...state, opened: false };

    case "open":
      return { ...state, opened: true };

    case "toggle":
      return { ...state, opened: !state.opened };

    default:
      return state;
  }
};

const ModalContext: Context<[State, (action: Action) => void]> = createContext([
    initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (action: Action) => Logger.error("Context not correctly initialized"),
  ]);
export const ModalConsumer = ModalContext.Consumer;
export const ModalConsumerHook = () => useContext(ModalContext);

interface Props {
  children?: ReactNode;
}

export const ModalProvider = ({ children }: Props) => (
  <ModalContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </ModalContext.Provider>
);
