import React, { useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { Trans } from 'react-i18next';
import { FormConsumerHook } from "services/stores/formStore";
import upload from "services/helper/upload";

export default function FilesUpload() {
  const { t } = useTranslation();
  const [{ data }, dispatch] = FormConsumerHook();

  function removeFile(file: File) {
    dispatch({
      type: "setValue",
      field: "attachments",
      value: data.attachments.filter((attachment: Record<string, unknown>) => attachment.file !== file),
    });
  }

  const setError = useCallback(
    (file: File) => {
      const attachments = data.attachments.map((attachment: Record<string, unknown>) => {
        if (attachment.file === file) {
          attachment.error = true;
          attachment.uploading = false;
        }

        return attachment;
      });
      dispatch({
        type: "setValue",
        field: "attachments",
        value: attachments,
      });
    },
    [data.attachments, dispatch]
  );
  const setUploadProgression = useCallback(
    (file: File, progression: number) => {
      const attachments = data.attachments.map((attachment: Record<string, unknown>) => {
        if (attachment.file === file) {
          attachment.uploading = true;
          attachment.progression = Number(progression).toFixed(0);
        }

        return attachment;
      });
      dispatch({
        type: "setValue",
        field: "attachments",
        value: attachments,
      });
    },
    [data.attachments, dispatch]
  );
  const setSwap = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (file: File, swap: Record<string, unknown>) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const attachments = data.attachments.map((attachment: Record<string, unknown>) => {
        if (attachment.file === file) {
          attachment.error = false;
          attachment.swap = swap;
          attachment.uploading = false;
        }

        return attachment;
      });
      dispatch({
        type: "setValue",
        field: "attachments",
        value: attachments,
      });
    },
    [data.attachments, dispatch]
  );
  useEffect(() => {
    let toUpdate = false;
    const attachments = (data?.attachments || []).map((attachment: Record<string, unknown>) => {
      if (
        attachment.uploading === false &&
        attachment.swap === null &&
        attachment.error === false &&
        attachment.file instanceof File
      ) {
        const typedFile: File = attachment.file;
        upload(typedFile, (uploaded) =>
          setUploadProgression(
            typedFile,
            (uploaded / typedFile.size) * 100
          )
        )
          .then((swap) => setSwap(typedFile, swap))
          .catch(() => setError(typedFile));
        attachment.uploading = true;
        attachment.progression = 0;
        toUpdate = true;
      }

      return attachment;
    });

    // @ts-expect-error TS2367
    if (toUpdate === true) {
      dispatch({
        type: "setValue",
        field: "attachments",
        value: attachments,
      });
    }
  }, [data, dispatch, setError, setSwap, setUploadProgression]);
  return (data?.attachments || []).map(
    ({ error, file, uploading, progression, swap }: Record<string, unknown>, index: number) => {
      if (file instanceof File && uploading instanceof Boolean) {
        <div
          key={index}
          className={`uploaded-files__file ${swap !== null ? "isLoaded" : ""} ${error ? "isError" : ""
            }`}
        >
          <p
            title={swap !== null ? file.name : undefined}
            className="uploaded-files__file-name"
          >
            {file.name}
          </p>

          <p>
            {(() => {
              if (swap !== null) {
                if (file.size < 1024) {
                  return (
                    <Trans
                      i18nKey="{{size}}B"
                      values={{
                        size: Number(file.size).toFixed(0),
                      }}
                    />
                  );
                } else if (file.size / 1024 < 1024) {
                  return (
                    <Trans
                      i18nKey="{{size}}KB"
                      values={{
                        size: Number(file.size / 1024).toFixed(1),
                      }}
                    />
                  );
                } else if (file.size / (1024 * 1024) < 1024) {
                  return (
                    <Trans
                      i18nKey="{{size}}MB"
                      values={{
                        size: Number(file.size / 1024 / 1024).toFixed(1),
                      }}
                    />
                  );
                }

                return (
                  <Trans
                    i18nKey="{{size}}GB"
                    values={{
                      size: Number(file.size / 1024 / 1024 / 1024).toFixed(2),
                    }}
                  />
                );
              } else if (uploading) {
                return `${progression}%`;
              }
            })()}
          </p>

          {uploading && <i className="icon icon-loader icon--loading" />}

          {error != null && (
            <i
              title={t("Remove")}
              className="uploaded-files__file-action fas fa-times pointer"
              onClick={() => removeFile(file)}
            />
          )}

          {swap != null && (
            <div className="uploaded-files__file-action">
              <i
                title={t("Remove file")}
                className="icon icon-trash icon--delete"
                onClick={() => removeFile(file)}
              />
              <i className="fas fa-check icon--valid" />
            </div>
          )}
        </div>
      }
    }
  );
}
