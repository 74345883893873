import { raw } from "security/token";

export async function deleteAttachment(
  game: number,
  attachment: number
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  const response = await fetch(
    `${process.env.API || ""}/api/v1/games/${game}/attachments/${attachment}`,
    {
      method: "DELETE",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    error: body.message,
  };
}
