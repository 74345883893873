import React from "react";
import { useTranslation } from 'react-i18next';

export default function Article6() {
  const { t } = useTranslation()

  return (
    <div className="text-primary">
      <ul className="list-unstyled d-grid gap-3">
        <li>
          {t(`The General Terms and Conditions are subject to the provisions of European Regulation 2016/679 of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data, as well as those of Law No. 78-17 of 6 January 1978 on information technology, files and freedoms and any other applicable regulations in this area that may subsequently supplement or replace them.`)}
        </li>
        <li>
          {t(`Wanadev needs to use the User's data to manage his/her account. Without such information, it will not be able to provide useful access to the Services. It may also use this information to send the User its best promotional offers, for statistical purposes and to combat fraud, for its legitimate business development interests, for the technical improvement of its systems and its knowledge of the User and, finally, where applicable with the User's consent, to personalize its services, in particular by means of advertising.`)}
        </li>
        <li>
          {t(`The User's data is kept for the duration of use of the services by the User and, beyond that, for a period of three years for commercial purposes, as well as for purposes of proof and accounting obligations, for a period not exceeding the applicable legal limitation periods.`)}
        </li>
        <li>
          {t(`For processing carried out by and/or on behalf of Wanadev, the User has, if necessary after providing valid identification, a right of access, where appropriate portability, modification, limitation and deletion of data concerning him and a right to object, possibly subject to legitimate and compelling reasons, to the processing of such data. They may also define directives concerning the fate of their personal data in the event of their death. These rights may be exercised directly with Wanadev by e-mail at contact@wanadev.fr or by post at the following address: Wanadev, 31 rue de Grenette 69002 Lyon.`)}
        </li>
        <li>
          {t(`In accordance with legal provisions, the User has the right to lodge a complaint with a supervisory authority - the CNIL.`)}
        </li>
        <li>
          <span className="fw-bold color-purple">{t(`Personal data provided.`)} </span>
          {t(`For the purposes of this article, "personal data" means any information relating to an identified or identifiable natural person, within the meaning of the applicable legislation in force. For all intents and purposes, an "identifiable natural person" is one who can be identified, directly or indirectly, in particular by reference to an identifier, such as a name, an identification number, location data, an online identifier, or to one or more factors specific to his or her physical, physiological, genetic, mental, economic, cultural or social identity. The categories of data concerned are as follows:`)}
        </li>
        <div className="d-flex flex-column align-items-center">
          <ul>
            <li>
              <span className="fw-bold fst-italic">{t(`Identity data`)} </span>
              {t(`: this information may include surname, first name, telephone number, email address or postal address;`)}
            </li>
            <li>
              <span className="fw-bold fst-italic">{t(`Technical and browsing data`)} </span>
              {t(`: Wanadev is likely to collect several types of data relating to the technical methods by which the User accesses and uses the Services and Internet sites, partner sites or third-party sites whose data is imported into the Site. This data may be collected using cookies and other similar techniques.`)}
            </li>
          </ul>
        </div>
        <li>
          <span className="fw-bold color-purple">{t(`Use of Users' personal data.`)} </span>
          {t(`Wanadev may use Users' personal data in defined cases:`)}
        </li>
        <div className="d-flex flex-column align-items-center">
          <ul>
            <li>
              <span className="fw-bold fst-italic">{t(`Operation & Optimization of Services`)} </span>
              {t(`: the personal data collected is necessary for the operation of the services offered as well as for the development and improvement of Wanadev's services;`)}
            </li>
            <li>
              <span className="fw-bold fst-italic">{t(`Communication & Personalization`)} </span>
              {t(`: subject to the required consent, Wanadev may use certain data in order to offer, in particular by means of advertising, personalized services and offers, promotions or any other commercial information relating to the services or services of partners;`)}
            </li>
            <li>
              <span className="fw-bold fst-italic">{t(`Compliance with current legislation.`)}</span>
            </li>
          </ul>
        </div>
        <li>
          <span className="fw-bold color-purple">{t(`Sharing of personal data.`)} </span>
          {t(`Wanadev may share Users' personal data with commercial partners or other companies with which Wanadev is affiliated (parent companies, sister companies or daughter companies), as part of the operation of the Services or in particular for logistical purposes.`)}
        </li>
        <li>
          {t(`Similarly, the User's data may be communicated in response to a specific request made by a competent administrative or judicial authority, and more generally in all situations where this is required by law, regulation or an administrative or judicial decision;`)}
        </li>
        <li>
          {t(`More generally, this data may be shared with any other third party after the User has been informed and subject to the absence of opposition or prior consent in accordance with the applicable rules.`)}
        </li>
        <li>
          <span className="fw-bold color-purple">{t(`Retention period for personal data.`)} </span>
          {t(`Personal data will be kept in active files or systems only for as long as is necessary to fulfil the purposes for which they were collected in accordance with the applicable rules, i.e.:`)}
        </li>
        <div className="d-flex flex-column align-items-center">
          <ul>
            <li>
              {t(`In principle, for all data;`)}
            </li>
            <li>
              {t(`By exception, for the purposes of commercial prospecting by electronic means, Wanadev may keep the email address, as well as the first and last names for up to three years after the complete cessation of use of Wanadev's services, subject to the exercise of a right of opposition.`)}
            </li>
          </ul>
        </div>
      </ul>
    </div>
  )
}