import { stringify } from "qs";

import { raw, setToken } from "security/token";
import type User from "model/user";
import type Password from "model/password";

export async function edit(
  profile: User
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any> | boolean> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: Record<string, any> = {
    firstname: profile.firstname,
    lastname: profile.lastname,
    phone: profile.phone,
    emailLanguage: profile.emailLanguage,
  };

  if (profile.firstname !== null) {
    data.firstname = profile.firstname;
  }

  if (profile.lastname !== null) {
    data.lastname = profile.lastname;
  }

  if (profile.phone !== null) {
    data.phone = profile.phone;
  }

  if (profile.emailLanguage !== null) {
    data.emailLanguage = profile.emailLanguage;
  }

  const response = await fetch(
    `${process.env.API || ""}/api/v1/users/${profile.id}`,
    {
      method: "PUT",
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
      body: stringify(data),
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    const refreshToken = await fetch(
      `${process.env.API || ""}/api/v1/refresh`,
      {
        method: "POST",
        headers: {
          "x-auth-token": raw() || "",
        },
      }
    );

    if (response.status === 200) {
      const token = await refreshToken.json();

      if (token.token === undefined) {
        console.error("No token returned");
        return false;
      }

      setToken(token.token);
    }

    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    errors: body.errors,
  };
}

export async function changePassword(
  form: Password
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: Record<string, any> = {
    currentPassword: form.currentPassword,
    newPassword: form.newPassword,
  };
  const response = await fetch(
    `${process.env.API || ""}/api/v1/users/${form.profile}/change-password`,
    {
      method: "PUT",
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
      body: stringify(data),
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    errors: body.errors,
  };
}
