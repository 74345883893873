import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl, getCountryCallingCode } from "react-phone-number-input";

import ControlledInput from "../ControlledInput/ControlledInput";
import PhoneCountrySelect from "../PhoneCountrySelect/PhoneCountrySelect";

export interface PhoneNumberInputProps {
  phoneNumber: string
  onChange: (phoneNumber: string) => void
}

export default function PhoneNumberInput(props: PhoneNumberInputProps) {
  const { t } = useTranslation();
  const { phoneNumber, onChange } = props
  const [countryCode, setCountryCode] = useState<string | undefined>()

  const updatePhoneNumberCountryCode = (code: string | undefined) => {
    if (code == undefined) {
      return
    }
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const countryCallingCode = getCountryCallingCode(code as any)
    let newValue = `+${countryCallingCode}`
    if (phoneNumber.includes(`+${countryCallingCode}`) || phoneNumber.includes(`00${countryCallingCode}`)) {
      newValue = phoneNumber
    }
    if (phoneNumber.substring(0, 2) !== '00' && phoneNumber[0] !== '+') {
      if (phoneNumber[0] === '0' && phoneNumber[1] !== '0') {
        newValue += phoneNumber.substring(1)
      } else {
        newValue += phoneNumber
      }
    }
    setCountryCode(code)
    onChange(newValue)
  }

  const formatPhoneNumber = ((phone: string) => {
    const formattedPhone = formatPhoneNumberIntl(phone)
    if (formattedPhone.length > 0 && formattedPhone !== phone) {
      onChange(formattedPhone)
    } else {
      return phone
    }
  })

  return (
    <>
      <PhoneCountrySelect
        defaultCountry={countryCode ?? ''}
        phoneNumber={phoneNumber}
        onChange={updatePhoneNumberCountryCode}
      />
      <ControlledInput
        className="form-control ps-1"
        placeholder={t("Phone")}
        required
        type="text"
        value={phoneNumber}
        onChange={(e) => onChange(e.currentTarget.value)}
        onBlur={(e) => formatPhoneNumber(e.target.value)}
      />
    </>
  )
}