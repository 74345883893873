import { defaultChunkSize, create, uploadPart, compile } from "api/swap";

function uploadChunk(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  swap: Record<string, any>,
  part: number,
  chunks: Array<Uint8Array>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  return uploadPart(
    swap,
    part,
    new Blob(chunks, {
      type: "application/octet-stream",
    })
  );
}

export default async function upload(
  file: File,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  progress?: (...args: Array<any>) => any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  let chunkPart = 0;
  let maxChunkSize: number = defaultChunkSize;
  const result = await create(file.name);

  if (result.success === false) {
    console.error("Could not create swap");
    throw new Error("Could not upload file");
  }

  const { data: swap } = result;
  let buffers: Array<Uint8Array> = [];
  let buffersSizeCumulated = 0;
  let bytesUploaded = 0;

  const flush = async (force = false): Promise<boolean> => {
    // eslint-disable-next-line no-unmodified-loop-condition
    while (buffersSizeCumulated >= maxChunkSize || force) {
      if (buffers.length === 0) {
        break;
      }

      const size: number = buffersSizeCumulated;
      let _buffers: Array<Uint8Array> = [];

      if (size >= maxChunkSize) {
        let cumulatedSizes = 0;

        for (let i = 0; i < buffers.length; i++) {
          if (cumulatedSizes + buffers[i].length < maxChunkSize) {
            cumulatedSizes += buffers[i].length;
            continue;
          }

          const _buffer: Uint8Array = buffers[i];
          buffers.splice(
            i,
            0,
            new Uint8Array(_buffer.slice(0, maxChunkSize - cumulatedSizes))
          );
          buffers[i + 1] = new Uint8Array(
            _buffer.slice(maxChunkSize - cumulatedSizes)
          );
          _buffers = buffers.slice(0, i + 1);
          break;
        }
      } else {
        _buffers = buffers;
      }

      const { success } = await uploadChunk(swap, chunkPart, _buffers);

      if (success === true) {
        buffers = buffers.slice(_buffers.length);
        buffersSizeCumulated = 0;
        // eslint-disable-next-line no-loop-func
        buffers.forEach((buffer) => {
          buffersSizeCumulated += buffer.length;
        });
        chunkPart += 1;

        // eslint-disable-next-line no-loop-func, no-return-assign
        _buffers.forEach((buffer) => (bytesUploaded += buffer.length));

        if (progress) {
          progress(bytesUploaded);
        }
      } else {
        maxChunkSize = size / 2;

        // If maxChunkSize < 1 MB
        if (maxChunkSize < 1024 * 1024) {
          return false;
        }
      }
    }

    return true;
  };

  const reader = file.stream().getReader();
  return reader
    .read()
    .then(async function process({ done, value }): Promise<void> {
      const success = await flush(done);

      if (success === false) {
        console.error("Could not send chunk to API");
        throw new Error("Could not upload file");
      } else if (done) {
        return;
      }

      buffers.push(value);
      buffersSizeCumulated += value.length;
      return reader.read().then(process);
    })
    .then(async () => {
      const { success } = await compile(swap);

      if (success === false) {
        console.error("Could not compile swap");
        throw new Error("Could not upload file");
      }

      return swap;
    });
}
