import React, { createContext, useContext, useReducer } from "react";
import type { Context, PropsWithChildren } from "react";

import Logger from "../logger";
import { BalanceSummary } from "model/balance";
import { getSummary } from "api/balance/summaries";

type State = {
  summaries: BalanceSummary[] | null;
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  summaries: null,
  loading: false,
  error: null,
};

const reducer = (state: State, actionUnknown: unknown): State => {
  if (actionUnknown === null || typeof actionUnknown !== "object") {
    return state;
  }

  const action: Record<string, unknown> = actionUnknown as Record<string, unknown>;

  switch (action.type) {
    case "FETCHING":
      return {
        summaries: null,
        loading: true,
        error: null,
      };

    case "FETCHING_SUCCESS":
      if (Array.isArray(action.summaries) || action.summaries === null) {
        return {
          summaries: action.summaries,
          loading: false,
          error: null,
        };
      }
      return state;

    case "FETCHING_ERROR":
      if (typeof action.error !== "string") {
        return state;
      }
      return {
        summaries: [],
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

const BalanceSummariesContext: Context<[State, (...args: Array<unknown>) => unknown]> =
  createContext([
    initialState,
    () => Logger.error("Context not correctly initialized"),
  ]);

export const BalanceSummariesConsumer = BalanceSummariesContext.Consumer;
export const BalanceSummariesConsumerHook = () => useContext(BalanceSummariesContext);
export const BalanceSummariesProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <BalanceSummariesContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </BalanceSummariesContext.Provider>
  );
};

export const fetchBalanceSummaries = async (
  dispatch: (...args: Array<unknown>) => unknown,
  locations: number[]
) => {
  dispatch({
    type: "FETCHING",
  });
  const summaries: BalanceSummary[] = [];

  for (const l of locations) {
    const res = await getSummary(l);
    if (res?.ok === true) {
      const summary = await res.json();
      summaries.push({ ...summary, location: l });
    } else {
      dispatch({
        type: "FETCHING_ERROR",
        error: 'Error fetching balance summaries',
      });
      return
    }
  }
  dispatch({
    type: "FETCHING_SUCCESS",
    summaries
  });
};
