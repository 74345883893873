import React from "react";
import { useTranslation } from 'react-i18next';

export default function Article4() {
  const { t } = useTranslation();

  return (
    <div className="text-primary">
      <ul className="list-unstyled d-grid gap-3">
        <li>
          {t(`Wanadev undertakes, as part of an obligation of means, to make the Services available. Although Wanadev endeavors to keep the Services accessible, Wanadev may nevertheless find itself obliged to temporarily suspend partial or total access to the Services, in particular for technical maintenance reasons, without this entailing any liability on the part of Wanadev.`)}
        </li>
        <li>
          {t(`Furthermore, it is specified that the Internet network and the computer and telecommunications systems used by the User to access and use the Services are not error-free and interruptions and breakdowns may occasionally occur. Wanadev cannot provide any guarantee in this respect and cannot therefore be held responsible for any damage inherent in the said use of the Internet network and computer and telecommunications systems, in particular, but without this list being limitative:`)}
        </li>
        <div className="d-flex flex-column align-items-center">
          <ol type="a">
            <li>{t(`poor transmission and/or reception of any data and/or information on the Internet;`)}</li>
            <li>{t(`external intrusion;`)}</li>
            <li>{t(`failure of any reception equipment or communication lines;`)}</li>
            <li>{t(`any other malfunction of the Internet network that prevents the Services from functioning properly and/or running smoothly.`)}</li>
          </ol>
        </div>
        <li>
          {t(`Furthermore, the User acknowledges and accepts that Wanadev may not be held liable for any consequences in the performance of the Services resulting from erroneous, approximate or incomplete information communicated by the User.`)}
        </li>
        <li>
          {t(`Also, the information transmitted when consulting or using the Services is provided for information purposes only and cannot be guaranteed to be accurate or up to date. The User acknowledges that Wanadev offers no guarantee and is under no obligation to verify the content made available by its partners and/or suppliers and disseminated via the website(s).`)}
        </li>
        <li>
          {t(`In the event of a breach by the User of these General Conditions, Wanadev may terminate the User's access to the Services, notwithstanding any damages to which Wanadev may be entitled.`)}
        </li>
      </ul>
    </div>
  )
}