import React, { useState } from "react";
import { useLocation, useParams, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";

import ApiImage from "components/ApiImage/ApiImage";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Loader from "components/Loader/Loader";
import { FormProvider } from "stores/formStore";
import {
  GameReleasesProvider,
  GameReleasesConsumer,
} from "stores/gameReleasesStore";
import { GamesConsumerHook, fetchGames } from "stores/gamesStore";
import showRelease from "api/release/show";
import { list } from "api/release/list";
import MoreReleasesModal from "./MoreReleasesModal";
import PreventFormUnsaved from "./components/PreventFormUnsaved/PreventFormUnsaved";
import GameShowHeader from "./components/GameShowHeader/GameShowHeader";
import GameSaveModal from "./components/GameSaveModal/GameSaveModal";
import GameTabs from "./components/GameTabs/GameTabs";
import Release from "model/release";

interface Releases {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  releases: Release[] | null;
  loading: boolean;
  error: null;
}

export default function GameShow() {
  const { t } = useTranslation()
  const [{ games, ...gamesMetadata }, dispatchGames] = GamesConsumerHook();
  const [{ releases, ...releasesMetadata }, dispatchReleases] = useState<Releases>({
    releases: null,
    loading: false,
    error: null,
  });
  const [moreReleasesModalOpened, setMoreReleasesModalOpened] = useState(false);
  const location = useLocation();

  const params = useParams();
  const query = new URLSearchParams(location.search);

  if (
    gamesMetadata.loading ||
    (games === null && gamesMetadata.error === null)
  ) {
    if (gamesMetadata.loading === false) {
      fetchGames(dispatchGames, history);
    }

    return <Loader message={t("Fetching game data")} />;
  } else if (gamesMetadata.error !== null) {
    return <ErrorMessage message={gamesMetadata.error} />;
  }

  const game = (games || []).find(({ id }) => `${id}` === params.id);

  if (game === undefined) {
    return <ErrorMessage message={t("No game found")} />;
  }

  if (
    releasesMetadata.loading ||
    (releases === null && releasesMetadata.error === null)
  ) {
    if (releasesMetadata.loading === false) {
      dispatchReleases({
        releases,
        ...releasesMetadata,
        loading: true,
      });
      list(game.id, 5, 0, {
        direction: "DESC",
        field: "createdAt",
      }).then(({ data, errors }) =>
        dispatchReleases({
          releases: data,
          loading: false,
          error: errors || null,
        })
      );
    }

    return <Loader message={t("Fetching releases data")} />;
  } else if (releasesMetadata.error !== null) {
    return <ErrorMessage message={releasesMetadata.error} />;
  }

  let _release : Release | null = null;

  if (query.get("release") !== null) {
    const id = parseInt(query.get("release")!, 10);
    _release = (releases || []).find((r) => r?.id === id) ?? null;
    if (_release == undefined) {
      showRelease(id).then(({ data, errors }) => {
        if (data !== null && data !== undefined) {
          dispatchReleases({
            releases: [...(releases || []), data],
            loading: false,
            error: errors || null,
          });
        } else {
          return <ErrorMessage message="Could not fetch the selected release. Please try again later." />
        }
      });
      return <Loader message={t("Fetching releases data")} />;
    }
  }

  if (_release === null || _release === undefined) {
    let lastCreatedAt: Moment | null = null;
    (releases || []).forEach((r) => {
      const createdAt = moment(r.createdAt, "YYYY-MM-DDTHH:mm:ssZZ");

      if (lastCreatedAt === null || createdAt.isAfter(lastCreatedAt)) {
        _release = r;
        lastCreatedAt = createdAt;
      }
    });
  }

  return (
    <GameReleasesProvider
      game={{ ...game, attachments: [] }}
      release={_release?.id}
      releases={releases ?? undefined}
    >
      <FormProvider>
        <PreventFormUnsaved />

        <GameReleasesConsumer>
          {([{ release }, dispatch]) => {
            if (release !== _release?.id) {
              dispatch({
                type: "setCurrentRelease",
                release: _release?.id,
              });
            }

            return null
          }}
        </GameReleasesConsumer>

        <div className="game-show">
          <div className="game-show-header">
            <div className="game-show-header__information-container">
              <div className="thumbnail">
                <ApiImage src={game.thumbnail} alt={game.name} />
              </div>
              <GameShowHeader game={game} />
            </div>

            <GameSaveModal />
          </div>

          <div className="game-show__main-bloc">
            <GameTabs release={_release} releases={releases} game={game} setMoreReleasesModalOpened={setMoreReleasesModalOpened} />

            <div className="game-show__main-bloc-container">
              <Outlet />

              {moreReleasesModalOpened === true && (
                <MoreReleasesModal
                  gameId={game.id}
                  closeModal={() => setMoreReleasesModalOpened(false)}
                />
              )}
            </div>
          </div>
        </div>
      </FormProvider>
    </GameReleasesProvider>
  );
}
