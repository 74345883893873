import { getAsDate } from "services/helper/query_parameters";
import { isCurrentMonth } from "services/helper/date";

export enum ConsumptionFilters {
  Game = 'consumptionFilters[game]',
  Location = 'consumptionFilters[location]',
  PeriodFrom = 'consumptionFilters[from]',
  PeriodTo = 'consumptionFilters[to]'
}

export const ALL_CONSUMPTION_FILTERS: string[] = Object.values(ConsumptionFilters)
const EXTRA_FILTERS = [ConsumptionFilters.Game, ConsumptionFilters.Location]


export function keepFiltersParametersFromLocation(): URLSearchParams {
  const query = new URLSearchParams(location.search)
  for (const key of query.keys()) {
    if (!ALL_CONSUMPTION_FILTERS.includes(key)) {
      query.delete(key)
    }
  }

  return query
}

/**
 * Counts the number of extra filters parameters in the current location.
 */
export function extraFiltersParametersCount(): number {
  let count = 0
  const query = new URLSearchParams(location.search)
  for (const key of query.keys()) {
    if (EXTRA_FILTERS.includes(key as ConsumptionFilters)) {
      count++
    }
  }

  if (query.has(ConsumptionFilters.PeriodFrom) || query.has(ConsumptionFilters.PeriodTo)) {
    if (!isCurrentMonth(
      getAsDate(query, ConsumptionFilters.PeriodFrom),
      getAsDate(query, ConsumptionFilters.PeriodTo))
    ) {
      count++
    }
  }

  return count
}