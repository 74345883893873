import { PropsWithChildren, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import moment from "moment"
import { lastDayOfMonth, startOfMonth } from "date-fns"

import { ALL_CONSUMPTION_FILTERS, ConsumptionFilters } from "components/Consumption/ConsumptionSummary/filters"
import { ConsumptionAction, ConsumptionConsumerHook } from "stores/consumptionStore"
import { compute } from "components/Consumption/ConsumptionWrapper/compute"
import usePrevious from "services/hooks/usePrevious"

/**
 * Manage ConsumptionStore according to filters given in URL.
 *
 * It must be the only component that writes into context.
 * Others components like ConsumptionSummary must read only / consume the store.
 */
export default function ConsumptionWrapper({ children }: PropsWithChildren<unknown>) {
  const [searchParams, setSearchParams] = useSearchParams()
  const prevSearchParams = usePrevious(searchParams)
  const [, dispatch] = ConsumptionConsumerHook()

  const setDefaultParamsIfNeeded = () => {
    if (!searchParams.has(ConsumptionFilters.PeriodFrom)) {
      setSearchParams(prev => {
        prev.set(ConsumptionFilters.PeriodFrom, moment(startOfMonth(new Date())).format("YYYY-MM-DD"))
        return prev
      })
    }

    if (!searchParams.has(ConsumptionFilters.PeriodTo)) {
      setSearchParams(prev => {
        prev.set(ConsumptionFilters.PeriodTo, moment(lastDayOfMonth(new Date())).format("YYYY-MM-DD"))
        return prev
      })
    }
  }

  const hasToRecompute = (): boolean => {
    if (!prevSearchParams) {
      return true
    }

    for (const key of ALL_CONSUMPTION_FILTERS) {
      if (searchParams.get(key) !== prevSearchParams.get(key)) {
        return true
      }
    }

    return false
  }

  useEffect(() => {
    setDefaultParamsIfNeeded()

    if (!hasToRecompute()) {
      return
    }

    dispatch({ type: ConsumptionAction.FETCHING })

    const params = {
      from: searchParams.get(ConsumptionFilters.PeriodFrom),
      to: searchParams.get(ConsumptionFilters.PeriodTo),
      game: searchParams.get(ConsumptionFilters.Game),
      location: searchParams.get(ConsumptionFilters.Location)
    }

    if (!params.from || !params.to) {
      dispatch({ type: ConsumptionAction.FETCHING_ERROR, error: "missing date filters" })
      return
    }

    compute(
      new Date(params.from),
      new Date(params.to),
      params.game ? parseInt(params.game, 10) : null,
      params.location ? parseInt(params.location, 10) : null
    )
      .then(res => dispatch({ type: ConsumptionAction.FETCHING_SUCCESS, data: res }))
      .catch(err => dispatch({ type: ConsumptionAction.FETCHING_ERROR, error: err }))
  }, [searchParams])

  return children
}