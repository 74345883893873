import React from 'react'
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import Modal from 'components/Modal/Modal';
import { FormConsumer, FormProvider } from 'services/stores/formStore';
import { ModalConsumer, ModalProvider } from 'services/stores/modalStore';
import { changePassword } from 'api/launchers/edit';
import Launcher from 'model/launcher';

export interface LaunchersPasswordModalProps {
  launcher: Launcher,
}

const LaunchersPasswordModal = (props: LaunchersPasswordModalProps) => {
  const { t } = useTranslation();
  const { launcher } = props;

  const updatePassword = async (password: string) => {
    const { success, errors } = await changePassword(launcher.id, password);
    return {
      success,
      errors,
    };
  };

  return (
    <ModalProvider>
      <FormProvider>
        <ModalConsumer>
          {([{ opened }, dispatch]) => (
            <div className='d-flex justify-content-center align-items-center'>
              <button
                title={t("Change Password")}
                className="btn btn-primary launchers__button d-flex justify-content-center align-items-center"
                onClick={() =>
                  dispatch({
                    type: opened ? "close" : "open",
                  })
                }
              >
                <img className="p-2" alt="launcher password icon" src={require("../../../../../static/images/picto-key.svg")} />
              </button>

              {(() => {
                if (opened === true) {
                  const formId = `edit-file-${launcher.id}`;
                  return (
                    <FormConsumer>
                      {([
                        { data, errors },
                        formDispatch,
                      ]) => (
                        <Modal
                          header={{
                            title: t("Change password"),
                            icon: "icon icon-lock",
                          }}
                          footer={{
                            btnCloseClass:
                              "btn-danger me-3",
                            btnCloseLabel: t("Cancel"),
                            btnValidClass: "btn-primary",
                            btnValidForm: formId,
                            btnValidLabel:
                              t("Save new password"),
                          }}
                          onClose={() =>
                            dispatch({
                              type: "close",
                            })
                          }
                        >
                          {errors
                            .filter(
                              (error) =>
                                error?.name === undefined
                            )
                            .map((error) => (
                              <div className="alert alert-danger">
                                {error}
                              </div>
                            ))}

                          <form>
                            <div className="form-row  pb-5 mt-3">
                              <div className="col-12">
                                <div className="input-container">
                                  <input
                                    className="form-control"
                                    placeholder={t(
                                      "Min 8 chars, 2 numbers"
                                    )}
                                    required
                                    type="password"
                                    defaultValue={
                                      data?.newPassword ||
                                      null
                                    }
                                    onChange={(e) =>
                                      formDispatch({
                                        type: "setValue",
                                        field:
                                          "newPassword",
                                        value:
                                          e.target.value,
                                      })
                                    }
                                  />
                                  <label className="label-wize">
                                    <Trans>New password</Trans>
                                  </label>
                                  {errors
                                    .filter(
                                      (error) =>
                                        error?.newPassword ===
                                        "newPassword"
                                    )
                                    .map(({ reason }) => (
                                      <small className="form-text text-danger">
                                        {reason}
                                      </small>
                                    ))}
                                  <i className="icon icon-edit"></i>
                                  <div className="liner"></div>
                                </div>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-12">
                                <div className="input-container">
                                  <input
                                    className="form-control"
                                    placeholder={t(
                                      "Min 8 chars, 2 numbers"
                                    )}
                                    required
                                    type="password"
                                    defaultValue={
                                      data?.repeatNP ||
                                      null
                                    }
                                    onChange={(e) =>
                                      formDispatch({
                                        type: "setValue",
                                        field: "repeatNP",
                                        value:
                                          e.target.value,
                                      })
                                    }
                                  />
                                  <label className="label-wize">
                                    <Trans>
                                      Repeat new password
                                    </Trans>
                                  </label>
                                  {errors
                                    .filter(
                                      (error) =>
                                        error?.repeatNP ===
                                        "repeatNP"
                                    )
                                    .map(({ reason }) => (
                                      <small className="form-text text-danger">
                                        {reason}
                                      </small>
                                    ))}
                                  <i className="icon icon-edit"></i>
                                  <div className="liner"></div>
                                </div>
                              </div>
                            </div>
                          </form>

                          <form
                            id={formId}
                            onSubmit={async (e) => {
                              e.preventDefault();
                              formDispatch({
                                type: "setSubmitting",
                                submitting: true,
                              });

                              if (data.newPassword) {
                                const regex = new RegExp(
                                  /^(?=.*[0-9]).{2,}(?=.*[a-zA-Z]).{8,}$/
                                );

                                if (
                                  regex.test(
                                    data.newPassword
                                  ) === false
                                ) {
                                  formDispatch({
                                    type: "setErrors",
                                    errors: [
                                      t(
                                        "Your password must contain at least 8 chars and 2 numbers"
                                      ),
                                    ],
                                  });
                                } else if (
                                  data.newPassword ===
                                  data.repeatNP
                                ) {
                                  const result =
                                    await updatePassword(
                                      data.newPassword
                                    );

                                  if (
                                    result.success ===
                                    true
                                  ) {
                                    dispatch({
                                      type: "close",
                                    });
                                    formDispatch({
                                      type: "reset",
                                    });
                                  } else {
                                    formDispatch({
                                      type: "setErrors",
                                      errors: [
                                        t(
                                          "Couldn't update your password"
                                        ),
                                      ],
                                    });
                                  }
                                } else {
                                  formDispatch({
                                    type: "setErrors",
                                    errors: [
                                      t(
                                        "Password do not match"
                                      ),
                                    ],
                                  });
                                }
                              }
                            }}
                          />
                        </Modal>
                      )}
                    </FormConsumer>
                  );
                }
              })()}
            </div>
          )}
        </ModalConsumer>
      </FormProvider>
    </ModalProvider>
  )
}

export default LaunchersPasswordModal