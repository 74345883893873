import React from "react";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';

import Game from "model/game";
import { getPath } from "routes";
import SelectList from "components/SelectList/SelectList";
import Release from "model/release";
import isGranted, { Role } from "security/voter";

export interface GameTabsProps {
  release: Release | null;
  releases: Release[] | null;
  game: Game;
  setMoreReleasesModalOpened: (opened: boolean) => void;
}

export default function GameTabs({ release, releases, game, setMoreReleasesModalOpened }: GameTabsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const params = useParams()
  const hasRights = isGranted(new Set([Role.Admin])) || isGranted(new Set([Role.Editor]));


  const processPath = (name: string) => {
    if (release === null) {
      return getPath(name, { id: params.id }) ?? ''
    } else {
      return getPath(name, { id: params.id }, { release: release.id }) ?? ''
    }
  }

  return (
    <div className="tabs">
      {hasRights && (() => {
        const label = (
          <>
            <i className="icon icon-release me-2" />
            <Trans>Releases</Trans>
          </>
        );
        const selector = (releases || []).length > 0 && (
          <div
            className={`ms-3 ${location.pathname ===
              getPath("game-show", {
                id: params.id,
              })
              ? ""
              : "not-active"
              }`}
          >
            <SelectList
              dataOptions={() =>
                (releases || [])
                  .sort((a, b) => {
                    const createdAt1 = moment(a.createdAt, "YYYY-MM-DDTHH:mm:ssZZ");
                    const createdAt2 = moment(b.createdAt, "YYYY-MM-DDTHH:mm:ssZZ");

                    if (createdAt1.isBefore(createdAt2)) {
                      return 1;
                    }

                    if (createdAt1.isSame(createdAt2)) {
                      return 0;
                    }

                    return -1;
                  })
                  .slice(0, 5)
                  .map((r) => ({
                    label: `v${r.version}`,
                    value: r.id,
                  }))
              }
              value={
                release !== null
                  ? {
                    label: `v${release?.version || ""}`,
                    value: release?.id,
                  }
                  : null
              }
              multiSelect={false}
              placeholderText={t("Versions")}
              onChange={({ value }: Record<string, unknown>) =>
                navigate(
                  getPath(
                    "game-show",
                    {
                      id: game.id,
                    },
                    {
                      release: value,
                    }
                  ) ?? ''
                )
              }
              thirdOption={
                <div>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => setMoreReleasesModalOpened(true)}
                  >
                    <Trans>More releases</Trans>
                  </button>
                </div>
              }
            />
          </div>
        );

        if (
          location.pathname ===
          getPath("game-show", {
            id: params.id,
          })
        ) {
          return (
            <div className="tabs-item active">
              <Link to={processPath("game-show")}>
                {label}
              </Link>
              {selector}
            </div>
          );
        }

        return (
          <Link className="tabs-item" to={processPath("game-show")}>
            {label}
            {selector}
          </Link>
        );
      })()}

      <Link
        className={`tabs-item ${location.pathname === getPath("game-show-infos", { id: params.id }) ? "active" : ""}`}
        to={processPath("game-show-infos")}
      >
        <i className="icon icon-info me-2" />
        <Trans>Informations</Trans>
      </Link>

      <Link
        className={`tabs-item ${location.pathname ===
          getPath("game-show-medias", {
            id: params.id,
          })
          ? "active"
          : ""
          }`}
        to={processPath("game-show-medias")}
      >
        <i className="icon icon-media me-2" />
        <Trans>Medias</Trans>
      </Link>

      {hasRights &&
        <Link
          className={`tabs-item ${location.pathname ===
            getPath("game-show-devs", {
              id: params.id,
            })
            ? "active"
            : ""
            }`}
          to={processPath("game-show-devs")}
        >
          <i className="icon icon-dev me-2" />
          <Trans>Devs</Trans>
        </Link>}
    </div >
  )
}