import { raw } from "security/token"
import handleUnauthorized from "services/helper/unauthorized_handler"
import Logger from "services/logger"

export async function list(
  locations?: number[],
  from?: string,
  to?: string,
  limit?: number,
  offset?: number,
): Promise<Response | null> {
  const params = new URLSearchParams()

  params.append('sort[field]', 'id')
  params.append('sort[direction]', 'DESC')

  if (limit != null) {
    params.append('limit', limit.toString(10))
  }

  if (offset != null) {
    params.append('offset', offset.toString(10))
  }

  if (from != null && to != null) {
    params.append('period[from]', from)
    params.append('period[to]', to)
  }

  if (locations != null) {
    locations.forEach(l => params.append('locations[]', l.toString(10)))
  }

  const headers: HeadersInit = {
    "x-auth-token": raw() || "",
  }

  return fetch(
    `${process.env.API || ""}/api/v1/billing/invoices?${params.toString()}`,
    {
      method: "GET",
      headers
    }
  )
    .then(handleUnauthorized)
    .catch((errors) => {
      Logger.error('could not perform get on "api/v1/billing/invoices"', {
        locations,
        from,
        to,
        limit,
        offset,
        errors
      })
      return Promise.resolve(null);
    })
}