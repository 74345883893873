import { stringify } from "qs";

import getToken, { raw } from "security/token";

export async function changePassword(
  launcher: number,
  password: string
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: Record<string, any> = {
    password: password,
  };
  const response = await fetch(
    `${process.env.API || ""}/api/v1/launchers/${launcher}/change-password`,
    {
      method: "PUT",
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
      body: stringify(data),
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    errors: body.errors,
  };
}
export async function editorAdd(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  login: Record<string, any>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: Record<string, any> = {
    couchDB: login.name,
  };

  if (login.login !== null) {
    data.couchDB = login.login;
  }

  const response = await fetch(
    `${process.env.API || ""}/api/v1/editors/${getToken()?.context.editor
    }/launchers`,
    {
      method: "POST",
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
      body: stringify(data),
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    errors: body.errors,
  };
}
