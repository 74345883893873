export default interface User {
  id: number;
  firstname: string;
  lastname: string;
  phone: string | null;
  emailLanguage: string;
  email: string;
  roles: UserRole[];
  client: number | null;
  distributor: number | null;
  editor: number | null;
  blockedAt: string | null;
  createdAt: string;
  expiredAt: string | null;
}

export enum UserRole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_EDITOR = 'ROLE_EDITOR',
  ROLE_DISTRIBUTOR = 'ROLE_DISTRIBUTOR',
  ROLE_CLIENT = 'ROLE_CLIENT',
  ROLE_USER = 'ROLE_USER',
}
