import { raw } from "security/token";
import Logger from "services/logger";

export default async function getEditor(
  editor: number
): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/editors/${editor}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "editor"', {
      editor,
      errors
    });
    return Promise.resolve(null);
  });
}
