import React from "react";
import { useTranslation, Trans } from 'react-i18next';

import Modal from "components/Modal/Modal";
import { FormConsumer } from "services/stores/formStore";
import { ModalConsumer, ModalProvider } from "services/stores/modalStore";
import { edit } from "api/profile/edit";
import User from "model/user";

export interface SaveProfileProps {
  profile: User
  callback: (p: User) => void
}

export default function SaveProfileModal(props: SaveProfileProps) {
  const { t } = useTranslation();

  const updateProfile = async (profile: User, form: Record<string, unknown>) => {
    const res = await edit({ ...profile, ...form });
    if (typeof res !== 'boolean' && res.success) {
      props.callback(res.data as User)
      return res
    } else {
      // TODO: handle error
    }
  };

  return (
    <ModalProvider>
      <ModalConsumer>
        {([{ opened }, modalDispatch]) => (
          <FormConsumer>
            {([{ data, errors, submitting }, formDispatch]) => {
              const disabled: boolean =
                Object.keys(data).length === 0;
              const formId = "game-save";
              return (
                <>
                  <div className="button-group--right">
                    <button
                      className="btn btn-primary"
                      disabled={disabled}
                      type="button"
                      onClick={() =>
                        modalDispatch({
                          type: opened ? "close" : "open",
                        })
                      }
                    >
                      <i className="icon me-2 icon--white icon-save" />
                      {t('Save Profile')}
                    </button>
                  </div>

                  {opened === true && (
                    <Modal
                      header={{
                        title: t("Confirm changes ?"),
                        icon: "icon icon-save",
                      }}
                      footer={{
                        btnCloseClass: "btn-danger w-50 me-2",
                        btnCloseLabel: t("Cancel"),
                        btnValidClass: "btn-primary w-50 ms-2",
                        btnValidDisabled: submitting,
                        btnValidForm: formId,
                        btnValidLabel: t(
                          submitting ? "Saving" : "Save"
                        ),
                        center: true,
                      }}
                      onClose={() =>
                        modalDispatch({
                          type: "close",
                        })
                      }
                    >
                      {(() => {
                        if (errors.length > 0) {
                          return (
                            <>
                              <div className="alert alert-danger">
                                <Trans
                                  i18nKey="{{total}} errors encountered"
                                  values={{
                                    total: errors.length,
                                  }}
                                />
                              </div>

                              {errors
                                .filter(
                                  (error) =>
                                    error?.name === undefined
                                )
                                .map((error) => (
                                  <div className="alert alert-danger">
                                    {error}
                                  </div>
                                ))}
                            </>
                          );
                        }
                      })()}

                      <form
                        id={formId}
                        onSubmit={async (e) => {
                          e.preventDefault();
                          formDispatch({
                            type: "setSubmitting",
                            submitting: true,
                          });
                          const result = await updateProfile(
                            props.profile,
                            data
                          );

                          if (result) {
                            modalDispatch({
                              type: "close",
                            });
                            formDispatch({
                              type: "reset",
                            });
                          } else {
                            formDispatch({
                              type: "setErrors",
                              errors,
                            });
                          }
                        }}
                      />
                    </Modal>
                  )}
                </>
              );
            }}
          </FormConsumer>
        )}
      </ModalConsumer>
    </ModalProvider>
  )
}