import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import Modal from 'components/Modal/Modal'
import { ModalConsumer, ModalProvider } from 'services/stores/modalStore'
import Game from 'model/game'

interface BalanceGamesModalProps {
  games: Game[];
}

const BalanceGamesModal = ({ games }: BalanceGamesModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalProvider>
      <ModalConsumer>
        {([{ opened }, dispatch]) => (
          <>
            <a className="balance-link" onClick={() => dispatch({ type: opened ? "close" : "open" })}>
              <Trans>See the games concerned</Trans>
            </a>

            {(() => {
              if (opened === true) {
                return (
                  <Modal
                    header={{
                      title: t("Games involved"),
                    }}
                    footer={{
                      btnCloseClass: "btn btn-primary balance-games-modal-close",
                      btnCloseLabel: t("Close"),
                    }}
                    onClose={() =>
                      dispatch({
                        type: "close",
                      })
                    }
                  >
                    <div className='balance-games-modal-body'>
                      <ul className='balance-games-modal-content'>
                        {games.map((g) => (
                          <li key={g.id}>{g.name}</li>
                        ))}
                      </ul>
                    </div>
                  </Modal>
                );
              }
            })()}
          </>
        )}
      </ModalConsumer>
    </ModalProvider>
  )
}

export default BalanceGamesModal
