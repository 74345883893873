import { raw } from 'security/token';
import Logger from 'services/logger';

export async function get(launcher: string): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/launchers/${launcher}/_/property`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "launchers/property"', {
      launcher,
      errors
    });
    return Promise.resolve(null);
  });

}