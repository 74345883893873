import React, { ReactElement } from "react";

import { Trans } from 'react-i18next';

type Props = {
  message: string;
  components?: Array<ReactElement>;
};

export default function ErrorMessage({ message, components }: Props) {
  return (
    <div className="center">
      <i className="icon-xl icon-alert" />

      <p>
        <Trans i18nKey={message} components={components} />
      </p>
    </div>
  );
}
