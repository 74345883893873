import { raw } from "security/token"
import Logger from "services/logger"

export function scores(
  launcher: string,
  session: string,
): Promise<Response | null> {
  const headers: HeadersInit = {
    "x-auth-token": raw() || "",
  }

  return fetch(
    `${process.env.API || ""}/api/v1/launchers/${launcher}/_/sessions/${session}/attachments/score`,
    {
      method: "GET",
      headers
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "api/v1/launcher/:id/_/sessions/:id2/attachments/score"', {
      launcher,
      session,
      errors
    });
    return Promise.resolve(null);
  })
}
