import React, { createContext, useContext, useReducer } from "react";
import type { Context, PropsWithChildren } from "react";

import Game from "model/game";
import Release from "model/release";
import Logger from "services/logger";
import GameAttachment from "model/game_attachment";

export interface GameWithAttachments extends Game {
  attachments: GameAttachment[];
}

type State = {
  game: GameWithAttachments | null;
  release: number | null;
  releases: Release[] | null;
};

const initialState: State = {
  game: null,
  release: null,
  releases: null,
};

type Action = {
  type: "removeGameAttachment" | "setCurrentRelease" | "setGame" | "updateGameAttachments" | "editGameAttachment" | "updateRelease";
  attachment?: GameAttachment | number;
  game?: Game;
  attachments?: GameAttachment[];
  release?: Release | number;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "removeGameAttachment":
      return {
        ...state,
        game: {
          ...state.game,
          // @ts-expect-error TS2322
          attachments: state.game?.attachments.filter(
            (attachment) => attachment.id !== action.attachment
          ),
        },
      };

    case "setCurrentRelease":
      // @ts-expect-error TS2322
      return { ...state, release: action.release };

    case "setGame":
      // @ts-expect-error TS2322
      return { ...state, game: action.game };

    case "updateGameAttachments":
      return {
        ...state,
        // @ts-expect-error TS2322
        game: { ...state.game, attachments: action.attachments },
      };

    case "editGameAttachment":
      return {
        ...state,
        game: {
          ...state.game,
          // @ts-expect-error TS2322
          attachments: state.game.attachments.map((attachment) => {
            // @ts-expect-error TS2339
            if (attachment.id === action.attachment.id) {
              return action.attachment;
            }

            return attachment;
          }),
        },
      };

    case "updateRelease":
      return {
        ...state,
        // @ts-expect-error TS2322
        releases: state.releases.map((r) =>
          // @ts-expect-error TS2339
          r.id === action.release.id ? action.release : r
        ),
      };
  }

  return state;
};

const GameReleasesContext: Context<[State, (action: Action) => void]> =
  createContext([
    initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (action) => Logger.error("Context not correctly initialized"),
  ]);

export const GameReleasesConsumer = GameReleasesContext.Consumer;
export const GameReleasesConsumerHook = () => useContext(GameReleasesContext);

type Props = {
  game?: GameWithAttachments;
  release?: number;
  releases?: Release[];
}

export const GameReleasesProvider = ({ children, game, release, releases }: PropsWithChildren<Props>) => {
  return (
    <GameReleasesContext.Provider
      value={useReducer(reducer, { ...initialState, game: game ?? null, release: release ?? null, releases: releases ?? null }, undefined)}
    >
      {children}
    </GameReleasesContext.Provider>
  );
};
