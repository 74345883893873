import React from "react";

import ConsumptionWrapper from "components/Consumption/ConsumptionWrapper/ConsumptionWrapper";
import ConsumptionSummary from "components/Consumption/ConsumptionSummary/ConsumptionSummary";
import ConsumptionDetails from "components/Consumption/ConsumptionDetails/ConsumptionDetails";

export default function Consumption() {
  return <ConsumptionWrapper>
    <ConsumptionSummary />
    <div className="my-4" />
    <ConsumptionDetails />
  </ConsumptionWrapper>
}
