import React, { useMemo } from "react"
import { SelectWithCollapsableGroups } from "@octopod/design-system"
import { useTranslation } from 'react-i18next'
import Logger from "services/logger"
import Game from "model/game";
import Editor from "model/editor";

interface Props {
  games: Game[] | null
  editors: Editor[] | null
  onSelect: (val: string | null) => void
  value: string | null
}

export default function SelectGames({ games, editors, onSelect, value = null }: Props) {
  const { t } = useTranslation();
  
  // useMemo for performance concerns (re-compute data tree only when needed)
  const dataTree = useMemo(() => editorGamesDataTree(editors, games), [editors, games])
  const hasData = (dataTree || []).length > 0
  const editorIcon = <img alt={t("Editor icon")} src={require("../../../static/images/picto-editor.svg")} />
  const gameIcon = <img alt={t("Game icon")} src={require("../../../static/images/picto-game.svg")} />
  const levelIcons = []

  if (editors !== null) {
    levelIcons.push(editorIcon);
  }
  levelIcons.push(gameIcon)

  const handleSelect = (gameId: string | null) => {
    if (gameId === null) {
      onSelect(null)
    } else {
      onSelect(gameId.replace("game-", ""))
    }
  }

  return (
    <SelectWithCollapsableGroups
      data={dataTree}
      value={value ? `game-${value}` : null}
      placeholder={hasData ? t('All games') : t('Loading')}
      levelIcons={levelIcons}
      inputIcon={hasData ? levelIcons[1] : null}
      isDisabled={!hasData}
      isClearable={hasData}
      onChange={hasData ? handleSelect : () => { /* do nothing */ }}
    />
  )
}

interface Node {
  label: string
  value: string
  children?: Node[]
}

const editorGamesDataTree = (editors: Editor[] | null, games: Game[] | null): Node[] => {
  if (games === null) {
    return []
  }

  const dataTree: Node[] = []

  /*
   * Build data tree
   * ---------------
   *
   * 1. sort editors and games before processed
   * 2. initialize tree with editors
   * 3. populate editors children with games
   * 4. remove editors without games
   */
  if (editors !== null) {
    editors
      .sort((e1, e2) => e1.name.localeCompare(e2.name))
      .forEach(e => dataTree.push({ label: e.name, value: `editor-${e.id}`, children: [] }))
  }

  games
    .sort((g1, g2) => g1.name.localeCompare(g2.name))
    .forEach(g => {
      if (editors !== null) {
        const targetEditor = `editor-${g.editor}`
        for (let i = 0; i < dataTree.length; i++) {
          const dataTreeItem = dataTree[i]
          if (dataTreeItem.value === targetEditor && dataTreeItem.children !== undefined) {
            dataTreeItem.children.push({ label: g.name, value: `game-${g.id}` })
            return
          }
        }

        Logger.warn("studio unknown for game", { game: g })
      } else {
        dataTree.push({ label: g.name, value: `game-${g.id}` })
        return
      }
    })
  if (editors !== null) {
    return dataTree.filter(e => e.children !== undefined && e.children.length > 0)
  }
  return dataTree
}
