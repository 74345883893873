export interface SessionCouch {
  _id: string | null;
  name: string | null;
  createdAt: string | null;
  startedAt: string | null;
  launchedAt: string | null;
  doneAt: string | null;
  release: string | null;
  sid: string | null;
  scores: unknown | null;
  mail: string | null;
  serverProcess: string | null;
  spectatorProcess: string | null;
  advancedConfigServer: unknown[] | null;
  advancedConfigSpectator: unknown[] | null;
}

export enum SessionCouchStatus {
  All = 'all',
  DONE = 'done',
  NOT_DONE = 'not-done',
}

export function getSessionCouchStatusFromString(value: string): SessionCouchStatus | null {
  switch (value) {
    case 'all':
      return SessionCouchStatus.All
    case 'done':
      return SessionCouchStatus.DONE
    case 'not-done':
      return SessionCouchStatus.NOT_DONE
    default:
      return null
  }
}
