import { stringify } from "qs";

import { raw } from "security/token";
import type Game from "model/game";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function create(name: string): Promise<Record<string, any>> {
  const response = await fetch(`${process.env.API || ""}/api/v1/games`, {
    method: "POST",
    headers: {
      "x-auth-token": raw() || "",
      "content-type": "application/x-www-form-urlencoded",
    },
    body: stringify({
      name,
      key: name
        .replace(/[^A-Za-z0-9]+/g, "-")
        .toLowerCase()
        .trim(),
      description: name,
    }),
  });
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    errors: body.errors,
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function edit(game: Game): Promise<Record<string, any>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: Record<string, any> = {
    description: game.description,
    name: game.name,
    warehouse: game.warehouse,
  };

  if (game.categories !== null) {
    data.categories = game.categories;
  }

  if (game.headsets !== null) {
    data.headsets = game.headsets;
  }

  if (game.languages !== null) {
    data.languages = game.languages;
  }

  if (game.spaces !== null) {
    data.spaces = game.spaces;
  }

  if (game.internetConnectionRequired !== null) {
    data.internetConnectionRequired = game.internetConnectionRequired
      ? "true"
      : "false";
  }

  if (game.minimumAge !== null) {
    data.minimumAge = game.minimumAge;
  }

  if (game.comfort !== null) {
    data.comfort = game.comfort;
  }

  const response = await fetch(
    `${process.env.API || ""}/api/v1/games/${game.id}`,
    {
      method: "PUT",
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
      body: stringify(data),
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    errors: body.errors,
  };
}

export async function addAttachment(
  game: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { name, category, swap }: Record<string, any>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  const response = await fetch(
    `${process.env.API || ""}/api/v1/games/${game}/attachments`,
    {
      method: "POST",
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
      body: stringify({
        name,
        category,
        "swap[id]": swap.id,
        "swap[key]": swap.key,
      }),
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
    };
  }

  return {
    success: false,
    error: body.message,
  };
}

export async function editAttachment(
  game: number,
  attachment: number,
  name: string
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  const response = await fetch(
    `${process.env.API || ""}/api/v1/games/${game}/attachments/${attachment}`,
    {
      method: "PUT",
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
      body: stringify({
        name,
      }),
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: body,
    };
  }

  return {
    success: false,
    error: body.message,
  };
}
