import React, { useState } from "react";
import moment from "moment";
import { useTranslation, Trans } from 'react-i18next';

import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Loader from "components/Loader/Loader";
import Modal from "components/Modal/Modal";
import { create, list, update } from "api/token";
import { ModalConsumer, ModalProvider } from "stores/modalStore";
import Token from "model/token";

const Tokens = () => {
  const { t } = useTranslation();

  const [token, setToken] = useState<Token | null>(null);
  const [tokens, setTokens] = useState<Token[] | null>(null);
  const [error, setError] = useState(false);
  const [tokenError, setTokenError] = useState(false);

  const loadTokens = () => {
    list().then(({ success, data }) => {
      if (success) {
        setTokens(data);
      } else {
        setError(true);
      }
    });
  };

  const setTokenActivation = (id: number | null, active: boolean) => {
    if (id === null) {
      return;
    }
    update(id, active).then(({ success, data }) => {
      if (success === true) {
        setTokens(
          (tokens || []).map((t) => {
            if (t.id === data.id) {
              return data;
            }

            return t;
          })
        );
      }
    });
  };

  if (tokens === null) {
    loadTokens();
    return <Loader message={t("Loading tokens")} />;
  } else if (error) {
    return <ErrorMessage message={t("Could not load tokens")} />;
  }

  return (
    <div className="tokens">
      <div className="row">
        <div className="col-10">
          <p className="tokens__description">
            <Trans>
              A permanent token allows you to attack API without
              re-authentication. Tokens can be used in lot of cases such deploy
              new game releases.
            </Trans>
          </p>
        </div>
        <div className="col-2">
          <ModalProvider>
            <ModalConsumer>
              {([{ opened }, dispatch]) => (
                <>
                  <div className="button-group--right mb-5">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        dispatch({
                          type: "open",
                        });
                        create().then(({ success, data }) => {
                          if (success === true) {
                            setToken(data);
                          } else {
                            setTokenError(true);
                          }
                        });
                      }}
                    >
                      <i className="icon--white icon-key me-2" />

                      <Trans>Generate token</Trans>
                    </button>
                  </div>

                  {opened === true && (
                    <Modal
                      header={{
                        title: t("Generated token"),
                        icon: "icon icon-key",
                      }}
                      footer={{
                        btnCloseClass: "btn-danger",
                        btnCloseLabel: t("ok"),
                      }}
                      onClose={() => {
                        dispatch({
                          type: "close",
                        });
                        setToken(null);
                        setTokenError(false);
                        setTokens(null);
                        loadTokens();
                      }}
                    >
                      {(() => {
                        if (token === null) {
                          if (tokenError === true) {
                            return (
                              <ErrorMessage
                                message={t("Could not create token")}
                              />
                            );
                          }

                          return <Loader inline message={false} />;
                        }

                        return (
                          <p className="text-center main-field w-100">
                            {token.token}
                            <i className="fas fa-check ms-4 icon--valid" />
                          </p>
                        );
                      })()}
                    </Modal>
                  )}
                </>
              )}
            </ModalConsumer>
          </ModalProvider>
        </div>
        <div className="col-12">
          <div className="card">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{t("Name")}</th>
                  <th scope="col">{t("Created at")}</th>
                  <th className="text-right" scope="col">
                    {t("Action")}
                  </th>
                </tr>
              </thead>

              <tbody>
                {(tokens || []).map(({ id, active, createdAt, token }) => (
                  <tr key={id}>
                    <td>{token}</td>

                    <td>
                      {moment(createdAt, "YYYY-MM-DDTHH:mm:ssZ").format("LLL")}
                    </td>

                    <td
                      className="text-right position-relative"
                      onClick={() => setTokenActivation(id, !active)}
                    >
                      <span
                        className={`badge-switch rounded-pill ${active ? "enable badge-enable" : "badge-disable"
                          }`}
                      >
                        <div className="badge-switch__container">
                          <i className="fal show-enable fa-check" />

                          <p className="badge-switch__label">
                            {(() => {
                              if (active) {
                                return <Trans>Enabled</Trans>
                              }

                              return <Trans>Disabled</Trans>
                            })()}
                          </p>

                          <i className="fal show-disable fa-times" />
                        </div>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokens;
