  // eslint-disable-next-line @typescript-eslint/no-explicit-any
type ListFunction = (limit: number, offset: number) => Promise<Record<string, any>>
interface FetchAllResponse {
  success: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
}


export const fetchAll = async (list: ListFunction): Promise<FetchAllResponse> => {
  const allData = []
  const limit = 50
  let offset = 0

  /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
  while (true) { // return statements as stopped conditions
    const { success, data, error } = await list(limit, offset)

    if (!success || (error !== null && error !== undefined)) {
      return Promise.resolve({ success: false, data: null, error})
    }

    allData.push(...data)
    if (data.length < limit) {
      return Promise.resolve({ success: true, data: allData, error: null})
    }

    offset += limit
  }
}