import React, { Fragment, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Button, DateRangePicker } from "@octopod/design-system"
import { lastDayOfMonth, startOfMonth } from "date-fns"

import SelectLocations from 'components/SelectLocations/SelectLocations'
import { fetchLocations, LocationsConsumerHook } from "services/stores/locationsStore"
import Invoice from "model/invoice"
import { list } from "api/invoices/list"
import { fetchGames, GamesConsumerHook } from "services/stores/gamesStore"
import BillingDetails from "./components/BillingDetails/BillingDetails"
import { addNotification, NotificationConsumerHook } from "services/stores/notificationStore"
import showZohoDetails from "api/invoices/zohoDetails"
import openLinkInNewTab from "services/helper/open_link_new_tab"

import pictoPlus from "images/picto-plus.svg"
import pictoMinus from "images/picto-minus.svg"
import pictoEye from "images/picto-eye.svg"

export enum BillingFilters {
  PeriodFrom = 'filters[period_from]',
  PeriodTo = 'filters[period_to]',
  Location = 'filters[location]',
  Details = 'filters[details]',
}

export default function Billing() {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [{ locations, loading: loadingLocations, error: errorLocations }, dispatchLocations] = LocationsConsumerHook()
  const [{ games, loading: loadingGames, error: errorGames }, dispatchGames] = GamesConsumerHook()
  const [, dispatchNotification] = NotificationConsumerHook();

  const [invoices, setInvoices] = useState<Invoice[] | null>(null)
  const [reachedMax, setReachedMax] = useState<boolean>(false)

  const setDefaultParamsIfNeeded = () => {
    if (!searchParams.has(BillingFilters.PeriodFrom)) {
      setSearchParams(prev => {
        prev.set(BillingFilters.PeriodFrom, moment(startOfMonth(new Date())).format("YYYY-MM-DD"))
        return prev
      })
    }

    if (!searchParams.has(BillingFilters.PeriodTo)) {
      setSearchParams(prev => {
        prev.set(BillingFilters.PeriodTo, moment(lastDayOfMonth(new Date())).format("YYYY-MM-DD"))
        return prev
      })
    }
  }

  const handleChange = (value: string | null, filter: BillingFilters) => {
    if (searchParams.get(filter) != value) {
      //? On location change, we remove the details filter
      if (filter === BillingFilters.Location) {
        setSearchParams(prev => {
          prev.delete(BillingFilters.Details)
          return prev
        })
      }

      setSearchParams(prev => {
        if (value !== null && value !== '') {
          prev.set(filter, value)
        } else {
          prev.delete(filter)
        }
        return prev
      })

    }
  }

  const handleChangePeriod = (startDate: Date, endDate: Date) => {
    setSearchParams(prev => {
      prev.set(BillingFilters.PeriodFrom, moment(startDate).format("YYYY-MM-DD"))
      prev.set(BillingFilters.PeriodTo, moment(endDate).format("YYYY-MM-DD"))
      return prev
    })
  }

  const processAddress = (locationId: number): JSX.Element => {
    const location = locations?.find(l => l.id === locationId)
    if (location === undefined) {
      return <span>-</span>
    }
    return (
      <>
        <div className="billing-location">{location.name} - {location.city}</div>
        <div className="billing-address">{location.address}</div>
      </>
    )
  }

  const fetchMore = () => {
    const locationsParam = searchParams.get(BillingFilters.Location)
    list(
      locationsParam ? [Number.parseInt(locationsParam, 10)] : undefined,
      searchParams.get(BillingFilters.PeriodFrom) ?? undefined,
      searchParams.get(BillingFilters.PeriodTo) ?? undefined,
      50,
      invoices?.length,
    ).then(async (response) => {
      if (response?.ok) {
        const body = await response?.json()

        setInvoices(prev => {
          if (prev == null) {
            return body
          }
          return [...prev, ...body]
        })

        if (body.length < 50) {
          setReachedMax(true)
        }
      } else {
        return Promise.reject(response)
      }
    }).catch(() => {
      addNotification(dispatchNotification, t('error'), t('Could not fetch more invoices'), 'error')
    })

  }

  const updateInvoiceDetails = (invoice: Invoice) => {
    if (invoices === null) {
      return
    }
    const invoiceToUpdate = invoices?.find(i => i.id === invoice.id);
    if (invoiceToUpdate === undefined) {
      return
    }
    invoiceToUpdate.lines = invoice.lines
    invoiceToUpdate.zohoInvoiceStatus = invoice.zohoInvoiceStatus
    invoiceToUpdate.zohoInvoiceUrl = invoice.zohoInvoiceUrl
    setInvoices(invoices)
  }

  const loadZohoUrlAndRedirect = (invoiceId: number) => {
    showZohoDetails(invoiceId)
      .then(async (response: Response | null) => {
        if (response?.ok) {
          const body = await response.json()
          const invoice = invoices?.find(i => i.id === invoiceId)
          if (invoice === undefined) {
            return Promise.reject(response)
          }
          invoice.zohoInvoiceStatus = body.zohoInvoiceStatus
          invoice.zohoInvoiceUrl = body.zohoInvoiceUrl
          invoice.lines = body.lines
          setInvoices(invoices || [])
          if (invoice.zohoInvoiceUrl != null) {
            openLinkInNewTab(invoice.zohoInvoiceUrl)
          } else {
            addNotification(dispatchNotification, t('error'), t('Could not find this invoice link'), 'error')
          }
        } else {
          return Promise.reject(response)
        }
      })
      .catch(() => {
        // reset details search param
        setSearchParams(prev => {
          prev.delete(BillingFilters.Details)
          return prev
        })
        addNotification(dispatchNotification, t('error'), t('Could not find this invoice details'), 'error')
      })
  }

  useEffect(() => {
    if (loadingLocations === false && locations === null && errorLocations === null) {
      fetchLocations(dispatchLocations, history)
    }
  }, [dispatchLocations, errorLocations, history, locations, loadingLocations])

  useEffect(() => {
    if (loadingGames === false && games === null && errorGames === null) {
      fetchGames(dispatchGames, history)
    }
  }, [dispatchLocations, errorLocations, history, locations, loadingLocations])

  useEffect(() => {
    const locationsParam = searchParams.get(BillingFilters.Location)
    list(
      locationsParam ? [parseInt(locationsParam, 10)] : undefined,
      searchParams.get(BillingFilters.PeriodFrom) ?? undefined,
      searchParams.get(BillingFilters.PeriodTo) ?? undefined
    )
      .then(async (response) => {
        if (response?.ok) {
          const body = await response.json()
          setReachedMax(body.length < 50)
          setInvoices(body)
        } else {
          return Promise.reject(response)
        }
      })
      .catch(() => {
        addNotification(dispatchNotification, t('error'), t('Could not fetch invoices'), 'error')
      })
  }, [searchParams.get(BillingFilters.Location), searchParams.get(BillingFilters.PeriodFrom), searchParams.get(BillingFilters.PeriodTo)])

  useEffect(() => {
    setDefaultParamsIfNeeded()
  }, [])

  return (
    <>
      <div className="row mx-0 mb-5">
        <div className="card col me-3">
          <SelectLocations
            locations={locations ?? []}
            onSelect={(value) => handleChange(value, BillingFilters.Location)}
            value={searchParams.get(BillingFilters.Location) || null}
          />
        </div>
        <div className="card col ms-3 d-flex" style={{ justifyContent: "center" }}>
          <DateRangePicker
            onChange={handleChangePeriod}
            initialValue={{
              startDate: new Date(searchParams.get(BillingFilters.PeriodFrom) || moment(startOfMonth(new Date())).toISOString()),
              endDate: new Date(searchParams.get(BillingFilters.PeriodTo) || moment(lastDayOfMonth(new Date())).toISOString()),
            }}
          />
        </div>
      </div>
      <div className='billing-table-border' />
      <table className='table table-light billing-table'>
        <thead className='billing-table-header'>
          <tr>
            <th className='text-primary text-center'>{t('Details')}</th>
            <th className='text-primary text-center'>{t('Id')}</th>
            <th className='text-primary text-start ps-5'>{t('Address')}</th>
            <th className='text-primary text-center'>{t('Creation date')}</th>
            <th className='text-primary text-center'>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody className='billing-table-body'>
          {invoices && invoices.length > 0 && invoices.map((invoice) => {
            return (
              //? <Fragment> = <> --- We use Fragment because we need to put a key on it to avoid a React warning
              <Fragment key={invoice.id}>
                <tr>
                  <td className='text-primary text-center align-middle'>
                    <div className="billing-action">
                      <button
                        className='btn btn-primary btn-sm billing-action billing-action-button'
                        onClick={() => handleChange(
                          searchParams.get(BillingFilters.Details) == invoice.id.toString() ? null : invoice.id.toString(),
                          BillingFilters.Details
                        )}
                      >
                        <img alt={t('Download scores button')} src={searchParams.get(BillingFilters.Details) === invoice.id.toString() ? pictoMinus : pictoPlus} />
                      </button>
                    </div>
                  </td>
                  <td className='text-primary text-center align-middle'>{invoice.id}</td>
                  <td className='text-primary text-start align-middle ps-5'>{processAddress(invoice.location)}</td>
                  <td className='text-primary text-center align-middle'>{moment(invoice.createdAt).format('L')}</td>
                  <td className="text-primary text-center align-middle">
                    {invoice.zohoInvoiceId &&
                      <div className="billing-action">
                        <a className='btn btn-primary btn-sm billing-action billing-action-button' onClick={() => loadZohoUrlAndRedirect(invoice.id)}>
                          <img alt={t('Show details button')} src={pictoEye} />
                        </a>
                      </div>
                    }
                  </td>
                </tr>
                {searchParams.get(BillingFilters.Details) === invoice.id.toString() && (
                  <BillingDetails
                    invoice={invoice}
                    games={games ?? []}
                    updateInvoice={updateInvoiceDetails}
                  />
                )}
              </Fragment>
            )
          })}

        </tbody>
      </table>
      {!reachedMax && (
        <div className='w-100 billing-pagination'>
          <Button label={t('Load more')} onClick={fetchMore}></Button>
        </div>
      )}
    </>
  )
}
