import { raw, reset } from "security/token";
import Unauthorized from "exception/unauthorized";

export async function list(
  limit = 50,
  offset = 0
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  const params = new URLSearchParams();

  if (Number.isInteger(limit) === true) {
    params.append("limit", `${limit}`);
  }

  if (Number.isInteger(offset) === true) {
    params.append("offset", `${offset}`);
  }

  const response = await fetch(
    `${process.env.API || ""}/api/v1/editors?${params.toString()}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  );
  const body = await response.json();

  switch (response.status) {
    case 401:
      reset();
      throw new Unauthorized();

    case 200:
      return {
        success: true,
        data: body,
      };

    default:
      return {
        success: false,
        errors: body.errors,
      };
  }
}
