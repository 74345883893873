import React from "react";
import { Notification } from "@octopod/design-system";

import { NotificationConsumerHook, removeNotification } from "services/stores/notificationStore";

export default function NotificationManager() {
  const [{ notifications }, dispatchNotification] = NotificationConsumerHook();

  return (
    <div className="notification-container">
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          title={notification.title}
          message={notification.message}
          closeNotification={() => removeNotification(dispatchNotification, notification.id)}
          type={notification.type}
        />
      ))}
    </div>
  )
}
