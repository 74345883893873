import React from 'react'
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal/Modal';
import { FormConsumer, FormProvider } from 'services/stores/formStore';
import { ModalConsumer, ModalProvider } from 'services/stores/modalStore';
import { Trans } from 'react-i18next';
import { editorAdd } from 'api/launchers/edit';
import isGranted from 'security/voter';
import Launcher from 'model/launcher';

export interface LaunchersAddModalProps {
  disabled: boolean,
  callback: (launcher: Launcher | null) => void
}

const LaunchersAddModal = (props: LaunchersAddModalProps) => {
  const { t } = useTranslation();
  const { disabled, callback } = props

  const addLauncher = async (form: Launcher) => {
    if (isGranted(new Set(['ROLE_EDITOR']))) {
      const { success, errors } = await editorAdd({ ...form })
      return {
        success,
        errors,
      };
    }
  };

  return (
    <ModalProvider>
      <FormProvider>
        <ModalConsumer>
          {([{ opened }, dispatch]) => (
            <>
              <div className="button-group--right mb-5">
                <button
                  className="btn btn-primary"
                  disabled={disabled}
                  onClick={() =>
                    dispatch({
                      type: opened ? "close" : "open",
                    })
                  }
                >
                  <i className="icon--white icon-add me-2" />

                  <Trans>Add launcher</Trans>
                </button>
              </div>

              {(() => {
                if (opened === true) {
                  const formId = "add-launcher";
                  return (
                    <FormConsumer>
                      {([
                        { data, errors, submitting },
                        formDispatch,
                      ]) => (
                        <Modal
                          header={{
                            title: t("Add new launcher"),
                          }}
                          footer={{
                            btnCloseClass: "btn-danger me-3",
                            btnCloseLabel: t("Cancel"),
                            btnValidClass: "btn-primary",
                            btnValidDisabled: submitting,
                            btnValidForm: formId,
                            btnValidLabel: t("Create"),
                          }}
                          onClose={() =>
                            dispatch({
                              type: "close",
                            })
                          }
                        >
                          <form
                            id={formId}
                            onSubmit={async (e) => {
                              e.preventDefault();
                              formDispatch({
                                type: "setSubmitting",
                                submitting: true,
                              });

                              if (data.login !== "") {
                                const result = await addLauncher(
                                  data
                                );
                                if (result && result.success === true) {
                                  callback(null);
                                  dispatch({
                                    type: "close",
                                  });
                                  formDispatch({
                                    type: "reset",
                                  });
                                } else {
                                  formDispatch({
                                    type: "setErrors",
                                    errors: result?.errors,
                                  });
                                }
                              }
                            }}
                          >
                            <div className="form-row py-3 pb-5 mt-5">
                              <div className="col-12">
                                <div className="input-container">
                                  <input
                                    className="form-control"
                                    placeholder={t(
                                      "Enter a login for your launcher"
                                    )}
                                    required
                                    title={t(
                                      "Only lowercase chars, numbers, dash and low dash allowed"
                                    )}
                                    pattern="^[a-z0-9-_]+$"
                                    defaultValue={data?.login || null}
                                    onChange={(e) =>
                                      formDispatch({
                                        type: "setValue",
                                        field: "login",
                                        value: e.target.value,
                                      })
                                    }
                                  />

                                  <label className="label-wize">
                                    <Trans>Login</Trans>
                                  </label>

                                  <i className="icon icon-edit"></i>
                                  <div className="liner"></div>
                                </div>

                                <small className="form-text text-secondary">
                                  <Trans>
                                    Only lowercase chars, numbers,
                                    dash and low dash allowed
                                  </Trans>
                                </small>

                                {(errors || []).map((error, index) => (
                                  <small
                                    className="form-text text-danger"
                                    key={index}
                                  >
                                    {error}
                                  </small>
                                ))}
                              </div>
                            </div>
                          </form>
                        </Modal>
                      )}
                    </FormConsumer>
                  );
                }
              })()}
            </>
          )}
        </ModalConsumer>
      </FormProvider>
    </ModalProvider>
  )
}

export default LaunchersAddModal
