import { raw } from "security/token";
import Logger from "services/logger";

export default async function getClient(
  client: number
): Promise<Response | null> {
  return fetch(
    `${process.env.API || ""}/api/v1/clients/${client}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": raw() || "",
      },
    }
  ).catch((errors) => {
    Logger.error('could not perform get on "client"', {
      client,
      errors
    });
    return Promise.resolve(null);
  });
}
