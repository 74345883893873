import { isFirstDayOfMonth, isLastDayOfMonth, isThisMonth } from 'date-fns'

/**
 * Check if provided interval is the current month.
 *
 * @param from
 * @param to
 * @return boolean
 */
export function isCurrentMonth(from: Date | null, to: Date | null): boolean {
  if (from === null || to === null) {
    return false
  }

  return isFirstDayOfMonth(from) && isLastDayOfMonth(to)
    && isThisMonth(from) && isThisMonth(to)
}