import React from "react";
import { Link, Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import OctopodInsightLogo from "../../../static/images/octopod-insight-logo.png";

import { Trans } from 'react-i18next';
import { getPath } from "routes";

export default function Unauthenticated() {
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <div className="row unauthenticated">
        <div className="col-6 p-0 unauthenticated__fixed">
          <div className="unauthenticated__left">
            <img src={OctopodInsightLogo} alt="Octopod" width="674" />
          </div>
        </div>
        <div className="col-6 p-0 unauthenticated__absolute">
          <div className="unauthenticated__right">
            <div className="row m-0 h-100 w-100 flex-column">
              <div className="col d-flex align-items-center">
                <Outlet />
              </div>
              <div className="col-auto text-center">
                © Octopod:Insight <Trans>by</Trans>{" "}
                <a
                  className="link--purple"
                  href="https://www.wanadev.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Wanadev
                </a>{" "}
                <Link to={getPath("terms-of-use-unauthenticated") ?? ''}>
                  <span className="link--blue">
                    {t('Legal notice & Terms of use')}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
