import Release from "model/release";
import { stringify } from "qs";

import { raw } from "security/token";
import Logger from "services/logger";

export async function edit(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  release: Release
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> {
  let channels: string[] = [];

  if (release.channels !== null) {
    channels = Array.from(release.channels);
  }

  const response = await fetch(
    `${process.env.API || ""}/api/v1/releases/${release.id}`,
    {
      method: "PUT",
      headers: {
        "x-auth-token": raw() || "",
        "content-type": "application/x-www-form-urlencoded",
      },
      body: stringify({
        channels,
      }),
    }
  );
  const body = await response.json();

  if (response.status === 200) {
    return {
      success: true,
      data: response.body,
    };
  }

  return {
    success: false,
    errors: body.errors,
  };
}

interface DeleteAvailabilityOptions {
  forceLauncherUninstallation: boolean;
}

export function deleteAvailability(
  release: number,
  options?: DeleteAvailabilityOptions,
): Promise<Response | null> {
  let reqBody: string | undefined;

  if (options !== undefined) {
    reqBody = stringify({
      force_launcher_uninstallation: options.forceLauncherUninstallation.toString(),
    })
  }

  return fetch(
      `${process.env.API || ""}/api/v1/releases/${release}/availability`,
      {
        method: "DELETE",
        headers: {
          "x-auth-token": raw() || "",
          "content-type": "application/x-www-form-urlencoded",
        },
        body: reqBody,
      }
    ).catch(err => {
      Logger.error('An error has occurred while delete release availability', {
        err,
      })
      return Promise.resolve(null)
    })
}
