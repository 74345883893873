export default interface Game {
  id: number;
  key: string;
  editor: number;
  createdAt: string;
  description: string;
  name: string;
  latest: string | null;
  comfort: 'comfortable' | 'moderate' | 'intense' | null;
  warehouse: boolean;
  languages: string[];
  minimumAge: number;
  categories: string[];
  headsets: string[];
  internetConnectionRequired: boolean;
  spaces: GameSpaces[];
  production: boolean;
  thumbnail: string | null;
  maximumPlayersAllowed: number | null;
}

export enum GameSpaces {
  BOX = 'box',
  ROOM = 'room',
  HALF = 'half',
  LINE = 'line',
  ARENA = 'arena',
  WAREHOUSE = 'warehouse',
}
